import { Grid, Typography } from "@material-ui/core";
import React from "react";

function CompanyComplianceRisk({ data, classes }) {
  return (
    <Grid style={{ marginTop: "10px", marginLeft: "10px" }}>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Current Sanctions:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Former Sanctions:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Regulatory Enforcement List:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Adverse Media:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Insolvency Register:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Profile of Interest:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}>
          Current State-owned Enterprise:
        </span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}>Former State-owned Enterprise:</span>
        {"  "}
        {/* {data?.creditScore} */}
      </Typography>
    </Grid>
  );
}

export default CompanyComplianceRisk;
