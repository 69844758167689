import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../../../../UI/button/button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useEffect, useState } from "react";
import { gql_get_room_members } from "../../../../../../../redux/actions/threadActions/ThreadActions";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "Approved") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  } else if (status === "yet to assign") {
    colorToUse = "#676767";
    bgColorToUse = "#E9E9E9";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 7,
    textTransform: "capitalize",
    padding: "8px 12px 8px 6px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    // gap: "6px",
    borderBottom: "none",
  };
};

const SurveyDetailsHeader = ({
  classes,
  selectedQues,
  setIsDrawerOpen,
  contributor,
  setContributor,
  setRoomMembers,
  members,
  roomId,
  frequencyDetails,
}) => {
  const { roomMembers } = useSelector((state) => state.threadReducer);
  const { taskQuestions } = useSelector((state) => state.taskReducer);
  const [updatedQuestion, setUpdatedQuestion] = useState({});

  const dispatch = useDispatch();

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleBack = () => {
    setContributor(null);
  };

  useEffect(() => {
    dispatch(gql_get_room_members(roomId));
  }, [roomId]);

  useEffect(() => {
    setUpdatedQuestion(() => {
      return taskQuestions?.find(
        (q) => q?.questionId === selectedQues?.questionId
      );
    });
  }, [taskQuestions]);

  useEffect(() => {
    if (roomMembers && roomMembers.length > 0) {
      // console.log("setting Room Members", roomMembers);
      setRoomMembers(roomMembers);
    }
  }, [roomMembers]);

  // const handleStatus = () => {
  //   return !contributor
  //     ? "In Progress"
  //     : contributor && contributor?.isApproved === false
  //     ? "Awaiting Approval"
  //     : "Approved";
  // };

  const uniqueMembers = members?.reduce((acc, cur) => {
    if (!acc?.some((obj) => obj._id === cur._id)) {
      acc.push(cur);
    }

    return acc;
  }, []);

  const MakeCard = ({ heading, value, type }) => {
    const key = `${heading}-${value}`;

    return (
      <Box
        key={key}
        sx={{
          m: 0.5,
          width: "100%",
          //   height: "96px",
          borderRadius: "8px",
          padding: "8px 0px 8px 0px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            marginBottom: 10,
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "21px",
            color: "#15314E",
          }}
        >
          {heading}
        </Typography>
        {type === "status" ? (
          <Box
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              height: "24px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: value ? "#154328" : "#8C7038",
              backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            }}
          >
            <FiberManualRecordIcon
              style={{ width: 8, height: 8, marginRight: 5 }}
            />
            {value ? "Completed" : "Ongoing"}
          </Box>
        ) : type === "common" ? (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        ) : type === "popup-quoter" || type === "popup-monthly" ? (
          <Select
            value={""}
            onChange={(e) => {}}
            variant="outlined"
            classes={{ root: classes.rootSelect }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {value?.map((freq) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  //   backgroundColor:
                  //     dropdownValue ===
                  //     `${toDateYear(freq?.startDate)}} - ${toDateYear(
                  //       freq?.endDate
                  //     )}`
                  //       ? "#EBF1F8"
                  //       : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                onClick={{}}
                key={freq._id}
                // value={`${toDateYear(freq?.startDate)} - ${toDateYear(
                //   freq?.endDate
                // )}`}
              >
                {/* {toDateYear(freq?.startDate)} - {toDateYear(freq?.endDate)} */}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerInner}>
          <Typography style={{ fontWeight: 500 }}>Chat Module</Typography>
        </Box>

        <Box
          className={classes.headerInner}
          style={{ alignItems: "center", gap: "10px" }}
        >
          <CustomButton
            color="primary"
            size="small"
            className={classes.smallerButton}
          >
            <MoreVertIcon className={classes.customButtom} />
          </CustomButton>

          <CustomButton
            color="primary"
            size="small"
            className={classes.smallerButton}
            onClick={handleCloseDrawer}
          >
            <CloseIcon className={classes.customButtom} />
          </CustomButton>
        </Box>
      </Box>
      <Box
        sx={{
          //   m: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <MakeCard
          heading="Assessment Name"
          value={frequencyDetails?.surveyName}
          type={"common"}
        />
        <MakeCard
          heading="Current Frequency"
          value={frequencyDetails?.type?.replaceAll("_", " ")}
          type={"common"}
        />
        <MakeCard
          heading="Assessment Date"
          value={moment(frequencyDetails?.dateOfAssessment).format(
            "Do MMM, YYYY"
          )}
          type={"date"}
        />
        <MakeCard
          heading="Assessment Status"
          value={frequencyDetails?.isCompleted}
          type={"status"}
        />
        <MakeCard
          heading={"Current Score"}
          value={
            frequencyDetails?.score ? frequencyDetails?.score + "/100" : "-"
          }
          type={"score"}
        />
      </Box>

      {/*-------- SUB-HEADER ---------*/}

      {contributor ? (
        <Box className={classes.subHeading} style={{ gap: "5px" }}>
          <KeyboardArrowLeftIcon
            className={classes.icon}
            onClick={handleBack}
            style={{ cursor: "pointer" }}
          />
          <FormatListBulletedIcon className={classes.icon} />

          <Avatar
            alt={contributor?.sentBy?.name}
            src={contributor?.sentBy?.url}
            className={classes.avatar}
          />
          <Box>
            <Typography variant="h3" className={classes.name}>
              {contributor?.sentBy?.name}
            </Typography>
            <Typography variant="body1" className={classes.message}>
              {contributor?.text}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          className={classes.subHeading}
          style={{ borderTop: `1px solid #f0f0f0` }}
        >
          <Box className={classes.avatarContainer}>
            <AvatarGroup max={3} spacing={9}>
              {uniqueMembers?.map((member) => (
                <Avatar
                  alt={member?.name}
                  src={member?.profileImg?.url}
                  className={classes.avatar}
                />
              ))}
            </AvatarGroup>
          </Box>

          <Box>
            <Typography variant="body2">
              <strong>{uniqueMembers?.length} </strong>
              {uniqueMembers?.length > 1 ? "Members" : "Member"}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
export default SurveyDetailsHeader;
