import { Box, Button, Typography } from "@material-ui/core";
import Header from "../vendorIntake/Header";
import TerminationOverallScore from "./TerminationOverallScore";
import TerminationExitPlan from "./TerminationExitPlan";
import TerminationAlternateVendorOptions from "./TerminationAlternateVendorOptions";
import AssessmentTable from "../vendorIntake/AssessmentTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_survey_list_dataform } from "redux/actions/vendorActions/vendorAction";
import ThreadDrawer from "components/widgets/vendorAdmin/surveySteps/step2/threadDrawer/ThreadDrawer";
import AlertsDrawer from "../vendorIntake/AlertsDrawer";
import { ChartRenderer } from "components/widgets/charts/HistoricChart";

function TerminationAndOffboarding({ isFrom }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [frequencyDetails, setFrequencyDetails] = useState({});
  const [bankRoomId, setBankRoomId] = useState("");
  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const dispatch = useDispatch();

  const { surveyListDataForm, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );

  const surveyIds = surveyListDataForm?.["terminationSurveys"].map(
    (item) => item?._id
  );

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    if (isFrom !== "tprmRiskReport") {
      dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }
  }, []);

  const handleCloseDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "100%" : "63vh" }}>
      {isFrom === "tprmRiskReport" || (
        <Box
          sx={{
            mb: 2,
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Termination And Offboarding
          </Typography>

          <Button
            variant="text"
            style={{ color: "#3374B9", textTransform: "none" }}
            onClick={toggleAlertsDrawer}
          >
            Set Alerts
          </Button>
        </Box>
      )}

      <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
        {/* <Header />
        <br /> */}

        {isFrom === "tprmRiskReport" || (
          <>
            <AssessmentTable
              setIsDrawerOpen={setIsDrawerOpen}
              setFrequencyDetails={setFrequencyDetails}
              setBankRoomId={setBankRoomId}
              surveyIds={surveyIds}
            />
            <br />
          </>
        )}
        {/* <TerminationOverallScore /> */}
        <TerminationExitPlan />
        <br />
        <TerminationAlternateVendorOptions />
      </Box>

      {isDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          isFrom="vendorDiagnosis"
          frequencyDetails={frequencyDetails}
          bankRoomId={bankRoomId}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"TERMINATION_AND_OFFBOARDING"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={["Termination_And_Offboarding"]}
        />
      )}
    </Box>
  );
}

export default TerminationAndOffboarding;
