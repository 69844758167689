import React from "react";

import { Grid } from "@material-ui/core";
import success from "../../../../../images/Survey_Success_Vector.png";
import { NoDataComponent } from "../step1/right/SurveyStakeHolderList";

function FinalSubmission() {
  return (
    <Grid
      container
      style={{ height: "68vh", padding: "10px" }}
      justifyContent="space-between"
    >
      <Grid
        item
        md={12}
        style={{
          height: "100%",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 0px 8px 0px rgba(51, 116, 185, 0.25)",
        }}
      >
        <NoDataComponent
          imgSrc={success}
          mainTitle={"Response Submission Has been completed"}
          subTitle={
            "Please Proceed to Assessment Listing page to Complete Other assessments"
          }
        />
      </Grid>
    </Grid>
  );
}
export default FinalSubmission;
