import { Box, Typography } from "@material-ui/core";
import { FaCheck } from "react-icons/fa6";

const TaskLeftPanel = ({
  taskSections,
  activeSection,
  setActiveSection,
  setActiveSectionIndex,
}) => {
  const tabStyle = (isActive) => ({
    gap: 10,
    backgroundColor: isActive ? "#EBF1F8" : "transparent",
    borderRadius: "8px",
    padding: "16px",

    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  });

  const handleSectionClick = (selectedSection, index) => {
    setActiveSection(selectedSection);
    setActiveSectionIndex(index);
  };

  return (
    <Box style={{ width: "261px", height: "75vh", overflowY: "auto" }}>
      {taskSections?.map((tab, index) => (
        <Box
          key={tab?._id}
          onClick={() => handleSectionClick(tab, index)}
          style={tabStyle(activeSection?._id === tab?._id)}
        >
          <Box
            style={{
              backgroundColor:
                tab?.status?.toLowerCase() === "Completed"
                  ? "#32A05F"
                  : activeSection?._id === tab?._id
                  ? "#3374B9"
                  : "#F6F6F6",
              borderRadius: "20px",
              minWidth: "24px",
              minHeight: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {tab?.status?.toLowerCase() === "Completed" ? (
              <FaCheck color="#EBF1F8" />
            ) : (
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "11px",
                  lineHeight: "16px",
                  color:
                    activeSection?._id === tab?._id ? "#EBF1F8" : "#6C6C6C",
                }}
              >
                {index + 1}
              </Typography>
            )}
          </Box>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              color: activeSection === index ? "#15314E" : "#6C6C6C",
            }}
          >
            {tab?.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TaskLeftPanel;
