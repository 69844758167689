import { Box, useTheme } from "@material-ui/core";
import RenderNode from "components/widgets/v2/OrgDetails/OrgStructure/RenderNode";
import StructureSearch from "components/widgets/v2/OrgDetails/OrgStructure/StructureSearch";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
// icons
import greydotsBG from "images/orgstructure/greydotsBG.png";
import { useEffect } from "react";
import Tree from "react-d3-tree";
import { useSelector } from "react-redux";
import { gql_get_bank_structure } from "redux/actions/vendorActions/vendorOrgAction";
const OrgStructure = () => {
  const theme = useTheme();
  const { bank_structure } = useSelector((state) => state.vendorOrg);
  const {
    // states and data
    openDrawer,
    // funcs
    toggleDrawer,
  } = usePaginationSearch();

  useEffect(() => {
    gql_get_bank_structure();
  }, []);

  return (
    <Box
      width={"100%"}
      height={"70vh"}
      position={"relative"}
      style={{
        background: `url(${greydotsBG})`,
        backgroundColor: theme.palette.background.paper,
        backgroundSize: "70em",
      }}
    >
      {bank_structure && (
        <>
          <StructureSearch orgData={[bank_structure]} />
          <Tree
            data={bank_structure}
            orientation="vertical"
            pathFunc="step"
            renderCustomNodeElement={(rd3tProps) => (
              <RenderNode {...rd3tProps} onNodeClick={toggleDrawer} />
            )}
            nodeSize={{ x: 500, y: 500 }} // Adjusted node size
            translate={{ x: window.innerWidth / 2, y: 100 }}
            zoom={0.7}
          />
        </>
      )}
    </Box>
  );
};
export default OrgStructure;

// ------------------ Temp Data ------------------
const orgData = [
  {
    _id: "66d542b9bc7730af5288f4cf",
    entity_name: "Vaibhaw's Company",
    location: null,
    stakeholders: [
      {
        _id: "66d54197bc7730af5288f4cd",
        profile_img: {
          url: "https://i.pravatar.cc/300?img=3",
          name: "Avatar",
          type: "image/png",
        },
      },
      {
        _id: "66d6ca069be2af4ea8112d63",
        profile_img: {
          url: "https://imgrows.s3.us-east-2.amazonaws.com/usersInsights_1725352451766.png",
          name: "Insights.png",
          type: "image/png",
        },
      },
      {
        _id: "66dabfa8cdd4f66a68f4432e",
        profile_img: {
          url: "https://imgrows.s3.us-east-2.amazonaws.com/users99686_1725611942753.jpg",
          name: "99686.jpg",
          type: "image/jpeg",
        },
      },
    ],
    total_suppliers: 2,
    total_assets: 2,
    children: [
      {
        _id: "66d95eedcdd4f66a68f44326",
        name: "Vaibhaw's Company Child",
        location: {
          street_name: "1600 Fake Street",
          city_state: "Mountain View",
        },
        type: "ENTITY",
        total_suppliers: 1,
        total_assets: 0,
        stakeholders: [
          {
            _id: "66d95f5ccdd4f66a68f44328",
            profile_img: {
              url: "https://imgrows.s3.us-east-2.amazonaws.com/users279097_1725521754165.jpg",
              name: "279097.jpg",
              type: "image/jpeg",
            },
          },
        ],
        children: [
          {
            _id: "66d95f21cdd4f66a68f44327",
            name: "Company Child Unit",
            location: {
              street_name: "Rua Inexistente, 2000",
              city_state: "Belo Horizonte",
            },
            type: "UNIT",
            total_suppliers: 1,
            total_assets: 0,
            stakeholders: [
              {
                _id: "66d95fddcdd4f66a68f4432a",
                profile_img: {
                  url: "https://imgrows.s3.us-east-2.amazonaws.com/userscoding-wallpaper-wallpaper-collections-qlib86o7bz1fqbub_1725521884514.jpg",
                  name: "coding-wallpaper-wallpaper-collections-qlib86o7bz1fqbub.jpg",
                  type: "image/jpeg",
                },
              },
            ],
          },
        ],
      },
      {
        _id: "66d968fcc2bf5a71b4019a76",
        name: "Vaibhaw's Company Child 2",
        location: {
          street_name: "millat nagar road 01, near arif garden",
          city_state: "maharashtra",
        },
        type: "ENTITY",
        total_suppliers: 1,
        total_assets: 1,
        stakeholders: [
          {
            _id: "66d96944c2bf5a71b4019a77",
            profile_img: {
              url: "https://imgrows.s3.us-east-2.amazonaws.com/userssurvey_1725524291149.png",
              name: "survey.png",
              type: "image/png",
            },
          },
          {
            _id: "66d96997c2bf5a71b4019a78",
            profile_img: {
              url: "https://imgrows.s3.us-east-2.amazonaws.com/usersEsgWiseIconNew_1725524374316.png",
              name: "EsgWiseIconNew.png",
              type: "image/png",
            },
          },
          {
            _id: "671b2f9b8202f030248dea3e",
            profile_img: {
              url: "https://imgrows.s3.us-east-2.amazonaws.com/usersdatahub_1729834906487.png",
              name: "datahub.png",
              type: "image/png",
            },
          },
        ],
        children: [],
      },
      {
        _id: "66d6c67bf5e9e37585433557",
        name: "Test Unit 1",
        location: {
          street_name: "Innovation Road",
          city_state: "Tech City, Test",
        },
        type: "UNIT",
        total_suppliers: 1,
        total_assets: 0,
        stakeholders: [
          {
            _id: "66d95fa6cdd4f66a68f44329",
            profile_img: {
              url: "",
              name: "1328865.png",
              type: "image/png",
            },
          },
        ],
      },
      {
        _id: "66dabf25cdd4f66a68f4432b",
        name: "Baibhav Unit 1",
        location: {
          street_name: "1600 Fake Street",
          city_state: "Mountain View",
        },
        type: "UNIT",
        total_suppliers: 0,
        total_assets: 2,
        stakeholders: [
          {
            _id: "66dabfd2cdd4f66a68f4432f",
            profile_img: {
              url: "https://imgrows.s3.us-east-2.amazonaws.com/users99686_1725611984742.jpg",
              name: "99686.jpg",
              type: "image/jpeg",
            },
          },
        ],
      },
    ],
  },
];
