
export const assignedSections = [
    {
        "_id": "659fb3dff62b501a0bb1354d",
        "title": "Self Assessment Section",
        "default": true,
        "active": true,
        "order": 1,
        "last_published_by": "63e20b1959c56d85c5910042",
        "last_published_on": "2024-02-01T09:15:26.192Z",
        "survey_id": "65ba207c058e57a4a9a37b35",
        "is_published": true,
        "version": 1
    },
    {
        "_id": "659fb470f62b501a0bb1354e",
        "title": "Vendor Survey",
        "default": true,
        "active": true,
        "order": 2,
        "last_published_by": "63e20b1959c56d85c5910042",
        "last_published_on": "2024-02-01T09:15:26.192Z",
        "survey_id": "65ba207c058e57a4a9a37b35",
        "is_published": true,
        "version": 1
    }
]

export const assigned1 = {
    "questions": [
        {
            "_id": "65a0c56dc1b4c84b328c9708",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well has your company been able to adapt its business model in response to environmental and social changes?",
            "answers": [
                {
                    "option": "Very Poorly",
                    "weightage": 1
                },
                {
                    "option": "Poorly",
                    "weightage": 2
                },
                {
                    "option": "Somewhat Poorly",
                    "weightage": 3
                },
                {
                    "option": "Somewhat Well ",
                    "weightage": 4
                },
                {
                    "option": "Very Well",
                    "weightage": 5
                }
            ],
            "order": 1,
            "weightage": 6,
            "question_type": "Satisfaction",
            "active": true,
            "edited_at": "2024-01-18T09:19:29.665Z",
            "edited_by": "63e20b1959c56d85c5910042",
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Business Model Resilience"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9709",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well does your company’s current business model take into account environmental and social changes that may occur in the future?",
            "answers": [
                {
                    "option": "Very Poorly",
                    "weightage": 1
                },
                {
                    "option": "Poorly",
                    "weightage": 2
                },
                {
                    "option": "Somewhat Poorly",
                    "weightage": 3
                },
                {
                    "option": "Somewhat Well ",
                    "weightage": 4
                },
                {
                    "option": "Very Well",
                    "weightage": 5
                }
            ],
            "order": 2,
            "weightage": 5,
            "question_type": "Satisfaction",
            "active": true,
            "edited_at": "2024-01-15T13:10:50.155Z",
            "edited_by": "63e20b1959c56d85c5910042",
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Business Model Resilience"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970a",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "To what extent has your company assessed the risks of climate change and other external environmental and social factors to its materials supply chains?",
            "answers": [
                {
                    "option": "We have not assessed the risks at all",
                    "weightage": 1
                },
                {
                    "option": "We have assessed the risks, but have not taken any action to mitigate them",
                    "weightage": 2
                },
                {
                    "option": "We have assessed the risks and have taken some action to mitigate them",
                    "weightage": 4
                },
                {
                    "option": "We have assessed the risks and have taken significant action to mitigate them",
                    "weightage": 5
                }
            ],
            "order": 3,
            "weightage": 5,
            "question_type": "Satisfaction",
            "active": true,
            "edited_at": "2024-01-16T08:43:50.411Z",
            "edited_by": "63e20b1959c56d85c5910042",
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Materials Sourcing & Efficiency"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970b",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "To what extent has your company taken action to reduce the use of key materials?",
            "answers": [
                {
                    "option": "We have not taken any action",
                    "weightage": 1
                },
                {
                    "option": "We have taken some action, but it has not been very effective\t",
                    "weightage": 2
                },
                {
                    "option": "We have taken action and it has been somewhat effective\t",
                    "weightage": 4
                },
                {
                    "option": "We have taken action and it has been very effective\t",
                    "weightage": 5
                }
            ],
            "order": 4,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Materials Sourcing & Efficiency"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970c",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "To what extent has your company taken action to make R&D investments in substitute materials?",
            "answers": [
                {
                    "option": "We have not taken any action",
                    "weightage": 1
                },
                {
                    "option": "We have taken some action, but it has not been very effective",
                    "weightage": 2
                },
                {
                    "option": "We have taken action and it has been somewhat effective",
                    "weightage": 4
                },
                {
                    "option": "We have taken action and it has been very effective",
                    "weightage": 5
                }
            ],
            "order": 5,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Materials Sourcing & Efficiency"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970d",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well is your company able to adapt to increased frequency and severity of extreme weather, shifting climate, sea level risk, and other expected physical impacts of climate change?",
            "answers": [
                {
                    "option": "Very Poorly",
                    "weightage": 1
                },
                {
                    "option": "Poorly",
                    "weightage": 2
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Well",
                    "weightage": 4
                },
                {
                    "option": "Very Well",
                    "weightage": 5
                }
            ],
            "order": 6,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Physical Impacts of Climate Change"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970e",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Do you agree that the company's Product Design & Lifecycle Management practices are environmentally and socially responsible?",
            "answers": [
                {
                    "option": "Very Responsible",
                    "weightage": 1
                },
                {
                    "option": "Somewhat Responsible",
                    "weightage": 2
                },
                {
                    "option": "Neutral / No Opinion",
                    "weightage": 3
                },
                {
                    "option": "Somewhat Irresponsible ",
                    "weightage": 4
                },
                {
                    "option": "Very Irresponsible",
                    "weightage": 5
                }
            ],
            "order": 7,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Product Design & Lifecycle Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c970f",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How important are environmental, social, and governance (ESG) considerations in the product design and lifecycle management of your company?",
            "answers": [
                {
                    "option": "Not at all important",
                    "weightage": 1
                },
                {
                    "option": "Slightly important",
                    "weightage": 2
                },
                {
                    "option": "Moderately important ",
                    "weightage": 3
                },
                {
                    "option": "Very important",
                    "weightage": 4
                },
                {
                    "option": "Extremely important",
                    "weightage": 5
                }
            ],
            "order": 8,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Product Design & Lifecycle Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9710",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How much does your company focus on reducing environmental and social risks in your supply chain?",
            "answers": [
                {
                    "option": "We don't really focus on it",
                    "weightage": 1
                },
                {
                    "option": "We focus on it a little bit",
                    "weightage": 2
                },
                {
                    "option": "We focus on it somewhat ",
                    "weightage": 4
                },
                {
                    "option": "We focus on it a great deal  ",
                    "weightage": 5
                }
            ],
            "order": 9,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Supply Chain Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9711",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How much does your company focus on promoting good labor practices in your supply chain?",
            "answers": [
                {
                    "option": "We don't really focus on it",
                    "weightage": 1
                },
                {
                    "option": "We focus on it a little bit",
                    "weightage": 2
                },
                {
                    "option": "We focus on it somewhat ",
                    "weightage": 4
                },
                {
                    "option": "We focus on it a great deal  ",
                    "weightage": 5
                }
            ],
            "order": 10,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Business Model & Innovation",
            "category_suggestion": "Supply Chain Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9712",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "The company has a comprehensive air quality management system that is designed to ensure compliance with all relevant laws and regulations:",
            "answers": [
                {
                    "option": "Strongly agree ",
                    "weightage": 1
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Neutral ",
                    "weightage": 3
                },
                {
                    "option": "Disagree  ",
                    "weightage": 4
                },
                {
                    "option": "Strongly disagree ",
                    "weightage": 5
                }
            ],
            "order": 11,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "Air Quality"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9713",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How much does your company consider ecological impacts when making decisions about land use for exploration, natural resource extraction, and cultivation?",
            "answers": [
                {
                    "option": "We never consider ecological impacts when making these decisions ",
                    "weightage": 1
                },
                {
                    "option": "We sometimes consider ecological impacts when making these decisions",
                    "weightage": 2
                },
                {
                    "option": "We always consider ecological impacts when making these decisions ",
                    "weightage": 3
                },
                {
                    "option": "We consider ecological impacts when making these decisions, but only after other factors have been considered  ",
                    "weightage": 4
                },
                {
                    "option": "We consider ecological impacts when making these decisions, and they are given equal weight to other factors ",
                    "weightage": 5
                }
            ],
            "order": 12,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "Ecological Impacts"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9714",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's level of maturity in Energy Management?",
            "answers": [
                {
                    "option": "We are in the early stages of Energy Management and are just beginning to implement some energy efficiency measures. ",
                    "weightage": 1
                },
                {
                    "option": "We have implemented some energy efficiency measures and have started to look at our energy mix.",
                    "weightage": 2
                },
                {
                    "option": "We have a good understanding of our energy use and have implemented a variety of energy efficiency measures. We are also starting to look at our energy mix. ",
                    "weightage": 3
                },
                {
                    "option": "We have a good understanding of our energy use and have implemented a variety of energy efficiency measures. We have also looked at our energy mix and are starting to make changes.  ",
                    "weightage": 4
                },
                {
                    "option": "We have a comprehensive Energy Management strategy that includes energy efficiency measures, management ",
                    "weightage": 5
                }
            ],
            "order": 13,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "Energy Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9715",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How much experience does your company have in measuring and managing GHG emissions from its operations?",
            "answers": [
                {
                    "option": "We have just started measuring our GHG emissions. ",
                    "weightage": 1
                },
                {
                    "option": "We have been measuring our GHG emissions for a few years.",
                    "weightage": 2
                },
                {
                    "option": "We have been measuring and managing our GHG emissions for many years. ",
                    "weightage": 4
                },
                {
                    "option": "We have been measuring and managing our GHG emissions for decades.  ",
                    "weightage": 5
                }
            ],
            "order": 14,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "GHG Emissions"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9716",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's maturity level on Waste & Hazardous Materials Management?",
            "answers": [
                {
                    "option": "We have just started to address this issue and have a long way to go.\t ",
                    "weightage": 1
                },
                {
                    "option": "We are making some progress but still have a lot of room for improvement.\t",
                    "weightage": 2
                },
                {
                    "option": "We are managing our waste and hazardous materials well but could still do more.\t ",
                    "weightage": 3
                },
                {
                    "option": "We are effectively managing our waste and hazardous materials and are always looking for ways to improve.\t  ",
                    "weightage": 4
                },
                {
                    "option": "We are leaders in managing our waste and hazardous materials and are constantly innovating to find new and better ways to do so.\t ",
                    "weightage": 5
                }
            ],
            "order": 15,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "Waste & Hazardous Materials Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9717",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's Water & Wastewater Management practices?",
            "answers": [
                {
                    "option": "Very Poor ",
                    "weightage": 1
                },
                {
                    "option": "Poor",
                    "weightage": 2
                },
                {
                    "option": "fair ",
                    "weightage": 3
                },
                {
                    "option": "Good  ",
                    "weightage": 4
                },
                {
                    "option": "Very Good ",
                    "weightage": 5
                }
            ],
            "order": 16,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Environment",
            "category_suggestion": "Water & Wastewater Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9718",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How important is Employee Engagement, Diversity & Inclusion to your company's success?",
            "answers": [
                {
                    "option": "Very Important ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat Important",
                    "weightage": 3
                },
                {
                    "option": "Not Important ",
                    "weightage": 1
                }
            ],
            "order": 17,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human Capital",
            "category_suggestion": "Employee Engagement, Diversity & Inclusion"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9719",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How diverse is your company's workforce?",
            "answers": [
                {
                    "option": "Not at all diverse ",
                    "weightage": 1
                },
                {
                    "option": "Somewhat diverse",
                    "weightage": 3
                },
                {
                    "option": "Very diverse ",
                    "weightage": 5
                }
            ],
            "order": 18,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human Capital",
            "category_suggestion": "Employee Engagement, Diversity & Inclusion"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971a",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How often are Employee Engagement, Diversity & Inclusion issues discussed at your company?",
            "answers": [
                {
                    "option": "Often ",
                    "weightage": 5
                },
                {
                    "option": "Sometimes",
                    "weightage": 3
                },
                {
                    "option": "Rarely ",
                    "weightage": 1
                }
            ],
            "order": 19,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human Capital",
            "category_suggestion": "Employee Engagement, Diversity & Inclusion"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971b",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "On a scale of 1-5, how would you rate your company's Employee Health & Safety policies and procedures?",
            "answers": [
                {
                    "option": "Needs Improvement ",
                    "weightage": 1
                },
                {
                    "option": "Satisfactory",
                    "weightage": 2
                },
                {
                    "option": "Good ",
                    "weightage": 3
                },
                {
                    "option": "Very Good  ",
                    "weightage": 4
                },
                {
                    "option": "Excellent ",
                    "weightage": 5
                }
            ],
            "order": 20,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human Capital",
            "category_suggestion": "Employee Health & Safety"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971c",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "On a scale of 1 to 5, how would you rate the maturity level of your company on Labor Practices?",
            "answers": [
                {
                    "option": "My company does not uphold commonly accepted labor standards in the workplace and does not comply with labor laws or internationally accepted norms and standards.\t ",
                    "weightage": 1
                },
                {
                    "option": "My company upholds some commonly accepted labor standards in the workplace, but does not comply with all labor laws or internationally accepted norms and standards.\t",
                    "weightage": 2
                },
                {
                    "option": "My company upholds most commonly accepted labor standards in the workplace and compliance with most labor laws or internationally accepted norms and standards.\t ",
                    "weightage": 3
                },
                {
                    "option": "My company upholds all commonly accepted labor standards in the workplace and compliance with all labor laws or internationally accepted norms and standards.\t  ",
                    "weightage": 4
                },
                {
                    "option": "My company goes above and beyond commonly accepted labor standards in the workplace and compliance with labor laws or internationally accepted norms and standards.\t ",
                    "weightage": 5
                }
            ],
            "order": 21,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human Capital",
            "category_suggestion": "Business Ethics"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971d",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's maturity level on Business Ethics?",
            "answers": [
                {
                    "option": "We have no formal policies or procedures in place regarding Business Ethics. ",
                    "weightage": 1
                },
                {
                    "option": "We have some formal policies and procedures in place regarding Business Ethics, but they are not regularly followed or enforced.",
                    "weightage": 2
                },
                {
                    "option": "We have formal policies and procedures in place regarding Business Ethics, and they are followed and enforced on a regular basis. ",
                    "weightage": 3
                },
                {
                    "option": "We have formal policies and procedures in place regarding Business Ethics, and they are followed and enforced on a regular basis. We also have a Business Ethics Officer who monitors compliance and investigates any potential violations.  ",
                    "weightage": 4
                },
                {
                    "option": "We have formal policies and procedures in place regarding Business Ethics, and they are followed and enforced on a regular basis. We also have a Business Ethics Officer who monitors compliance and investigates any potential violations. We also have an Ethics Hotline where employees can report any potential violations anonymously. ",
                    "weightage": 5
                }
            ],
            "order": 22,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Business Ethics"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971e",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's management of legal and social expectation around monopolistic and anti-competitive practices?",
            "answers": [
                {
                    "option": "Very Poor ",
                    "weightage": 1
                },
                {
                    "option": "Poor",
                    "weightage": 2
                },
                {
                    "option": "fair ",
                    "weightage": 3
                },
                {
                    "option": "Good  ",
                    "weightage": 4
                },
                {
                    "option": "Very Good ",
                    "weightage": 5
                }
            ],
            "order": 23,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Competitive Behavior"
        },
        {
            "_id": "65a0c56dc1b4c84b328c971f",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's maturity level on Critical Incident Risk Management?",
            "answers": [
                {
                    "option": "We don't have any formal systems or processes in place to identify, understand, or prevent high-impact accidents and emergencies. ",
                    "weightage": 1
                },
                {
                    "option": "We have some informal systems and processes in place to identify, understand, or prevent high-impact accidents and emergencies, but they are not well-defined or consistently followed.",
                    "weightage": 2
                },
                {
                    "option": "We have formal systems and processes in place to identify, understand, or prevent high-impact accidents and emergencies, but they could be improved. ",
                    "weightage": 3
                },
                {
                    "option": "We have formal systems and processes in place to identify, understand, or prevent high-impact accidents and emergencies, and they are regularly followed and regularly reviewed for effectiveness.  ",
                    "weightage": 4
                },
                {
                    "option": "We have formal systems and processes in place to identify, understand, or prevent high-impact accidents and emergencies, and they are regularly followed and regularly reviewed for effectiveness, with improvements made on a continual basis. ",
                    "weightage": 5
                }
            ],
            "order": 24,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Critical Incident Risk Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9720",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How would you rate your company's management of the legal and regulatory environment?",
            "answers": [
                {
                    "option": "We have a clear and well-defined policy on how to engage with regulators in cases where conflicting corporate and public interests may have the potential for long-term adverse direct or indirect environmental and social impacts. ",
                    "weightage": 5
                },
                {
                    "option": "We rely on regulatory policy or monetary incentives (such as subsidies and taxes) to influence our approach to this issue.",
                    "weightage": 4
                },
                {
                    "option": "We rely heavily on a favourable regulatory environment for business competitiveness, and our ability to comply with relevant regulations is strong. ",
                    "weightage": 3
                },
                {
                    "option": "Our management and investor views on regulatory engagement and compliance are aligned.",
                    "weightage": 2
                },
                {
                    "option": "We do not have a clear policy on this issue, and our management and investor views on regulatory engagement and compliance are not aligned. ",
                    "weightage": 1
                }
            ],
            "order": 25,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Management of the Legal & Regulatory Environment"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9721",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well does your company's risk management reduce its contributions to systemic risks?",
            "answers": [
                {
                    "option": "Very well ",
                    "weightage": 5
                },
                {
                    "option": "Well",
                    "weightage": 4
                },
                {
                    "option": "Somewhat well ",
                    "weightage": 2
                },
                {
                    "option": "Not well  ",
                    "weightage": 1
                }
            ],
            "order": 26,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Systemic Risk Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9722",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well does your company's risk management improve safeguards against systemic risks?",
            "answers": [
                {
                    "option": "Very well ",
                    "weightage": 5
                },
                {
                    "option": "Well",
                    "weightage": 4
                },
                {
                    "option": "Somewhat well ",
                    "weightage": 2
                },
                {
                    "option": "Not well  ",
                    "weightage": 1
                }
            ],
            "order": 27,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Systemic Risk Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9723",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well does your company's risk management protect against financial and economic stress?",
            "answers": [
                {
                    "option": "Very well ",
                    "weightage": 5
                },
                {
                    "option": "Well",
                    "weightage": 4
                },
                {
                    "option": "Somewhat well ",
                    "weightage": 2
                },
                {
                    "option": "Not well  ",
                    "weightage": 1
                }
            ],
            "order": 28,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Leadership & Governance",
            "category_suggestion": "Systemic Risk Management"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9724",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Do you think that the company's products and services are accessible to underserved markets and population groups?",
            "answers": [
                {
                    "option": "Very Likely ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat Likely",
                    "weightage": 4
                },
                {
                    "option": "Neutral ",
                    "weightage": 3
                },
                {
                    "option": "Somewhat Unlikely  ",
                    "weightage": 2
                },
                {
                    "option": "Very Unlikely ",
                    "weightage": 1
                }
            ],
            "order": 29,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Access & Affordability"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9725",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Do you think that the company is doing enough to ensure that its products and services are accessible and affordable to underserved markets and population groups?",
            "answers": [
                {
                    "option": "Very Likely ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat Likely",
                    "weightage": 4
                },
                {
                    "option": "Neutral ",
                    "weightage": 3
                },
                {
                    "option": "Somewhat Unlikely  ",
                    "weightage": 2
                },
                {
                    "option": "Very Unlikely ",
                    "weightage": 1
                }
            ],
            "order": 30,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Access & Affordability"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9726",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How mature is your company in managing risks related to the use of personally identifiable information (PII) and other customer or user data for secondary purposes?",
            "answers": [
                {
                    "option": "We have very little understanding of the risks and do not have any policies or procedures in place to mitigate these risks. ",
                    "weightage": 1
                },
                {
                    "option": "We have a basic understanding of the risks and have some policies and procedures in place to mitigate these risks.",
                    "weightage": 2
                },
                {
                    "option": "We have a good understanding of the risks and have comprehensive policies and procedures in place to mitigate these risks. ",
                    "weightage": 3
                },
                {
                    "option": "We have a excellent understanding of the risks and have robust policies and procedures in place to mitigate these risks.  ",
                    "weightage": 4
                },
                {
                    "option": "We have a world-class understanding of the risks and have leading-edge policies and procedures in place to mitigate these risks. ",
                    "weightage": 5
                }
            ],
            "order": 31,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Customer Privacy"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9727",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How well does the company address customer welfare concerns over issues including, but not limited to, health and nutrition of foods and beverages, antibiotic use in animal production, and management of controlled substances?",
            "answers": [
                {
                    "option": "Poorly ",
                    "weightage": 1
                },
                {
                    "option": "Fairly",
                    "weightage": 2
                },
                {
                    "option": "Adequately ",
                    "weightage": 3
                },
                {
                    "option": "Well  ",
                    "weightage": 4
                },
                {
                    "option": "Very well ",
                    "weightage": 5
                }
            ],
            "order": 32,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Customer Welfare"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9728",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How effective do you feel your company is at managing risks related to customer or user data?",
            "answers": [
                {
                    "option": "Very effective ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat effective",
                    "weightage": 4
                },
                {
                    "option": "Not very effective ",
                    "weightage": 2
                },
                {
                    "option": "Not at all effective  ",
                    "weightage": 1
                }
            ],
            "order": 33,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Data Security"
        },
        {
            "_id": "65a0c56dc1b4c84b328c9729",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How secure does your company keep customer or user data?",
            "answers": [
                {
                    "option": "Very secure ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat secure",
                    "weightage": 4
                },
                {
                    "option": "Not very secure ",
                    "weightage": 2
                },
                {
                    "option": "Not at all secure  ",
                    "weightage": 1
                }
            ],
            "order": 34,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Data Security"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972a",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Does the company identify and assess impacts on local communities, including impacts on core human rights and indigenous peoples?",
            "answers": [
                {
                    "option": "Strongly agree ",
                    "weightage": 5
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Neither agree nor disagree ",
                    "weightage": 3
                },
                {
                    "option": "Disagree  ",
                    "weightage": 2
                },
                {
                    "option": "Strongly disagree ",
                    "weightage": 1
                }
            ],
            "order": 35,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Human Rights & Community Relations"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972b",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Does the company engage with local communities to understand their perspectives and needs?",
            "answers": [
                {
                    "option": "Strongly agree ",
                    "weightage": 5
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Neither agree nor disagree ",
                    "weightage": 3
                },
                {
                    "option": "Disagree  ",
                    "weightage": 2
                },
                {
                    "option": "Strongly disagree ",
                    "weightage": 1
                }
            ],
            "order": 36,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Human Rights & Community Relations"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972c",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "Does the company proactively seek to create positive impacts on local communities?",
            "answers": [
                {
                    "option": "Strongly agree ",
                    "weightage": 5
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Neither agree nor disagree ",
                    "weightage": 3
                },
                {
                    "option": "Disagree  ",
                    "weightage": 2
                },
                {
                    "option": "Strongly disagree ",
                    "weightage": 1
                }
            ],
            "order": 37,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Human Rights & Community Relations"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972d",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How effective is your company at ensuring product quality and safety?",
            "answers": [
                {
                    "option": "Very effective ",
                    "weightage": 5
                },
                {
                    "option": "Somewhat effective",
                    "weightage": 4
                },
                {
                    "option": "Not very effective ",
                    "weightage": 2
                },
                {
                    "option": "Not at all effective  ",
                    "weightage": 1
                }
            ],
            "order": 38,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Product Quality & Safety"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972e",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "How often do you test your products for quality and safety?",
            "answers": [
                {
                    "option": "Always ",
                    "weightage": 5
                },
                {
                    "option": "Often",
                    "weightage": 4
                },
                {
                    "option": "Sometimes ",
                    "weightage": 3
                },
                {
                    "option": "Rarely  ",
                    "weightage": 2
                },
                {
                    "option": "Never ",
                    "weightage": 1
                }
            ],
            "order": 39,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Product Quality & Safety"
        },
        {
            "_id": "65a0c56dc1b4c84b328c972f",
            "section_id": "659fb3dff62b501a0bb1354d",
            "question": "To what extent do you agree that the company's selling practices & product labeling are ethical and responsible?",
            "answers": [
                {
                    "option": "Strongly agree ",
                    "weightage": 5
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Neither agree nor disagree ",
                    "weightage": 3
                },
                {
                    "option": "Disagree  ",
                    "weightage": 2
                },
                {
                    "option": "Strongly disagree ",
                    "weightage": 1
                }
            ],
            "order": 40,
            "weightage": 1,
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Social Capital",
            "category_suggestion": "Selling Practices & Product Labeling"
        }
    ],
    "submit_answers": [
        {
            "question_id": "65a0c56dc1b4c84b328c9708",
            "answer": {
                "option": "Poorly",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "65a0c56dc1b4c84b328c9709",
            "answer": {
                "option": "Poorly",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970a",
            "answer": {
                "option": "We have assessed the risks, but have not taken any action to mitigate them",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970b",
            "answer": {
                "option": "We have taken some action, but it has not been very effective\t",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970c",
            "answer": {
                "option": "We have not taken any action",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970d",
            "answer": {
                "option": "Neutral",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970e",
            "answer": {
                "option": "Somewhat Irresponsible ",
                "weightage": 4
            },
            "answer_weightage": 4
        },
        {
            "question_id": "65a0c56dc1b4c84b328c970f",
            "answer": {
                "option": "Very important",
                "weightage": 4
            },
            "answer_weightage": 4
        },
        {
            "question_id": "65a0c56dc1b4c84b328c9710",
            "answer": {
                "option": "We focus on it somewhat ",
                "weightage": 4
            },
            "answer_weightage": 4
        },
        {
            "question_id": "65a0c56dc1b4c84b328c9711",
            "answer": {
                "option": "We don't really focus on it",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "65a0c56dc1b4c84b328c9712",
            "answer": {
                "option": "Neutral ",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "65a0c56dc1b4c84b328c9713",
            "answer": {
                "option": "We consider ecological impacts when making these decisions, but only after other factors have been considered  ",
                "weightage": 4
            },
            "answer_weightage": 4
        }
    ]
}

export const assigned2 = {
    "questions": [
        {
            "_id": "659fbea9f62b501a0bb1355a",
            "order": 1,
            "question": "How concerned are you about controversies linked to responsible R&D in our company?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 9,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        },
        {
            "_id": "659fbea9f62b501a0bb13561",
            "order": 2,
            "question": "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 6,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Resource use"
        },
        {
            "_id": "659fbea9f62b501a0bb13568",
            "order": 3,
            "question": "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
            "answers": [
                {
                    "option": "Strongly disagree",
                    "weightage": 1
                },
                {
                    "option": "Disagree",
                    "weightage": 2
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Strongly agree",
                    "weightage": 5
                }
            ],
            "weightage": 3,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Workforce"
        },
        {
            "_id": "659fbea9f62b501a0bb1355c",
            "order": 4,
            "question": "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
            "answers": [
                {
                    "option": "Strongly Disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly Agree",
                    "weightage": 1
                }
            ],
            "weightage": 1,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Workforce"
        },
        {
            "_id": "659fbea9f62b501a0bb13558",
            "order": 5,
            "question": "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
            "answers": [
                {
                    "option": "Not concerned at all",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 7,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human rights"
        },
        {
            "_id": "659fbea9f62b501a0bb1355d",
            "order": 6,
            "question": "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
            "answers": [
                {
                    "option": "Strongly disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly agree",
                    "weightage": 1
                }
            ],
            "weightage": 2,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Management"
        },
        {
            "_id": "659fbea9f62b501a0bb13554",
            "order": 7,
            "question": "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 3,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Workforce"
        },
        {
            "_id": "659fbea9f62b501a0bb13559",
            "order": 8,
            "question": "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
            "answers": [
                {
                    "option": "Never",
                    "weightage": 5
                },
                {
                    "option": "Rarely",
                    "weightage": 4
                },
                {
                    "option": "Sometimes",
                    "weightage": 3
                },
                {
                    "option": "Often",
                    "weightage": 2
                },
                {
                    "option": "Almost always",
                    "weightage": 1
                }
            ],
            "weightage": 8,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Workforce"
        },
        {
            "_id": "659fbea9f62b501a0bb13564",
            "order": 9,
            "question": "\"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing.\"",
            "answers": [
                {
                    "option": "Strongly Disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly Agree",
                    "weightage": 1
                }
            ],
            "weightage": 9,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13562",
            "order": 10,
            "question": "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Greatly concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 7,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13555",
            "order": 11,
            "question": "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
            "answers": [
                {
                    "option": "Strongly Disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly Agree",
                    "weightage": 1
                }
            ],
            "weightage": 4,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Shareholders"
        },
        {
            "_id": "659fbea9f62b501a0bb13565",
            "order": 12,
            "question": "How much do you agree with the following statement: \"There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.",
            "answers": [
                {
                    "option": "Strongly Disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly Agree",
                    "weightage": 1
                }
            ],
            "weightage": 10,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13553",
            "order": 13,
            "question": "How often do you come across controversies related to patents and intellectual property infringements?",
            "answers": [
                {
                    "option": "Rarely",
                    "weightage": 5
                },
                {
                    "option": "Occasionally",
                    "weightage": 4
                },
                {
                    "option": "Sometimes",
                    "weightage": 3
                },
                {
                    "option": "Frequently",
                    "weightage": 2
                },
                {
                    "option": "Almost always",
                    "weightage": 1
                }
            ],
            "weightage": 2,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13552",
            "order": 14,
            "question": "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 5,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "edited_at": "2024-01-16T08:41:41.849Z",
            "edited_by": "63e20b1959c56d85c5910042",
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13556",
            "order": 15,
            "question": "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
            "answers": [
                {
                    "option": "Not concerned at all",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Moderately concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 5,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Shareholders"
        },
        {
            "_id": "659fbea9f62b501a0bb13566",
            "order": 16,
            "question": "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
            "answers": [
                {
                    "option": "Strongly disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly agree",
                    "weightage": 1
                }
            ],
            "weightage": 1,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Shareholders"
        },
        {
            "_id": "659fbea9f62b501a0bb13563",
            "order": 17,
            "question": "How often do you believe that access to a product has been associated with controversies?",
            "answers": [
                {
                    "option": "Never",
                    "weightage": 5
                },
                {
                    "option": "Rarely",
                    "weightage": 4
                },
                {
                    "option": "Sometimes",
                    "weightage": 3
                },
                {
                    "option": "Often",
                    "weightage": 2
                },
                {
                    "option": "Almost always",
                    "weightage": 1
                }
            ],
            "weightage": 8,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        },
        {
            "_id": "659fbea9f62b501a0bb1355f",
            "order": 18,
            "question": "How many controversies have been linked to employees or customers in the past year?",
            "answers": [
                {
                    "option": "None",
                    "weightage": 5
                },
                {
                    "option": "A few",
                    "weightage": 4
                },
                {
                    "option": "Some",
                    "weightage": 3
                },
                {
                    "option": "Many",
                    "weightage": 2
                },
                {
                    "option": "A lot",
                    "weightage": 1
                }
            ],
            "weightage": 4,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        },
        {
            "_id": "659fbea9f62b501a0bb1355b",
            "order": 19,
            "question": "How concerned are you about the number of controversies linked to customer health and safety at this company?",
            "answers": [
                {
                    "option": "Not concerned at all",
                    "weightage": 5
                },
                {
                    "option": "Mildly concerned",
                    "weightage": 4
                },
                {
                    "option": "Somewhat concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 10,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        },
        {
            "_id": "659fbea9f62b501a0bb13560",
            "order": 20,
            "question": "How strongly do you agree with the statement: \"The company should prioritize avoiding controversies in countries that do not respect human rights principles\"?",
            "answers": [
                {
                    "option": "Strongly agree",
                    "weightage": 5
                },
                {
                    "option": "Agree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Disagree",
                    "weightage": 2
                },
                {
                    "option": "Strongly disagree",
                    "weightage": 1
                }
            ],
            "weightage": 5,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Community"
        },
        {
            "_id": "659fbea9f62b501a0bb13567",
            "order": 21,
            "question": "How concerned are you about the number of controversies linked to human rights issues within the company?",
            "answers": [
                {
                    "option": "Not at all concerned",
                    "weightage": 5
                },
                {
                    "option": "Slightly concerned",
                    "weightage": 4
                },
                {
                    "option": "Somewhat concerned",
                    "weightage": 3
                },
                {
                    "option": "Very concerned",
                    "weightage": 2
                },
                {
                    "option": "Extremely concerned",
                    "weightage": 1
                }
            ],
            "weightage": 2,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Human rights"
        },
        {
            "_id": "659fbea9f62b501a0bb1355e",
            "order": 22,
            "question": "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
            "answers": [
                {
                    "option": "Strongly disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly agree",
                    "weightage": 1
                }
            ],
            "weightage": 3,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        },
        {
            "_id": "659fbea9f62b501a0bb13557",
            "order": 23,
            "question": "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
            "answers": [
                {
                    "option": "Strongly disagree",
                    "weightage": 5
                },
                {
                    "option": "Disagree",
                    "weightage": 4
                },
                {
                    "option": "Neutral",
                    "weightage": 3
                },
                {
                    "option": "Agree",
                    "weightage": 2
                },
                {
                    "option": "Strongly agree",
                    "weightage": 1
                }
            ],
            "weightage": 6,
            "section_id": "659fb470f62b501a0bb1354e",
            "question_type": "Satisfaction",
            "active": true,
            "version": 1,
            "category": "Product responsibility"
        }
    ],
    "submit_answers": [
        {
            "question_id": "659fbea9f62b501a0bb1355a",
            "answer": {
                "option": "Moderately concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13561",
            "answer": {
                "option": "Very concerned",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "659fbea9f62b501a0bb13568",
            "answer": {
                "option": "Neutral",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb1355c",
            "answer": {
                "option": "Strongly Agree",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13558",
            "answer": {
                "option": "Moderately concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb1355d",
            "answer": {
                "option": "Strongly agree",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13554",
            "answer": {
                "option": "Extremely concerned",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13559",
            "answer": {
                "option": "Almost always",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13564",
            "answer": {
                "option": "Neutral",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13562",
            "answer": {
                "option": "Moderately concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13555",
            "answer": {
                "option": "Agree",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "659fbea9f62b501a0bb13565",
            "answer": {
                "option": "Strongly Agree",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13553",
            "answer": {
                "option": "Almost always",
                "weightage": 1
            },
            "answer_weightage": 1
        },
        {
            "question_id": "659fbea9f62b501a0bb13552",
            "answer": {
                "option": "Slightly concerned",
                "weightage": 4
            },
            "answer_weightage": 4
        },
        {
            "question_id": "659fbea9f62b501a0bb13556",
            "answer": {
                "option": "Moderately concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13566",
            "answer": {
                "option": "Neutral",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13563",
            "answer": {
                "option": "Sometimes",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb1355f",
            "answer": {
                "option": "Some",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb1355b",
            "answer": {
                "option": "Somewhat concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13560",
            "answer": {
                "option": "Neutral",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb13567",
            "answer": {
                "option": "Somewhat concerned",
                "weightage": 3
            },
            "answer_weightage": 3
        },
        {
            "question_id": "659fbea9f62b501a0bb1355e",
            "answer": {
                "option": "Agree",
                "weightage": 2
            },
            "answer_weightage": 2
        },
        {
            "question_id": "659fbea9f62b501a0bb13557",
            "answer": {
                "option": "Agree",
                "weightage": 2
            },
            "answer_weightage": 2
        }
    ]
}