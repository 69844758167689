import { Box, Typography } from "@material-ui/core";
import OrgDetailsScreens from "components/screen/v2/OrgDetailsScreens";

const OrgDetailsCont = () => {
  return (
    <Box width={"100%"} p={2}>
      {/* Heading */}
      <Typography variant="body1" style={{ marginBottom: 10, fontWeight: 600 }}>
        Organisation Details
      </Typography>

      {/* Main Content */}
      <OrgDetailsScreens />
    </Box>
  );
};
export default OrgDetailsCont;
