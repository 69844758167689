var UNIVERSAL = {
  BASEURL: process.env.REACT_APP_BASEURL,
  // BASEURL:"https://frontend-dot-imgrows-test.el.r.appspot.com/api",
  //  BASEURL: "https://esg-prl.herokuapp.com/api",
  //BASEURL :  "https://api.imgrows.com/api",
  // PDF_MICROSERVICE: process.env.React_App_PDF_MICROSERVICE,
  // URL_FOR_SOCKET: process.env.React_App_URL_FOR_SOCKET
  ESG_DIAGNOSIS_REST: process.env.REACT_APP_ESG_DIAGNOSIS_REST,
};

export default UNIVERSAL;
