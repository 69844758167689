import {
  SET_ACTIVE_CONTRACT_FILE_DATA,
  SET_ARTICLES,
  SET_COMPLETED_QUESTIONS,
  SET_CONTROVERSIES_DETAILS,
  SET_CONTROVERSIES_SCORE,
  SET_CONTRO_INSIGHTS,
  SET_DORA_COMPLIANCE_FIELS,
  SET_DORA_COMPLIANCE_FIELS_CONTRACTING,
  SET_DORA_COMPLIANCE_RESULTS,
  SET_ESG_INSIGNTS,
  SET_ESG_SCORE,
  SET_FREQUENCIES,
  SET_SELF_ASSESSMENT_SCORE,
  SET_TPRM_SCORE,
  SET_VENDOR_ASSESSMENT_SCORE,
} from "../../../constants/esgDiagnosisConstant";
import { SET_ESG_RISK, SET_VENDOR_RISK } from "../../../constants/vendorRisk";

const initialState = {
  esgScore: [],
  esgInsights: {},
  controversiesDetails: {},
  articles: [],
  totalArticles: 0,
  selfAssessmentScore: [],
  controversiesScore: null,
  frequencies: [],
  completedQuestions: [],
  tprmScore: [],
  vendorRisk: {},
  esgRisk: {},

  controInsights: {},

  vendorAssessmentScore: null,

  doraComplianceFiles: [],
  doraComplianceFilesContracting: [],
  doraComplianceResults: null,
  activeContractFileData: [],
};

export default function esgDiagnosisReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ESG_SCORE:
      return (state = { ...state, esgScore: action.payload });

    case SET_ESG_INSIGNTS:
      return (state = { ...state, esgInsights: action.payload });

    case SET_CONTROVERSIES_DETAILS:
      return { ...state, controversiesDetails: action.payload };

    case SET_ARTICLES:
      return {
        ...state,
        articles: action.payload.articles,
        totalArticles: action.payload.total,
      };

    case SET_SELF_ASSESSMENT_SCORE:
      return { ...state, selfAssessmentScore: action.payload };

    case SET_CONTROVERSIES_SCORE:
      return { ...state, controversiesScore: action.payload };

    case SET_FREQUENCIES:
      return { ...state, frequencies: action.payload };

    case SET_COMPLETED_QUESTIONS:
      return { ...state, completedQuestions: action.payload };
    case SET_TPRM_SCORE:
      return { ...state, tprmScore: action.payload };

    case SET_VENDOR_RISK:
      return { ...state, vendorRisk: action.payload };

    case SET_ESG_RISK:
      return { ...state, esgRisk: action.payload };

    case SET_CONTRO_INSIGHTS:
      return { ...state, controInsights: action.payload };

    case SET_VENDOR_ASSESSMENT_SCORE:
      return { ...state, vendorAssessmentScore: action.payload };

    case SET_DORA_COMPLIANCE_FIELS:
      return { ...state, doraComplianceFiles: action.payload };

    case SET_DORA_COMPLIANCE_FIELS_CONTRACTING:
      return { ...state, doraComplianceFilesContracting: action.payload };

    case SET_DORA_COMPLIANCE_RESULTS:
      return { ...state, doraComplianceResults: action.payload };

    case SET_ACTIVE_CONTRACT_FILE_DATA:
      return { ...state, activeContractFileData: action.payload };

    default:
      return state;
  }
}
