import { SET_VENDOR_DASHBOARD_DATA } from "../../../constants/brmConstants";
import { SET_VENDOR_DASHBOARD_ALERT } from "../../../constants/vendorRisk";
import {
  GQL_CREATE_DASHBOARD_ALERT,
  GQL_DASHBOARD_ALERT,
  GQL_UPDATE_DASHBOARD_ALERT,
} from "../../graphql/gql_BankAdminQueries";
import { GET_VENDOR_DASHBOARD_QUERY } from "../../graphql/gql_VendorDashboardQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";
import { set_snack_bar } from "../snackbar/snackbar_action";

const setVendorDashboardData = (payload) => {
  return {
    type: SET_VENDOR_DASHBOARD_DATA,
    payload,
  };
};

export function gql_get_vendor_dashboard_data() {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GET_VENDOR_DASHBOARD_QUERY,
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GET_VENDOR_DASHBOARD_QUERY },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }
      const data = {
        vendorCount: result.data.dashboardData.count,
        vendors: result.data.dashboardData.vendors,
        sectorCount: {
          industries: Object.keys(result.data.dashboardData.groupedByIndustry),
          values: Object.keys(result.data.dashboardData.groupedByIndustry).map(
            (key) => result.data.dashboardData.groupedByIndustry[key]
          ),
        },
        avgEsgRisk: result.data.dashboardData.avgEsgRisk,
        avgVendorRisk: result.data.dashboardData.avgVendorRisk,

        vendorRiskGroupCount: result.data.dashboardData.vendorRiskGroupCount,
        esgRiskGroupCount: result.data.dashboardData.esgRiskGroupCount,
        overallRiskPerformance:
          result.data.dashboardData.overallRiskPerformance,
      };
      await dispatch(setVendorDashboardData(data));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_vendor_dashboard_alert(getAlertInput) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GQL_DASHBOARD_ALERT,
        variables: { getAlertInput },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor({ query: GQL_DASHBOARD_ALERT }, "query")
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_VENDOR_DASHBOARD_ALERT,
        payload: result.data.getAlert,
      });
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_create_dashboard_alert(createAlertInput) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.mutate({
        mutation: GQL_CREATE_DASHBOARD_ALERT,
        variables: { createAlertInput },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GQL_CREATE_DASHBOARD_ALERT },
              "mutate"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result.data.createAlert.message));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_update_dashboard_alert(updateAlertInput) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.mutate({
        mutation: GQL_UPDATE_DASHBOARD_ALERT,
        variables: { updateAlertInput },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GQL_UPDATE_DASHBOARD_ALERT },
              "mutate"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result.data.updateAlert.message));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}
