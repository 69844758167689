import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import NoData from "../../../../NoData/NoData";

function UserDetailsWithRole({ userList = [], who }) {
  return (
    <Box style={{ display: "flex", gap: 30 }}>
      <List
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            width: "20%",
            marginLeft: "10px",
          }}
        >
          {who}
        </Typography>
        {userList?.map((user) => (
          <ListItem
            key={user?._id}
            role={undefined}
            dense={true}
            button
            //   selected={value?.isCheck}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              flex: 1,
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt={user?.name}
                style={{ backgroundColor: "blueViolet" }}
                // src={`/static/images/avatar/${value + 1}.jpg`}
              />
            </ListItemAvatar>
            <ListItemText
              // id={labelId}
              primary={user?.name}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function ExploreQuestion({ quesAssignedData }) {
  const noData =
    !quesAssignedData ||
    quesAssignedData === null ||
    quesAssignedData?.length === 0;

  // const admin = [
  //   {
  //     _id: quesAssignedData?.assignedBy?._id,
  //     name: quesAssignedData?.assignedBy?.name,
  //     role: quesAssignedData?.assignedBy?.role || "Assigned By",
  //   },
  // ];
  // const admin = [
  //   {
  //     _id: quesAssignedData?.assignedBy?._id,
  //     name: quesAssignedData?.assignedBy?.name,
  //   },
  // ];

  return (
    <>
      {noData ? (
        <NoData message={"No Available Data"} />
      ) : (
        <>
          <UserDetailsWithRole
            userList={[quesAssignedData?.assignedBy]}
            who={"Admin"}
          />
          <UserDetailsWithRole
            userList={quesAssignedData?.approvers}
            who={"Approver"}
          />
          <UserDetailsWithRole
            userList={quesAssignedData?.contributors}
            who={"Contributor"}
          />
        </>
      )}
    </>
  );
}

export default ExploreQuestion;
