import React, { useEffect } from "react";

import { Box, Paper, Typography } from "@material-ui/core";

import { IoEllipsisVertical } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_vendor_dashboard_alert } from "../../../redux/actions/vendorActions/vendorDashboardAction";

function ChartsComponent({
  title,
  children,
  setAlertPopper,
  setPopup,
  setAlertName,
  setModalText,
  defaultAlertValue,
  inputRef,
  handleShowRisk,
}) {
  const dispatch = useDispatch();

  function severity(value, type) {
    if (type === "text") {
      return value > 0 && value <= 1.5
        ? "#154328"
        : value > 1.5 && value <= 3.5
        ? "#8C7038"
        : value > 3.5
        ? "#52231D"
        : "#154328";
    }

    if (type === "bg") {
      return value > 0 && value <= 1.5
        ? "#BFE2CD"
        : value > 1.5 && value <= 3.5
        ? "#FFEFD0"
        : value > 3.5
        ? "#EDCAC6"
        : "#BFE2CD";
    }
  }

  return (
    <Paper
      elevation={3}
      style={{ padding: "10px", flex: 1, marginTop: "10px" }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography
            style={{
              color: "#15314E",
              fontSize: "20px",
              fontWeight: 500,
              // marginBottom: "10px",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box ref={inputRef}>
          <IoEllipsisVertical
            size={20}
            style={{ color: "#3374B9", cursor: "pointer" }}
            onClick={(e) => {
              if (title === "Average Risk by Vendors") {
                setAlertPopper(e.currentTarget);
                setAlertName("AVERAGE_RISK_BY_VENDORS");
                setModalText({
                  heading: "Set Alert for Average Risk by Vendors",
                  buttonText: "Set Alert",
                  message:
                    "You will get an alert email if the given threshold value exceeds.",
                });
              }
            }}
          />
        </Box>
      </Box>
      <Box style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
        {children}
      </Box>
      {title === "Average Risk by Vendors" &&
        (defaultAlertValue ||
          handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.threshold) &&
        !handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.pause && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: severity(
                  defaultAlertValue ||
                    handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.threshold,
                  "text"
                ),
                backgroundColor: severity(
                  defaultAlertValue ||
                    handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.threshold,
                  "bg"
                ),
                padding: "6px 40px",
                textAlign: "center",
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={() => {
                setPopup(true);
                setModalText({
                  heading: "Update Alert for Average Risk by Vendors",
                  buttonText: "Update Alert",
                  message: "Alert has already been set, you can update it.",
                });
                setAlertName("AVERAGE_RISK_BY_VENDORS");
              }}
            >
              Risk Alert:{" "}
              <span style={{ fontWeight: 500 }}>
                {defaultAlertValue ||
                  handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.threshold}
              </span>
            </Typography>
          </Box>
        )}
      {title === "Average Risk by Vendors" &&
        (defaultAlertValue ||
          handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.threshold) &&
        handleShowRisk("AVERAGE_RISK_BY_VENDORS")?.pause && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography variant="body2">
              Risk Alert: <span style={{ fontWeight: 500 }}>Paused</span>
            </Typography>
          </Box>
        )}
    </Paper>
  );
}

export default ChartsComponent;
