import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomButton from "../../UI/button/button";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Paid from "../../../images/paid.svg";
import Free from "../../../images/free.svg";
import Check from "../../../images/check.svg";
import CheckIcon from "@material-ui/icons/Check";
import planStyles from "./plansStyles";
import {
  AppBar,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@material-ui/core";
import TabPanel, { tabTheme } from "../../UI/tabPanel/tabPanel";
import UpgradeCard from "./plans/upgradeCard";
import PlanCard from "./plans/planCard";
import InvoiceCard from "./plans/invoiceCard";
import Plan from "./plans/plan";
import { useDispatch, useSelector } from "react-redux";
// import puppeteer from 'puppeteer'
const myTheme = createTheme({
  palette: {
    primary: { main: "#FFFFFF" },
    secondary: { main: "#FFFFFF" },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#3374B9",
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "#3374B9",
        },
      },
    },
  },
});

const invoiceData = [
  {
    date: "dec 26 2022",
    desc: "reporting service",
    total: "₹ 1200",
    status: "paid",
  },
];

export default function PlansPage(props) {
  // const { upgradePlan, login, getInvoice, generalSettings, getPlans } = props;
  const { generalSettings, login } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = planStyles();
  const [planTanbno, setPlanTabno] = useState(0);
  const [hovered, sethovered] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState("00");
  const [upgradePlans, setUpgradePlans] = useState(
    !login?.isSubscriptionActive ? true : false
  );
  const [checked, setChecked] = useState(false);
  const [isExpiredDialogopen, setIsExpiredDialogopen] = useState(
    !login?.isSubscriptionActive ? true : false
  );
  // console.log("login", login?.organization_id);
  useEffect(() => {
   
  }, [login?.organization_id]);

  useEffect(() => {
    localStorage.getItem("status") === "ACTIVE" && setUpgradePlans(false);
  }, [localStorage.getItem("status")]);

  useEffect(() => {
    generalSettings &&
      generalSettings?.razorpay_invoice &&
      generalSettings?.razorpay_invoice[0]?.paid &&
      !login?.isSubscriptionActive &&
      setUpgradePlans(false);
  }, [
    generalSettings &&
      generalSettings?.razorpay_invoice &&
      generalSettings?.razorpay_invoice[0]?.paid,
  ]);

  const expiredDialog = () => {
    return (
      <Dialog
        maxWidth="sm"
        open={isExpiredDialogopen}
        onClose={() => setIsExpiredDialogopen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Subscription Expired
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={10}>
              <Typography className={classes.expiredText}>
                Your Subscription has Expired!
              </Typography>
              <Typography className={classes.blueText}>
                Please Complete payment to avail our Premium Services.
              </Typography>
              <div style={{ marginTop: 15 }}></div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => {
              setIsExpiredDialogopen(false);
              setUpgradePlans(false);
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setUpgradePlans(true);
              setIsExpiredDialogopen(false);
            }}
            variant="contained"
            color="primary"
          >
            Continue
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {isExpiredDialogopen &&
        localStorage.getItem("status") === "EXPIRED" &&
        expiredDialog()}
      <div className={classes.mainDiv}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.Typography}>
              Plans
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className={classes.Grid}>
          <MuiThemeProvider theme={tabTheme}>
            <AppBar
              position="static"
              color="inherit"
              className={classes.AppBar}
            >
              <Tabs
                value={planTanbno}
                onChange={(event, newValue) => {
                  setPlanTabno(newValue);
                }}
              >
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>Plans</span>}
                />
                <Tab
                  disableRipple
                  className={classes.tab}
                  // disabled={localStorage.getItem("status") === "TRIAL"}
                  onClick={() => {
                  
                    setChecked(false);
                  }}
                  label={<span className={classes.tabLabel}>Invoices</span>}
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
          <div className={classes.cardContainer}>
            {/* <CardContent className={classes.cardContent}> */}
            {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={planTanbno} index={0}>
              {upgradePlans && (
                <PlanCard
                  setChecked={setChecked}
                  checked={checked}
                  // upgradePlan={upgradePlan}
                  setUpgradePlans={setUpgradePlans}
                  {...props}
                />
              )}
              {!upgradePlans && (
                <>
                  {/* {localStorage.getItem("status") !== "ACTIVE" && (
                    <Plan
                      plan={"Basic Plan"}
                      amount={"Free"}
                      upgrade={false}
                      setUpgradePlans={setUpgradePlans}
                      next_payment_date={
                        login.selectedOrganization?.next_payment_date
                      }
                      description={`Explore our platform's features and experience the benefits of
                    our services during your trial period. Upgrade to a premium plan
                    for advanced functionalities and continued sustainability
                    support.`}
                      {...props}
                    />
                  )}
                  {localStorage.getItem("status") === "EXPIRED" && (
                    <Plan
                      plan={"Premium Plan"}
                      amount={
                        generalSettings?.planAmount > 0
                          ? generalSettings?.planAmount
                          : "Free"
                      }
                      upgrade={true}
                      setUpgradePlans={setUpgradePlans}
                      next_payment_date={
                        login.selectedOrganization?.next_payment_date
                      }
                      description={`Explore our platform's features and experience the benefits of
                    our services during your trial period. Upgrade to a premium plan
                    for advanced functionalities and continued sustainability
                    support.`}
                      {...props}
                    />
                  )} */}

                  {/* TRIAL  */}
                  {login.isSubscriptionActive &&
                    !login.selectedOrganization?.next_payment_date &&
                    localStorage.getItem("status") === "TRIAL" && (
                      <>
                        <Plan
                          amount={"Free"}
                          plan={"Basic Plan"}
                          setUpgradePlans={setUpgradePlans}
                          {...props}
                          active={true}
                          upgrade={false}
                          expireTag={false}
                          next_payment_date={
                            login.selectedOrganization?.next_payment_date
                          }
                          description={`Explore our platform's features and experience the benefits of
                        our services during your trial period. Upgrade to a premium plan
                        for advanced functionalities and continued sustainability
                        support.`}
                        />
                        <Plan
                          plan={"Premium Plan"}
                          amount={
                            generalSettings?.planAmount > 0
                              ? generalSettings?.planAmount
                              : "Free"
                          }
                          upgrade={true}
                          expireTag={false}
                          setUpgradePlans={setUpgradePlans}
                          next_payment_date={
                            login.selectedOrganization?.next_payment_date
                          }
                          description={`Unlock the full potential of our product with the Premium Package - an all-inclusive offering packed with advanced features, enhanced customization options, and priority support. Elevate your experience and maximize your impact with our premium subscription.`}
                          {...props}
                        />
                      </>
                    )}
                  {/* TRIAL EXPIRED */}
                  {!login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED" &&
                    !login.selectedOrganization?.next_payment_date && (
                      <>
                        <Plan
                          amount={"Free"}
                          plan={"Basic Plan"}
                          upgrade={false}
                          setUpgradePlans={setUpgradePlans}
                          next_payment_date={
                            login.selectedOrganization?.next_payment_date
                          }
                          expireTag={true}
                          description={` Upgrade to a premium plan to regain access and continue your
                        sustainability initiatives with our powerful tools and
                        resources.`}
                          {...props}
                        />
                        <Plan
                          amount={
                            generalSettings?.planAmount > 0
                              ? generalSettings?.planAmount
                              : "Free"
                          }
                          plan={"Premium Plan"}
                          upgrade={true}
                          expireTag={false}
                          setUpgradePlans={setUpgradePlans}
                          next_payment_date={
                            login.selectedOrganization?.next_payment_date
                          }
                          description={`Unlock the full potential of our product with the Premium Package - an all-inclusive offering packed with advanced features, enhanced customization options, and priority support. Elevate your experience and maximize your impact with our premium subscription.`}
                          {...props}
                        />
                      </>
                    )}

                  {/* ACTIVE */}
                  {login.isSubscriptionActive &&
                    localStorage.getItem("status") === "ACTIVE" && (
                      <Plan
                        amount={generalSettings?.planAmount}
                        plan={"Premium Plan"}
                        setUpgradePlans={setUpgradePlans}
                        upgrade={false}
                        expireTag={false}
                        next_payment_date={
                          login.selectedOrganization?.next_payment_date
                        }
                        description={`Congratulations on being a premium member! Dive deeper into
                        sustainability analytics, leverage expert insights, and unlock
                        exclusive benefits for maximum impact.`}
                        {...props}
                      />
                    )}

                  {/* EXPIRED */}
                  {!login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED" &&
                    login.selectedOrganization?.next_payment_date && (
                      <Plan
                        amount={generalSettings?.planAmount}
                        plan={"Premium Plan"}
                        expireTag={false}
                        upgrade={true}
                        setUpgradePlans={setUpgradePlans}
                        next_payment_date={
                          login.selectedOrganization?.next_payment_date
                        }
                        description={`Renew your Subscription to regain access to our premium features
                        and continue driving positive change through enhanced tools and
                        resources.`}
                        {...props}
                      />
                    )}
                </>
              )}
            </TabPanel>
            <TabPanel value={planTanbno} index={1}>
              <InvoiceCard
                hovered={hovered}
                sethovered={sethovered}
                {...props}
              />
            </TabPanel>
            {/* </div> */}
            {/* </CardContent> */}
          </div>
        </Grid>
        {/* Plan Cards */}
        {false && (
          <Grid
            container
            justifyContent="space-between"
            className={classes.Grid}
          >
            {/* Free */}
            <Grid item xs={5}>
              <Card className={classes.Card}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={10}>
                    <Typography variant="h6" className={classes.Typography1}>
                      Beta
                    </Typography>
                    <Typography variant="h6" className={classes.Typography2}>
                      Free
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <img src={Free} alt="free" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.Typography3}>
                      Currently you are on the beta plan
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.Grid1}>
                    <div className={classes.div}>
                      <img src={Check} alt="check" />
                      <Typography className={classes.Typography4}>
                        20 Users
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.Grid2}>
                    <div className={classes.div}>
                      <img src={Check} alt="check" />
                      <Typography className={classes.Typography4}>
                        Customer support
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.Grid2}>
                    <div className={classes.div}>
                      <img src={Check} alt="check" />
                      <Typography className={classes.Typography4}>
                        10 Company profile
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 65 }}
                >
                  <Grid item xs={8}>
                    <MuiThemeProvider theme={myTheme}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<CheckIcon />}
                      >
                        Current Plan
                      </Button>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* Paid */}
            <Grid item xs={5}>
              <Card elevation={3} className={classes.Card1}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={10}>
                    <Typography variant="h6" className={classes.Typography5}>
                      Premium
                    </Typography>
                    <Typography variant="h6">For SEM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <img src={Paid} alt="free" />
                  </Grid>
                </Grid>
                <Grid container className={classes.Grid3}>
                  <Grid item xs={12}>
                    <Typography>
                      Our premium version will be available on late feb 2022.
                    </Typography>
                    <Typography className={classes.Grid3}>
                      Click on notify me to get further updates.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  className={classes.Grid4}
                >
                  <Grid item xs={6}>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.CustomButton}
                    >
                      Notify Me
                    </CustomButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
