import {
  SET_BANK_STRUCTURE,
  SET_ORG_ENTITY_LIST,
} from "../../../constants/vendorOrgConst";

const initialState = {
  entity_list: [],
  bank_structure: null,
};

const vendorOrgReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_ENTITY_LIST:
      return { ...state, entity_list: action.payload };
    case SET_BANK_STRUCTURE:
      return { ...state, bank_structure: action.payload };

    default:
      return state;
  }
};
export default vendorOrgReducer;
