// ----------------------------------------------------------------------

import { pxToRem, responsiveFontSizes } from "utils/getFontValue";

const FONT_PRIMARY = "poppins, sans-serif";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  // h1: {
  //   fontWeight: 700,
  //   lineHeight: pxToRem(28),
  //   fontSize: pxToRem(22),
  //   // letterSpacing: 2,
  //   ...responsiveFontSizes({ xs: 18, sm: 20, md: 22 }),
  // },
  // h2: {
  //   fontWeight: 700,
  //   lineHeight: pxToRem(24),
  //   fontSize: pxToRem(20),
  //   ...responsiveFontSizes({ xs: 14, sm: 16, md: 20 }),
  // },
  // h3: {
  //   fontWeight: 700,
  //   lineHeight: pxToRem(20),
  //   fontSize: pxToRem(12),
  //   ...responsiveFontSizes({ xs: 14, sm: 16, md: 16 }),
  // },
  // h4: {
  //   fontWeight: 600,
  //   lineHeight: pxToRem(20),
  //   fontSize: pxToRem(12),
  //   ...responsiveFontSizes({ xs: 12, sm: 17, md: 14 }),
  // },
  // h5: {
  //   fontWeight: 500,
  //   lineHeight: pxToRem(16),
  //   fontSize: pxToRem(16),
  //   ...responsiveFontSizes({ xs: 10, sm: 14, md: 13 }),
  // },
  // h6: {
  //   fontWeight: 400,
  //   lineHeight: pxToRem(16),
  //   fontSize: pxToRem(17),
  //   ...responsiveFontSizes({ xs: 10, sm: 14, md: 12 }),
  // },
  // subtitle1: {
  //   fontWeight: 500,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(13),
  //   ...responsiveFontSizes({ xs: 10, sm: 10, md: 13 }),
  //   // color: "#000000A1",
  // },
  // subtitle2: {
  //   fontWeight: 600,
  //   lineHeight: 22 / 14,
  //   fontSize: pxToRem(11),
  //   ...responsiveFontSizes({ xs: 10, sm: 10, md: 11 }),
  //   // color: "#000000A1",
  // },
  // body1: {
  //   fontWeight: 400,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(14),
  //   ...responsiveFontSizes({ xs: 12, sm: 12, md: 14 }),
  // },
  // body2: {
  //   fontWeight: 400,
  //   lineHeight: 22 / 14,
  //   fontSize: pxToRem(14),
  //   ...responsiveFontSizes({ xs: 11, sm: 13, md: 13 }),
  // },
  // caption: {
  //   fontWeight: 500,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(10),
  //   // color: "#000000",
  // },
  // overline: {
  //   fontWeight: 600,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(10),
  //   textTransform: "none",
  // },
  // button: {
  //   fontWeight: 600,
  //   lineHeight: 24 / 14,
  //   fontSize: pxToRem(12),
  //   textTransform: "none",
  // },
  // article: {
  //   fontWeight: 600,
  // },
};

export default typography;
