import { gql } from "@apollo/client";

export const GET_VA_BANKS_LIST = gql`
  query Banks($type: String!, $pagination: PaginationInput, $search: String) {
    Banks(type: $type, pagination: $pagination, search: $search) {
      total
      banks {
        _id
        name
        count
        active
        details
        assignedBy {
          name
          profileImg {
            name
            type
            url
          }
        }
        assignedOn
        branchOfficeAddresses {
          _id
          state
        }
        headOfficeAddress {
          _id
          state
        }
      }
    }
  }
`;

// export const GET_TASK_BANKS_LIST = gql`
//   query GetBanksWhoseSurveysWereAssigned {
//     getBanksWhoseSurveysWereAssigned {
//       _id
//       active
//       name
//       noOfSurveyShared
//     }
//   }
// `;

export const GET_SHARED_SURVEY = gql`
  query GetSharedSurveys($bankId: String, $vendorId: String) {
    getSharedSurveys(
      getSharedSurveysInput: { bankId: $bankId, vendorId: $vendorId }
    ) {
      _id
      frequencyType
      frequencyId
      latestVersion
      sharedOn
      surveyId
      surveyName
      isCompleted
      isApproved
      chatRoomId
      sharedBy {
        _id
        name
        designation
        profileImg {
          name
          type
          url
        }
      }
    }
  }
`;

export const ASSIGN_SURVEY = gql`
  mutation AssignSurvey($assignSurveyInput: AssignSurveyToStakeholdersInput!) {
    assignSurvey(assignSurveyInput: $assignSurveyInput) {
      _id
    }
  }
`;

export const GET_ASSIGNED_STATUS = gql`
  query GetAssignedStatus(
    $getQuestionAssignedStatusInput: GetQuestionAssignedStatusInput!
  ) {
    getAssignedStatus(
      getQuestionAssignedStatusInput: $getQuestionAssignedStatusInput
    ) {
      _id
      approvers {
        designation
        name
        _id
        profileImg {
          name
          type
          url
        }
      }
      assignedBy {
        _id
        name
        designation
        profileImg {
          name
          type
          url
        }
      }
      contributors {
        _id
        designation
        name
        profileImg {
          name
          type
          url
        }
      }
      question {
        _id
        question
      }
    }
  }
`;

export const UPDATE_QUESTION_STATUS = gql`
  mutation UpdateSubmittedQuestionStatus(
    $updateSubmittedQuestionStatusInput: UpdateSubmittedQuestionStatusInput!
  ) {
    updateSubmittedQuestionStatus(
      updateSubmittedQuestionStatusInput: $updateSubmittedQuestionStatusInput
    ) {
      _id
    }
  }
`;

export const GET_REQUESTED_FILES = gql`
  query GetRequestedFiles($bankId: String!, $timestamp: String!) {
    getRequestedFiles(bankId: $bankId, timestamp: $timestamp) {
      type
      year
      _id
      isCompleted
      uploadedFile {
        name
        type
        url
      }
    }
  }
`;

export const GET_ALL_SUBMISSIONS = gql`
  query GetAllSubmissions($frequencyId: String!) {
    getAllSubmissions(getAllSubmissionsInput: { frequencyId: $frequencyId }) {
      _id
      chatRoomId
      createdAt
      frequencyId
      isApproved
      isRejected
      sharedSurveyId
      surveyId
      updatedAt
    }
  }
`;

export const MARK_SURVEY_COMPLETE = gql`
  mutation MarkSurveyAsComplete(
    $markSurveyAsCompleteInput: MarkSurveyAsCompleteInput!
  ) {
    markSurveyAsComplete(
      markSurveyAsCompleteInput: $markSurveyAsCompleteInput
    ) {
      _id
    }
  }
`;
