import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function OngoingAssessmentOverallScore() {
  const { sharedSurveyDetails } = useSelector((state) => state.vendorDiagnosis);

  const nameFormat = (name) => {
    if (name === "overallScore") {
      return "Overall Score";
    } else if (name === "climateRiskScore") {
      return "Climate Risk Score";
    }
  };

  const defaultData = {
    overallScore: {
      comment: "Some Comment",
      score: 0,
    },
  };

  const totalScore = Math?.round(
    sharedSurveyDetails?.reduce((cur, acc) => cur + acc?.score, 0) /
      sharedSurveyDetails?.length
  );

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Grid container style={{ width: "100%" }}>
      <Grid md={12}>
        <BlueBackgroundCard heading={"Overall Score"} />
        {/* <Typography variant="body2" style={{ fontWeight: 500 }}>
          Overall Score
        </Typography> */}
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={"15px"}
          marginLeft={"20px"}
        >
          {totalScore && (
            <Chip
              size="small"
              label={
                totalScore >= 66
                  ? "High"
                  : totalScore < 66 && totalScore >= 33
                  ? "Mid"
                  : "Low"
              }
              style={{
                backgroundColor:
                  totalScore >= 66
                    ? severity("bg", "green")
                    : totalScore < 66 && totalScore >= 33
                    ? severity("bg", "yellow")
                    : severity("bg", "red"),
                color:
                  totalScore >= 66
                    ? severity("text", "green")
                    : totalScore < 66 && totalScore >= 33
                    ? severity("text", "yellow")
                    : severity("text", "red"),
              }}
            />
          )}
          <Typography
            style={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "40px" }}>{totalScore || "-"}</span>
            <span style={{ fontSize: "20px" }}>/</span>
            <span>100</span>
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            padding: "5px",
            marginBottom: "10px",
          }}
          // component={Paper}
        >
          The score reflects the vendor's performance and can guide them in
          making informed decisions.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default OngoingAssessmentOverallScore;
