import React from "react";

import { Grid, Typography, Box, Chip } from "@material-ui/core";

import { useSelector } from "react-redux";
import VendorScoreChart from "../../esgCompanyProfile/VendorScoreChart";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function VendorScore() {
  const { vendorMaterialityScores } = useSelector((state) => state?.brmData);

  const nameFormat = (name) => {
    if (name === "vendorCategorization") {
      return "Vendor Categorization";
    } else if (name === "vendorMateriality") {
      return "Vendor Materiality";
    } else if (name === "vendorPerformance") {
      return "Vendor Performance";
    }
  };

  const defaultData = {
    vendorCategorization: {
      comment: "",
      score: 0,
    },
    vendorMateriality: {
      comment: "",
      score: 0,
    },
    vendorPerformance: {
      comment: "",
      score: 0,
    },
  };

  const total =
    Object?.entries(vendorMaterialityScores ?? defaultData)?.reduce(
      (acc, [key, value]) => acc + value?.score,
      0
    ) / 3;

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <div style={{ paddingBottom: "18px", width: "100%" }}>
      <Grid container md={12}>
        <Grid item md={7} sm={12}>
          <BlueBackgroundCard heading={"Overall Score"} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            {!!parseFloat(total) && (
              <Chip
                label={
                  parseFloat(total) <= 33
                    ? "Low"
                    : parseFloat(total) > 33 && parseFloat(total) <= 66
                    ? "Mid"
                    : "High"
                }
                size="small"
                style={{
                  color:
                    parseFloat(total) <= 33
                      ? severity("text", "red")
                      : parseFloat(total) > 33 && parseFloat(total) <= 66
                      ? severity("text", "yellow")
                      : severity("text", "green"),
                  backgroundColor:
                    parseFloat(total) <= 33
                      ? severity("bg", "red")
                      : parseFloat(total) > 33 && parseFloat(total) <= 66
                      ? severity("bg", "yellow")
                      : severity("bg", "green"),
                }}
              />
            )}
            <Typography
              style={{ fontWeight: 600, display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "40px" }}>
                {Math.round(total.toFixed(1)) || "-"}
              </span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{ fontSize: "13px", padding: "5px", marginBottom: "10px" }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography>
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: 10, rowGap: 30 }}
          >
            {Object?.entries(vendorMaterialityScores ?? defaultData)?.map(
              ([key, value], index) => (
                <div
                  key={index}
                  style={{
                    width: "49%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    // paddingBottom: 5,
                  }}
                >
                  <TopicHeader
                    topic={nameFormat(key)}
                    score={value?.score || "-"}
                    noIcon
                  />
                  {/* {data.subTopics.map((sub, i) => (
                    <TopicData key={i} topic={sub.subTopic} score={sub.score} />
                  ))} */}
                </div>
              )
            )}
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
          {
            <VendorScoreChart
              vendorScopingScore={vendorMaterialityScores ?? defaultData}
              innerWidth={"20vw"}
              outerWidth={"10vw"}
              totalScore={parseFloat(total.toFixed(1)) || "-"}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default VendorScore;
