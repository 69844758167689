import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import MuiSlider from "components/UI/slider/MuiSlider";

const FrequencySlider = ({
  range = [30, 40],
  handleRange,
  disabledEdit = false,
}) => {
  const [freRange, setFreRange] = useState(range);

  const handleSlider = (event, newValue) => {
    if (!disabledEdit) {
      setFreRange(newValue);

      // Trigger callback
      if (handleRange) {
        handleRange(newValue);
      }
    }
  };

  const marks = [
    { value: 0, label: "0" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
    { value: 60, label: "60" },
    { value: 70, label: "70" },
    { value: 80, label: "80" },
    { value: 90, label: "90" },
    { value: 100, label: "100" },
  ];

  useEffect(() => {
    setFreRange(range);
  }, [range]);

  return (
    <Box width={400}>
      <MuiSlider
        min={0}
        max={100}
        marks={marks}
        value={freRange}
        valueLabelDisplay="auto"
        onChange={handleSlider}
        disableSwap
      />
    </Box>
  );
};

export default FrequencySlider;
