import { Box, Grid, Typography } from "@material-ui/core";
import pdfImg from "../../../images/PDF.png";
import doc from "../../../images/fileIcon.svg";
import clock from "../../../images/clock.svg";

function ProcessDocuments({ isFrom }) {
  return (
    <div
      style={{
        marginTop: isFrom === "processDocuments" ? 5 : 18,
        marginBottom: isFrom === "processDocuments" ? "50px" : 0,
        height: isFrom === "processDocuments" ? "fit-content" : "60vh",
      }}
    >
      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
        justifyContent="space-between"
      >
        <Grid item md={3} xs={3}>
          <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
            Documents
          </Typography>
        </Grid>

        <Grid container item md={7} xs={7} justifyContent="flex-end">
          <Grid item md={3} xs={3} align="center">
            <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
          </Grid>
          <Grid item md={3} xs={3} align="center">
            <Typography style={{ fontSize: "13px" }}>Action</Typography>
          </Grid>
        </Grid>
      </Grid>
      {[
        {
          title: "Service Document",
          resource:
            "http://demoblueprint.gieom.com/Content/MarkedId/eba31174-9b70-4ffd-b07b-536f9eda6fef",
          type: "link",
          uploadedAt: "15th Oct, 24",
        },
        {
          title: "Technology Document",
          resource:
            "https://drive.google.com/file/d/1Y9lviEQb9OyqzEahcsPjyD4vlt0jU-nv/view?usp=sharing",
          type: "doc",
          uploadedAt: "16th Oct, 24",
        },
      ].map((item) => (
        <Box>
          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: "10px",
              padding: "5px",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "2px",
              alignItems: "center",
            }}
            justifyContent="space-between"
          >
            <Grid
              item
              md={3}
              xs={3}
              container
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              {/* <img
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
              src={pdfImg}
              alt="pdf"
            /> */}
              {item?.title}
            </Grid>
            <Grid container item md={7} xs={7} justifyContent="flex-end">
              <Grid
                item
                md={3}
                xs={3}
                container
                align="right"
                style={{ flexWrap: "nowrap", justifyContent: "center" }}
              >
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  src={clock}
                  alt="pdf"
                />
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {item?.uploadedAt}
                </Typography>
              </Grid>

              <Grid
                item
                md={3}
                xs={3}
                container
                align="right"
                style={{ flexWrap: "nowrap", justifyContent: "center" }}
              >
                <Typography
                  style={{
                    fontSize: "13px",
                    marginLeft: "5px",
                    fontWeight: 600,
                  }}
                >
                  <a
                    href={item?.resource}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#3374B9" }}
                  >
                    {item?.type === "link" ? "View Link" : "View PDF"}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
    </div>
  );
}

export default ProcessDocuments;
