import { useState } from "react";
import { Box, Button, Collapse, Typography } from "@material-ui/core";
import { FaAngleUp, FaPlus } from "react-icons/fa6";

const Section = ({ title, content, setAlert }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = (e) => {
    e.stopPropagation();
    setIsCollapsed((prev) => !prev);
  };

  const handleAlertScreen = (e, from) => {
    e.stopPropagation();
    setAlert({ type: "add", from: from?.toUpperCase()?.split(" ").join("_") });
  };

  return (
    <Box mb={2}>
      <Box
        p={1}
        bgcolor="#EBF1F8"
        borderBottom="1px solid #A1BFDF"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        // onClick={handleCollapse}
      >
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center" sx={{ gap: 20 }}>
          <Button
            startIcon={<FaPlus size={16} />}
            onClick={(e) => handleAlertScreen(e, title)}
            style={{
              backgroundColor: "#FEFEFE",
              textTransform: "none",
              color: "#3374B9",
            }}
          >
            add Alert
          </Button>
          <Button
            onClick={handleCollapse}
            style={{
              minWidth: "auto",
              backgroundColor: "#FEFEFE",
              color: "#3374B9",
              padding: "0.6em",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              <FaAngleUp size={16} />
            </Box>
          </Button>
        </Box>
      </Box>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        <Box my={2}>{content}</Box>
      </Collapse>
    </Box>
  );
};

export default Section;
