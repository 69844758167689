import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import DynamicTable, {
  statusStyle,
  textStyle,
} from "../../../../UI/dynamicTable/DynamicTable";
import {
  assignedHeadings,
  assignedData,
} from "../../../../screen/vendorAdminScreen/tempData";
import { IoIosArrowForward } from "react-icons/io";
import { dateFormat } from "../../../taskSections/SelectedTaskComps/DateFormat";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_shared_survey,
  setSelectedVASurvey,
} from "../../../../../redux/actions/vendorActions/vendorAction";
import {
  toMonthYear,
  toDDMMYY,
} from "../../../../../utils/dateTime/convertTimestamp";
import moment from "moment";

// -------- stylings -----------

const noBgStatusStyle = (status) => {
  let colorToUse = "#FFC250";

  if (status === "complete") {
    colorToUse = "#5BB37F";
  }

  return {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    gap: "4px",
  };
};

// Hover styles for TableRow
const useStyles = makeStyles({
  tableRowHover: {
    // border: "1px solid #E9E9E9",
    borderRadius: "8px",
    margin: 10,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

// ------------------------------

// -------- main render ---------
const CompletedTab = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { selectedBankDetailsForVa, bankSharedSurvey } = useSelector(
    (state) => state?.brmData
  );

  const { user_type } = useSelector((state) => state?.login);

  const dispatch = useDispatch();

  // -------------- Inner Functions --------------

  // Filter Survey based
  const filteredSurveys = bankSharedSurvey.filter(
    (e) => e?.isApproved === true
  );

  // Grouping tasks based on date
  const groupedTasks = filteredSurveys.reduce((acc, curr) => {
    const date = toMonthYear(curr.sharedOn);
    acc[date] = [...(acc[date] || []), curr];
    return acc;
  }, {});

  // handle survey click
  // const handleSurveyClick = async (selectedSurvey) => {
  //   // update redux state
  //   await dispatch(setSelectedVASurvey(selectedSurvey));

  //   // navigate user
  //   history.push(
  //     `${location.pathname}/${selectedSurvey.surveyName.replace(/ /g, "_")}`
  //   );
  // };

  // ----------------------------------------------

  useEffect(() => {
    // get latest surveys
    dispatch(gql_get_shared_survey(selectedBankDetailsForVa?._id, user_type));

    // clear selected survey
    dispatch(setSelectedVASurvey({}));
  }, []);

  const handleSurveyClick = async (selectedSurvey) => {
    // update redux state
    await dispatch(setSelectedVASurvey(selectedSurvey));

    // navigate user
    history.push(
      `${location.pathname}/${selectedSurvey.surveyName.replace(
        / /g,
        "_"
      )}/completed`
    );
  };

  return (
    <Box>
      <DynamicTable headings={assignedHeadings}>
        {Object.entries(groupedTasks).map(([date, tasks]) => (
          <React.Fragment key={date}>
            {/* Render Date Header */}
            <TableRow>
              <TableCell
                colSpan={7}
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#15314E",
                }}
              >
                {date}
              </TableCell>
            </TableRow>
            {/* Render Task Rows */}
            {tasks.map((rowData) => (
              <TableRow
                key={rowData._id}
                className={classes.tableRowHover}
                onClick={() => handleSurveyClick(rowData)}
              >
                <TableCell style={textStyle("main")}>
                  {rowData.surveyName}
                </TableCell>

                <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.status ? "complete" : "pending"
                    )}
                  >
                    <DotIcon style={{ width: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      {rowData.frequencyType}
                      {/* {"Once"} */}
                    </Typography>
                  </Box>
                </TableCell>
                {/* <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.status ? "complete" : "pending"
                    )}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      Contributed
                    </Typography>
                  </Box>
                </TableCell> */}
                {/* <TableCell>
                  <Box
                    style={statusStyle(rowData.status ? "complete" : "pending")}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    {rowData.status ? "Completed" : "Pending"}
                  </Box>
                </TableCell> */}
                <TableCell style={textStyle()}>
                  {moment(rowData.sharedOn).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell style={textStyle()}>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    sx={{ gap: 10 }}
                  >
                    <Avatar
                      src={rowData?.assigned_by?.profileImg?.url}
                      alt={rowData?.sharedBy?.profileImg?.name}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography style={textStyle("main")}>
                        {rowData?.sharedBy?.name}
                      </Typography>
                      <Typography
                        style={{
                          color: "#AFAFAF",
                          fontWeight: 500,
                          fontSize: "11px",
                          lineHeight: "16px",
                        }}
                      >
                        {rowData?.sharedBy?.designation}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  <IoIosArrowForward color="#374151" size={20} />
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default CompletedTab;
