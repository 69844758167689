import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Icon,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomButton from "../../../UI/button/button";

const reportData = [
  {
    reportingFrequency: "Monthly",
    typesOfReportsProvided: "Performance, Compliance",
    reportSubmissionDates: "01-Sep-2024",
    reportAccuracy: "Satisfactory",
    followUpActionsRequired: "View Action",
    nextReportingDate: "01-Oct-2024",
  },
  {
    reportingFrequency: "Quarterly",
    typesOfReportsProvided: "Financial, Risk Management",
    reportSubmissionDates: "15-Jul-2024",
    reportAccuracy: "Satisfactory",
    followUpActionsRequired: "View Action",
    nextReportingDate: "15-Oct-2024",
  },
  {
    reportingFrequency: "Semi-Annual",
    typesOfReportsProvided: "Compliance, Audit",
    reportSubmissionDates: "01-Jun-2024",
    reportAccuracy: "Unsatisfactory",
    followUpActionsRequired: "View Action",
    nextReportingDate: "01-Dec-2024",
  },
  {
    reportingFrequency: "Monthly",
    typesOfReportsProvided: "Service Level Agreement (SLA), Performance",
    reportSubmissionDates: "10-Aug-2024",
    reportAccuracy: "Satisfactory",
    followUpActionsRequired: "View Action",
    nextReportingDate: "10-Sep-2024",
  },
  {
    reportingFrequency: "Quarterly",
    typesOfReportsProvided: "Financial, ESG",
    reportSubmissionDates: "05-Jul-2024",
    reportAccuracy: "Satisfactory",
    followUpActionsRequired: "View Action",
    nextReportingDate: "05-Oct-2024",
  },
];

const OnboardingReportingTable = () => {
  const [openRow, setOpenRow] = useState({}); // Store the open state of each row

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
        >
          Reporting Table
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#EBF1F8" }}>
            <TableRow>
              <TableCell>Reporting Frequency</TableCell>
              <TableCell>Types of Report Provided</TableCell>
              <TableCell>Reports Submission Date</TableCell>
              <TableCell>Reports Accuracy</TableCell>
              <TableCell>Follow-Up Actions Required</TableCell>
              <TableCell>Next Reporting Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{row.reportingFrequency}</TableCell>
                  <TableCell>{row.typesOfReportsProvided}</TableCell>
                  <TableCell>{row.reportSubmissionDates}</TableCell>
                  <TableCell>{row.reportAccuracy}</TableCell>
                  <TableCell>
                    <CustomButton color="primary">View Action</CustomButton>
                  </TableCell>
                  <TableCell>{row.nextReportingDate}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OnboardingReportingTable;
