import { Box } from "@material-ui/core";
import React from "react";
import AffiliatedCompanies from "./GroupStructure/AffiliatedCompanies";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import ImmidiateAndUltimateParent from "./GroupStructure/ImmidiateAndUltimateParent";
import SubsidiaryCompanies from "./GroupStructure/SubsidiaryCompanies";

function GroupStructure({ data, classes }) {
  return (
    <Box>
      {data && (
        <BlueBackgroundCard
          heading={"Affiliated Companies - Group Structure"}
        />
      )}
      <AffiliatedCompanies data={data?.affiliatedCompanies} />

      {data && (
        <BlueBackgroundCard
          heading={"Subsidiary Companies - Group Structure"}
        />
      )}
      <SubsidiaryCompanies data={data?.subsidiaryCompanies} />

      <ImmidiateAndUltimateParent
        immediateParent={data?.immediateParent}
        ultimateParent={data?.ultimateParent}
        classes={classes}
      />
    </Box>
  );
}

export default GroupStructure;
