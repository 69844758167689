import { useState } from "react";
import { Box, Typography } from "@material-ui/core";

// icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PublishIcon from "@material-ui/icons/Publish";

// redux imports

import VersionDrawer from "./VersionDrawer";
import CustomButton from "../../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_section_questions,
  gql_get_survey_sections,
  gql_publish_survey,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import { SET_SELECTED_SURVEY } from "../../../../../../../../constants/riskDashboardConstants";

const MainHeader = ({ setSelectedSection, selectedSection }) => {
  // from redux
  const dispatch = useDispatch();
  const { selectedSurveyData } = useSelector((state) => state.riskDashboard);

  // states
  const [showVersionDrawer, setShowVersionDrawer] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  // ----------Inner Functions----------

  const handleVersionDrawer = (value) => {
    setShowVersionDrawer(value);
  };

  // const handlePublish = (type) => {
  //   if (type === "publish") {
  //     const publishSurveyObj = {
  //       surveyId: selectedSurveyData?._id,
  //       version: selectedSurveyData?.latestVersion,
  //     };

  //     dispatch(gql_publish_survey(publishSurveyObj, setIsPublished));

  //     dispatch({
  //       type: SET_SELECTED_SURVEY,
  //       payload: {
  //         ...selectedSurveyData,
  //         latestVersion: selectedSurveyData?.latestVersion + 1,
  //       },
  //     });
  //   } else {
  //     setIsPublished(false);
  //   }
  // };

  const handlePublish = () => {
    const publishSurveyObj = {
      surveyId: selectedSurveyData?._id,
      version: selectedSurveyData?.latestVersion,
    };

    dispatch(gql_publish_survey(publishSurveyObj, setIsPublished));

    dispatch({
      type: SET_SELECTED_SURVEY,
      payload: {
        ...selectedSurveyData,
        latestVersion: selectedSurveyData?.latestVersion + 1,
      },
    });
  };

  // -----------------------------------

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          color: "#15314E",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        Questions list
      </Typography>
      <CustomButton
        style={{
          textTransform: "none",
          marginLeft: "auto",
          borderRadius: "8px",
          marginRight: "10px",
        }}
        size="small"
        color="primary"
        variant="text"
        onClick={() => {
          handleVersionDrawer(true);
        }}
      >
        <AccessTimeIcon
          style={{ color: "#5C90C7", fontSize: "18px", marginRight: "5px" }}
        />

        <Typography>Version History</Typography>
      </CustomButton>
      <CustomButton
        style={{
          textTransform: "none",

          // width: "200px",
          borderRadius: "8px",
        }}
        size="small"
        color="primary"
        variant="contained"
        // onClick={() => handlePublish(isPublished ? "unpublish" : "publish")}
        onClick={handlePublish}
      >
        <PublishIcon style={{ fontSize: "18px", marginRight: "5px" }} />

        <Typography>Publish</Typography>
      </CustomButton>

      {showVersionDrawer && (
        <VersionDrawer
          open={showVersionDrawer}
          handleClose={handleVersionDrawer}
          setSelectedSection={setSelectedSection}
        />
      )}
    </Box>
  );
};
export default MainHeader;
