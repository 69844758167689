import { Box, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Header from "../vendorIntake/Header";
import MonitoringOverallScore from "./MonitoringOverallScore";
import RisksScore from "./KypData";
import MonitoringCompanyFindings from "./MonitoringCompanyFindings";
import IncidentOverview from "./IncidentOverview";
import WazuhData from "./WazuhData";
import KypData from "./KypData";
import { useSelector } from "react-redux";
import AlertsDrawer from "../vendorIntake/AlertsDrawer";
import { ChartRenderer } from "components/widgets/charts/HistoricChart";

function Monitoring({ isFrom }) {
  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };
  const { kypData, vendorBasicDetails } = useSelector((state) => state.brmData);
  const { bankId } = useSelector((state) => state.login);
  const { vendorAssessmentScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const overallScore =
    ((parseFloat(kypData?.risks?.companyRisk) || 0) +
      (parseFloat(vendorAssessmentScore?.score) || 0)) /
    2;

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };
  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "100%" : "63vh" }}>
      {isFrom === "tprmRiskReport" || (
        <Box
          sx={{
            mb: 2,
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Monitoring
          </Typography>

          <Button
            variant="text"
            style={{ color: "#3374B9", textTransform: "none" }}
            onClick={toggleAlertsDrawer}
          >
            Set Alerts
          </Button>
        </Box>
      )}

      <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
        {/* <Header />
        <br /> */}

        <MonitoringOverallScore
          overallScore={overallScore}
          severity={severity}
        />
        <br />
        <ChartRenderer
          vendorId={vendorBasicDetails?._id}
          bankId={bankId}
          surveys={[
            {
              title: "Monitoring Score",
              value: "MONITORING_SCORE",
            },
            {
              title: "Security Information Management",
              value: "SECURITY_INFORMATION_MANAGEMENT",
            },
            {
              title: "Threat Detection And Response",
              value: "THREAT_DETECTION_AND_RESPONSE",
            },
            {
              title: "Audit And Policy Monitoring",
              value: "AUDIT_AND_POLICY_MONITORING",
            },
            {
              title: "Regulatory Compliance",
              value: "REGULATORY_COMPLIANCE",
            },
            {
              title: "Wazuh Score",
              value: "WAZUH_SCORE",
            },
            {
              title: "KYP Score",
              value: "KYP_SCORE",
            },
          ]}
          defaultSurvey={{
            title: "Monitoring Score",
            value: "MONITORING_SCORE",
          }}
        />

        <br />
        <WazuhData severity={severity} />
        <br />
        <KypData severity={severity} />
        <br />
        {/* <MonitoringCompanyFindings />
        <br /> */}
        {/* <IncidentOverview /> */}
      </Box>

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"MONITORING"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={[
            "Monitoring_Score",
            "Wazuh_Score",
            "Security_Information_Management",
            "Threat_Detection_and_Response",
            "Audit_and_Policy_Monitoring",
            "Regulatory_Compliance",
            "Kyp_Score",
          ]}
        />
      )}
    </Box>
  );
}

export default Monitoring;
