import { Box, createTheme } from "@material-ui/core";
import React from "react";
export const tabTheme = createTheme({
  palette: {
    secondary: {
      main: '#47BB76',
    },
  },
  topicname: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
  selectTopicText: {
    color: '#9FA1AF',
    fontSize: 13,
    padding: '10px 0px',
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DADBE655',
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #DADBE6',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Poppins',
  },
  stakholderTitle: {
    fontWeight: '600',
    marginLeft: 15,
    fontFamily: 'Poppins',
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
});
export default function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box>{children}</Box>}</div>;
}
