import React from "react";

import StepHeader from "./stepHeader/StepHeader";
import SurveyStep1 from "./step1";
import SurveyStep2 from "./step2";
import SurveyStep3 from "./step3";
import SurveyStep4 from "./step4";
import SurveyButton from "./surveyButton/SurveyButton";
import FinalSubmission from "./finalStep/FinalSubmission";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  response1,
  response2,
} from "../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { useDispatch, useSelector } from "react-redux";
import { gql_mark_survey_complete } from "../../../../redux/actions/vendorActions/vendorAction";

function getStepContent(step, response, isFrom) {
  switch (step) {
    case 0:
      return <SurveyStep1 isFrom={isFrom} />;
    case 1:
      return <SurveyStep2 response={response} isFrom={isFrom} />;
    case 2:
      return <SurveyStep3 response={response} isFrom={isFrom} />;
    case 3:
      return <SurveyStep4 response={response} />;
    case 4:
      return <FinalSubmission />;
    default:
      return null;
  }
}

const steps = [
  "Add Stakeholder",
  "View & Assign Assessment",
  "Audit & Freeze",
  "Submission",
];

const SurveySteps = ({ isFrom }) => {
  const { location } = useHistory();

  // from redux
  const dispatch = useDispatch();
  const { selectedVASurvey, completedSecQues } = useSelector(
    (state) => state.vendorReducer
  );
  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  const { pathname } = location;
  // console.log(history);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmitResponses = async () => {
    if (activeStep === 2) {
      if (login?.user_type === "BSA") {
        await dispatch(
          gql_mark_survey_complete(
            selectedVASurvey?.surveyId,
            selectedVASurvey?.frequencyId,
            login?.bankId,
            setActiveStep,
            selectedBankDetailsForVa?._id,
            selectedVASurvey?.chatRoomId
          )
        );
      } else {
        await dispatch(
          gql_mark_survey_complete(
            selectedVASurvey?.surveyId,
            selectedVASurvey?.frequencyId,
            selectedBankDetailsForVa?._id,
            setActiveStep,
            login?.vendorId,
            selectedVASurvey?.chatRoomId
          )
        );
      }
      setActiveStep(4);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {/* header */}
      <StepHeader steps={steps} activeStep={activeStep} />
      {/* main content */}
      {getStepContent(
        activeStep,
        pathname === "/vendor_admin/FlowSource/Survey_2_(Month_Feb)"
          ? response1
          : response2,
        isFrom
      )}
      {/* proceed button */}
      <SurveyButton
        isFrom={isFrom}
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmitResponses={handleSubmitResponses}
        completedSecQues={completedSecQues}
      />
    </>
  );
};
export default SurveySteps;
