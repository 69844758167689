import {
  Box,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmDataRight";
import CustomTextfield from "../../UI/textfield/textfield";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import CustomButton from "../../UI/button/button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { TurnoverYearPickerDialog } from "../riskAssessment/riskAssessment";

function BasicAssessmentDetails(props) {
  const { organizaitonDetailsObject, sectors, setIsDatePickerDialogOpen } =
    props;
  const classes = brmRightStyles();
  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");

  const [peerData, setPeerData] = useState([]);

  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setCompanyLogoURI(organizaitonDetailsObject?.logo?.url);
  }, [organizaitonDetailsObject.logo]);

  const [turnoverData, setTurnoverData] = useState([]);
  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const [yearRange, setyearRange] = useState([]);
  const currentIndex = useRef(0);
  console.log("turnoverData", turnoverData);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const currentYear = useRef(new Date().getFullYear());
  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear.current - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [turnoverPicker]);
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      // console.log("year", year, tstartYear, endYear);
      setTStartYear(year);
      setTEndYear("");
      // console.log("setTEndYear 2");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
      // console.log("setTEndYear 3");
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
      // console.log("setEndYear 4");
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i) => {
    setTurnoverPicker(true);
    currentIndex.current = i;
  };
  const handleSetYear = () => {
    console.log("currentIndex", currentIndex.current);
    setTurnoverData((prev) => {
      let copy = [...prev];
      copy[currentIndex.current].year = `${tstartYear}-${tendYear}`;
      return copy;
    });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {/* <Card
variant="elevation"
id="drawer-container"
elevation={1}
className={classes.cardContainer}
data-tut="reactour__basicRight"
>
<CardContent style={{ padding: 10 }}> */}
      <Typography className={classes.cardTypography}>
        Basic Company Details dvs
      </Typography>

      <div className="scroll" style={{ height: "61vh" }} id="compCont">
        <Grid container>
          <Grid item xs={12} md={11}>
            <Box my={2}>
              <CustomTextfield
                name="compName"
                variant="outlined"
                fullWidth
                size="small"
                label="Company Name"
                value={organizaitonDetailsObject?.name}
                // onChange={(e) => {
                //   setCompanyDetails((prev) => ({
                //     ...prev,
                //     companyName: e.target.value,
                //   }));
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box my={2}>
              <Typography className={classes.headerText}>
                Company Logo
              </Typography>
            </Box>
            <Box my={2}>
              {!companyLogoURI ? (
                <>
                  <input
                    id="company-logo"
                    type="file"
                    onChange={(event) => handleUploadCompanyLogo(event)}
                    style={{ display: "none" }}
                    cursor="pointer"
                  />
                  <label
                    htmlFor="company-logo"
                    className={classes.logoSelecter}
                  >
                    {/* <div className={classes.logoSelecter}> */}
                    <AddCircleOutlineIcon
                      name="addLogoIcon"
                      fontSize="large"
                      className={classes.addIcon}
                    />
                    {/* </div> */}
                  </label>
                </>
              ) : (
                <div className={classes.companyLogo}>
                  <img src={companyLogoURI} alt="company-logo" />
                  {/*  <IconButton
                  onClick={handleClearCompanyLogo}
                  className={classes.ClearImage}
                >
                  <CancelIcon
                    style={{ color: "gray" }}
                    fontSize="small"
                  /> 
                </IconButton>*/}
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box my={2}>
              <Typography className={classes.headerText}>
                Sector & Industry
              </Typography>
            </Box>
          </Grid>
          {/* <Grid
          item
          xs={12}
          md={4}
          className={classes.addSectorContainer}
        >
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(true);
            }}
            variant="outlined"
            color="primary"
          >
            Add Sector
          </CustomButton>
        </Grid> */}

          <Grid item xs={12} md={8}>
            <Box my={2}>
              {sectors.length !== 0 ? (
                sectors.map((sector) => (
                  <div style={{ marginBottom: 8 }}>
                    {sector.industry_data.some((inds) => inds.isSelected) && (
                      <>
                        <Typography className={classes.selectedSectorTitle}>
                          {sector.Sector}
                        </Typography>
                        {sector.industry_data.map(
                          (industry) =>
                            industry.isSelected && (
                              <Chip
                                className={classes.sectorChip}
                                label={industry.Industry}
                              />
                            )
                        )}
                      </>
                    )}
                  </div>
                ))
              ) : (
                <Chip className={classes.sectorChip} label="Non Selected" />
              )}
            </Box>
          </Grid>
          {/* <Grid
          container
          justifyContent="space-between"
          spacing={1}
          item
          xs={12}
          md={11}
        >
          <Grid item md={6}>
            <CustomTextfield
              name="NumOfEmployee"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="User First Name"
            />
          </Grid>
          <Grid item md={6}>
            <CustomTextfield
              name="NumOfEmployee"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="User Last Name"
            />
          </Grid>
        </Grid> */}
          {/* <Grid item xs={12} md={5}>

</Grid> */}
          {/* <Grid item xs={12} md={11}>
          <Box my={2}>
            <CustomTextfield
              name="NumOfEmployee"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Designation Of The Employee"
            />
          </Box>
        </Grid> */}
          <Grid item xs={12} md={11}>
            <Box my={2}>
              <CustomTextfield
                name="NumOfEmployee"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label="Country"
                value={organizaitonDetailsObject?.compnay_name || ''}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={11}>
            <Box my={2}>
              <CustomTextfield
                name="NumOfEmployee"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label="ISIN Number"
                value={organizaitonDetailsObject?.isin}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={11}>
            <Box
              my={2}
              onClick={() => setIsDatePickerDialogOpen(true)}
              style={{
                border: "1px solid #00000050",
                padding: 12,
                borderRadius: 5,
                cursor: "pointer",
                minHeight: 45,
              }}
            >
              {organizaitonDetailsObject.year_of_assessment ? (
                <Typography variant="body1" name="yearOfAssesment">
                  {`${
                    organizaitonDetailsObject.year_of_assessment.split("-")[0]
                  } - ${
                    organizaitonDetailsObject.year_of_assessment.split("-")[1]
                  }`}
                </Typography>
              ) : (
                <Typography
                  style={{ color: "#878282e6", fontFamily: "Roboto" }}
                >
                  Year Of Assessment
                </Typography>
              )}

              {/* <Typography variant="body1" name="yearOfAssesment">
        {basicInfo.year_of_assessment[0].startDate !== "" &&
        basicInfo.year_of_assessment[0].endDate !== ""
          ? `${moment(
              new Date(basicInfo.year_of_assessment[0].startDate)
            ).format("DD MMM yy")} - ${moment(
              new Date(basicInfo.year_of_assessment[0].endDate)
            ).format("DD MMM yy")}`
          : "Year Of Assessment"}
      </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={11}>
            <Box my={2}>
              <CustomTextfield
                name="compOperation"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                size="small"
                label="Company Operation"
                value={organizaitonDetailsObject?.operation}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={11}>
            <Box my={2}>
              <CustomTextfield
                name="NumOfEmployee"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label="Number of Employees"
                value={organizaitonDetailsObject?.no_of_employees}
              />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              my={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={classes.headerText}>
              Revenue Data
              </Typography>
              <CustomButton
                name="addBranch"
                size="medium"
                onClick={() => {
                  const initialPeer = {
                    turnover: "",
                    year: "",
                  };
                  const copy = [...turnoverData];
                  copy.push(initialPeer);
                  setTurnoverData(copy);
                  window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                  });
                  // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                }}
                variant="outlined"
                color="primary"
              >
                Add Revenue
              </CustomButton>
            </Box>
            {turnoverData.map((turnover, i) => {
              return (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={5}>
                    <CustomTextfield
                      name="turnovers"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="Add Turnover"
                      value={turnover.turnover}
                      onChange={(e) => {
                        const copy = [...turnoverData];
                        copy[i].turnover = e.target.value;
                        setTurnoverData(copy);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    role="button"
                    onClick={() => handleTurnover(i)}
                  >
                    <CustomTextfield
                      name="NumOfEmployee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="Year"
                      onChange={(e) => {
                        const copy = [...turnoverData];
                        copy[i].domain = e.target.value;
                        setTurnoverData(copy);
                      }}
                      value={turnover.year}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const copy = [...turnoverData];
                        copy.splice(i, 1);
                        setTurnoverData(copy);
                      }}
                    >
                      remove
                    </Icon>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} md={11}>
            <Box my={2}>
              <Typography className={classes.headerText}>
                Company Address
              </Typography>
            </Box>
            <Box my={2}>
              <CustomTextfield
                name="compAddress"
                variant="outlined"
                fullWidth
                size="small"
                label="Street/Location"
                value={
                  organizaitonDetailsObject?.address &&
                  organizaitonDetailsObject?.address[0]?.companyAddress
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RoomOutlinedIcon fontSize="small" color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box my={2}>
              <CustomTextfield
                variant="outlined"
                name="compLandmark"
                fullWidth
                size="small"
                label="Landmark"
                value={
                  organizaitonDetailsObject?.address &&
                  organizaitonDetailsObject?.address[0]?.landMark
                }
              />
            </Box>
            <Box my={2}>
              <CustomTextfield
                name="compPincode"
                variant="outlined"
                fullWidth
                size="small"
                label="Pincode"
                type="number"
                value={
                  organizaitonDetailsObject?.address &&
                  organizaitonDetailsObject?.address[0]?.pinCode
                }
              />
            </Box>
            <Box my={2}>
              <CustomTextfield
                name="compState"
                variant="outlined"
                fullWidth
                size="small"
                label="State"
                value={
                  organizaitonDetailsObject?.address &&
                  organizaitonDetailsObject?.address[0]?.state
                }
              />
            </Box>
            <Box my={2}>
              <CustomTextfield
                name="compState"
                variant="outlined"
                fullWidth
                size="small"
                label="Country"
                value={
                  organizaitonDetailsObject?.address &&
                  organizaitonDetailsObject?.address[0]?.country
                }
              />
              {/* <Autocomplete
              name="compCountry"
              id="countryCode"
              options={countryList}
              // getOptionLabel={(option) => option}
              // renderOption={(option) => option}
              size="small"
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Country"
                  variant="outlined"
                />
              )}
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.country
                  ? organizaitonDetailsObject?.address[0]?.country
                  : ""
              }
              // onChange={(event, newValue) => {
              //   if (newValue) {
              //     console.log(newValue);
              //     // setCompanyCountry(newValue);
              //   }
              // }}
            /> */}
            </Box>
          </Grid>

          <Grid item xs={12} md={11}>
            <Box
              my={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={classes.headerText}>Add Peers</Typography>
              <CustomButton
                name="addBranch"
                size="medium"
                onClick={() => {
                  const initialPeer = {
                    name: "",
                    domain: "",
                  };
                  const copy = [...peerData];
                  copy.push(initialPeer);
                  setPeerData(copy);
                  window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                  });
                  // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                }}
                variant="outlined"
                color="primary"
              >
                Add Peers
              </CustomButton>
            </Box>
          </Grid>
          <Grid item md={12}>
            <TurnoverYearPickerDialog
              isOpen={turnoverPicker}
              onCancel={handleCancelYear}
              onSet={handleSetYear}
              onYearBack={handleYearBack}
              onYearForward={handleYearForward}
              start={tstartYear}
              end={tendYear}
              yearRange={yearRange}
              onYearClick={handleTurnoverYearClick}
              range={trange}
              onClear={() => {
                setTEndYear("");
                setTStartYear("");
              }}
            />
            {peerData.map((peer, i) => {
              return (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={5}>
                    <CustomTextfield
                      name="Peers"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="Add Peers"
                      value={peer.name}
                      onChange={(e) => {
                        const copy = [...peerData];
                        copy[i].name = e.target.value;
                        setPeerData(copy);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextfield
                      name="NumOfEmployee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="Domain Name"
                      onChange={(e) => {
                        const copy = [...peerData];
                        copy[i].domain = e.target.value;
                        setPeerData(copy);
                      }}
                      value={peer.domain}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const copy = [...peerData];
                        copy.splice(i, 1);
                        setPeerData(copy);
                      }}
                    >
                      remove
                    </Icon>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {/* <Grid item xs={12} md={11}>
          <Box
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.headerText}>
              Branch Office Address
            </Typography>
            <CustomButton
              name="addBranch"
              size="medium"
              // onClick={handleAddBranch}
              variant="outlined"
              color="primary"
            >
              Add Another Branch
            </CustomButton>
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="branchAddress"
              variant="outlined"
              fullWidth
              size="small"
              label="Street/Location"
              // value={basicInfo.branchAddress || ""}
              // onChange={(e) => setBranchAddress(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RoomOutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="branchLandmark"
              variant="outlined"
              fullWidth
              size="small"
              label="Landmark"
              // value={basicInfo.branchLandmark || ""}
              // onChange={(e) => setBranchLandmark(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="branchPincode"
              variant="outlined"
              fullWidth
              size="small"
              label="Pincode"
              type="number"
              // value={basicInfo.branchPincode || ""}
              // onChange={(e) => setBranchPincode(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="branchState"
              variant="outlined"
              fullWidth
              size="small"
              label="State"
              // value={basicInfo.branchState || ""}
              // onChange={(e) => setBranchState(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <Autocomplete
              name="branchCountry"
              id="countryCode"
              options={countryList}
              getOptionLabel={(option) => option}
              renderOption={(option) => option}
              size="small"
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Country"
                  variant="outlined"
                />
              )}
              // value={basicInfo.branchCountry || ""}
              onChange={(event, newValue) => {
                if (newValue) {
                  // setBranchCountry(newValue);
                }
              }}
            />
          </Box>
        </Grid> */}

          <Grid xs={7}></Grid>
          <Grid item xs={9}>
            {/* {LocationStore} */}
          </Grid>
        </Grid>
      </div>
      {/* </CardContent>
</Card> */}
    </div>
  );
}

export default BasicAssessmentDetails;
