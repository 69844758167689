import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Icon,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomButton from "../../../UI/button/button";
import {
  gql_get_exit_plan,
  gql_get_vendor_recommendation,
} from "redux/actions/vendorActions/vendorDiagnosisAction";
import { useSelector } from "react-redux";

const data = [
  {
    vendorName: "SwiftPay Solutions",
    overallScore: 88,
    reasonForRecommendation:
      "Proven Track Record in Performance And Compliance, Favorable Cost-Benefit Ratio, Strong Technical Expertise",
  },
  {
    vendorName: "FastPay Networks",
    overallScore: 85,
    reasonForRecommendation:
      "Competitive Pricing, Scalability, Good Data Protection Policies, Slightly Lower Operational Efficiency",
  },
  {
    vendorName: "TransPay Corp",
    overallScore: 90,
    reasonForRecommendation:
      "Strong in Regulatory Compliance And Customer Service, Excellent Uptime, Slightly Higher Service Costs",
  },
  {
    vendorName: "SafePay Systems",
    overallScore: 80,
    reasonForRecommendation:
      "Data Security Strengths, Moderate Operational Capability, Good Scalability But Needs Improvement in Resource Availability",
  },
];

const TerminationAlternateVendorOptions = () => {
  const [openRow, setOpenRow] = useState({}); // Store the open state of each row

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const { bankId } = useSelector((state) => state.login);
  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );
  const { vendorRecommendation } = useSelector(
    (state) => state.vendorDiagnosis
  );

  const storedVendorId = localStorage.getItem("vendorId");

  const terminationAndOffboardingSurveyId = surveyListDataForm?.[
    "terminationSurveys"
  ]?.find(
    (item) => item?.name === "Alternate Vendor Options for Replacement"
  )?._id;

  useEffect(() => {
    if (terminationAndOffboardingSurveyId) {
      gql_get_vendor_recommendation({
        bankId,
        surveyId: terminationAndOffboardingSurveyId,
        vendorId: vendorBasicDetails?._id || storedVendorId,
      });
    }
  }, [bankId, terminationAndOffboardingSurveyId, vendorBasicDetails?._id]);

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
        >
          Alternate Vendor Options for Replacement
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#EBF1F8" }}>
            <TableRow>
              <TableCell>Vendor Name</TableCell>
              {/* <TableCell>Overall Score</TableCell> */}
              <TableCell>Reason for Recommendation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorRecommendation?.length > 0 &&
              vendorRecommendation[0]?.questions[0]?.approvedTableRows?.map(
                (row, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      {row?.map((col, i) => (
                        <TableCell key={i}>{col.option}</TableCell>
                      ))}
                      {/* <TableCell>{row.overallScore}/100</TableCell> */}
                      {/* <TableCell>{row.reasonForRecommendation}</TableCell> */}
                    </TableRow>
                  </React.Fragment>
                )
              )}
          </TableBody>
        </Table>
        {vendorRecommendation?.length === 0 && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Typography variant="body2">
              No recommendation data available
            </Typography>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default TerminationAlternateVendorOptions;
