import {
  AppBar,
  Box,
  createTheme,
  Drawer,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question, Wrapper } from "../../selfAssessment/selfAssessmentResponse";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_bank_admin_single_survey } from "../../../../redux/actions/vendorActions/vendorAction";
import TabPanel from "../../../UI/tabPanel/tabPanel";
import ResponsesTop from "./ResponsesTop";
import ResponseSectionTopCard from "../../riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionTopCard";
import {
  gql_get_completed_questions,
  gql_get_frequencies,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_COMPLETED_QUESTIONS } from "../../../../constants/esgDiagnosisConstant";
import {
  setSubmittedQuestions,
  setSurveyRespondedUsers,
} from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import NoData from "../../NoData/NoData";
import CloseIcon from "@material-ui/icons/Close";

export const appbarStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3374B9",
    },
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

export default function ViewSurveyDrawer({
  viewSurveyDrawer,
  handleCloseViewDialog,
  showSelectedSurvey,
}) {
  const tabClasses = appbarStyles();
  const [tab, setTab] = useState(0);
  const [sectionSelect, setSectionSelect] = useState([]);
  const [questions, setQuestions] = useState([]);
  const scroll = useRef();

  const { frequencies, completedQuestions } = useSelector(
    (state) => state.esgDiagnosisReducer
  );
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { total_no_of_questions, surveyStatus } = useSelector(
    (state) => state.riskDashboard
  );

  console.log(questions);

  const { bankId } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const handleTabChange = async (event, newValue) => {
    setTab(newValue);
  };

  const handleSelectedSection = (id) => {
    if (tab === 0) {
      const singleSection = showSelectedSurvey?.sections?.sections.find(
        (section) => section._id === id
      );

      setQuestions(singleSection?.questions);
    } else if (tab === 1) {
      const singleSection = completedQuestions?.find(
        (section) => section.sectionId === id
      );

      setQuestions(singleSection?.questions);
    }
  };

  useEffect(() => {
    if (showSelectedSurvey !== null && tab === 0) {
      const settingSection = showSelectedSurvey?.sections?.sections[0];

      setSectionSelect({
        title: settingSection?.title,
        id: settingSection?._id,
        description: settingSection?.description,
      });

      const defaultQuestion =
        showSelectedSurvey?.sections?.sections[0]?.questions;

      setQuestions(defaultQuestion);
    } else if (completedQuestions?.length > 0 && tab === 1) {
      const settingSection = completedQuestions[0];

      setSectionSelect({
        title: settingSection?.sectionTitle,
        id: settingSection?.sectionId,
        description: settingSection?.sectionDescription,
      });

      const defaultQuestion = completedQuestions[0]?.questions;

      setQuestions(defaultQuestion);
    }
  }, [showSelectedSurvey, completedQuestions, tab]);

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [sectionSelect]);

  // console.log(completedQuestions);

  useEffect(() => {
    const getCompletedQuestionsInput = {
      frequencyId: frequencies[0]?._id,
      surveyId: showSelectedSurvey?._id,
      vendorId: vendorBasicDetails?._id,
      bankId: bankId,
    };

    if (frequencies?.length > 0) {
      dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
    }

    return () => {
      dispatch({ type: SET_COMPLETED_QUESTIONS, payload: [] });
    };
  }, [frequencies]);

  const handleFrequencyChange = (freqId) => {
    const getCompletedQuestionsInput = {
      frequencyId: freqId,
      surveyId: showSelectedSurvey?._id,
      vendorId: vendorBasicDetails?._id,
      bankId: bankId,
    };

    dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
  };

  useEffect(() => {
    if (tab === 1) {
      const surveyResponseFrequenciesInput = {
        bankId,
        vendorId: vendorBasicDetails?._id,
        surveyId: showSelectedSurvey?._id,
      };

      dispatch(gql_get_frequencies(surveyResponseFrequenciesInput));

      return () => {
        dispatch(setSurveyRespondedUsers([]));
        dispatch(setSubmittedQuestions([], 0, false));
      };
    }
  }, [tab]);

  return (
    <Drawer
      anchor="right"
      onClose={() => {
        setTab(0);
        handleCloseViewDialog();
      }}
      open={viewSurveyDrawer}
      PaperProps={{
        style: { width: "75%" }, // Replace 'dynamicWidth' with your variable
      }}
    >
      <Box
        style={{ padding: "7px 10px 0" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Typography
          variant="body1"
          style={{ fontSize: "18px", fontWeight: 500 }}
        >
          {showSelectedSurvey?.name}
        </Typography>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTab(0);
            handleCloseViewDialog();
          }}
        />
      </Box>
      <MuiThemeProvider theme={appTheme}>
        <AppBar
          position="static"
          color="inherit"
          className={tabClasses.AppBar}
          style={{ marginLeft: "10px" }}
        >
          {
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab
                disableRipple
                className={tabClasses.tab}
                label={
                  <span className={tabClasses.tabLabel}>Questionnaire</span>
                }
              />

              <Tab
                disableRipple
                className={tabClasses.tab}
                label={<span className={tabClasses.tabLabel}>Response</span>}
              />
            </Tabs>
          }
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={tab} index={0}>
        {showSelectedSurvey &&
          showSelectedSurvey?.sections?.sections?.length > 0 &&
          sectionSelect?.title !== "HIDDEN" && (
            <Wrapper
              users={showSelectedSurvey?.sections?.sections}
              sectionSelect={sectionSelect}
              setSectionSelect={setSectionSelect}
              hidesearch
              handleSelectedSection={handleSelectedSection}
            >
              <div
                className="scroll"
                ref={scroll}
                style={{ width: "100%", height: "85vh" }}
              >
                <>
                  <BlueBackgroundCard
                    heading={sectionSelect?.title}
                    subHeading={sectionSelect?.description}
                  />
                  {questions?.map((question) => (
                    <Question
                      question={question?.question}
                      options={question?.answers}
                      type={question?.questionType}
                      weightage={question?.weightage}
                      comment={question?.showComment}
                      tableHeadersApi={question?.tableHeaders}
                      isFrom={"publishedSurvey"}
                    />
                  ))}
                </>
              </div>
            </Wrapper>
          )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {completedQuestions?.length === 0 ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            // style={{ height: "100%" }}
          >
            <NoData message={"No response found."} />
          </Box>
        ) : (
          <>
            <ResponseSectionTopCard
              total_no_of_questions={total_no_of_questions}
              surveyStatus={surveyStatus}
              handleFrequencyChange={handleFrequencyChange}
            />
            {completedQuestions && completedQuestions?.length > 0 && (
              <Wrapper
                completedQuestions={completedQuestions}
                sectionSelect={sectionSelect}
                setSectionSelect={setSectionSelect}
                // hidesearch
                handleSelectedSection={handleSelectedSection}
              >
                <div
                  className="scroll"
                  ref={scroll}
                  style={{ width: "100%", height: "65vh" }}
                >
                  <>
                    <BlueBackgroundCard
                      heading={sectionSelect?.title}
                      subHeading={sectionSelect?.description}
                    />
                    {questions?.map((question) => (
                      <Question
                        key={question?.questionId}
                        question={question?.question}
                        options={question?.answers}
                        type={question?.questionType}
                        weightage={question?.weightage}
                        submittedAnswer={
                          question?.questionType === "TABLE"
                            ? question?.approvedTableRows
                            : question?.approvedAnswer
                        }
                        comment={
                          question?.questionType === "TABLE"
                            ? question?.approvedTableRows?.length > 0 &&
                              question?.approvedTableRows[0][0]?.comment !==
                                null &&
                              question?.approvedTableRows[0][0]?.comment !== ""
                            : question?.approvedAnswer &&
                              question?.approvedAnswer[0]?.comment !== null &&
                              question?.approvedAnswer[0]?.comment !== ""
                        }
                        tableHeadersApi={question?.tableHeaders}
                      />
                    ))}
                  </>
                </div>
              </Wrapper>
            )}
          </>
        )}
      </TabPanel>
    </Drawer>
  );
}
