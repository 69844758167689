import React from "react";
import { Box, withStyles } from "@material-ui/core";

const Stack = withStyles((theme) => ({}))(
  ({ direction = "row", spacing = 0, style, ...props }) => (
    <Box
      display="flex"
      flexDirection={direction}
      style={{
        gap: spacing,
        ...style,
      }}
      {...props}
    />
  )
);

export default Stack;
