import {
  SET_SELECTED_QUESTION,
  SET_SELECTED_TASK,
  SET_SELECTED_BANK_SURVEY,
  SET_BANK_TASKS,
  SET_TASK_SECTIONS,
  SET_TASK_QUESTIONS,
  SET_TOTAL_SUBMITTED_QUESTIONS,
} from "../../../constants/taskConstants";

const initial_state = {
  completedSections: [],

  selectedQuestion: null,

  selectedBankSurvey: null,
  bankTasks: [],

  // selected task
  selectedTask: null,

  // list of sections and questions
  taskSections: [],
  taskQuestions: [],

  totalSubmittedQuestions: 0,
};

export default function taskReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SELECTED_TASK:
      return { ...state, selectedTask: action.payload };

    case SET_SELECTED_QUESTION:
      return { ...state, selectedQuestion: action.payload };

    case SET_SELECTED_BANK_SURVEY:
      return { ...state, selectedBankSurvey: action.payload };

    case SET_BANK_TASKS:
      return { ...state, bankTasks: action.payload };

    case SET_TASK_SECTIONS:
      return { ...state, taskSections: action.payload };

    case SET_TASK_QUESTIONS:
      return { ...state, taskQuestions: action.payload };

    case SET_TOTAL_SUBMITTED_QUESTIONS:
      return { ...state, totalSubmittedQuestions: action.payload };

    default:
      return state;
  }
}
