import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import root from "../reducers/root";
import thunk from "redux-thunk";

import { ApolloClient, InMemoryCache } from "@apollo/client";

// -------------- Redux Store --------------
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const store = createStore(root, composeEnhancers(applyMiddleware(thunk)));

// -------------- ApolloClient Creation --------------
export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default store;
