import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
} from "@material-ui/core";

// icons
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoIosArrowForward } from "react-icons/io";

// other imports
import DynamicTable, {
  textStyle,
  useTableStyles,
} from "../../../UI/dynamicTable/DynamicTable";
import { selectedTaskHeadings } from "../../../screen/taskScreen/taskTemp";

import NoData from "../../NoData/NoData";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_bank_tasks,
  SelectedSurveyTask,
  setBankTasks,
} from "../../../../redux/actions/taskActions/TaskAction";
import { toDDMMYY } from "../../../../utils/dateTime/convertTimestamp";
import moment from "moment";

// ----------------- Main Render -----------------

const CompletedTab = () => {
  const classes = useTableStyles();
  const history = useHistory();
  const location = useLocation();

  // from redux
  const dispatch = useDispatch();
  const { bankTasks } = useSelector((state) => state?.taskReducer);

  const { login } = useSelector((state) => state);

  const { selectedBankDetailsForVa } = useSelector((state) => state?.brmData);

  // ------------------- Inner Functions -------------------
  const handleTaskClick = async (selectedTask) => {
    selectedTask = {
      ...selectedTask,
      _id: selectedTask.originalId, // Set _id to the value of originalId
    };

    const address =
      selectedTask?.type === "data_request"
        ? "Data Request"
        : selectedTask?.survey?.name;
    await dispatch(SelectedSurveyTask(selectedTask));
    history.push(`${location.pathname}/${address.replace(/ /g, "_")}`);
  };
  // ----------------------------------------------------

  // ------------------- useEffects -------------------
  useEffect(() => {
    if (login?.user_type === "BSA" || login?.user_type === "BG") {
      dispatch(
        gql_get_bank_tasks(false, login?.bankId, selectedBankDetailsForVa?._id)
      );
    } else {
      dispatch(
        gql_get_bank_tasks(
          false,
          selectedBankDetailsForVa?._id,
          login?.vendorId
        )
      );
    }

    return () => {
      dispatch(setBankTasks([]));
    };
  }, []);

  const filteredTasks = (tasks) => {
    return tasks?.filter((task) => task.isCompleted === true);
  };

  const tasks = bankTasks
    ?.flatMap((e) => e?.tasks)
    .filter((task) => task?.isCompleted === true);

  // --------------------------------------------------

  return (
    <Box>
      <DynamicTable headings={selectedTaskHeadings}>
        {tasks?.length > 0 &&
          bankTasks?.map((e) => (
            <React.Fragment key={e?.date}>
              {/* Render Date Header */}

              {filteredTasks(e?.tasks)?.length > 0 && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#15314E",
                    }}
                  >
                    {e?.date}
                  </TableCell>
                </TableRow>
              )}
              {/* Render Task Rows */}
              {filteredTasks(e?.tasks)?.map((rowData) => (
                <TableRow
                  key={rowData?._id}
                  className={classes.tableRowHover}
                  onClick={() => {
                    handleTaskClick(rowData);
                  }}
                >
                  <TableCell style={textStyle("main")}>
                    {rowData?.type === "data_request"
                      ? "Data Request"
                      : rowData?.survey?.name}
                  </TableCell>

                  <TableCell>
                    <Box
                      style={noBgStatusStyle(
                        rowData.status ? "pending" : "complete"
                      )}
                    >
                      <DotIcon style={{ width: "10px", height: "10px" }} />
                      <Typography style={textStyle("main")}>
                        {rowData?.type === "data_request"
                          ? "Once"
                          : rowData?.frequencyType}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      style={noBgStatusStyle(
                        rowData?.status ? "complete" : "pending"
                      )}
                    >
                      <DotIcon style={{ widht: "10px", height: "10px" }} />
                      <Typography style={textStyle("main")}>
                        {rowData?.actionType ||
                          (rowData?.type === "data_request" && "Request")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={textStyle()}>
                    {moment(rowData?.assignedOn).format("Do MMM, YYYY")}
                  </TableCell>
                  <TableCell style={textStyle()}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      sx={{ gap: 10 }}
                    >
                      <Avatar
                        src={rowData?.assignedBy?.profileImg?.url}
                        alt={
                          rowData?.assignedBy?.profileImg?.name ||
                          rowData?.assignedBy?.name
                        }
                      />
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography style={textStyle("main")}>
                          {rowData?.assignedBy?.name}
                        </Typography>
                        <Typography
                          style={{
                            color: "#AFAFAF",
                            fontWeight: 500,
                            fontSize: "11px",
                            lineHeight: "16px",
                          }}
                        >
                          {rowData?.assignedBy?.user_type}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell style={textStyle()}>
                    <IoIosArrowForward color="#374151" size={20} />
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
      </DynamicTable>
      {tasks?.length === 0 && <NoData message={"No Completed Task Found"} />}
    </Box>
  );
};
export default CompletedTab;

// ---------------------------------------------------------
const noBgStatusStyle = (status) => {
  let colorToUse = "#FFC250";

  if (status === "complete") {
    colorToUse = "#5BB37F";
  }

  return {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    gap: "4px",
  };
};
