import { Box } from "@material-ui/core";
import React from "react";
import { BlueBackgroundCard } from "../../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import ImmidateParent from "./ImmidateAndUltimateParent/ImmidateParent";
import UltimateParent from "./ImmidateAndUltimateParent/UltimateParent";

function ImmidiateAndUltimateParent({
  immediateParent,
  ultimateParent,
  classes,
}) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box style={{ width: "49%" }}>
        <BlueBackgroundCard heading={"Immediate Parent - Group Structure"} />
        <ImmidateParent data={immediateParent} classes={classes} />
      </Box>
      <Box style={{ width: "49%" }}>
        <BlueBackgroundCard heading={"Ultimate Parent - Group Structure"} />
        <UltimateParent data={ultimateParent} classes={classes} />
      </Box>
    </Box>
  );
}

export default ImmidiateAndUltimateParent;
