import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounce } from "lodash";
import axios from "axios";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import { useSelector } from "react-redux";

import { set_snack_bar } from "../../../../../../../../redux/actions/snackbar/snackbar_action";

const Location = ({
  option,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const fetchLocations = debounce(async (query) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&type=locality&apiKey=6c147cbfca9e4f768c57f8fc6a1e7642`
      );
      setOptions(response?.data?.features);
    } catch (error) {
      set_snack_bar(true, "Could not find the location.");
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (submittedAnswer?.length > 0) {
      setInputValue(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    fetchLocations(value);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: inputValue,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [inputValue, commentBox]);

  const formattedLocation = (location) => {
    return `${location?.city}, ${location?.state}, ${location?.country}`;
  };

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = inputValue || submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = inputValue || selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = inputValue || submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = inputValue || responseAnswer;
  } else {
    handleValue = inputValue || "";
  }

  return (
    <Box>
      <Autocomplete
        style={{ pointerEvents: event }}
        options={options}
        getOptionLabel={(option) => option?.properties?.formatted}
        inputValue={handleValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={submittedAnswer ? "" : "Search Location"}
            variant={"outlined"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {submittedAnswer ? null : params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default Location;
