import React, { useEffect } from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";
import { LeftCard } from "../../step1/left/SurveyLeftCard";

import { MdOutlineAccessTime } from "react-icons/md";
import { BiDockLeft } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import SurveyList from "../../step2/left/SurveyList";
import PreviousSubmissionList from "./PreviousSubmissionList";
import {
  gql_get_all_submissions,
  gql_get_submitted_survey_responses,
} from "redux/actions/vendorActions/vendorAction";

const basicDataFirstTab = [
  {
    id: 1,
    mainHead: "Previous Submissions",
    subHead: "",
  },
];

function Step4Left() {
  const { step3Sections, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );

  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  const { allSubmissions } = useSelector((state) => state.brmData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gql_get_all_submissions(selectedVASurvey?.frequencyId));
  }, []);

  const handleSelectSection = (section) => {
    if (login?.user_type === "BSA") {
      dispatch(
        gql_get_submitted_survey_responses(
          selectedBankDetailsForVa?._id,
          login?.bankId,
          section?._id
        )
      );
    } else {
      dispatch(
        gql_get_submitted_survey_responses(
          login?.vendorId,
          selectedBankDetailsForVa?._id,
          section?._id
        )
      );
    }
  };

  return (
    <StepLeft>
      {/* <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
        Sections
      </Typography> */}
      <LeftCard toMap={basicDataFirstTab} subTabNo={1} tab={4} />
      {/* <Box
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          gap: 10,
        }}
      >
        <MdOutlineAccessTime size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {selectedVASurvey?.frequencyType}
        </Typography>
        <BiDockLeft size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {step3Sections?.sections?.length} Section(s)
        </Typography>
      </Box> */}
      <Box style={{ overflow: "scroll", height: "90%" }}>
        <PreviousSubmissionList
          submissionList={allSubmissions}
          handleSelectSection={handleSelectSection}
          step={4}
          // setIsThreadDrawerOpen={setIsThreadDrawerOpen}
          // isThreadDrawerOpen={isThreadDrawerOpen}
        />
      </Box>
    </StepLeft>
  );
}

export default Step4Left;
