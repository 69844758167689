import { gql } from "@apollo/client";

export const GQL_GET_API_INTEGRATIONS = gql`
  query ApiIntegrations($vendorId: String!) {
    apiIntegrations(vendorId: $vendorId) {
      esgApiConnectors {
        active
        description
        id
        name
        risk
        type
      }
      vendorApiConnectors {
        active
        description
        id
        name
        risk
        type
      }
    }
  }
`;

export const GQL_UPDATE_API_INTEGRATIONS_STATUS = gql`
  mutation ChangeApiIntegrationStatus(
    $active: Boolean!
    $type: String!
    $vendorId: String!
  ) {
    changeApiIntegrationStatus(
      active: $active
      type: $type
      vendorId: $vendorId
    ) {
      message
      success
    }
  }
`;
