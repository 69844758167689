import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import OverallScore from "./OverallScore";
import CompanyFindings from "./CompanyFindings";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_default_surveys,
  gql_get_tprm_score,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_SHOW_SURVEYS } from "../../../../constants/brmConstants";
import TprmSubScoreTable from "./TprmSubScoreTable";
import {
  gql_get_risk_register,
  gql_get_survey_list_dataform,
} from "../../../../redux/actions/vendorActions/vendorAction";
import AlertsDrawer from "../vendorIntake/AlertsDrawer";
import AssessmentTable from "../vendorIntake/AssessmentTable";
import ThreadDrawer from "components/widgets/vendorAdmin/surveySteps/step2/threadDrawer/ThreadDrawer";
import { ChartRenderer } from "components/widgets/charts/HistoricChart";

const defaultScores = {
  "Human and Environmental Resilience": {
    company_profile: {
      "Company Profile": 0,
    },
    information_security: {
      "Information Security": 0,
    },
    data_privacy: {
      "Data Privacy": 0,
    },
    "third_party/subcontractors": {
      "Third Party/Subcontractors": 0,
    },
    business_and_ethical_conduct: {
      "Business and Ethical Conduct": 0,
    },
    environment: {
      Environment: 0,
    },
  },
  "Digital Integrity and Compliance": {
    labour_standards: {
      "Labour Standards": 0,
    },
    human_rights: {
      "Human Rights": 0,
    },
    compliance_compliance: {
      "Compliance Compliance": 0,
    },
    risk_management: {
      "Risk Management": 0,
    },
    organizational_security: {
      "Organizational Security": 0,
    },
    physical_and_environmental_security: {
      "Physical and Environmental Security": 0,
    },
  },
  "Operational Resilience & Security": {
    asset_management: {
      "Asset Management": 0,
    },
    access_control: {
      "Access Control": 0,
    },
    vulnerability_and_threat_management: {
      "Vulnerability and Threat Management": 0,
    },
    business_continuity_management: {
      "Business Continuity Management": 0,
    },
    cloud_specific_requirements: {
      "Cloud Specific Requirements": 0,
    },
  },
};

function RiskAssessment({ isFrom }) {
  const dispatch = useDispatch();
  const { bankId } = useSelector((state) => state?.login);
  const storedBankId = localStorage.getItem("bank_id");
  const storedVendorId = localStorage.getItem("vendorId");

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );
  const { sharedSurveyDetails } = useSelector((state) => state.vendorDiagnosis);
  const { tprmScore } = useSelector((state) => state.esgDiagnosisReducer);

  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [frequencyDetails, setFrequencyDetails] = useState({});
  const [bankRoomId, setBankRoomId] = useState("");

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const surveyId = surveyListDataForm?.["riskAssessmentSurveys"].find(
    (item) => (item?.name).toLowerCase() === "dora tprm"
  );

  useEffect(() => {
    if (isFrom !== "tprmRiskReport") {
      dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }
  }, []);

  useEffect(() => {
    const getScoreInput = {
      vendorId: vendorBasicDetails?._id || storedVendorId,
      surveyId: surveyId?._id,
    };

    dispatch(gql_get_tprm_score(getScoreInput));

    dispatch(gql_get_risk_register(vendorBasicDetails?._id || storedVendorId));
  }, [surveyListDataForm]);

  const calculateScores = () => {
    const totals = {};
    let grandTotal = 0;

    // Calculate total score for each group
    Object.entries(
      tprmScore?.["Human and Environmental Resilience"]?.company_profile?.[
        "Company Profile"
      ]
        ? tprmScore
        : defaultScores
    ).forEach(([group, items]) => {
      const groupTotal = Object.values(items).reduce((sum, item) => {
        const score = parseFloat(Object.values(item)[0]); // Extract the score as a float
        return sum + score;
      }, 0);

      totals[group] = groupTotal.toFixed(2); // Save each group total
      grandTotal += groupTotal; // Accumulate grand total
    });

    // Calculate the overall average
    const overallAverage = (grandTotal / 3).toFixed(2);

    return { totals, overallAverage };
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "100%" : "63vh" }}>
      {isFrom === "tprmRiskReport" || (
        <Box
          sx={{
            mb: 2,
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Risk Assessment
          </Typography>

          <Button
            variant="text"
            style={{ color: "#3374B9", textTransform: "none" }}
            onClick={toggleAlertsDrawer}
          >
            Set Alerts
          </Button>
        </Box>
      )}

      <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
        {isFrom === "tprmRiskReport" || (
          <>
            <AssessmentTable
              surveyIds={surveyId?._id ? surveyId?._id : []}
              setIsDrawerOpen={setIsDrawerOpen}
              setFrequencyDetails={setFrequencyDetails}
              setBankRoomId={setBankRoomId}
            />
            <br />
          </>
        )}
        <OverallScore
          calculateScores={calculateScores}
          defaultScores={defaultScores}
        />
        <br />
        <TprmSubScoreTable
          severity={severity}
          calculateScores={calculateScores}
          defaultScores={defaultScores}
        />
        <br />
        <CompanyFindings />
        <br />
        <ChartRenderer
          vendorId={vendorBasicDetails?._id}
          bankId={bankId}
          surveys={[
            { title: "Risk Assessment Score", value: "RISK_ASSESSMENT_SCORE" },
            {
              title: "Human And Environmental Resilience",
              value: "HUMAN_AND_ENVIRONMENTAL_RESILIENCE",
            },
            {
              title: "Digital Integrity And Compliance",
              value: "DIGITAL_INTEGRITY_AND_COMPLIANCE",
            },
            {
              title: "Operational Resilience And Security",
              value: "OPERATIONAL_RESILIENCE_AND_SECURITY",
            },
          ]}
          defaultSurvey={{
            title: "Risk Assessment Score",
            value: "RISK_ASSESSMENT_SCORE",
          }}
        />
      </Box>

      {isDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          isFrom="vendorDiagnosis"
          frequencyDetails={frequencyDetails}
          bankRoomId={bankRoomId}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"RISK_ASSESSMENT"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={[
            "Risk_Assessment_Score",
            "Human_And_Environmental_Resilience",
            "Digital_Integrity_And_Compliance",
            "Operational_Resilience_And_Security",
          ]}
        />
      )}
    </Box>
  );
}

export default RiskAssessment;
