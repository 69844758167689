import React from "react";
import { connect } from "react-redux";
import LoginPage from "../../components/screen/login/LoginPage";
import {
  forgetPassword,
  login_with_email,
} from "../../redux/actions/login/loginAction.js";

export class LoginCon extends React.PureComponent {
  render() {
    return <LoginPage {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    login_with_email: (email, password, remember) => {
      dispatch(login_with_email(email, password, remember));
    },
    forgetPassword: (email) => {
      dispatch(forgetPassword(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCon);
