import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TrashIcon from "../../../../../../../../../src/images/trashRed.png";

import React from "react";

export default function EditPopperMenu({
  anchorEl,
  handleClose,
  handleClick,
  handleEditName,
  sectionId,
  handleRemoveSection,
}) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEditName(sectionId)}>
          <ListItemIcon>
            <EditOutlined style={{ color: "#3374B9" }} />
          </ListItemIcon>
          <ListItemText primary="Rename" />
        </MenuItem>
        <MenuItem onClick={() => handleRemoveSection(sectionId)}>
          <ListItemIcon>
            <img src={TrashIcon} style={{ height: "20px" }} alt="upload" />
          </ListItemIcon>
          <ListItemText primary="Remove Section" />
        </MenuItem>
        <MenuItem onClick={() => handleClick(true)}>
          <ListItemIcon>
            <SettingsOutlinedIcon style={{ color: "#3374B9" }} />
          </ListItemIcon>
          <ListItemText primary="Section Settings" />
        </MenuItem>
      </Menu>
    </div>
  );
}
