import React from "react";

import { Card, CardContent, makeStyles } from "@material-ui/core";

const leftCardStyle = makeStyles(() => ({
  cardContainerDefault: {
    width: "100%",
    // height: "8.4rem",
    // height: "125px" || '8.4rem',
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },

  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },
}));

export const LeftCard = ({ toMap = [], handleTabChange, subTabNo, tab }) => {
  // Map each line to a JSX element
  const renderedLines = (multiLineText) => {
    const lines = multiLineText.split("\n");

    const rendered = lines.map((line, index) => {
      return (
        <span key={index}>
          {line}
          <br /> {/* Add a line break after each line */}
        </span>
      );
    });

    return rendered;
  };

  const classes = leftCardStyle();
  return toMap.map((data, index) => (
    <Card
      variant="outlined"
      className={classes.cardContainerDefault}
      onClick={() => false && handleTabChange(index)}
      key={data.id}
      style={{
        background: data.id === subTabNo ? "#EBF1F8" : "#FDFDFD",
        cursor: "pointer",
      }}
    >
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <h1
            className={classes.mainHeadingDefault}
            style={{
              borderLeft: data.id === subTabNo && "4px solid #3374B9",
              paddingLeft: data.id === subTabNo && 5,
            }}
          >
            {data.mainHead}
          </h1>
        </div>
        {tab !== 4 && (
          <div style={{ overflowX: "scroll" }}>
            <h3
              className={classes.subHeadingDefault}
              style={{
                color: data.id === subTabNo ? "#3374b9" : "#BBBBBB",
              }}
            >
              {renderedLines(data.subHead)}
            </h3>
          </div>
        )}
      </CardContent>
    </Card>
  ));
};
