import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Icon,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomButton from "../../../UI/button/button";
import { useSelector } from "react-redux";
import moment from "moment";

const data = [
  {
    severity: "High",
    finding: "'Samba' Port Open",
    description: "Unnecessary open port",
    riskType: "Data Privacy",
    firstDetected: "30 Apr 2023",
    occurrences: 1,
    overview: "",
  },
  {
    severity: "High",
    finding: "No Security Incident Response Procedure",
    description: "Lack of organizational readiness for cybersecurity incidents",
    overview:
      "Having a strong security program to adequately protect all confidential information processed or accessed by your company helps demonstrate to your top customers that you will be able to keep their information safe.",
    riskType: "Security/Cyber",
    firstDetected: "",
    occurrences: 1,
  },
  {
    severity: "High",
    finding: "No Risk Assessment Program",
    description: "Potential gaps in cybersecurity readiness",
    riskType: "Security/Cyber",
    firstDetected: "",
    occurrences: 1,
    overview: "",
  },
  {
    severity: "Medium",
    finding: "Potential Negative Environmental Impact",
    description: "Environmental Impact Risk",
    riskType: "ESG/Sustainability",
    firstDetected: "10 Aug 2023",
    occurrences: 2,
    overview: "",
  },
  {
    severity: "High",
    finding: "Risk Of Non-Compliance With Regulations",
    description: "Regulatory Non-Compliance Risk",
    riskType: "Regulatory Compliance",
    firstDetected: "19 Oct 2018",
    occurrences: 4,
    overview: "",
  },
];

const CompanyFindings = () => {
  const [openRow, setOpenRow] = useState({});
  const { riskRegister } = useSelector((state) => state.brmData);

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#EFB700";
      } else if (color === "green") {
        return "#008450";
      } else {
        return "#B81D13";
      }
    }
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
        >
          Risk Register
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#EBF1F8" }}>
            <TableRow>
              <TableCell>Sev.</TableCell>
              <TableCell>Findings</TableCell>
              <TableCell>Risk Type</TableCell>
              <TableCell>First Detected</TableCell>
              <TableCell>Occurrences</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {riskRegister?.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <Icon
                      style={{
                        color:
                          row.severity === "HIGH"
                            ? severity("text", "red")
                            : row.severity === "LOW"
                            ? severity("text", "green")
                            : severity("text", "yellow"),
                      }}
                    >
                      error
                    </Icon>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">{row?.finding}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {row?.description}
                    </Typography>
                  </TableCell>
                  <TableCell>{row?.risk}</TableCell>
                  <TableCell>
                    {moment(row?.createdAt).format("Do MMM, YYYY")}
                  </TableCell>
                  <TableCell align="center">{row?.occurrences}</TableCell>
                  <TableCell>{row?.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => toggleRow(index)}>
                      {openRow[index] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                  >
                    <Collapse in={openRow[index]} timeout="auto" unmountOnExit>
                      <Box
                        style={{
                          margin: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {row.riskCompensationStatement && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ width: "65%" }}
                          >
                            <strong>Overview:</strong>{" "}
                            {row.riskCompensationStatement}
                          </Typography>
                        )}
                        {/* <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            width: "30%",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <CustomButton color="primary" size="small">
                            Waive this risk
                          </CustomButton>
                          <CustomButton color="primary" size="small">
                            Request Remediation
                          </CustomButton>
                        </div> */}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompanyFindings;
