import React, { useEffect } from "react";
import { Box, Typography, Chip, makeStyles } from "@material-ui/core";
import logo from "../../../../images/reactLogo.png";
import CustomButton from "../../../UI/button/button";
import CriticalityForVendor from "./CriticalityForVendor";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../../UI/chip/chip";

const useStyle = makeStyles((theme) => ({
  vendorDetails: {
    fontSize: "14px",
  },
}));

const VendorBasicDetails = () => {
  const classes = useStyle();
  const { vendorBasicDetails: details } = useSelector(
    (state) => state?.brmData
  );

  console.log(details);

  return (
    <Box>
      {/* Container for Basic Assessment Details */}
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>
          Basic Assessment Details
        </Typography>
        <CustomButton color="primary">Edit Details</CustomButton>
      </Box>

      {/* Vendor Image */}
      <Box ml={3} className="scroll" style={{ height: "59vh" }}>
        <Box display="flex" gridGap={"20px"}>
          <img
            src={details?.logo?.url} // Replace with the actual logo URL
            alt={details?.logo?.name}
            style={{ borderRadius: "50%", width: 100, height: 100 }}
          />

          <Box>
            {/* Vendor Name and URL */}
            <Typography variant="h6">{details?.name}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginBottom: "0.5rem" }}
            >
              {details?.website}
            </Typography>

            {/* Chips for tags */}
            <Box mb={2}>
              <CustomChip
                label={details?.sector?.industry?.industry}
                style={{ marginRight: 5 }}
                color="primary"
              />
              <CustomChip
                label={details?.sector?.sector?.sector}
                style={{ marginRight: 5 }}
                color="primary"
              />
              <CustomChip
                label={details?.address?.country}
                style={{ marginRight: 5 }}
                color="primary"
              />
            </Box>
          </Box>
        </Box>

        {/* Additional Vendor Details */}
        <Box display="flex" justifyContent={"space-between"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gridGap={"10px"}
            width={"40%"}
          >
            {/* Vendor details */}
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Vendor Name: <br />
              <strong>{details?.name}</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              ISIN No.: <br />
              <strong>{details?.isin}</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Vendor Region: <br />
              <strong>Europe, UK</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Industry: <br />
              <strong>{details?.sector?.industry?.industry}</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Sector: <br />
              <strong>{details?.sector?.sector?.sector}</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Established Date: <br />
              <strong>10-12-2005</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Products: <br />
              <strong>Software Solutions</strong>
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            gridGap={"10px"}
            width={"50%"}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Location: <br />
              <strong>32, Branko Towers, Chennai Regional Office</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Criticality: <br />
              <Chip label="Medium" color="primary" variant="outlined" />
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Regulation: <br />
              <strong>The Digital Operational Resilience Act (DORA)</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Number of Employees: <br />
              <strong>{details?.employeesCount}</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Key Stakeholders: <br />
              <strong>Team Leads</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Risk Assessment: <br />
              <strong>Cybersecurity Risk Assessment</strong>
            </Typography>
          </Box>
        </Box>

        {/* Vendor Contact Details */}
        <Box display="flex" justifyContent={"space-between"} mt={5}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gridGap={"10px"}
            width={"40%"}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Company Address: <br />
              <strong>
                Estancia IT Park, Plot No. 140 & 151, Grand Southern Trunk Rd,
                Allancherry
              </strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Contact Number: <br />
              <strong>+91-9876543210</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Email Address: <br />
              <strong>Contact@Example.Com</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Office Hours: <br />
              <strong>Monday To Friday, 9:00 AM - 6:00 PM</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Social Media Handles: <br />
              <strong>@PayWise_club</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Customer Support: <br />
              <strong>+91-1234567890</strong>
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Feedback Email: <br />
              <strong>Feedback@PayWise.Com</strong>
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            gridGap={"10px"}
            width={"50%"}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.vendorDetails}
            >
              Company Operation: <br />
              <strong>
                PayWise Cloud Hosting Is A Leading Provider Of Cloud Hosting
                Services, Specializing In Managing Servers And Backup Data For
                Businesses Of All Sizes. Our State-Of-The-Art Infrastructure
                Ensures Reliable And Secure Hosting Solutions For Your Valuable
                Assets.
              </strong>
            </Typography>
          </Box>
        </Box>

        {/* Criticality of Vendor */}
        <Box mt={5}>
          <Typography
            variant="body2"
            style={{ fontWeight: 500, marginBottom: "10px" }}
          >
            Criticality of Function for Vendor
          </Typography>
          <CriticalityForVendor />
        </Box>
      </Box>
    </Box>
  );
};

export default VendorBasicDetails;
