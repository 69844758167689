import { reduxGet } from "utils/_helpers/reduxGet";
import apiGqlBoilerplate from "utils/_helpers/apiGqlBoilerplate";
import { set_snack_bar } from "../snackbar/snackbar_action";
// constants
import {
  SET_BANK_STRUCTURE,
  SET_ORG_ENTITY_LIST,
} from "constants/vendorOrgConst";

// gql
import {
  GQL_ADD_ENTITY,
  GQL_GET_BANK_STRUCTURE,
  GQL_GET_ENTITY_LIST,
} from "redux/graphql/gql_vendorOrg";

// ------------------------- Payloads -------------------------
export const setOrgEntityList = (payload) => {
  return {
    type: SET_ORG_ENTITY_LIST,
    payload,
  };
};

export const setBankStructure = (payload) => {
  return {
    type: SET_BANK_STRUCTURE,
    payload,
  };
};

// -------------------------- Actions --------------------------

export async function gql_get_entity_list() {
  const { bank } = reduxGet.getState().login;

  const rootNodeId = bank?.rootNodeId ? bank?.rootNodeId : bank?._id;

  const data = await apiGqlBoilerplate({
    querySchema: GQL_GET_ENTITY_LIST,
    variables: { rootNodeId },
    queryType: "query",
    hideLoader: true,
    callback: (data) => {
      reduxGet.dispatch(setOrgEntityList(data?.bankEntityList));
    },
  });

  return data;
}

export function gql_add_enitity({
  name,
  entityType,
  userDefinedId,
  streetName,
  state,
  closeDrawer,
}) {
  const { bank } = reduxGet.getState().login;

  const parentId = bank?.parentId ? bank?.parentId : bank?._id;
  const rootNodeId = bank?.rootNodeId ? bank?.rootNodeId : bank?._id;

  apiGqlBoilerplate({
    querySchema: GQL_ADD_ENTITY,
    variables: {
      name,
      entityType,
      userDefinedId,
      streetName,
      state,
      parentId,
      rootNodeId,
    },
    queryType: "mutate",
    callback: (data) => {
      if (data?.addBankEntity?.success) {
        gql_get_entity_list({ rootNodeId });
        if (Boolean(closeDrawer)) {
          reduxGet.dispatch(set_snack_bar(true, "Entity added"));
          closeDrawer();
        }
      } else {
        reduxGet.dispatch(set_snack_bar(true, data?.addBankEntity?.message));
      }
    },
  });
}

export function gql_get_bank_structure() {
  const { bank } = reduxGet.getState().login;
  const rootNodeId = bank?.rootNodeId ? bank?.rootNodeId : bank?._id;

  apiGqlBoilerplate({
    querySchema: GQL_GET_BANK_STRUCTURE,
    variables: { rootNodeId },
    queryType: "query",
    callback: (data) => {
      reduxGet.dispatch(setBankStructure(data?.bankStructure));
    },
  });
}

// --------------------------------------------------------------
