import React, { useEffect } from "react";
import StepLayOut from "../stepLayout";
import Step4Left from "./left/Step4Left";
import Step4Right from "./right/Step4Right";

// from redux
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_completed_section_questions,
  setCompletedSecQues,
} from "../../../../../redux/actions/vendorActions/vendorAction";

function SurveyStep4({ response }) {
  const dispatch = useDispatch();
  const { completedSecQues, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );

  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  // useEffect(() => {
  //   if (login?.user_type === "BSA") {
  //     dispatch(
  //       gql_get_completed_section_questions(
  //         selectedVASurvey?.surveyId,
  //         selectedVASurvey?.frequencyId,
  //         selectedBankDetailsForVa?._id
  //       )
  //     );
  //   } else {
  //     dispatch(
  //       gql_get_completed_section_questions(
  //         selectedVASurvey?.surveyId,
  //         selectedVASurvey?.frequencyId,
  //         login?.vendorId
  //       )
  //     );
  //   }

  //   return () => {
  //     dispatch(setCompletedSecQues([]));
  //   };
  // }, []);

  return (
    <StepLayOut
      left={<Step4Left />}
      right={
        <Step4Right completedSecQues={completedSecQues} response={response} />
      }
    />
  );
}

export default SurveyStep4;
