import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ProgressHeader from "../CommonComps/ProgressHeader";
import SurveySuccess from "../../../../../../../images/SurveySuccess.svg";
import CustomButton from "../../../../../../UI/button/button";
import { useState } from "react";
import RedoDialog from "../CommonComps/RedoDialog";
import { useDispatch, useSelector } from "react-redux";
import { gql_redo_entire_section } from "../../../../../../../redux/actions/taskActions/TaskAction";

const SectionComplete = ({
  progress,
  setProgress,
  totalQues,
  currentQuesNo,
  setRedoSpecific,
  activeSectionIndex,
  setActiveSectionIndex,
  setCurrentQuesNo,
  setCurrentQuestion,
  setSectionComplete,
  activeSection,
}) => {
  const history = useHistory();

  // from redux
  const { taskSections, taskQuestions, selectedTask } = useSelector(
    (state) => state.taskReducer
  );

  const dispatch = useDispatch();

  // states
  const [openRedoDialog, setOpenRedoDialog] = useState(false);

  const isLastSection = activeSectionIndex === taskSections?.length - 1;

  const changeProgressBy = (1 / totalQues) * 100;

  // ------------------ redo actions ------------------
  const toggleRedoDialog = () => {
    setOpenRedoDialog(!openRedoDialog);
  };

  const handleRedoSection = (option) => {
    if (option === "SpecificQues") {
      setRedoSpecific(true);
    }
    if (option === "EntireSec") {
      const fetchQuestionsObj = {
        frequencyId: selectedTask?.frequencyId,
        sectionId: activeSection?._id,
        setCurrentQuestion,
      };

      dispatch(gql_redo_entire_section(fetchQuestionsObj));

      setProgress(changeProgressBy);
      setCurrentQuesNo(1);
      setCurrentQuestion(taskQuestions[currentQuesNo - 1]);
      setSectionComplete(false);
    }

    setOpenRedoDialog(false);
  };
  // ---------------------------------------------------

  // ------------------ next section actions ------------------
  const handleNextSection = () => {
    if (isLastSection) {
      history.goBack();
    } else {
      setActiveSectionIndex((prev) => prev + 1);
    }
  };
  // ----------------------------------------------------------

  return (
    <Box width={"70vw"} height={"100%"} style={{ position: "relative" }}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "0px 0px 8px 0px #3374B940",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
          {/* Header */}
          <ProgressHeader
            title={"Section Complete"}
            sub={"Thank you"}
            progress={progress}
            totalQues={totalQues}
            currentQuesNo={currentQuesNo}
          />

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ gap: 10 }}
          >
            <img
              src={SurveySuccess}
              alt="SurveyComplete"
              style={{ aspectRatio: "1:1", width: "35%" }}
            />
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              This Section has been Saved.
            </Typography>
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              If you wish to redo the survey please use the redo button. Or go
              back to the task section.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* action buttons */}
      <Box
        style={{
          display: "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        <CustomButton
          variant={"text"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={toggleRedoDialog}
        >
          Redo Section
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={handleNextSection}
        >
          {isLastSection ? "Go Back" : "Continue to Next Section"}
        </CustomButton>
      </Box>

      <RedoDialog
        open={openRedoDialog}
        handleClose={toggleRedoDialog}
        proceedAction={handleRedoSection}
      />
    </Box>
  );
};
export default SectionComplete;
