const { reduxGet } = require("./reduxGet");
const { client } = require("redux/store/store");
const {
  set_loader,
  unset_loader,
} = require("redux/actions/loader/loader_action");
const {
  checkIsUnAuth,
  handleUnauthInterceptor,
} = require("redux/actions/login/loginAction");
const { set_snack_bar } = require("redux/actions/snackbar/snackbar_action");

const apiGqlBoilerplate = async ({
  querySchema, // from graphql folder
  variables, // variables that are to be passed
  queryType = "query", // query or mutate
  callback = () => {},
  hideLoader,
  hideMsg = true,
}) => {
  const { dispatch } = reduxGet;

  if (!hideLoader) {
    dispatch(set_loader());
  }

  try {
    const queryObj = {
      [queryType === "mutate" ? "mutation" : queryType]: querySchema,
      variables: { ...variables },
    };

    const result = await client[queryType](queryObj);

    if (result.errors) {
      const isUnAuth = checkIsUnAuth(result.errors[0]);
      if (isUnAuth) {
        await dispatch(handleUnauthInterceptor(queryObj, queryType));
      } else {
        throw result.errors[0];
      }
    }

    await callback(result?.data);

    if (!hideMsg) {
      dispatch(set_snack_bar(true, result?.data?.message));
    }

    // Return the data from the function
    return result?.data;
  } catch (error) {
    console.log(error);
    dispatch(set_snack_bar(true, error?.message));
  } finally {
    if (!hideLoader) {
      dispatch(unset_loader());
    }
  }
};

export default apiGqlBoilerplate;
