import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../../../../../../UI/button/button";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { useState } from "react";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { formattedDate } from "./DateFormatter";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";

import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";

const useStyles = makeStyles((theme) => ({
  chatBox: {
    backgroundColor: "#EBF1F8",
    border: "2px solid #A1BFDF",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  messageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },

  avatar: {
    marginRight: theme.spacing(2),
  },

  name: {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },

  message: {
    fontSize: "12px",
  },

  icon: {
    color: "#3374B9",
    marginRight: "10px",
  },
  time: {
    fontSize: "12px",
    fontWeight: "400",
    marginLeft: "10px",
    color: "#afafaf",
  },
}));

function SubmissionBox({ userId, data, setContributor, isFrom }) {
  const classes = useStyles();

  const [expand, setExpand] = useState(false);

  console.log(data);

  const userName = (sentBy) => {
    return userId === sentBy?._id ? "You" : sentBy?.name;
  };

  function containsSameWord(text, wordToCheck) {
    let regex = new RegExp("\\b" + wordToCheck + "\\b", "i");
    return regex.test(text);
  }

  return (
    <Box className={classes.chatBox}>
      {/* <Box key={data?.sentBy?._id} className={classes.messageContainer}>
        <FormatListBulletedIcon className={classes.icon} />

        <Avatar
          alt={data?.sentBy?.name}
          src={data?.sentBy?.url}
          className={classes.avatar}
        />
        <Box>
          <Typography variant="h3" className={classes.name}>
            {data?.sentBy?.name}
            <span className={classes.time}>{formattedDate(data?.sentAt)}</span>
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {data?.text}
          </Typography>
        </Box>
      </Box> */}

      {data?.next?.messages
        ?.slice(0, expand ? data?.next?.messages?.length : 2)
        ?.map((message, index) => (
          <Box key={index} className={classes.messageContainer}>
            {
              message?.type === "Hidden" &&
              containsSameWord(message?.text, "submission") ? (
                <FormatListBulletedIcon className={classes.icon} />
              ) : message?.type === "Hidden" &&
                containsSameWord(message?.text, "approved") ? (
                <CheckIcon className={classes.icon} />
              ) : message?.type === "Hidden" &&
                containsSameWord(message?.text, "unapproved") ? (
                <UndoRoundedIcon className={classes.icon} />
              ) : message?.type === "Hidden" &&
                containsSameWord(message?.text, "in progress") ? (
                <AutorenewRoundedIcon className={classes.icon} />
              ) : (
                <ChatBubbleOutlineOutlinedIcon className={classes.icon} />
              )
              // <SettingsBackupRestoreOutlinedIcon className={classes.icon} />
              // <UndoOutlinedIcon className={classes.icon} />
              // <HistoryOutlinedIcon className={classes.icon} />
              // <UndoRoundedIcon className={classes.icon} />
            }

            <Avatar
              alt={message?.sentBy?.name}
              src={message?.sentBy?.profileImg?.url}
              className={classes.avatar}
            />
            <Box>
              <Typography variant="h3" className={classes.name}>
                {userName(message?.sentBy)}
                <span className={classes.time}>
                  {formattedDate(message?.sentAt)}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.message}>
                {userId === message?.sentBy?._id &&
                message?.type === "Hidden" &&
                containsSameWord(message?.text, "approved")
                  ? "You approved this version."
                  : userId === message?.sentBy?._id &&
                    message?.type === "Hidden" &&
                    containsSameWord(message?.text, "unapproved")
                  ? "You unapproved this version."
                  : userId === message?.sentBy?._id &&
                    message?.type === "Hidden" &&
                    containsSameWord(message?.text, "in progress")
                  ? `You marked this as "in progress"`
                  : message?.text}
              </Typography>
            </Box>
          </Box>
        ))}

      <Box
        style={{
          position: "relative",
        }}
      >
        {isFrom === "vendorDiagnosis" ? (
          <CustomButton
            variant="contained"
            color="primary"
            size="small"
            style={{
              marginTop: "10px",
            }}
            onClick={() => {
              setContributor(data);
            }}
          >
            {data?.isApproved === true
              ? "View Approved Submission"
              : data?.isApproved === null
              ? "View Submission"
              : "View Rejected Submission"}
          </CustomButton>
        ) : (
          <CustomButton
            variant="contained"
            color="primary"
            size="small"
            style={{
              marginTop: "10px",
            }}
            onClick={() => {
              setContributor(data);
            }}
          >
            {data?.isApproved === true
              ? "View Approved Submission"
              : "View Submission"}
          </CustomButton>
        )}

        {data?.next?.messages?.length > 2 && (
          <Typography
            color="primary"
            size="small"
            style={{
              position: "absolute",
              bottom: -10,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => setExpand((prev) => !prev)}
          >
            {!expand ? (
              <KeyboardArrowDownRoundedIcon />
            ) : (
              <KeyboardArrowUpRoundedIcon />
            )}
          </Typography>
        )}
      </Box>

      {/* {isSubmissionOpen && (
        <SubmissionDrawer
          isOpen={isSubmissionOpen}
          setIsOpen={setIsSubmissionOpen}
          contributor={contributor}
        />
      )} */}
    </Box>
  );
}

export default SubmissionBox;
