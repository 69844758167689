import React from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { Box, Typography, Checkbox } from "@material-ui/core";
import { ListItem } from "../left/SurveyList";
import { FaListUl } from "react-icons/fa6";

export default function DrawerStep1({
  listData = [],
  drawerFor,
  handleToggle,
}) {
  return (
    <Box style={{ height: "75%", marginTop: "10px", overflow: "hidden" }}>
      {/* header */}
      <Box
        style={{
          backgroundColor: "#EBF1F8",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          height: "10%",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            // width: "20%",
            color: "#505050",
          }}
        >
          {drawerFor === "sections" ? "Sections" : "Questions"}
        </Typography>

        {drawerFor === "sections" && (
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 500,
              // width: "20%",
              color: "#505050",
            }}
          >
            No. of Questions
          </Typography>
        )}
      </Box>
      {/* body */}
      <List style={{ overflow: "scroll", marginTop: "10px", height: "85%" }}>
        {listData.map((value, index) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={value?.id}
              role={undefined}
              dense={true}
              button
              selected={value?.isCheck}
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                marginBottom: "10px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              onClick={() => handleToggle(value?.id)}
            >
              <ListItemText
                id={labelId}
                primary={
                  <span>
                    <Checkbox
                      color="primary"
                      edge="start"
                      // checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      inputProps={{ "aria-labelledby": labelId }}
                      checked={value?.isCheck}
                    />
                    {value?.name}
                  </span>
                }
              />

              {drawerFor === "sections" && (
                <ListItemText
                  style={{ textAlign: "right" }}
                  id={labelId}
                  primary={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: 4,
                      }}
                    >
                      <FaListUl size={"16px"} style={{ color: "#5C90C7" }} />
                      {value?.noOfQuestions}
                    </span>
                  }
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
