import { Fragment, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";

import { Box, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { useDispatch, useSelector } from "react-redux";
import ShareSurveyStep1 from "./ShareSurveyStep1";
import ShareSurveyStep2 from "./ShareSurveyStep2";
import SurveyStepper from "components/widgets/vendorAdmin/surveySteps/stepHeader/SurveyStepper";
import CustomButton from "components/UI/button/button";
import ShareSurveyStep3 from "./ShareSurveyStep3";
import {
  gql_get_survey_list_dataform,
  gql_share_bank_admin_survey,
} from "redux/actions/vendorActions/vendorAction";
import VendorBasicDetails from "components/widgets/companySetup/vendorBasicDetails/VendorBasicDetails";

export default function ShareSurveyDrawer(props) {
  const { isDrawerOpen, handleCloseDrawer, optList, drawerFor, surveyData } =
    props;

  console.log(surveyData);

  // from redux
  const dispatch = useDispatch();
  const { members, vendorBasicDetails } = useSelector((state) => state.brmData);
  const { generalSettings, login } = useSelector((state) => state);

  const updatedMembers = members?.map((member) => ({
    ...member,
    isCheck: false,
  }));

  // states
  const [activeStep, setActiveStep] = useState(0);
  const [list, setList] = useState([]); // contains list of objects for selected sections/questions
  const [contributors, setContributors] = useState([]);
  const [approvers, setApprover] = useState([]);
  const [users, setUsers] = useState(updatedMembers);
  const [type, setType] = useState("INTERNAL");

  const [selectedOption, setSelectedOption] = useState("never");

  const [frequencyData, setFrequencyData] = useState({});

  useEffect(() => {
    const updatedMembers =
      type === "INTERNAL" && surveyData?.type !== "EXTERNAL"
        ? generalSettings?.users
            ?.filter((member) => member?.user_type !== "BG")
            ?.map((member) => ({
              ...member,
              isCheck: false,
            }))
        : type === "INTERNAL" && surveyData?.type === "EXTERNAL"
        ? generalSettings?.users
            ?.filter((member) => member?.user_type === "BA")
            ?.map((member) => ({
              ...member,
              isCheck: false,
            }))
        : members?.map((member) => ({
            ...member,
            isCheck: false,
          }));

    setUsers(updatedMembers);
  }, [members, generalSettings?.users, type, surveyData?.type]);

  // --------------- Constants ---------------
  const steps = ["Select Stakeholder", "Set Frequency"];
  // const steps = ["Select Stakeholder", "Workflow Settings", "Set Frequency"];

  // these list will contain the final selected items ids
  const selectedOptions = [];
  const selectedContributors = [];
  const selectedApprovers = [];

  // list?.map((e) => e?.isCheck && selectedOptions.push(e?.id));
  contributors?.map((e) => selectedContributors.push(e?._id));
  approvers?.map((e) => selectedApprovers.push(e?._id));
  // --------------------------------------------

  // --------------- Inner Functions ---------------

  // step 1 functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // const toggleSingleItemList = (itemId) => {
  //   const updatedList = list?.map((item) => {
  //     return item.id === itemId ? { ...item, isCheck: !item.isCheck } : item;
  //   });

  //   setList(updatedList);
  // };

  const toggleAllItems = (value) => {
    const updatedList = list.map((item) => ({
      ...item,
      isCheck: value,
    }));
    setList(updatedList);
  };

  // step 2 functions
  const handleContributers = () => {
    // Filter the selected users
    const updateList = users.filter((data) => data?.isCheck);

    // Add only users who are not already in the contributer list
    const newContributers = updateList.filter(
      (item) =>
        !contributors.some((contributer) => contributer._id === item._id)
    );

    // Update contributers and reset isCheck
    setContributors([
      ...contributors,
      ...newContributers.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);

    // Reset isCheck for all users
    setUsers((prevUsers) =>
      prevUsers.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleApprovers = () => {
    // Filter the selected users
    const updateList = users.filter((data) => data?.isCheck);

    // Add only users who are not already in the approvers list
    const newApprovers = updateList.filter(
      (item) => !approvers.some((approver) => approver._id === item._id)
    );

    // Update approvers and reset isCheck
    setApprover([
      ...approvers,
      ...newApprovers.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);

    // Reset isCheck for all users
    setUsers((prevUsers) =>
      prevUsers.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleDeleteContributers = (id) => {
    const updateList = contributors.filter((data) => data?._id !== id);
    setContributors(updateList);
  };

  const handleDeleteApprovers = (id) => {
    const updateList = approvers.filter((data) => data?._id !== id);
    setApprover(updateList);
  };

  const toggleSingleItemUser = (itemId) => {
    const updatedList = users.map((item) =>
      item._id === itemId ? { ...item, isCheck: !item.isCheck } : item
    );

    setUsers(updatedList);
  };

  const filteredBAUsers = users
    ?.filter((user) => user?.isCheck)
    ?.every((user) => user?.user_type === "BA");

  const filteredBSAUsers = users
    ?.filter((user) => user?.isCheck)
    ?.every((user) => user?.user_type === "BSA");

  const filteredVAUsers = users
    ?.filter((user) => user?.isCheck)
    ?.every((user) => user?.user_type !== "BSA" || user?.user_type !== "BSA");

  console.log(filteredVAUsers);

  const handleReset = () => {
    setContributors([]);
    setApprover([]);
    handleCloseDrawer();
    setActiveStep(0);
    setType("INTERNAL");
    setSelectedOption("never");
  };

  const handleCompleteList = async () => {
    let obj;
    const startDate =
      frequencyData?.day &&
      new Date(
        `${frequencyData?.year}-${frequencyData?.month}-${frequencyData?.day}`
      ).toISOString();
    const endOn =
      frequencyData?.endOnDay &&
      new Date(
        `${frequencyData?.endOnYear}-${frequencyData?.endOnMonth}-${frequencyData?.endOnDay}`
      ).toISOString();
    const endAfter =
      frequencyData?.endAfterDay &&
      new Date(
        `${frequencyData?.endAfterYear}-${frequencyData?.endAfterMonth}-${frequencyData?.endAfterDay}`
      ).toISOString();

    if (selectedOption === "never") {
      obj = {
        approverIds: selectedApprovers,
        contributorIds: selectedContributors,
        endNever: true,
        startDate,
        frequencyTypeName: frequencyData?.frequencyTypeName,
        vendorId: vendorBasicDetails?._id,
        version: surveyData?.publishedVersion,
        surveyId: surveyData?._id,
        bankId: login?.bankId,
      };
    } else if (selectedOption === "on") {
      obj = {
        approverIds: selectedApprovers,
        contributorIds: selectedContributors,
        endOn,
        startDate,
        frequencyTypeName: frequencyData?.frequencyTypeName,
        vendorId: vendorBasicDetails?._id,
        version: surveyData?.publishedVersion,
        surveyId: surveyData?._id,
        bankId: login?.bankId,
      };
    } else {
      obj = {
        approverIds: selectedApprovers,
        contributorIds: selectedContributors,
        endAfter,
        startDate,
        frequencyTypeName: frequencyData?.frequencyTypeName,
        vendorId: vendorBasicDetails?._id,
        version: surveyData?.publishedVersion,
        surveyId: surveyData?._id,
        bankId: login?.bankId,
      };
    }
    await dispatch(gql_share_bank_admin_survey(obj));

    dispatch(gql_get_survey_list_dataform(vendorBasicDetails?._id));

    handleReset();
  };

  // ---------------------------------------------

  // --------------- useEffects() ---------------
  useEffect(() => {
    const updated = optList?.map((survey, index) => ({
      id: survey?._id,
      name: drawerFor === "sections" ? survey?.title : survey?.question,
      noOfQuestions: survey?.noOfQuestions || 0,
      isCheck: false,
    }));
    setList(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------------------------------------------

  return (
    <div>
      <Fragment>
        <Drawer
          anchor={"right"}
          open={isDrawerOpen}
          onClose={() => {
            handleCloseDrawer();
            handleReset();
          }}
        >
          <Box
            style={{
              padding: "15px",
              width: "70vw",
              height: "100%",
            }}
          >
            <Box
              display={"flex"}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  width: "20%",
                }}
              >
                {/* Assign {drawerFor === "sections" ? "Section" : "Questions"} */}
                Share Assessment
              </Typography>
              <IconButton onClick={handleCloseDrawer}>
                <ClearIcon
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#3374B9",
                  }}
                />
              </IconButton>
            </Box>
            {/* header */}

            <Box style={{ width: "100%" }}>
              <SurveyStepper steps={steps} activeStep={activeStep} />
            </Box>

            {/* instruction */}
            {/* <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                // width: "20%",
                color: "#505050",
              }}
            >
              Empower your survey experience by selecting specific {drawerFor}{" "}
              tailored to your interests, ensuring a focused and relevant
              exploration of environmental, social, and governance aspects.
            </Typography> */}

            {/* table */}
            {activeStep === 0 && (
              <ShareSurveyStep1
                listData={users}
                handleToggle={toggleSingleItemUser}
                handleApprovers={handleApprovers}
                handleContributers={handleContributers}
                contributors={contributors}
                approvers={approvers}
                handleDeleteApprovers={handleDeleteApprovers}
                handleDeleteContributers={handleDeleteContributers}
                type={type}
                setType={setType}
                surveyData={surveyData}
                isDrawerOpen={isDrawerOpen}
                filteredBAUsers={filteredBAUsers}
                filteredBSAUsers={filteredBSAUsers}
                filteredVAUsers={filteredVAUsers}
              />
            )}
            {activeStep === 1 && (
              <ShareSurveyStep3
                setFrequencyData={setFrequencyData}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            )}

            {/* button */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "absolute",
                bottom: 5,
                right: 15,
                gap: 10,
              }}
            >
              <CustomButton
                variant="text"
                color="primary"
                onClick={() => {
                  handleReset();
                  setFrequencyData({});
                }}
              >
                Cancel
              </CustomButton>

              {activeStep > 0 && (
                <CustomButton
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                    setFrequencyData({});
                  }}
                >
                  Go Back
                </CustomButton>
              )}

              <CustomButton
                variant="contained"
                color="primary"
                onClick={activeStep === 0 ? handleNext : handleCompleteList}
                disabled={
                  activeStep === 1
                    ? !frequencyData?.day ||
                      !frequencyData?.month ||
                      !frequencyData?.year ||
                      !frequencyData?.frequencyTypeName
                    : contributors.length === 0 || approvers.length === 0
                }
              >
                {activeStep === steps?.length - 1
                  ? "Share"
                  : "Proceed to next step"}
              </CustomButton>
            </Box>
          </Box>
        </Drawer>
      </Fragment>
    </div>
  );
}
