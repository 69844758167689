import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import RiskXchangeIcon from "../../../../images/riskXchange.png";
import ComplyAdvantageIcon from "../../../../images/complyAdv.png";
import WazuhIcon from "../../../../images/wazuh.png";
import ArivuIcon from "../../../../images/arivu.png";
import GrapheneIcon from "../../../../images/graphene.png";
import SbtIcon from "../../../../images/sbt.png";
import RefinitiveIcon from "../../../../images/refinitive.png";
import IqIcon from "../../../../images/VendorIQ.svg";
import KypIcon from "../../../../images/VendorKYP.png";

import CustomButton from "../../../UI/button/button";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_api_integration,
  gql_update_api_integration_status,
} from "../../../../redux/actions/vendorActions/vendorAction";
import { setControversyData } from "../../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import DynamicDrawer from "../../../UI/dynamicDrawer/DynamicDrawer";
import ApiConnections from "../apiConnections";

const vendorApiList = [
  {
    name: "Risk Xchange",
    logo: RiskXchangeIcon,
    risk: "4 Risks Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
  {
    name: "Comply Advantage",
    logo: ComplyAdvantageIcon,
    risk: "4 Risks Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
  {
    name: "Wazuh",
    logo: WazuhIcon,
    risk: "2 Risks Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
  {
    name: "Arivu",
    logo: ArivuIcon,
    risk: "3 Risks Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
  {
    name: "Grapheneis",
    logo: GrapheneIcon,
    risk: "5 Risks Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
];

const esgApiList = [
  {
    name: "Science Based Targets",
    logo: SbtIcon,
    risk: "6 Data's Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
  {
    name: "Refinitive",
    logo: RefinitiveIcon,
    risk: "4 Data's Identified",
    description:
      "The construction of a hydroelectric power station has an ambiguous impact on the life of the local population.",
  },
];

const ApiConnectionV2 = () => {
  const [switchEnable, setSwitchEnable] = useState({
    KYP: null,
    IQ: null,
    WAZUH: null,
  });
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const dispatch = useDispatch();
  const { vendorBasicDetails, apiIntegration } = useSelector(
    (state) => state?.brmData
  );

  const storedVendorId = localStorage.getItem("vendorId");

  const handleClose = () => {
    setToggleDrawer(false);
  };

  useEffect(() => {
    dispatch(
      gql_get_api_integration(vendorBasicDetails?._id || storedVendorId)
    );
  }, []);

  const handleSwitchToggle = (type, checked) => {
    dispatch(
      gql_update_api_integration_status(vendorBasicDetails?._id, checked, type)
    );
  };

  const logo = {
    WAZUH: WazuhIcon,
    KYP: KypIcon,
    VENDORIQ: IqIcon,
  };

  return (
    <Box style={{ height: "63vh" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          API Connection
        </Typography>
        <CustomButton color={"primary"} onClick={() => setToggleDrawer(true)}>
          Add API Integration
        </CustomButton>
      </Box>

      <Grid spacing={2} className="scroll">
        {/* Vendor APIs */}
        {apiIntegration?.vendorApiConnectors?.length > 0 && (
          <>
            <BlueBackgroundCard heading="Vendor API Connectors" />

            {apiIntegration?.vendorApiConnectors?.map((item, i) => (
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Card>
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gridGap={"20px"}
                    >
                      <Box>
                        <img
                          src={logo[item.type]}
                          alt="logo"
                          style={{
                            width: "120px",
                            objectFit: "contain",
                            height: "55px",
                          }}
                        />
                      </Box>

                      <Box>
                        <Typography variant="h6" ml={2} fontWeight={600}>
                          {item?.type}
                        </Typography>
                        <Typography variant="body2" mt={1}>
                          • {item?.risk}
                        </Typography>
                        <Typography variant="body2">
                          {item?.description}
                        </Typography>
                        <CustomButton
                          variant="outlined"
                          size=" small"
                          color="secondary"
                        >
                          Synchronize Now
                        </CustomButton>
                      </Box>

                      <Box ml="auto">
                        <Switch
                          checked={switchEnable[item?.type] ?? item?.active}
                          onChange={(e) => {
                            setSwitchEnable((prev) => {
                              return {
                                ...prev,
                                [item?.type]: e.target.checked,
                              };
                            });

                            handleSwitchToggle(item?.type, e.target.checked);
                          }}
                          color="primary"
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        )}

        {/* Vendor APIs */}
        {apiIntegration?.esgApiConnectors?.length > 0 && (
          <>
            <BlueBackgroundCard heading="ESG API Connectors" />

            {apiIntegration?.esgApiConnectors?.map((item, i) => (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gridGap={"20px"}
                    >
                      <Box>
                        <img
                          src={item.logo}
                          alt="Risk Xchange Logo"
                          style={{
                            width: "120px",
                            objectFit: "contain",
                            height: "55px",
                          }}
                        />
                      </Box>

                      <Box>
                        <Typography variant="h6" ml={2} fontWeight={600}>
                          {item.name}
                        </Typography>
                        <Typography variant="body2" mt={1}>
                          • {item.risk}
                        </Typography>
                        <Typography variant="body2">
                          {item.description}
                        </Typography>
                        <CustomButton
                          variant="outlined"
                          size=" small"
                          color="secondary"
                        >
                          Synchronize Now
                        </CustomButton>
                      </Box>

                      <Box ml="auto">
                        <Switch
                          checked={switchEnable[item?.type]}
                          onChange={(e) => {
                            setSwitchEnable((prev) => {
                              return {
                                ...prev,
                                [item?.type]: e.target.checked,
                              };
                            });
                            handleSwitchToggle(item?.type, e.target.checked);
                          }}
                          color="primary"
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>

      {toggleDrawer && (
        <DynamicDrawer
          heading="Add API Integration"
          button1Label={"Back"}
          buttonText={"Save Changes"}
          hasDrawerOpen={toggleDrawer}
          handleClose={handleClose}
          dynamicWidth={1000}
        >
          <Box style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <ApiConnections />
          </Box>
        </DynamicDrawer>
      )}
    </Box>
  );
};

export default ApiConnectionV2;
