import { Box, Drawer } from "@material-ui/core";
import { useEffect, useState } from "react";
import AddAlert from "./AddAlert";
import SetAlert from "./SetAlert";
import AlertsHead from "./AlertsHead";
import AlertFooter from "./AlertFooter";
import { useSelector } from "react-redux";
import { gql_get_alert } from "redux/actions/vendorActions/vendorDiagnosisAction";

const AlertsDrawer = ({ open, onClose, stepName, tabName, widgetNames }) => {
  const { alerts } = useSelector((state) => state.vendorDiagnosis);
  const [alertScreen, setAlert] = useState({ type: "set", from: "" }); // "set" for Set Alert, "add" for Add Alert
  const initialAlert = {
    name: "",
    frequency: "",
    range: [25, 40],
    comment: "",
    active: false,
  };
  const [newAlert, setNewAlert] = useState(initialAlert);
  const [updateAlert, setUpdateAlert] = useState(alerts || []);

  console.log(alertScreen);

  const renderAlertsScreen = () => {
    switch (alertScreen?.type) {
      case "set":
        return (
          <SetAlert
            setAlert={setAlert}
            updateAlert={updateAlert}
            setUpdateAlert={setUpdateAlert}
            widgetNames={widgetNames}
          />
        );
      case "add":
        return <AddAlert newAlert={newAlert} setNewAlert={setNewAlert} />;
      default:
        return (
          <SetAlert
            setAlert={setAlert}
            updateAlert={updateAlert}
            setUpdateAlert={setUpdateAlert}
            widgetNames={widgetNames}
          />
        );
    }
  };

  const fetchAlerts = () => {
    const updatedNames = widgetNames?.map((name) => name.toUpperCase());

    gql_get_alert({
      widgetName: updatedNames,
    });
  };

  useEffect(() => {
    if (open) {
      fetchAlerts();
    }
  }, [alertScreen?.type, open]);

  useEffect(() => {
    setUpdateAlert(alerts);
  }, [alerts, alertScreen?.type]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        p={1}
        maxWidth={"60vw"}
        width={"100%"}
        display="flex"
        flexDirection="column"
        height="100vh"
      >
        {/* Alerts Head */}
        <Box
          position="sticky"
          top={0}
          zIndex={1}
          style={{ backgroundColor: "#ffffff" }}
        >
          <AlertsHead
            alertScreen={alertScreen}
            setAlert={setAlert}
            closeDrawer={onClose}
          />
        </Box>

        {/* Scrollable content */}
        <Box flexGrow={1} style={{ overflowY: "auto" }}>
          {renderAlertsScreen()}
        </Box>

        <Box
          position="sticky"
          bottom={0}
          zIndex={1}
          bgcolor="background.paper"
          p={1}
        >
          <AlertFooter
            alertScreen={alertScreen}
            setAlert={setAlert}
            initialAlert={initialAlert}
            newAlert={newAlert}
            setNewAlert={setNewAlert}
            closeDrawer={onClose}
            alerts={alerts}
            updateAlert={updateAlert}
            setUpdateAlert={setUpdateAlert}
            fetchAlerts={fetchAlerts}
            stepName={stepName}
            tabName={tabName}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default AlertsDrawer;
