import React, { useState } from "react";
import { Box, MenuItem, TextField } from "@material-ui/core";

const FrequencyDropdown = ({
  options = frequencyOptions,
  selectedOpt = "",
  handleOption,
  disableEdit = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedOpt);

  const handleChange = (e) => {
    const opt = e.target.value;
    setSelectedOption(opt);
    // Trigger the handleOption callback
    if (handleOption) {
      handleOption(opt);
    }
  };

  return (
    <Box>
      <TextField
        variant="outlined"
        value={selectedOption}
        onChange={handleChange}
        label={!selectedOption && "Select Frequency"}
        InputProps={{ readOnly: disableEdit }}
        select
        fullWidth
      >
        {options.map(({ label, value }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};

export default FrequencyDropdown;

// ------------ Additional ------------
const frequencyOptions = [
  {
    label: "Everyday (Daily)",
    value: "Daily",
  },
  {
    label: "Every 6 months (Half Yearly)",
    value: "Half_Yearly",
  },
  {
    label: "Every 30 days (Monthly)",
    value: "Monthly",
  },
  {
    label: "Every 90 days (Quarterly)",
    value: "Quarterly",
  },
  {
    label: "Every year (Yearly)",
    value: "Yearly",
  },
];
