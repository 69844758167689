import { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";

// takes input in array and return an array
/*
Example: 
range: [30, 40]
callback on handleRange: [40, 50]
*/
const RangeFields = ({
  range = [10, 20],
  handleRange,
  startName = "Starting Range",
  endName = "Ending Range",
  minValue = 0, // Default minimum value
  maxValue = 100, // Default maximum value
  disableEdit = false,
}) => {
  const [currRange, setCurrRange] = useState(range);

  const handleChange = (e, index) => {
    const newValue = parseInt(e.target.value, 10);

    // Update the currRange state with the new value
    const updatedRange = [...currRange];
    updatedRange[index] = isNaN(newValue) ? "" : newValue; // Handling empty input
    setCurrRange(updatedRange);

    // Call handleRange if it's defined && range is within the defined bounds
    if (updatedRange.every((val) => val >= minValue && val <= maxValue)) {
      handleRange && handleRange(updatedRange);
    }
  };

  useEffect(() => {
    setCurrRange(range);
  }, [range]);

  return (
    <Box display="flex" alignItems="flex-end" justifyContent="center" gap={2}>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {startName}
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          value={currRange[0] || ""}
          onChange={(e) => handleChange(e, 0)} // Handle change for Starting Range
          InputProps={{ readOnly: disableEdit }}
          inputProps={{
            min: minValue, // Set the minimum value
            max: maxValue, // Set the maximum value
          }}
        />
      </Box>

      <Box style={{ margin: "0 20px", height: "1.5em", width: "4em" }}>
        <hr />
      </Box>

      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {endName}
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          value={currRange[1] || ""}
          onChange={(e) => handleChange(e, 1)} // Handle change for Ending Range
          InputProps={{ readOnly: disableEdit }}
          inputProps={{
            min: minValue, // Set the minimum value
            max: maxValue, // Set the maximum value
          }}
        />
      </Box>
    </Box>
  );
};

export default RangeFields;
