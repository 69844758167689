import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";

const CommentBox = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: inputValue,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [inputValue, commentBox]);

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer[0]));
        setCommentBox(submittedAnswer[0]?.comment);
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleValue = "";

  // if (isFrom === "taskSection") {
  //   handleValue = inputValue || submittedAnswer[0]?.option || "";
  // } else if (selectedOption?.option !== "") {
  //   handleValue = inputValue || selectedOption;
  // } else if (submittedAnswer) {
  //   handleValue = submittedAnswer[0]?.option;
  // } else {
  //   handleValue = inputValue || "";
  // }

  useEffect(() => {
    if (submittedAnswer) {
      setInputValue(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, []);

  return (
    <Box>
      <TextField
        style={{ width: "100%", pointerEvents: event }}
        aria-label="minimum height"
        minRows={4}
        placeholder="Comment"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        variant="outlined"
        multiline
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={submittedAnswer && submittedAnswer[0]?.comment}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentBox;
