import { Typography, useTheme } from "@material-ui/core";

const RequiredField = (show) => {
  const { error } = useTheme().palette;

  return show ? (
    <Typography component="span" style={{ color: error.main }}>
      *
    </Typography>
  ) : (
    ""
  );
};

export default RequiredField;
