import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query Me {
    me {
      _id
      active
      designation
      email
      is_premium
      name
      user_type
      profileImg {
        name
        type
        url
      }
      address {
        _id
        country
        district
        landmark
        location
        state
        phone
        pincode
      }
    }
  }
`;
