import React, { useEffect, useRef, useState } from "react";
import CustomTextField from "../../UI/textfield/textfield";
import {
  Typography,
  makeStyles,
  RadioGroup,
  Select,
  FormControl,
  Grid,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import CustomTextfield from "../../UI/textfield/textfield";
import RadioButton from "../../UI/radioButton/radioButton";
import { useDispatch } from "react-redux";
import { setSelectedSection } from "../../../redux/actions/riskdashboard/riskDashboardActions";
import QuestionBody from "../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { response1 } from "../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import NoData from "../NoData/NoData";
import ResponseSectionTopCard from "../riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionTopCard";

const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "80%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    color: "#3374B9",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "16px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,

    borderRadius: 5,
    cursor: "pointer",
  },
}));
export const Wrapper = ({
  isFrom,
  users,
  children,
  hidesearch = false,
  width = "22%",
  sectionSelect,
  setSectionSelect,
  completedQuestions,
  handleSelectedSection,
  pointerEvents,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const sectionsCopy = usersList?.length > 0 && [...usersList];

  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users?.filter((user) =>
        user.title.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px" }}>
      {completedQuestions ? (
        <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
          {!isFrom && hidesearch && (
            <CustomTextField
              variant="outlined"
              size="small"
              placeholder="search"
              fullwidth
              onChange={searchUsers}
            />
          )}
          <div
            className="scroll"
            style={{
              height: hidesearch ? "56vh" : "48vh",
              marginTop: 8,
              paddingBottom: 12,
            }}
          >
            {completedQuestions?.length > 0 &&
              completedQuestions.map((user, i) => (
                <Typography
                  key={i}
                  className={classes.userName}
                  style={{
                    fontWeight:
                      sectionSelect.id === user?.sectionId ? 500 : 400,
                    backgroundColor:
                      sectionSelect.id === user?.sectionId && "#EBF1F8",
                  }}
                  role="button"
                  onClick={() => {
                    handleSelectedSection(user?.sectionId);
                    setSectionSelect({
                      title: user?.sectionTitle,
                      id: user?.sectionId,
                      description: user?.sectionDescription,
                    });
                  }}
                >
                  {user?.sectionTitle}
                </Typography>
              ))}
          </div>
        </div>
      ) : (
        <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
          {!hidesearch && (
            <CustomTextField
              variant="outlined"
              size="small"
              placeholder="search"
              fullwidth
              onChange={searchUsers}
            />
          )}
          <div
            className="scroll"
            style={{
              height:
                hidesearch && isFrom === "editVendor"
                  ? "56vh"
                  : isFrom === "createVendor"
                  ? "66vh"
                  : "48vh",
              marginTop: 8,
              paddingBottom: 12,
            }}
          >
            {usersList?.length > 0 &&
              sectionsCopy
                ?.sort((a, b) => a?.order - b?.order)
                .map((user, i) => (
                  <Typography
                    key={i}
                    className={classes.userName}
                    style={{
                      fontWeight: sectionSelect?.id === user?._id ? 500 : 400,
                      backgroundColor:
                        sectionSelect?.id === user?._id && "#EBF1F8",
                      pointerEvents: pointerEvents,
                    }}
                    role="button"
                    onClick={() => {
                      handleSelectedSection(user?._id, user?.title);
                      setSectionSelect({
                        title: user?.title,
                        id: user?._id,
                        description: user?.description,
                      });
                    }}
                  >
                    {user?.title}
                  </Typography>
                ))}
          </div>
        </div>
      )}
      <Box width>{children}</Box>
    </div>
  );
};
export const survey = [
  { name: "Self Assessment", isSelected: true },
  { name: "Controversies", isSelected: false },
  { name: "Banking Policy", isSelected: false },
];

export const Question = ({
  question,
  type,
  options,
  weightage = 0,
  submittedAnswer,
  comment,
  tableHeadersApi,
  isFrom,
  event,
  handleQuesAnswer,
  questionId,
}) => {
  const classes = responseStyles();
  return (
    <div className={classes.questionCont}>
      <div style={{ display: "flex", width: "100%" }}>
        <Typography className={classes.question}>{question}</Typography>
        <Typography className={classes.weightage}>
          {weightage} Weightage
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          padding:
            type === "TF" || type === "NF" || type === "CF"
              ? "25px 10px"
              : "25px 25px",
          pointerEvents: "initial",
        }}
      >
        <QuestionBody
          questionType={type}
          answers={options}
          removeEditOption
          submittedAnswer={submittedAnswer}
          isFrom={isFrom}
          event={event ? event : "none"}
          comment={comment}
          tableHeadersApi={tableHeadersApi}
          handleQuesAnswer={handleQuesAnswer}
          questionId={questionId}
        />
      </div>
    </div>
  );
};

export const Tile = ({ text, value }) => {
  return (
    <div
      style={{
        border: "none",
        borderRadius: 5,
        backgroundColor: "#F6F6F6",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          padding: "3px 0px",
          wordBreak: "break-all",
        }}
      >
        {text}
      </Typography>
      {text === "Survey Status" ? (
        <div
          style={{
            backgroundColor: "#BFE2CD",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 5px",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{ fontSize: 11, fontWeight: 600, color: "#154328" }}
          >
            Complete
          </Typography>
        </div>
      ) : (
        <Typography
          style={{ fontSize: 12, fontWeight: 600, padding: "3px 0px" }}
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

function SelfAssessmentResponse({
  user,
  completedQuestions,
  questions,
  handleSelectedSection,
}) {
  // console.log(completedQuestions);
  // console.log(Object.keysresponse1);

  const classes = responseStyles();

  const [sectionSelect, setSectionSelect] = useState({});

  useEffect(() => {
    if (completedQuestions?.length > 0) {
      setSectionSelect({
        id: completedQuestions[0]?.sectionId,
      });
    }
  }, [completedQuestions]);

  // let questions = [
  //   {
  //     id: 1,
  //     question:
  //       "Is an approved Access Control and Remote Working policy in place? \n\nIf 'Yes' please provide details of level of the following \n- multi-factor authentication \n- how your organization restricts access to environments and systems \n- What type of technology used \n\nIf 'No' please provide a justification",
  //     question_type: "likert",
  //     comment: true,
  //     isEdit: false,
  //     weightage: 10,
  //     hasNewlyAdded: false,
  //   },
  // ];
  // const users = [
  //   { title: "Bessie Cooper", isSelected: true },
  //   // { title: "Ralph Edwards", isSelected: false },
  //   // { title: "Dianne Russell", isSelected: false },
  //   // { title: "Guy Hawkins", isSelected: false },
  //   // { title: "Jane Cooper", isSelected: false },
  // ];
  const scroll = useRef();

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [sectionSelect]);

  return (
    // <Wrapper users={survey} hidesearch width="13%">

    <>
      {!completedQuestions || completedQuestions.length === 0 ? (
        <NoData message={"No Responses Found"} />
      ) : (
        <Wrapper
          sectionSelect={sectionSelect}
          setSectionSelect={setSectionSelect}
          users={user}
          completedQuestions={completedQuestions}
          handleSelectedSection={handleSelectedSection}
        >
          <div
            className="scroll"
            ref={scroll}
            style={{ width: "78%", height: "59vh", paddingBottom: 100 }}
          >
            {questions?.map((question) => (
              <Question
                question={question?.question}
                options={question?.answers}
                type={question?.questionType}
                weightage={question?.weightage}
                submittedAnswer={
                  question?.questionType === "TABLE"
                    ? question?.approvedTableRows
                    : question?.approvedAnswer
                }
                comment={
                  question?.questionType === "TABLE"
                    ? question?.approvedAnswer &&
                      question?.approvedTableRows[0][0]?.comment !== null &&
                      question?.approvedTableRows[0][0]?.comment !== ""
                    : question?.approvedAnswer &&
                      question?.approvedAnswer[0]?.comment !== null &&
                      question?.approvedAnswer[0]?.comment !== ""
                }
                tableHeadersApi={question?.tableHeaders}
              />
            ))}
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default SelfAssessmentResponse;

// {!type || type === "radio" ? (
//   <RadioGroup
//     value={"order"}
//     style={{ marginTop: 10 }}
//     // onChange={(e) => handleChange(e, que?.order)}
//     // name={que?._id + ""}
//   >
//     {options &&
//       options.map((ans, j) => {
//         return (
//           <div key={j} style={{ width: "100%" }}>
//             <div
//               key={j}
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               <RadioButton value={ans[1]} id={ans[0] + ""} />
//               {/* <CustomCheckBox
//                 color="tertiary"
//                 checked={opt[1]}
//                 disabled={opt[1]}
//               /> */}
//               <Typography
//                 style={{
//                   fontSize: 13,
//                   fontWeight: 400,
//                   padding: "3px 0px",
//                 }}
//               >
//                 {ans[0]}
//               </Typography>
//             </div>
//             {/* <FormControlLabel
//               style={{
//                 width: "100%",
//                 padding: type === "textField" && "15px 0px",
//               }}
//               value={ans[0]}
//               control={
//                 <>
//                   {(!type || type === "radio") && (
//                     // <TextField
//                     //   // className={clsx(classes.root, classes.checked)}
//                     //   onChange={(e) => {
//                     //     // checkBoxChange(e, i, j);
//                     //   }}
//                     //   name={que.id + ""}
//                     //   id={ans[1] + ""}
//                     // />
//                     <RadioButton value={ans[1]} id={ans[0] + ""} />
//                     //  <FormControlLabel value="female" control={<Radio />} label="Female" />
//                   )}
//                 </>
//               }
//               label={ans[0]}
//               // onChange={(e) => {
//               //   addSurvey(e, i);
//               // }}
//             /> */}
//           </div>
//         );
//       })}
//   </RadioGroup>
// ) : (
//   <>
//     {type === "textField" && (
//       <div style={{ padding: "15px 0px" }}>
//         <CustomTextfield
//           style={{
//             width: "50vw",
//           }}
//           name="userName"
//           label="Answer"
//           variant="outlined"
//           size="small"
//           fullWidth
//           // value={data.question}
//         />
//       </div>
//     )}
//     {type === "multi select" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         {/* <Typography
//                                 style={{
//                                   fontSize: "14px",
//                                   marginBottom: "5px",
//                                   color: "#9A9A9A",
//                                 }}
//                               >
//                                 Select File
//                               </Typography> */}

//         <Select
//           defaultValue={"Select an Option"}
//           style={{
//             border: "1px solid #C0D4E9",
//             backgroundColor: "#FEFEFE",
//             borderRadius: "8px",
//             height: "40px",
//             width: "50vw",
//             marginTop: "15px",
//           }}
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setCollect((prev) => {
//             //   return {
//             //     ...prev,
//             //     question_id: que?._id,
//             //     value: value,
//             //   };
//             // });
//             // setDisclosureSort(value);
//           }}
//           input={<OutlinedInput />}
//         >
//           {options.map((item) => {
//             return (
//               <MenuItem key={item[0]} value={item[0]}>
//                 {item[0]}
//               </MenuItem>
//             );
//           })}
//         </Select>
//       </Grid>
//     )}
//     {type === "location" && (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           height: `400px`,
//           width: "800px",
//         }}
//       >
//         <Typography> latitude: , longitude: </Typography>
//         {/* <GoogleMaps {...props}/> */}
//       </div>
//     )}
//     {type === "date" && (
//       <Grid item xs={12} md={12} className={classes.grid4}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="date"
//           variant="outlined"
//           type="date"
//           size="small"
//           fullWidth
//           value={""}
//         />
//       </Grid>
//     )}
//     {type === "file" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="userName"
//           variant="outlined"
//           type="file"
//           size="small"
//           fullWidth
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setDisclosureSort(value);
//           }}
//         />
//       </Grid>
//     )}
//   </>
// )}
// {/* {options?.map((opt) => (
//   <div style={{ display: "flex", alignItems: "center" }}>
//     <CustomCheckBox color="tertiary" checked={opt[1]} disabled={opt[1]} />
//     <Typography
//       style={{ fontSize: 13, fontWeight: 400, padding: "3px 0px" }}
//     >
//       {opt[0]}
//     </Typography>
//   </div>
// ))} */}
