import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";
import { useSelector } from "react-redux";

function VendorContractDetails({ classes }) {
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);

  return (
    <Card style={{ marginBottom: "20px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>
          Vendor Contract Details
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"space-between"} width={"70%"}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "50%",
          }}
        >
          <Typography variant="body2">
            TPSP Name: <br />
            <strong>{vendorBasicDetails?.name}</strong>
          </Typography>
          <Typography variant="body2">
            Contract Reference Number: <br />
            <strong>CN123456</strong>
          </Typography>
          <Typography variant="body2">
            Service Provided: <br />
            <strong>Cloud Hosting</strong>
          </Typography>
          <Typography variant="body2">
            Contract Start Date: <br />
            <strong>01-Jan-2023</strong>
          </Typography>
          <Typography variant="body2">
            Contract End Date: <br />
            <strong>31-Dec-2025</strong>
          </Typography>
        </CardContent>

        <CardContent
          style={{ display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <Typography variant="body2">
            Service Level Agreement(SLAs): <br />
            <strong>99.9%</strong>
          </Typography>
          <Typography variant="body2">
            Performance Metrics: <br />
            <strong>Quarterly Performance</strong>
          </Typography>
          <Typography variant="body2">
            Annual Audits: <br />
            <strong>Proceed</strong>
          </Typography>
          <Typography variant="body2">
            Termination Clauses: <br />
            <strong>30-Day Notic Period</strong>
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

export default VendorContractDetails;
