import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import CustomTextfield from "../../../UI/textfield/textfield";
import { useState } from "react";
import RadioButton from "components/UI/radioButton/radioButton";

function ShareSurveyStep3({
  setFrequencyData,
  setSelectedOption,
  selectedOption,
}) {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [onDate, setOnDate] = useState("");
  const [onMonth, setOnMonth] = useState("");
  const [onYear, setOnYear] = useState("");
  const [afterDate, setAfterDate] = useState("");
  const [afterMonth, setAfterMonth] = useState("");
  const [afterYear, setAfterYear] = useState("");
  const [category, setCategory] = useState("");

  const handleDateChange = (event) => setDate(event.target.value);
  const handleMonthChange = (event) => setMonth(event.target.value);
  const handleYearChange = (event) => setYear(event.target.value);

  const handleSubmit = () => {
    if (date && month && year) {
      const formattedDate = `${year}-${month}-${date}`;
      console.log(formattedDate); // This is your final string in JS Date format (YYYY-MM-DD)
      // alert(`Selected Date: ${formattedDate}`);
    } else {
      // alert("Please select all fields!");
    }
  };

  const days = Array.from({ length: 31 }, (_, i) => i + 1); // Days: 1 to 31
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() + i
  ); // Years: Current year to 100 years back

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        {/* Date Selector */}

        <Grid item xs={4}>
          <InputLabel>Date of receiving</InputLabel>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <CustomTextfield
              value={date}
              onChange={(e) => {
                handleDateChange(e);
                setFrequencyData((prev) => ({ ...prev, day: e.target.value }));
              }}
              variant="outlined"
              size="small"
              select
            >
              {days.map((day) => (
                <MenuItem key={day} value={String(day).padStart(2, "0")}>
                  {day}
                </MenuItem>
              ))}
            </CustomTextfield>
          </FormControl>
        </Grid>

        {/* Month Selector */}
        <Grid item xs={4}>
          <InputLabel>Month</InputLabel>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <CustomTextfield
              value={month}
              onChange={(e) => {
                handleMonthChange(e);
                setFrequencyData((prev) => ({
                  ...prev,
                  month: e.target.value,
                }));
              }}
              variant="outlined"
              select
              size="small"
            >
              {months.map((m) => (
                <MenuItem key={m.value} value={m.value}>
                  {m.label}
                </MenuItem>
              ))}
            </CustomTextfield>
          </FormControl>
        </Grid>

        {/* Year Selector */}
        <Grid item xs={4}>
          <InputLabel>Year</InputLabel>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <CustomTextfield
              value={year}
              onChange={(e) => {
                handleYearChange(e);
                setFrequencyData((prev) => ({ ...prev, year: e.target.value }));
              }}
              variant="outlined"
              select
              size="small"
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </CustomTextfield>
          </FormControl>
        </Grid>

        {/* Submit Button */}
        {/* <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
        </Button>
        </Grid> */}
      </Grid>
      <Grid style={{ marginTop: "20px" }}>
        <InputLabel>Repeat Frequency</InputLabel>
        <CustomTextfield
          name="userCategory"
          label="Choose the required frequency"
          variant="outlined"
          size="small"
          fullWidth
          select
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
            setFrequencyData((prev) => ({
              ...prev,
              frequencyTypeName: e.target.value,
            }));
          }}
          style={{ margin: "10px 0 20px " }}
        >
          {[
            { title: "Every 1 month (Every 30 days)", value: "Monthly" },
            { title: "Every 3 months (Quarterly)", value: "Quarterly" },
            {
              title: "Every 6 months (Half yearly)",
              value: "Half_Yearly",
            },
            { title: "Every 1 Year", value: "Yearly" },
            { title: "Everyday", value: "Daily" },
          ].map((cat, i) => (
            <MenuItem key={i} value={cat?.value}>
              {cat?.title}
            </MenuItem>
          ))}
        </CustomTextfield>
      </Grid>

      <FormLabel>Ends</FormLabel>
      <FormControl component="fieldset" fullWidth>
        {/* Radio Group */}
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel
            value="never"
            control={
              <RadioButton
                onChange={(e) =>
                  setFrequencyData((prev) => ({
                    ...prev,
                    endNever: e.target.checked ? true : false,
                  }))
                }
              />
            }
            label="Never"
          />
          <FormControlLabel value="on" control={<RadioButton />} label="On" />
          {selectedOption === "on" && (
            <Grid container spacing={2} style={{ marginLeft: 24 }}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>Date</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={onDate}
                    onChange={(e) => {
                      setOnDate(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endOnDay: e.target.value,
                      }));
                    }}
                    select
                    variant="outlined"
                    size="small"
                  >
                    {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                      <MenuItem key={day} value={String(day).padStart(2, "0")}>
                        {day}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>Month</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={onMonth}
                    onChange={(e) => {
                      setOnMonth(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endOnMonth: e.target.value,
                      }));
                    }}
                    select
                    variant="outlined"
                    size="small"
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>Year</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={onYear}
                    onChange={(e) => {
                      setOnYear(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endOnYear: e.target.value,
                      }));
                    }}
                    variant="outlined"
                    select
                    size="small"
                  >
                    {years.map((y) => (
                      <MenuItem key={y} value={y}>
                        {y}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <FormControlLabel
            value="after"
            control={<RadioButton />}
            label="After"
          />
          {selectedOption === "after" && (
            <Grid container spacing={2} style={{ marginLeft: 24 }}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  {/* <Typography>Date</Typography> */}
                  <FormLabel>Date</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={afterDate}
                    onChange={(e) => {
                      setAfterDate(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endAfterDay: e.target.value,
                      }));
                    }}
                    select
                    variant="outlined"
                    size="small"
                  >
                    {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                      <MenuItem key={day} value={String(day).padStart(2, "0")}>
                        {day}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>Month</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={afterMonth}
                    onChange={(e) => {
                      setAfterMonth(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endAfterMonth: e.target.value,
                      }));
                    }}
                    select
                    variant="outlined"
                    size="small"
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>Year</FormLabel>
                  <CustomTextfield
                    style={{ marginTop: "10px" }}
                    value={afterYear}
                    onChange={(e) => {
                      setAfterYear(e.target.value);
                      setFrequencyData((prev) => ({
                        ...prev,
                        endAfterYear: e.target.value,
                      }));
                    }}
                    variant="outlined"
                    select
                    size="small"
                  >
                    {years.map((y) => (
                      <MenuItem key={y} value={y}>
                        {y}
                      </MenuItem>
                    ))}
                  </CustomTextfield>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default ShareSurveyStep3;
