import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "../vendorIntake/Header";
import ContractingOverallScore from "./ContractingOverallScore";
import CompilanceScore from "../dueDiligence/ComplianceScore";
import ComplianceList from "../dueDiligence/ComplianceList";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_active_contract_file_data,
  gql_get_dora_compliance_files_for_contracting,
  gql_get_dora_compliance_results,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import DoraDocuments from "../../vendor/DoraDocuments";
import AlertsDrawer from "../vendorIntake/AlertsDrawer";

function Contracting({ isFrom }) {
  const [docId, setDocId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");

  const [alertsDrawer, setAlertsDrawer] = useState(false);
  const dispatch = useDispatch();

  const {
    doraComplianceResults,
    doraComplianceFilesContracting,
    activeContractFileData,
  } = useSelector((state) => state.esgDiagnosisReducer);
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);
  const storedVendorIsin = localStorage.getItem("vendorIsin");

  const { doraOverallScores, doraComplianceFiles: complianceFilesContracting } =
    doraComplianceFilesContracting;

  const handleSelectedDoc = (id, pdfUrl, pdfName) => {
    setDocId(id);
    dispatch(gql_get_dora_compliance_results(id));
    setPdfUrl(pdfUrl);
    setPdfName(pdfName);
  };

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  const customDoraResults =
    doraComplianceResults?.length > 0 ? [...doraComplianceResults] : [];

  useEffect(() => {
    dispatch(
      gql_get_dora_compliance_files_for_contracting(
        vendorBasicDetails?.isin || storedVendorIsin,
        "Contract"
      )
    );

    dispatch(
      gql_get_active_contract_file_data(
        vendorBasicDetails?.isin || storedVendorIsin,
        "Contract"
      )
    );
  }, []);

  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "100%" : "63vh" }}>
      {docId === null && (
        <>
          {isFrom === "tprmRiskReport" || (
            <Box
              sx={{
                mb: 2,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Contarcting
              </Typography>

              <Button
                variant="text"
                style={{ color: "#3374B9", textTransform: "none" }}
                onClick={toggleAlertsDrawer}
              >
                Set Alerts
              </Button>
            </Box>
          )}

          <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
            {/* <Header />
        <br /> */}
            {activeContractFileData?.length > 0 && (
              <>
                <ContractingOverallScore
                  activeContractFileData={activeContractFileData}
                />
                <br />
              </>
            )}
            {doraOverallScores?.overallScore && (
              <>
                <CompilanceScore score={doraOverallScores?.overallScore} />
                <br />
              </>
            )}
            <ComplianceList
              type={"Contract"}
              handleSelectedDoc={handleSelectedDoc}
              complianceFiles={complianceFilesContracting}
              doraOverallScores={doraOverallScores}
            />
          </Box>
        </>
      )}
      {docId !== null && (
        <DoraDocuments
          setDocId={setDocId}
          docId={docId}
          pdfUrl={pdfUrl}
          pdfName={pdfName}
          fileData={customDoraResults[0]?.data}
        />
      )}

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"CONTRACTING"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={["Contracting_Compliance_Score"]}
        />
      )}
    </Box>
  );
}

export default Contracting;
