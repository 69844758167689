import React, { useEffect, useRef, useState } from "react";

import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
// import CustomButton from "@material-ui/core/CustomButton";

import Paper from "@material-ui/core/Paper";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import RadioButton from "../../UI/radioButton/radioButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import notebook from "../../../images/notebook.svg";
import green from "../../../images/TickGreen.png";
import SurveySuccess from "../../../images/SurveySuccess.svg";
import useGeoLocation from "../../../customHooks/getGeoLocation";
// import ScrollToTop from '../scrollto/ScrollToTop';
// import ScrollToBottm from '../scrollto/ScrollToBottom';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";

import {
  Box,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  TextField,
} from "@material-ui/core";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import GoogleMaps from "../surveyQuestions/googleMaps";
import { useDispatch, useSelector } from "react-redux";
import { getQuestion } from "../../../redux/actions/riskdashboard/riskDashboardActions";
import { Question } from "../selfAssessment/selfAssessmentResponse";
import {
  getAssignedQuestion,
  redoSpecificSectionSubmit,
  redoWholeSection,
  setPublishAnswers,
  setSelectedOption,
  submitSectionAnswer,
} from "../../../redux/actions/corporateSurvey/corporateSurveyActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const checkBoxStyles = makeStyles((theme) => ({
  root: {
    "&.Mui-checked": {
      color: "#3374B9",
    },
  },
  qno: { marginRight: "10px" },
  input1: { display: "none" },
  pointer: { cursor: "pointer" },
  BoxG: { borderBottom: "2px solid gray" },
  Box6: { borderBottom: "2px solid #3374B9" },
}));

function RiskSurvey() {
  const classes = checkBoxStyles();
  const { token } = useSelector((state) => state?.login);

  const {
    assignedSection,
    selectedOrganizationCorp,
    selectedOption,
    publishAnswer,
  } = useSelector((state) => state?.corporateSurvey);

  const { sectionWiseQuestion } = useSelector((state) => state?.riskDashboard);
  const dispatch = useDispatch();

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [redoSpecific, setRedoSpecific] = useState(false);
  const [redoQuestion, setRedoQuestion] = useState(false);

  // TEMPORARY HARDCODED STATES

  const [currentquestions, setCurrentQuestions] = useState("");
  const [questions, setQuestions] = useState([]);
  const [ans, setAns] = useState(1);

  // console.log("sectionWiseQuestion", sectionWiseQuestion);
  // console.log("selectedSection", selectedSection);
  // console.log("selectedQuestion", selectedQuestion);
  console.log("publishAnswer", publishAnswer);
  // console.log("selectedOrganizationCorp", selectedOrganizationCorp);
  // console.log("selectedOption", redoQuestion, redoSpecific, selectedOption);

  useEffect(() => {
    !selectedSection && setSelectedSection(assignedSection[0]);
    selectedSection &&
      dispatch(
        getAssignedQuestion(
          token,
          selectedSection?._id,
          selectedOrganizationCorp?._id,
          selectedOrganizationCorp?.version,
          selectedOrganizationCorp?.frequency_id,
          selectedSection?.order
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection?._id, assignedSection.length]);

  useEffect(() => {
    sectionWiseQuestion?.length > 0 &&
      setSelectedQuestion([sectionWiseQuestion[publishAnswer?.length]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionWiseQuestion?.length, publishAnswer?.length]);

  const getIndex = () => {
    const getQuestionIndex = sectionWiseQuestion.findIndex(
      (q) => q?._id === selectedQuestion[0]?._id
    );
    return getQuestionIndex;
  };
  // console.log("inside", selectedOption);
  useEffect(() => {
    const index = getIndex();
    const previous_answered = publishAnswer[index];

    console.log(
      "effect",
      index,
      selectedOption,
      previous_answered,
      selectedQuestion
    );
    // if (selectedOption && !previous_answered) {
    //   // const copy = [...JSON.parse(JSON.stringify(publishAnswer))];
    //   publishAnswer.push(selectedOption);
    //   // dispatch(setPublishAnswers(copy));
    // }
  }, [selectedQuestion, selectedOption]);
  const handleQuestionChange = (value) => {
    const index = getIndex();
    setSelectedQuestion([sectionWiseQuestion[index + value]]);
    const answered = publishAnswer[index + value];
    // console.log("answerd", answered, previous_answered);

    if (value === 1) {
      if (answered === undefined) {
        setValue("");
        dispatch(setSelectedOption(null));
        setAns(null);
        if (selectedOption) {
          publishAnswer[index] = selectedOption;
        }
      } else {
        dispatch(setSelectedOption(answered));
        setValue(answered?.answer_weightage || 1);
      }
    } else if (value === -1) {
      if (answered) {
        dispatch(setSelectedOption(answered));
        setValue(answered?.answer_weightage || 1);
      }
    }
  };
  const history = useHistory();
  const [redo, setRedo] = useState(false);

  const RedoDialog = ({
    heading,
    subHeading,
    onClose,
    open,
    onSubmit,
    cardData,
  }) => {
    const [selected, setSelected] = useState(null);
    return (
      <Dialog
        handleOnsubmit={() => {}}
        open={open}
        onClose={onClose}
        maxWidth="md"
      >
        <div style={{ width: "760px" }}>
          <DialogTitle style={{ backgroundColor: "#EBF1F8", padding: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ padding: 5, fontSize: 18, fontWeight: 600 }}>
                {heading}
              </Typography>
              {/* <IconButton onClick={handleClose}> */}
              {/* <CloseIcon /> */}
              {/* </IconButton> */}
            </div>
            <Typography
              style={{
                padding: 5,
                fontSize: 12,
                fontWeight: 400,
                color: "#5C90C7",
                textTransform: "capitalize",
              }}
            >
              {subHeading}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="space-between">
              {cardData.map(({ heading, subHeading }, i) => (
                <Card
                  role="button"
                  onClick={() => setSelected(i)}
                  item
                  style={{
                    width: "100%",
                    padding: "12px 10px",
                    marginTop: "1.5em",
                    backgroundColor: selected === i ? "#EBF1F8" : "#fff",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      borderLeft: selected === i ? "4px solid #3374B9" : "",
                      paddingLeft: 3,
                      marginLeft: 5,
                    }}
                  >
                    <Typography
                      style={{
                        padding: "0px 5px",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      {heading}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      padding: 5,
                      fontSize: 13,
                      fontWeight: 400,
                      textTransform: "capitalize",
                    }}
                  >
                    {subHeading}
                  </Typography>
                </Card>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton
              autoFocus
              onClick={() => {
                onClose();
                setSelected();
              }}
              style={{
                textTransform: "none",
                // backgroundColor: "#5C90C7",
                // color: "#FFFF",
                padding: "2px 3em",
              }}
              color={"primary"}
              // variant={"contained"}
              // disabled={!details?.name || !details?.email || !details?.orgName}
            >
              Cancel
            </CustomButton>
            <CustomButton
              autoFocus
              onClick={() => {
                onClose();
                onSubmit(selected);
              }}
              disabled={selected === null}
              style={{
                textTransform: "none",
                // backgroundColor: "#5C90C7",
                // color: "#FFFF",
                padding: "2px 3em",
              }}
              color={"primary"}
              variant={"contained"}
            >
              Proceed
            </CustomButton>
          </DialogActions>
        </div>
      </Dialog>
    );
  };
  const RedoQuestion = () => {
    return <div>redoQuestion</div>;
  };
  const Progress = withStyles({
    root: {
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#3374B9",
      },
      height: "6px",
      borderRadius: "8px",
    },
  })(LinearProgress);
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    console.log("tessf", JSON.parse(event.target.name));
    let val = Number(event.target.value);
    setValue(val);
    const list = JSON.parse(event.target.name);

    let selectedAns = list.filter((ans) => {
      return ans.weightage == val;
    });

    dispatch(
      setSelectedOption({
        question_id: "65a0c56dc1b4c84b328c",
        answer: {
          ...selectedAns,
        },
        answer_weightage: val,
      })
    );
  };

  return (
    <Grid container md={12}>
      <RedoDialog
        heading="Redo Section"
        subHeading="What wad the issue with the response ?"
        cardData={[
          {
            heading: "Redo a specific Question",
            subHeading: `Opt for precision in your insights by redoing a specific
            question, refining your survey response for a more accurate
            and nuanced reflection.`,
          },
          {
            heading: "Redo the Entire Section (*Previous data will be erased)",
            subHeading: `Embark on a fresh perspective by redoing the entire survey,
            with a note that previous data will be erased—ensuring a clean
            slate for updated and comprehensive feedback.`,
          },
        ]}
        onClose={() => setRedo(false)}
        open={redo}
        onSubmit={(index) => {
          if (index === 1) {
            // dispatch(
            //   redoWholeSection(
            //     token,
            //     selectedOrganizationCorp?._id,
            //     selectedSection?._id,
            //     selectedOrganizationCorp?.frequency_id
            //   )
            // );
            dispatch(setPublishAnswers([]));
          } else {
            setRedoSpecific(true);
          }
          setRedo(false);
        }}
      />
      <Grid
        md={3}
        style={{ marginTop: 10, height: "calc(42em)", overflow: "auto" }}
      >
        <img
          src={notebook}
          alt="notebook"
          // onClick={toggleNotebookDrawer}
          style={{
            marginTop: 50,
            cursor: "pointer",
            paddingLeft: 80,
            marginBottom: 10,
          }}
        />
        <Grid md={10} style={{ paddingLeft: "30px" }}>
          {assignedSection.map((section, index) => {
            const is_selected = section?._id === selectedSection?._id;
            const getColor = () => {
              if (is_selected) {
                // return "#121111";
                return "red";
              } else {
                return "#544f4f";
              }
            };

            const getStyles = () => {
              if (is_selected) {
                return {
                  backgroundColor: "#EBF1F8",
                  fontWeight: 500,
                  padding: 6,
                  borderRadius: 5,
                };
              } else
                return {
                  // fontWeight: 500,
                  padding: 6,
                };
            };
            return (
              <Box
                key={section._id}
                style={{
                  ...getStyles(),
                  marginBottom: 10,
                  display: "flex",
                  // alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedSection(section)}
              >
                {/* <img
                  alt=""
                  src={green}
                  style={{ width: "20px", height: "20px" }}
                /> */}
                <Typography
                  style={{
                    padding: "1px 4px",
                    borderRadius: "50%",
                    backgroundColor: "#3374B9",
                    color: "#fff",
                    fontSize: 11,
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {section?.order}
                </Typography>
                <Typography
                  style={{
                    fontWeight: is_selected ? 500 : 400,
                    marginLeft: 8,
                  }}
                  variant="body2"
                >
                  {section?.title}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      </Grid>
      <Grid md={7}>
        <Card
          variant="outlined"
          style={{
            backgroundColor: "#FFFFFF",
            height: "80vh",
          }}
        >
          {/* <CardHeader style={{ backgroundColor: "#A1BFDF" }}>
            <Typography style={{ fontWeight: "600", color: "#15314E" }}>
              {selectedSection?.title}
            </Typography>
          </CardHeader> */}
          {!redoSpecific && (
            <DialogTitle
              style={{
                backgroundColor: "#EBF1F8",
                padding: 10,
                borderBottom: "0.1em solid #91c4f9",
                transitionProperty: "height",
                transitionDuration: "2s",
                transitionDelay: "1s",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    padding: "0px 5px 5px 5px",
                    fontSize: 15,
                    fontWeight: 600,
                  }}
                >
                  {sectionWiseQuestion.length === publishAnswer?.length &&
                  !redoQuestion
                    ? "Section Complete"
                    : selectedSection?.title}
                </Typography>
                {/* <IconButton onClick={handleClose}> */}
                {/* <CloseIcon /> */}
                {/* </IconButton> */}
              </div>
              <Typography
                style={{
                  padding: "0px 5px 0px 5px",
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#5C90C7",
                  textTransform: "capitalize",
                }}
              >
                {sectionWiseQuestion.length === publishAnswer?.length &&
                !redoQuestion
                  ? "Thank You"
                  : `Shape the narrative of your company's sustainability journey by
              providing detailed insights in our Company Profile Section,
              capturing the essence of your environmental, social, and
              governance practices.`}
              </Typography>
              {!redoQuestion && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 5px 0px 5px",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <Progress
                      // style={{ zIndex: 0 + '!important',}}
                      color="primary"
                      variant="determinate"
                      value={
                        sectionWiseQuestion.length === publishAnswer?.length
                          ? 100
                          : (100 / sectionWiseQuestion?.length) *
                            selectedQuestion?.[0]?.order
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      style={{
                        padding: 5,
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#15314E",
                        textTransform: "capitalize",
                        marginLeft: 15,
                      }}
                    >
                      {" "}
                      {`question  ${
                        sectionWiseQuestion.length === publishAnswer?.length
                          ? publishAnswer?.length
                          : selectedQuestion?.[0]?.order
                      } / ${sectionWiseQuestion?.length}`}
                    </Typography>
                  </div>
                </div>
              )}
            </DialogTitle>
          )}

          {!redoSpecific ? (
            <CardContent style={{ padding: "5px 10px 10px 10px" }}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ backgroundColor: "#A1BFDF" }}
                >
                  {/* <Typography style={{ fontWeight: "600", color: "#15314E" }}>
                  {selectedSection?.title}
                </Typography> */}
                </Grid>
              </Grid>
              {sectionWiseQuestion.length !== publishAnswer?.length ||
              redoQuestion ? (
                <>
                  <div
                    className="scroll"
                    style={{
                      height: redoQuestion ? "62vh" : "55vh",
                    }}
                  >
                    <Grid
                      container
                      // style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item xs={12} md={12} sm={12}>
                        {/* <Card variant="outlined"> */}
                        {/* <CardContent style={{ height: "100%" }}> */}
                        <Grid sm={12} md={12} lg={12} spacing={1}>
                          {selectedQuestion?.map((que, i) => {
                            return (
                              <div
                                style={{
                                  border: "none",
                                  borderRadius: 5,

                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  // justifyContent: "center",
                                  padding: "16px 0px",
                                }}
                              >
                                <div style={{ display: "flex", width: "100%" }}>
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 400,
                                      padding: "5px 6px",
                                      wordBreak: "break-all",
                                      border: "1px solid #E9E9E9",
                                      backgroundColor: "#FCFCFC",
                                      borderRadius: "6px",
                                      marginLeft: 5,
                                      width: "80%",
                                    }}
                                  >
                                    {que?.question}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 400,
                                      padding: "5px 6px",
                                      wordBreak: "break-all",
                                      border: "1px solid #EBF1F8",
                                      backgroundColor: "#EBF1F8",
                                      borderRadius: "6px",
                                      marginLeft: 5,
                                      width: "18%",
                                      textAlign: "center",
                                      height: "fit-content",
                                    }}
                                  >
                                    {que?.weightage} Weightage
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    padding: "25px 25px",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                                    <RadioGroup
                                      aria-label="gender"
                                      // name="gender1"
                                      value={value}
                                      onChange={handleChange}
                                      name={JSON.stringify(que?.answers)}
                                    >
                                      {que?.answers?.map((o) => (
                                        <FormControlLabel
                                          value={o?.weightage || o}
                                          control={<Radio />}
                                          label={o?.option || o}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            );
                          })}

                          {/* {selectedQuestion?.map((que, i) => {
                            return (
                              <Question
                                key={que?._id}
                                question={que?.question}
                                type={que?.question_type}
                                options={que?.answers}
                                weightage={que?.weightage}
                                questionId={que?._id}
                                removeEditOption={true}
                              />
                            );
                          })} */}
                        </Grid>
                        {/* </CardContent> */}
                        {/* </Card> */}
                        {/* <Grid container spacing={1} style={{ marginTop: "25px" }}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Add A comment</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="commentText"
                            id="standard-multiline-static"
                            multiline
                            rows={2}
                            variant="outlined"
                            style={{ width: "100%" }}
                            // value={collect.comment}
                            // onChange={(event) =>
                            //   // setCollect((prev) => ({
                            //   //   ...prev,
                            //   //   comment: event.target.value,
                            //   // }))
                            // }
                          />
                        </Grid>
                      </Grid> */}
                      </Grid>
                    </Grid>
                  </div>

                  {redoQuestion ? (
                    <Grid lg={12} md={12} sm={12} style={{ float: "right" }}>
                      <CustomButton
                        // variant="contained"
                        style={{
                          marginRight: "15px",
                          color: "#3374B9",
                          textTransform: "none",
                          padding: "4px 20px",
                        }}
                        onClick={() => {
                          setSelectedQuestion([]);
                          setRedoSpecific(true);
                        }}
                        disabled={!getIndex()}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        // disabled={collect.value === ""}
                        style={{
                          backgroundColor: "#3374B9",
                          textTransform: "none",
                          padding: "4px 20px",
                        }}
                        onClick={() => {
                          // dispatch(
                          //   submitSectionAnswer(
                          //     token,
                          //     selectedSection?._id,
                          //     selectedOrganizationCorp?._id,
                          //     selectedOption,
                          //     selectedOrganizationCorp?.version,
                          //     selectedOrganizationCorp?.frequency_id,
                          //     selectedQuestion[0]?._id,
                          //     selectedOption?.weightage || null,
                          //     selectedQuestion[0]?.order,
                          //     selectedSection?.default,
                          //     selectedQuestion[0]?.question_type
                          //   )
                          // );
                          dispatch(setSelectedOption(null));
                          setRedoSpecific(true);
                        }}
                        disabled={!selectedOption}
                      >
                        Submit
                      </CustomButton>
                    </Grid>
                  ) : (
                    <Grid lg={12} md={12} sm={12} style={{ float: "right" }}>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: "15px",
                          padding: "4px 20px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          handleQuestionChange(-1);
                        }}
                        disabled={!getIndex()}
                      >
                        Previous
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        // disabled={collect.value === ""}
                        style={{ padding: "4px 20px", textTransform: "none" }}
                        onClick={() => {
                          handleQuestionChange(1);
                        }}
                        disabled={!selectedOption}
                      >
                        Submit & Next
                      </CustomButton>
                    </Grid>
                  )}
                </>
              ) : (
                <div className="scroll" style={{ height: "65vh" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "4em",
                    }}
                  >
                    <img
                      style={{ maxWidth: "300px", maxHeight: "300px" }}
                      src={SurveySuccess}
                      alt="survey_success_vector"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "30px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#0F0F0F",
                          paddingBottom: "13px",
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "500",
                          letterSpacing: "0.0015rem",
                          textTransform: "capitalize",
                        }}
                      >
                        The survey has been Saved.
                      </Typography>
                      <Typography
                        style={{
                          color: "#0F0F0F",
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "400",
                          letterSpacing: "0.0025em",
                          textTransform: "capitalize",
                        }}
                      >
                        If you wish to redo the survey please use the redo
                        button. Or go back to the task section.
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingTop: "30px",
                    }}
                  >
                    <CustomButton
                      style={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        padding: "4px 20px",
                      }}
                      // variant="contained"
                      color="primary"
                      onClick={() => setRedo(true)}
                    >
                      Redo Section
                    </CustomButton>
                    <CustomButton
                      style={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        padding: "4px 20px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => history.push("/corporate_questions")}
                    >
                      Go To Task Menu
                    </CustomButton>
                  </div>
                </div>
              )}
            </CardContent>
          ) : (
            <CardContent style={{ padding: 0 }}>
              <Grid
                container
                style={{
                  display: "flex",
                  backgroundColor: "#FBFBFB",
                  padding: "5px 10px 10px 10px",
                }}
              >
                <Grid item md={1}>
                  <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                    Que. no
                  </Typography>
                </Grid>
                <Grid item md={10}>
                  <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                    Question
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    Explore
                  </Typography>
                </Grid>
              </Grid>
              <Grid className="scroll" style={{ height: "66vh" }}>
                {sectionWiseQuestion.length &&
                  sectionWiseQuestion.map((question) => (
                    <Grid
                      container
                      style={{ display: "flex", padding: "5px 10px 10px 10px" }}
                    >
                      <Grid item md={1}>
                        <Typography style={{ paddingLeft: 8, fontSize: 13 }}>
                          {question.order}
                        </Typography>
                      </Grid>
                      <Grid item md={10}>
                        <Typography style={{ fontSize: 13 }}>
                          {question.question}
                        </Typography>
                      </Grid>
                      <Grid item md={1} align="right">
                        <Typography
                          style={{
                            padding: 5,
                            cursor: "pointer",
                            color: "#3374B9",
                            fontSize: 16,
                          }}
                          onClick={() => {
                            setRedoSpecific(false);
                            setRedoQuestion(true);
                            setSelectedQuestion([question]);
                          }}
                        >
                          {`>`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{ float: "right", padding: "10px 10px" }}
              >
                {/* <CustomButton
                  // variant="contained"
                  style={{
                    marginRight: "15px",
                    color: "#3374B9",
                    textTransform: "none",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    setSelectedQuestion([]);
                    setRedoQuestion(!redoQuestion);
                  }}
                  disabled={!getIndex()}
                >
                  Cancel
                </CustomButton> */}
                <CustomButton
                  variant="contained"
                  color="primary"
                  // disabled={true}
                  style={{
                    // color: "#fff",
                    textTransform: "none",
                    fontSize: 13,
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    // dispatch(
                    //   redoSpecificSectionSubmit(
                    //     token,
                    //     selectedOrganizationCorp?._id,
                    //     selectedSection?._id,
                    //     selectedOrganizationCorp?.frequency_id,
                    //     sectionWiseQuestion?.length,
                    //     selectedOrganizationCorp?.version
                    //   )
                    // );
                    setRedoQuestion(false);
                    setRedoSpecific(false);
                  }}
                >
                  Submit Changes On Section
                </CustomButton>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default RiskSurvey;
