import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import RadioGroup from "@material-ui/core/RadioGroup";

import { TextField } from "@material-ui/core";

import RadioButton from "../../../../../../../UI/radioButton/radioButton";

const Scale = ({
  questionId,
  questionType,
  options,
  submittedAnswer,
  responseAnswer,
  handleQuesAnswer,
  isFrom,
  event,
  variant,
  comment,
  linkageAnswer,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});

  const handleOption = (opt) => {
    setOption(opt);
  };

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        comment: commentBox,
        weightage: option?.weightage,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  return (
    <div>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          {options?.map((option) => (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FormControlLabel
                key={option.option}
                value={option.option}
                control={<RadioButton color="primary" />}
                // control={
                //   <RadioButton color="primary" style={{ padding: "5px" }} />
                // }
                // label={option.option}
                onClick={() => handleOption(option)}
                label={
                  <Typography variant={variant}>{option.option}</Typography>
                }
                style={{ pointerEvents: event }}
              />
              {/* <span style={{ marginRight: "10px" }}>
                  <ErrorOutlineOutlinedIcon style={{ color: "#3374B9" }} />
                </span> */}
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

export default Scale;
