import { Box, TextField, Typography } from "@material-ui/core";
import QuestionBody from "../../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";
import { BiBorderRadius } from "react-icons/bi";

const textFieldStyling = () => {
  return {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#242424",
    // border: "1px solid #999",
    // padding: "5px 10px",
    // borderRadius: "10px",
    // minHeight: "50px",
  };
};

const SubmissionQues = ({
  question,
  answers,
  approvedAnswer,
  questionType,
  tableHeadersApi,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      mt={4}
      style={{ gap: 20, padding: "0 10px" }}
    >
      <TextField
        multiline
        value={question}
        variant="outlined"
        InputProps={{
          style: textFieldStyling(),
        }}
        style={{ wordWrap: "normal" }}
      />

      {/* <Typography variant="body1" style={textFieldStyling()}>
        {question}
      </Typography> */}
      <QuestionBody
        questionType={questionType}
        answers={answers}
        submittedAnswer={approvedAnswer}
        comment={
          questionType === "TABLE"
            ? approvedAnswer?.length > 0 &&
              approvedAnswer[0][0]?.comment !== null &&
              approvedAnswer[0][0]?.comment !== ""
            : approvedAnswer &&
              approvedAnswer[0]?.comment !== null &&
              approvedAnswer[0]?.comment !== ""
        }
        event={"none"}
        tableHeadersApi={tableHeadersApi}
      />
    </Box>
  );
};
export default SubmissionQues;
