import React, { useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { TextField } from "@material-ui/core";

const DocumentUpload = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    // Perform upload logic here
    if (selectedFile) {
      const obj = {
        document: selectedFile,
        comment: commentBox,
        weightage: 1,
      };
      handleQuesAnswer(obj);
    }
  }, [selectedFile, commentBox]);

  useEffect(() => {
    // Perform upload logic here
    if (submittedAnswer) {
      setCommentBox(submittedAnswer[0]?.comment);
    }
  }, [submittedAnswer]);

  return (
    <Box>
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {
          // (isFrom === "approvalSection" || isFrom === "completedSection") &&
          submittedAnswer ? (
            <Box
              border={1}
              borderRadius={4}
              p={1}
              borderColor={"#cececece"}
              ml={2}
            >
              <a
                href={submittedAnswer[0]?.documentUrl?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer[0]?.documentUrl?.url}
              </a>
            </Box>
          ) : (
            <>
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none", pointerEvents: event }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {selectedFile?.name ? selectedFile.name : "Upload file"}
              </div>
            </>
          )
        }
      </Box>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentUpload;
