import React from "react";
import { useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";

function TechnicalAndOperationalSecurity({ severity }) {
  const { isoSurveyScores } = useSelector((state) => state.brmData);

  const nameFormat = (name) => {
    if (name === "governanceAndOrganizationalSecurity") {
      return "Governance And Organizational Security";
    } else if (name === "technicalAndOperationalSecurity") {
      return "Technical And Operational Security";
    }
  };

  const defaultData = {
    governanceAndOrganizationalSecurity: {
      comment: "",
      score: isoSurveyScores?.governanceAndOrganizationalSecurity?.totalScore,
    },
    technicalAndOperationalSecurity: {
      comment: "",
      score: isoSurveyScores?.technicalAndOperationalSecurity?.totalScore,
    },
  };

  const scoresDefaultData = {
    governanceAndOrganizationalSecurity: {
      "Information Security Policies":
        isoSurveyScores?.governanceAndOrganizationalSecurity?.[
          "Information Security Policies"
        ],
      "Organization of information security":
        isoSurveyScores?.governanceAndOrganizationalSecurity?.[
          "Organization of information security"
        ],
      "Human Resource Security":
        isoSurveyScores?.governanceAndOrganizationalSecurity?.[
          "Human Resource Security"
        ],
      "Information security aspects of business continuity management":
        isoSurveyScores?.governanceAndOrganizationalSecurity?.[
          "Information security aspects of business continuity management"
        ],
      Compliance:
        isoSurveyScores?.governanceAndOrganizationalSecurity?.["Compliance"],
    },

    technicalAndOperationalSecurity: {
      "Asset management":
        isoSurveyScores?.technicalAndOperationalSecurity?.["Asset management"],
      "Access control":
        isoSurveyScores?.technicalAndOperationalSecurity?.["Access control"],
      Cryptography:
        isoSurveyScores?.technicalAndOperationalSecurity?.["Cryptography"],
      "Physical and environmental security":
        isoSurveyScores?.technicalAndOperationalSecurity?.[
          "Physical and environmental security"
        ],
      "Operations Security":
        isoSurveyScores?.technicalAndOperationalSecurity?.[
          "Operations Security"
        ],
      "System acquisition, development and maintenance":
        isoSurveyScores?.technicalAndOperationalSecurity?.[
          "System acquisition, development and maintenance"
        ],
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {defaultData &&
        Object?.entries(defaultData)?.map(([key, value], index) => (
          <Grid md={6}>
            <BlueBackgroundCard heading={nameFormat(key)} />
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"15px"}
              marginLeft={"20px"}
            >
              {value?.score && (
                <Chip
                  label={
                    parseFloat(value?.score) <= 33
                      ? "Low"
                      : parseFloat(value?.score) > 33 &&
                        parseFloat(value?.score) <= 66
                      ? "Mid"
                      : "High"
                  }
                  style={{
                    color:
                      parseFloat(value?.score) <= 33
                        ? severity("text", "red")
                        : parseFloat(value?.score) > 33 &&
                          parseFloat(value?.score) <= 66
                        ? severity("text", "yellow")
                        : severity("text", "green"),
                    backgroundColor:
                      parseFloat(value?.score) <= 33
                        ? severity("bg", "red")
                        : parseFloat(value?.score) > 33 &&
                          parseFloat(value?.score) <= 66
                        ? severity("bg", "yellow")
                        : severity("bg", "green"),
                  }}
                  size="small"
                />
              )}
              <Typography
                style={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "40px" }}>{value?.score || "-"}</span>
                <span style={{ fontSize: "20px" }}>/</span>
                <span>100</span>
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "13px",
                padding: "5px",
                marginBottom: "10px",
              }}
              // component={Paper}
            >
              The score reflects the vendor's performance and can guide them in
              making informed decisions.
            </Typography>
            <Grid justifyContent="space-around">
              <Grid item md={5}>
                {/* <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  /> */}
              </Grid>
              <Grid item md={5}>
                <div
                  key={index}
                  style={{
                    width: "95%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    marginTop: "20px",
                  }}
                >
                  <TopicHeader
                    topic={nameFormat(key)}
                    // score={value?.score}
                    noIcon
                  />
                  {(value?.sectionWiseScore || scoresDefaultData) &&
                    Object.entries(
                      value?.sectionWiseScore ?? scoresDefaultData[key]
                    )?.map(([section, score], i) => (
                      <TopicData
                        key={i}
                        topic={section}
                        // score={score}
                        textValue={score}
                        marginTop={"10px"}
                      />
                    ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default TechnicalAndOperationalSecurity;
