import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

const CurrencyField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleChange = (event) => {
    // Remove non-numeric characters and format the value as currency
    const formattedValue = event.target.value
      .replace(/[^\d.]/g, "") // Remove non-numeric characters except '.'
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2") // Ensure only one '.' is present
      .replace(/^(\d*)(\.\d{0,2}).*/, "$1$2"); // Allow only up to two decimal places

    setValue(formattedValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [value]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setValue(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  return (
    <Box>
      <TextField
        style={{ pointerEvents: event }}
        label={"Currency"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            inputMode: "numeric", // Set input mode to allow numeric input
            pattern: "[0-9]*", // Set pattern to allow only numeric characters
          },
        }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CurrencyField;
