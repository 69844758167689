import React from "react";
import { Box, Typography, IconButton, Button, Drawer } from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/CloseOutlined";

const DynamicDrawer = ({
  heading,
  buttonText,
  disableSubmit,
  submitClick,
  hasDrawerOpen,
  handleClose,
  style,
  centerHead,
  hasDefaultActionButton = true,
  hasCustomActionButton = false,
  hasSecondaryHeader = false,
  children,
  dynamicWidth,
}) => {
  return (
    <Drawer
      anchor="right"
      open={hasDrawerOpen}
      onClose={handleClose}
      PaperProps={{
        style: { width: dynamicWidth },
      }}
    >
      {/* Drawer Header */}
      <Box
        style={{
          boxShadow: "0px 2px 4px #3374B926",
          padding: "10px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Typography style={{ fontWeight: 600, flexShrink: 0, width: "90%" }}>
            {heading}
          </Typography>
          {centerHead && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {centerHead}
            </div>
          )}
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </div>
      </Box>

      {/* secondary header */}
      {hasSecondaryHeader && <Box>{hasSecondaryHeader}</Box>}

      {/* Drawer Contents */}
      <Box
        style={{
          height: "90%",
          overflow: "scroll",
          minWidth: "40vw",
          ...style,
        }}
      >
        <Box
          mt={1}
          style={{
            height: "99%",
            overflow: "scroll",
          }}
        >
          {children}
        </Box>
      </Box>

      {/* Drawer Default action Buttons */}
      {hasDefaultActionButton && (
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 10,
            padding: 10,
          }}
        >
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submitClick}
            disabled={disableSubmit}
            style={{ minWidth: "10em" }}
          >
            {buttonText}
          </Button>
        </Box>
      )}

      {/* Drawer custom action Buttons */}
      {hasCustomActionButton && (
        <Box
          style={{
            display: "flex",
            padding: 2,
          }}
        >
          {hasCustomActionButton}
        </Box>
      )}
    </Drawer>
  );
};

export default DynamicDrawer;
