import { reduxGet } from "utils/_helpers/reduxGet";
import apiGqlBoilerplate from "utils/_helpers/apiGqlBoilerplate";
// constants
import {
  SET_SHARED_SURVEY_DETAILS,
  SET_TERMINATION_EXIT_PLAN,
  SET_VENDOR_ALERTS,
  SET_VENDOR_RECOMMENDATION,
} from "constants/vendorDiagnosisConstants";
// gql
import {
  GQL_CREATE_ALERT,
  GQL_GET_ALERT,
  GQL_GET_SHARED_SURVEY_DETAILS,
  GQL_GET_TERMINATION_EXIT_PLAN,
  GQL_UPDATE_ALERT,
} from "redux/graphql/gql_VendorDiagnosis";

// ------------------------- Payloads -------------------------
export const setSharedSurveyDetails = (payload) => {
  return {
    type: SET_SHARED_SURVEY_DETAILS,
    payload,
  };
};

export const setVendorAlerts = (payload) => {
  return {
    type: SET_VENDOR_ALERTS,
    payload,
  };
};

export const setTerminationExitPlan = (payload) => ({
  type: SET_TERMINATION_EXIT_PLAN,
  payload,
});

export const setVendorRecommendation = (payload) => ({
  type: SET_VENDOR_RECOMMENDATION,
  payload,
});
// -------------------------- Actions --------------------------

export function gql_get_shared_survey_details({
  bankId,
  surveyIds,
  vendorId,
  isFrom,
}) {
  return async (dispatch) => {
    apiGqlBoilerplate({
      querySchema: GQL_GET_SHARED_SURVEY_DETAILS,
      variables:
        isFrom === "ongoingAssessment"
          ? { bankId, vendorId }
          : { bankId, surveyIds, vendorId },
      queryType: "query",
      callback: (data) => {
        dispatch(setSharedSurveyDetails(data?.sharedSurveyDetails));
      },
    });
  };
}

// ---------- Alert Queries ----------
export function gql_get_alert({ widgetName }) {
  apiGqlBoilerplate({
    querySchema: GQL_GET_ALERT,
    variables: { widgetName },
    queryType: "query",
    hideLoader: true,
    callback: (data) => {
      reduxGet.dispatch(setVendorAlerts(data?.getAlert));
    },
  });
}

export function gql_create_alert({
  comment,
  surveyId,
  frequencyType,
  lowerBounds,
  name,
  upperBounds,
  widgetName,
  stepName,
}) {
  const { vendorBasicDetails } = reduxGet.getState().brmData;

  return apiGqlBoilerplate({
    querySchema: GQL_CREATE_ALERT,
    variables: {
      vendorId: vendorBasicDetails?._id,
      surveyId,
      comment,
      frequencyType,
      lowerBounds,
      name,
      upperBounds,
      widgetName,
      stepName,
    },
    queryType: "mutate",
    hideLoader: true,
    callback: (data) => {
      // callback
    },
  });
}

export function gql_update_alert({ updatedAlerts }) {
  return apiGqlBoilerplate({
    querySchema: GQL_UPDATE_ALERT,
    variables: {
      updatedAlerts,
    },
    queryType: "mutate",
    hideLoader: true,
    callback: (data) => {
      // callback
    },
  });
}

// Termination and Offboarding
export function gql_get_exit_plan({ bankId, surveyId, vendorId }) {
  apiGqlBoilerplate({
    querySchema: GQL_GET_TERMINATION_EXIT_PLAN,
    variables: { bankId, surveyId, vendorId },
    queryType: "query",
    hideLoader: true,
    callback: (data) => {
      reduxGet.dispatch(
        setTerminationExitPlan(data?.getLatestSubmittedSurveyResponse)
      );
    },
  });
}

export function gql_get_vendor_recommendation({ bankId, surveyId, vendorId }) {
  apiGqlBoilerplate({
    querySchema: GQL_GET_TERMINATION_EXIT_PLAN,
    variables: { bankId, surveyId, vendorId },
    queryType: "query",
    hideLoader: true,
    callback: (data) => {
      reduxGet.dispatch(
        setVendorRecommendation(data?.getLatestSubmittedSurveyResponse)
      );
    },
  });
}
// --------------------------------------------------------------
