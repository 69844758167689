import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function PreviousStakeholdersRisk({ data }) {
  const classes = useStyle();

  return (
    <Box sx={{ margin: "10px 0px" }}>
      <TableContainer component="paper">
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Company Relationship</TableCell>

              <TableCell align="left">Date of Birth</TableCell>
              <TableCell align="left">Latest Address</TableCell>
              <TableCell align="left">Match Type</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                className={classes.tableRow}
                // onClick={() => console.log(data.id)}
              >
                <TableCell align="left">{item?.name}</TableCell>
                <TableCell align="left">{item?.role}</TableCell>
                <TableCell align="left">
                  {item?.dateOfBirth
                    ? moment(item?.dateOfBirth).format("Do MMM, YYYY")
                    : "-"}
                </TableCell>

                <TableCell align="left">{item?.address}</TableCell>
                <TableCell align="left">{item?.matched}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
