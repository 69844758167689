import { Slider, withStyles } from "@material-ui/core";

const MuiSlider = withStyles({
  root: {
    color: "#3374B9", // Customize track color
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff", // White circle inside
    border: "4px solid currentColor", // Border to match the slider color
    marginTop: -8,
    marginLeft: -8,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + -6px)",
  },
  track: {
    height: 8, // Make the track thicker
    marginTop: -4,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    backgroundColor: "#BFBFBF",
    marginTop: -4,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: "#BBBBBB",
    height: 8,
    width: 2,
    marginTop: 14,
  },
  markLabel: {
    marginTop: 12,
    color: "#505050",
  },
})(Slider);

export default MuiSlider;
