import { Box, Typography, TextField } from "@material-ui/core";
import FrequencyDropdown from "../common/FrequencyDropdown";
import FrequencySlider from "../common/FrequencySlider";
import FrequencyRange from "../common/FrequencyRange";

const AddAlert = ({ newAlert, setNewAlert }) => {
  const handleAlertData = (value, item) => {
    setNewAlert((prev) => ({ ...prev, [item]: value }));
  };

  return (
    <Box p={2} display="flex" flexDirection="column" sx={{ gap: 10 }}>
      {/* Alert Name */}
      <Box>
        <Typography
          variant="subtitle2"
          style={{ color: "#9A9A9A" }}
          gutterBottom
        >
          Name of the Alert
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Enter Here"
          value={newAlert?.name || ""}
          onChange={(e) => handleAlertData(e.target.value, "name")}
          fullWidth
        />
      </Box>
      {/* Frequency */}
      <Box display="flex" flexDirection="column" sx={{ gap: 5 }}>
        <Typography variant="subtitle2" style={{ color: "#9A9A9A" }}>
          Freqency
        </Typography>
        {/* Dropdown menu */}
        <FrequencyDropdown
          selectedOpt={newAlert?.frequency || ""}
          handleOption={(opt) => handleAlertData(opt, "frequency")}
        />

        {/* Range slider and inputs */}
        <Box display="flex" my={2} px={1} sx={{ gap: 40 }}>
          {/* Range Slider */}
          <FrequencySlider
            range={newAlert?.range}
            handleRange={(values) => handleAlertData(values, "range")}
          />
          {/* Frequency Inputs */}
          <FrequencyRange
            range={newAlert?.range}
            handleRange={(values) => handleAlertData(values, "range")}
          />
        </Box>

        {/* Comment */}
        <Box>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ color: "#9A9A9A" }}
          >
            Comment
          </Typography>

          <TextField
            placeholder="Enter message to be displayed while receiving this alert"
            variant="outlined"
            value={newAlert?.comment || ""}
            onChange={(e) => handleAlertData(e.target.value, "comment")}
            fullWidth
            multiline
            minRows={3}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default AddAlert;
