import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";

const DatePicker = ({
  hasRange = false,
  submittedAnswer,
  responseAnswer,
  handleQuesAnswer,
  isFrom,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);
  const [commentBox, setCommentBox] = useState("");

  const handleStartDateChange = (key, date) => {
    dispatch(
      setSelectedOption(
        selectedOption !== null
          ? { ...selectedOption, [key]: date }
          : { [key]: date }
      )
    );
  };

  function formatDate(dateString) {
    return dateString?.split("T")[0];
  }

  React.useEffect(() => {
    if (submittedAnswer) {
      dispatch(setSelectedOption(submittedAnswer[0]));
      setCommentBox(submittedAnswer[0]?.comment);
    } else {
      dispatch(setSelectedOption(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOption && isFrom === "taskSection") {
      const obj = {
        startDate: selectedOption?.startDate,
        endDate: selectedOption?.endDate,
        comment: commentBox,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, commentBox]);

  useEffect(() => {
    if (linkageAnswer !== null && isFrom === "taskSection") {
      const obj = {
        startDate: linkageAnswer,
        comment: commentBox,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  }, [linkageAnswer]);

  return (
    <Box>
      <form
        noValidate
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <TextField
          id="start-date"
          label={hasRange ? "Start Date" : "Select Date"}
          type="date"
          //   className={classes.textField}
          disabled={linkageAnswer !== null ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          value={
            submittedAnswer
              ? formatDate(selectedOption?.startDate)
              : selectedOption?.startDate
          }
          onChange={(e) => handleStartDateChange(e.target.name, e.target.value)}
          variant="outlined"
          color="primary"
          name="startDate"
          style={{ pointerEvents: event }}
        />
        {hasRange && (
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            disabled={linkageAnswer !== null ? true : false}
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={
              submittedAnswer
                ? formatDate(selectedOption?.endDate)
                : selectedOption?.endDate
            }
            onChange={(e) =>
              handleStartDateChange(e.target.name, e.target.value)
            }
            variant="outlined"
            color="primary"
            name="endDate"
            style={{ pointerEvents: event }}
          />
        )}
      </form>
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default DatePicker;
