import { Box, IconButton, Typography } from "@material-ui/core";
import { IoArrowBack, IoClose } from "react-icons/io5";

const AlertsHead = ({ alertScreen, setAlert, closeDrawer }) => {
  return (
    <Box mb={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {/* Heading */}
        <Box display={"flex"} gap={10} alignItems={"center"}>
          {alertScreen?.type === "add" && (
            <IconButton onClick={() => setAlert({ type: "set", from: "" })}>
              <IoArrowBack size={22} color="#3374B9" />
            </IconButton>
          )}
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            {alertScreen?.type === "set" ? "Set Alerts" : "Add Alert"}
          </Typography>
        </Box>

        {/* Close Drawer */}
        <IconButton onClick={closeDrawer}>
          <IoClose size={22} color="#3374B9" />
        </IconButton>
      </Box>

      {/* Sub Head */}
      <Typography variant="body2" style={{ fontWeight: 500 }}>
        Stay informed with our survey alerts! You'll receive notifications when
        a vendor's score falls below or exceeds the set thresholds, ensuring you
        always have the latest updates on their performance.
      </Typography>
    </Box>
  );
};
export default AlertsHead;
