import { Box, Chip, TextField, Typography, useTheme } from "@material-ui/core";
import DynamicDrawer from "components/UI/dynamicDrawer/DynamicDrawer";
import RequiredField from "components/UI/inputField/requiredShow";
import Stack from "components/UI/Stack/Stack";
import { useState } from "react";
import { gql_add_enitity } from "redux/actions/vendorActions/vendorOrgAction";

const EntityDrawer = ({ open, onClose }) => {
  const { action } = useTheme().palette;

  const initialState = {
    name: "",
    entityType: "",
    userDefinedId: "",
    streetName: "",
    state: "",
  };

  const [entityData, setEntityData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEntityData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChipClick = (entityType) => {
    setEntityData((prev) => ({ ...prev, entityType }));
  };

  const disableSubmit = Object.values(entityData).some((value) => !value);

  const closeDrawer = () => {
    setEntityData(initialState);

    onClose();
  };

  const handleSubmit = () => {
    gql_add_enitity({ ...entityData, closeDrawer });
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Entity"}
      buttonText={"Add Entity"}
      disableSubmit={disableSubmit}
      submitClick={handleSubmit}
      style={{ padding: 10 }}
    >
      <Stack flexDirection={"column"} spacing={20}>
        {/* Entity Name */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Entity Name {RequiredField(!entityData.name)}
          </Typography>
          <TextField
            name="name"
            variant="outlined"
            placeholder="Enter Name"
            value={entityData.name}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Entity Type */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Entity Type {RequiredField(!entityData.entityType)}
          </Typography>
          <Stack spacing={10}>
            <Chip
              label="Subsidary"
              variant="outlined"
              onClick={() => handleChipClick("Subsidary")}
              color={
                entityData.entityType === "Subsidary" ? "primary" : "default"
              }
            />
            <Chip
              label="Joint Venture"
              variant="outlined"
              onClick={() => handleChipClick("Joint Venture")}
              color={
                entityData.entityType === "Joint Venture"
                  ? "primary"
                  : "default"
              }
            />
          </Stack>
        </Box>

        {/* CIN No. */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            CIN No. {RequiredField(!entityData.userDefinedId)}
          </Typography>
          <TextField
            name="userDefinedId"
            variant="outlined"
            placeholder="CIN No."
            value={entityData.userDefinedId}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Location */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Location{" "}
            {RequiredField(!entityData.streetName || !entityData.state)}
          </Typography>
          <TextField
            name="streetName"
            variant="outlined"
            placeholder="Street name, building name"
            value={entityData.streetName}
            onChange={handleChange}
            style={{ marginBottom: 10 }}
            required
            fullWidth
          />
          <TextField
            name="state"
            variant="outlined"
            placeholder="State"
            value={entityData.state}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>
      </Stack>
    </DynamicDrawer>
  );
};
export default EntityDrawer;
