import React from "react";
import { connect } from "react-redux";
import VendorDashboard from "../../components/screen/vendorDashboard/VendorDashboard";

function VendorDashboardCont(props) {
  return <VendorDashboard {...props} />;
}

export const mapStateToProps = (store) => ({
  data: store.vendorDashboardReducer,
  brmData: store.brmData,
});

// export const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(VendorDashboardCont);
