import { Box, Chip, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },

  chip: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: "13px",
    fontWeight: 500,
    backgroundColor: "#F6F6F6",
  },
}));
const HighlightComp = ({ data }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.messageContainer}
      style={{ justifyContent: "center" }}
    >
      <Chip className={classes.chip} label={data.text} />
    </Box>
  );
};
export default HighlightComp;
