import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Box from "@material-ui/core/Box";

import CustomTextfield from "../../../../../../../UI/textfield/textfield";

import { TextField } from "@material-ui/core";

import { useSelector } from "react-redux";

const CountryField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [commentBox, setCommentBox] = useState("");
  const [country, setCountry] = useState("");

  const { brmData } = useSelector((state) => state);

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: country,
        weightage: 1,
        comment: commentBox,
      };

      handleQuesAnswer(obj);
    }
  }, [country]);

  useEffect(() => {
    if (linkageAnswer || (submittedAnswer && submittedAnswer?.length > 0)) {
      setCountry(linkageAnswer || submittedAnswer[0]?.option);
    }
  }, [submittedAnswer, linkageAnswer]);

  return (
    <div>
      <Box my={2}>
        {submittedAnswer && submittedAnswer[0]?.option ? (
          <CustomTextfield
            value={submittedAnswer[0]?.option}
            variant="outlined"
            disabled={true}
            fullWidth
            size="small"
          />
        ) : (
          <Autocomplete
            name="compCountry"
            id="countryCode"
            options={brmData.countries.map((c) => c?.country_name || "")}
            getOptionLabel={(option) => option || ""}
            // renderOption={(option) => option}
            size="small"
            value={country || ""}
            renderInput={(params) => (
              <CustomTextfield
                {...params}
                label={"Country"}
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => {
              if (newValue !== "") {
                setCountry(newValue);
              } else {
                setCountry("");
              }
            }}
          />
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

export default CountryField;
