import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import {
  RESET_CORPORATE,
  SET_CORPORATE_ANSWERS,
  SET_CORPORATE_ORGANIZATIONS,
  SET_CORPORATE_QUESTIONS,
  SET_SURVEY_QUESTIONS,
  SET_ASSIGNED_SECTION,
  SET_SELECTED_ORGANIZATIO_CORP,
  SET_VERSION_HISTORY,
  SET_SELECTED_OPTION,
  SET_PUBLISH_ANSWER,
} from "../../../constants/corporateSurveyConstants";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setQuestions } from "../riskdashboard/riskDashboardActions";
import { assigned1, assigned2 } from "../../../utils/riskUtils/vendorUtils";

export const setCorporateQuestions = (payload) => {
  return {
    type: SET_CORPORATE_QUESTIONS,
    payload,
  };
};

export const setSurveyQuestions = (payload) => {
  return {
    type: SET_SURVEY_QUESTIONS,
    payload,
  };
};

export const setCorporateAnswers = (payload) => {
  return {
    type: SET_CORPORATE_ANSWERS,
    payload,
  };
};

export const setOrganizations = (payload) => {
  return {
    type: SET_CORPORATE_ORGANIZATIONS,
    payload,
  };
};

export const setAssignedSection = (payload) => {
  return {
    type: SET_ASSIGNED_SECTION,
    payload,
  };
};

export const setSelectedOrganizationCorp = (payload) => {
  return {
    type: SET_SELECTED_ORGANIZATIO_CORP,
    payload,
  };
};

export const setVersionHistory = (payload) => {
  return {
    type: SET_VERSION_HISTORY,
    payload,
  };
};

export const setSelectedOption = (payload) => {
  return {
    type: SET_SELECTED_OPTION,
    payload,
  };
};

export const setPublishAnswers = (payload) => {
  return {
    type: SET_PUBLISH_ANSWER,
    payload,
  };
};

export const getCorporateQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCorporateQuestions(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

export const getSurveyeQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_all_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSurveyQuestions(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

export const addSurveyeQuestions = (token, organization_id, question) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
      question,
    });
    return fetch(UNIVERSAL.BASEURL + "/questions/add_question ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSurveyeQuestions(token, organization_id));
        }
        dispatch(unset_loader);
      });
  };
};

export const submitAnswer = (
  token,
  organization_id,
  question_id,
  value,
  file
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      question_id,
      value,
    });
    var formData = new FormData();
    formData.append("data", data);
    formData.append("file", file);
    return fetch(UNIVERSAL.BASEURL + "/questions/submit_answer", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // Array.isArray(responseJson.result) && dispatch(setCorporateAnswers(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

export const getAssignedQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_assigned_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCorporateAnswers(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

// corporate user actions survey$section

export const getAssignedOrganizations = (token) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganizations(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};

export const getAssignedQuestion = (
  token,
  section_id,
  organization_id,
  version,
  frequency_id,
  order
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = {
      "user-token": token,
      section_id,
      organization_id,
      version,
      frequency_id,
    };

    console.log(data);
    if (order === 1) {
      const { questions, submit_answers } = assigned1;
      dispatch(setQuestions(questions));
      console.log('ans',submit_answers)
      dispatch(setPublishAnswers(submit_answers));
    }else{
      const { questions, submit_answers } = assigned2;
      dispatch(setQuestions(questions));
      dispatch(setPublishAnswers(submit_answers));
    }
    // return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_questions", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ data: data }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       const { questions, submit_answers } = responseJson?.result;
    //       dispatch(setQuestions(questions));
    //       dispatch(setPublishAnswers(submit_answers));
    //       dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
    //     }
    //     dispatch(unset_loader);
    //   });
  };
};

export const getAssignedSection = (token, version) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      version,
    });
    dispatch(setAssignedSection());
    // return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_sections", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ data: data }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       dispatch(setAssignedSection(responseJson.result));
    //       dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
    //     }
    //     dispatch(unset_loader);
    //   });
  };
};

export const redoSpecificSectionSubmit = (
  token,
  organization_id,
  section_id,
  frequency_id,
  total_no_of_questions,
  version
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      section_id,
      frequency_id,
      total_no_of_questions,
      version,
    });
    // console.log(organization_id, section_id, frequency_id);
    return fetch(
      UNIVERSAL.BASEURL + "/survey/calculate_default_section_scores  ",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};
export const redoWholeSection = (
  token,
  organization_id,
  section_id,
  frequency_id
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      section_id,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/redo_entire_survey ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setPublishAnswers([]));
          dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};

export const submitSectionAnswer = (
  token,
  section_id,
  organization_id,
  answer,
  version,
  frequency_id,
  question_id,
  answer_weightage,
  question_order,
  is_default,
  question_type
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      section_id,
      organization_id,
      answer,
      version,
      frequency_id,
      question_id,
      answer_weightage,
      question_order,
      is_default,
      question_type,
    });

    const handelFormData = () => {
      const dataFile = encrypt({
        "user-token": token,
        section_id,
        organization_id,
        version,
        frequency_id,
        question_id,
        answer_weightage,
        question_order,
        is_default,
        question_type,
        answer: null,
      });

      var formData = new FormData();
      formData.append("data", dataFile);
      formData.append("document", answer);
      return formData;
    };

    const documentBody =
      question_type === "Document Upload" && handelFormData();
    let requestBody =
      question_type === "Document Upload"
        ? {
            method: "POST",

            body: documentBody,
          }
        : {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data }),
          };

    return fetch(UNIVERSAL.BASEURL + "/survey/submit_answers", requestBody)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setPublishAnswers(responseJson.result));
          dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};

export const publishSurvey = (token, version) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      version,
    });

    return fetch(UNIVERSAL.BASEURL + "/survey/publish_survey", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};

export const getVersionHistory = (token) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/survey/get_version_history ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setVersionHistory(responseJson?.result));
          dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
        }
        dispatch(unset_loader);
      });
  };
};
