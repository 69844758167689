import React from "react";
import { useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";

function VendorMaterialityScore() {
  const { vendorMaterialityScores } = useSelector((state) => state.brmData);

  const nameFormat = (name) => {
    if (name === "vendorCategorization") {
      return "Vendor Categorization";
    } else if (name === "vendorMateriality") {
      return "Vendor Materiality";
    } else if (name === "vendorPerformance") {
      return "Vendor Performance";
    }
  };

  const defaultData = {
    vendorCategorization: {
      comment: "",
      score: 0,
    },
    vendorMateriality: {
      comment: "",
      score: 0,
    },
    vendorPerformance: {
      comment: "",
      score: 0,
    },
  };

  const scoresDefaultData = {
    vendorCategorization: {
      "Importance and Impact of Vendor Services": 0,
      "Financial and Operational Impact": 0,
      "Risk of Service Disruption": 0,
      "Cost Considerations": 0,
      "Vendor Alternatives": 0,
      "Compliance and Contractual Agreements": 0,
    },
    vendorMateriality: {
      Customer: 0,
      Regulatory: 0,
      Exposure: 0,
      Concentration: 0,
      "Brand Reputation": 0,
      "Criticality  (over-riding factors)": 0,
    },
    vendorPerformance: {
      "Adherence to Bank's Instructions": 0,
      "Vendor Performance Review": 0,
      "Vendor Service Evaluation": 0,
      "Technical / Product Support": 0,
      "Compliance with On Boarding Terms & Conditions": 0,
    },
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {Object?.entries(vendorMaterialityScores ?? defaultData)?.map(
        ([key, value], index) => (
          <Grid md={4}>
            <BlueBackgroundCard heading={nameFormat(key)} />
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"15px"}
              marginLeft={"20px"}
            >
              {value?.score && (
                <Chip
                  label={
                    parseFloat(value?.score) <= 33
                      ? "Low"
                      : parseFloat(value?.score) > 33 &&
                        parseFloat(value?.score) <= 66
                      ? "Mid"
                      : "High"
                  }
                  size="small"
                  style={{
                    color:
                      parseFloat(value?.score) <= 33
                        ? severity("text", "red")
                        : parseFloat(value?.score) > 33 &&
                          parseFloat(value?.score) <= 66
                        ? severity("text", "yellow")
                        : severity("text", "green"),
                    backgroundColor:
                      parseFloat(value?.score) <= 33
                        ? severity("bg", "red")
                        : parseFloat(value?.score) > 33 &&
                          parseFloat(value?.score) <= 66
                        ? severity("bg", "yellow")
                        : severity("bg", "green"),
                  }}
                />
              )}
              <Typography
                style={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "40px" }}>{value?.score || "-"}</span>
                <span style={{ fontSize: "20px" }}>/</span>
                <span>100</span>
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "13px",
                padding: "5px",
                marginBottom: "10px",
              }}
              // component={Paper}
            >
              The score reflects the vendor's performance and can guide them in
              making informed decisions.
            </Typography>
            <Grid justifyContent="space-around">
              <Grid item md={5}>
                {/* <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  /> */}
              </Grid>
              <Grid item md={5}>
                <div
                  key={index}
                  style={{
                    width: "95%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    marginTop: "20px",
                  }}
                >
                  <TopicHeader
                    topic={nameFormat(key)}
                    score={value?.score}
                    noIcon
                  />
                  {(value?.sectionWiseScore || scoresDefaultData) &&
                    Object.entries(
                      value?.sectionWiseScore ?? scoresDefaultData[key]
                    )?.map(([section, score], i) => (
                      <TopicData
                        key={i}
                        topic={section}
                        score={score}
                        marginTop={"10px"}
                      />
                    ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
}

export default VendorMaterialityScore;
