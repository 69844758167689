import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

import { TextField } from "@material-ui/core";

import CustomButton from "../../../../../../../UI/button/button";

import RiskDrawer from "../../NewQuestionTabComps/RightSection/riskDrawer/RiskDrawer";

const MultiChoice = ({
  questionId,
  option,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  removeEditOption,
  handleQuesAnswer,
  questionType,
  isFrom,
  event,
  comment,
  usingQues,
  linkageAnswer,
}) => {
  // const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  // const checkBoxOptionArray = [
  //   { option: "option 1", checked: false },
  //   { option: "option 2", checked: false },
  // ];

  const checkBoxOptionArray = [
    { option: "option 1", weightage: 1 },
    { option: "option 2", weightage: 2 },
  ];

  // use in add / update
  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(checkBoxOptionArray);
      } else {
        setCheckBoxOption(option || checkBoxOptionArray);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editedText, setEditedText] = React.useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  const [commentBox, setCommentBox] = useState("");

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [riskData, setRiskData] = useState({
    description: "",
    enableCompensationControl: false,
    finding: "",
    risk: "",
    severity: "",
  });

  const [optionIndex, setOptionIndex] = useState(null);
  const [compensationStatement, setCompensationStatement] = useState("");

  let handleOption = checkBoxOption || option;

  useEffect(() => {
    if (isFrom === "taskSection") {
      // const obj = {
      //   value: handleOption,
      // };

      const withCommentOption = optionsArray?.map((opt) => ({
        ...opt,
        comment: commentBox,
      }));

      handleQuesAnswer(withCommentOption);
    }
  }, [optionsArray, commentBox]);

  const handleSubAns = () => {
    return submittedAnswer?.map((ans) => {
      return ans?.option;
    });
  };

  useEffect(() => {
    setOptionsArray(option);
  }, [option]);

  useEffect(() => {
    if (submittedAnswer) setCommentBox(submittedAnswer[0]?.comment);
  }, [submittedAnswer]);

  const handleChange = (event, optionIndex) => {
    if (isFrom !== "taskSection") {
      setCheckBoxOption((preValue) =>
        preValue.map((option, index) =>
          index === optionIndex
            ? { ...option, checked: event.target.checked }
            : option
        )
      );
    }

    if (event.target.checked) {
      setOptionIndex(optionIndex);
    } else {
      setOptionIndex(null);
    }

    setOptionsArray((prev) => {
      handleOption = prev?.map((options, index) => {
        const newOptions =
          index === optionIndex
            ? { ...options, checked: event.target.checked }
            : options;
        return newOptions;
      });

      return handleOption;
    });
  };

  const handleAddOption = () => {
    setCheckBoxOption((preValue) => [
      ...preValue,
      {
        option: `option ${preValue.length + 1}`,
        // checked: false,
        weightage: preValue.length + 1,
      },
    ]);
  };

  const handleDoubleClick = (index, currentLabel) => {
    setEditingIndex(index);
    setEditedText(currentLabel);
  };

  const handleLabelBlur = (index) => {
    setEditingIndex(null);

    const updatedOptions = checkBoxOption.map((option, i) => {
      if (i === index) {
        return { ...option, option: editedText };
      }
      return option;
    });

    setCheckBoxOption(updatedOptions);
  };

  const handleLabelChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleMapRiskData = (i) => {
    if (isFrom === "surveyCreation") {
      setRiskData(checkBoxOption[i]?.questionRisk);
    }
  };

  const handleToggleRiskDrawer = () => {
    setToggleDrawer(false);
  };

  const handleRiskData = (e) => {
    setCheckBoxOption((prev) =>
      prev?.map((item, i) => {
        if (i === optionIndex) {
          return {
            ...item,
            questionRisk: {
              ...item?.questionRisk,
              [e.target.name]: e.target.value ? e.target.value : "",
              // description:
              //   e.target.name === "description" ? e.target.value : "",
              // finding: e.target.name === "finding" ? e.target.value : "",
              // risk: e.target.name === "risk" ? e.target.value : "",
              // severity: e.target.name === "severity" ? e.target.value : "",
              enableCompensationControl: e.target.checked ? true : false,
            },
          };
        }
        return item;
      })
    );

    if (e.target.name === "enableCompensationControl") {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <FormControl component="fieldset">
          <FormGroup>
            {handleOption &&
              handleOption.map((option, index) => (
                <div key={index}>
                  {editingIndex === index ? (
                    <TextField
                      type="text"
                      value={editedText}
                      onChange={handleLabelChange}
                      onBlur={() => handleLabelBlur(index)}
                      autoFocus
                      style={{ pointerEvents: event }}
                    />
                  ) : (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // width: "60vw",
                          pointerEvents: event,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox
                                disabled={isFrom !== "taskSection"}
                                size="small"
                                style={{
                                  color: "#3374B9",
                                  pointerEvents: event,
                                }}
                                checked={
                                  submittedAnswer
                                    ? handleSubAns()?.includes(option.option)
                                    : option?.checked
                                }
                                onChange={(event) => handleChange(event, index)}
                                name={option.option}
                              />
                            </>
                          }
                          label={
                            <div style={{ wordBreak: "break-all" }}>
                              {option.option}
                            </div>
                          }
                          onDoubleClick={() =>
                            !submittedAnswer &&
                            !removeEditOption &&
                            handleDoubleClick(index, option.option)
                          }
                        />

                        {/* {!submittedAnswer && !removeEditOption && (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gridGap={"20px"}
                              style={{ marginRight: "10px" }}
                            >
                              <ErrorOutlineOutlinedIcon
                                onClick={() => {
                                  setToggleDrawer(true);
                                  setOptionIndex(index);
                                  handleMapRiskData(index);
                                }}
                                style={{
                                  color: !option?.questionRisk
                                    ? "#3374B9"
                                    : "#B81D13",
                                  cursor: "pointer",
                                }}
                              />
  
                              <DeleteOutlinedIcon
                                onClick={() =>
                                  setCheckBoxOption((preValue) =>
                                    preValue.filter((option, i) => i !== index)
                                  )
                                }
                                style={{ color: "#3374B9", cursor: "pointer" }}
                              />
                            </Box>
                          )} */}
                      </Box>
                      {/* {((index === optionIndex &&
                          option?.questionRisk?.enableCompensationControl &&
                          isFrom !== "surveyCreation") ||
                          (submittedAnswer?.length > 0 &&
                            submittedAnswer[0]?.option === option?.option)) && (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gridGap={7}
                            mb={1}
                            ml={3}
                          >
                            {(option?.questionRisk?.enableCompensationControl ||
                              submittedAnswer[0]?.riskCompensationStatement) && (
                              <TextField
                                fullWidth
                                id="comment"
                                label="Compensation Statement"
                                variant="outlined"
                                multiline
                                minRows={2}
                                // placeholder="Compensation comment"
                                style={{ marginTop: "10px" }}
                                value={compensationStatement}
                                // defaultValue={usingQues?.commentBox}
                                onChange={(e) =>
                                  setCompensationStatement(e.target.value)
                                }
                              />
                            )}
                          </Box>
                        )} */}
                    </>
                  )}
                </div>
              ))}
          </FormGroup>
          {!submittedAnswer && !removeEditOption && (
            <FormHelperText>Double Click to Edit Option</FormHelperText>
          )}
        </FormControl>
        {!submittedAnswer && !removeEditOption && (
          <CustomButton
            style={{ width: "15%" }}
            variant="outlined"
            color="primary"
            onClick={() => handleAddOption()}
          >
            add option
          </CustomButton>
        )}
      </Box>
      {comment && (
        <Box style={{ pointerEvents: event }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={submittedAnswer}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}

      <RiskDrawer
        toggleDrawer={toggleDrawer}
        handleToggleRiskDrawer={handleToggleRiskDrawer}
        setRiskData={setRiskData}
        handleRiskData={handleRiskData}
        riskData={riskData}
      />
    </Box>
  );
};

export default MultiChoice;
