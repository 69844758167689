import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import { HiMiniXMark } from "react-icons/hi2";

import CustomButton from "../../../../../../UI/button/button";
import { useSelector } from "react-redux";

const RedoDialog = ({ open, handleClose, proceedAction }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const { taskQuestions } = useSelector((state) => state.taskReducer);

  const isApproved = taskQuestions?.find((ques) => ques?.isApproved === true);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleProceed = () => {
    proceedAction(selectedOption);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogTitle style={{ backgroundColor: "#EBF1F8" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ gap: 10 }}
          pb={5}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              style={{
                color: "#15314E",
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "28px",
              }}
            >
              Redo Section
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <HiMiniXMark color="#15314E" size={30} />
            </IconButton>
          </Box>

          <Typography
            style={{
              color: "#5C90C7",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            What was the issue with the response?
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Card
          onClick={() => handleOptionSelect("SpecificQues")}
          variant="outlined"
          style={{
            marginBottom: "10px",
            backgroundColor:
              selectedOption === "SpecificQues" ? "#EBF1F8" : "inherit",
          }}
        >
          <CardActionArea>
            <CardContent>
              <Typography
                style={{
                  borderLeft:
                    selectedOption === "SpecificQues"
                      ? "4px solid #3374B9"
                      : "none",
                  paddingLeft:
                    selectedOption === "SpecificQues" ? "5px" : "none",
                  color: "#242424",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Redo a Specific Question
              </Typography>
              <Typography
                style={{
                  marginTop: "5px",
                  color: "#505050",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Opt for precision in your insights by redoing a specific
                question, refining your survey response for a more accurate and
                nuanced reflection.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          onClick={() => handleOptionSelect("EntireSec")}
          style={{
            marginBottom: "10px",
            backgroundColor:
              selectedOption === "EntireSec" ? "#EBF1F8" : "inherit",
            pointerEvents: isApproved?.isApproved ? "none" : "initial",
            opacity: isApproved?.isApproved ? 0.7 : 1,
          }}
          disabled={isApproved?.isApproved}
        >
          <CardActionArea>
            <CardContent>
              <Typography
                style={{
                  borderLeft:
                    selectedOption === "EntireSec"
                      ? "4px solid #3374B9"
                      : "none",
                  paddingLeft: selectedOption === "EntireSec" ? "5px" : "none",
                  color: "#242424",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Redo the Entire Section
                <span style={{ color: "#6C6C6C" }}>
                  (*Previous data will be erased)
                </span>
              </Typography>
              <Typography
                style={{
                  marginTop: "5px",
                  color: "#505050",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Embark on a fresh perspective by redoing the entire survey, with
                a note that previous data will be erased—ensuring a clean slate
                for updated and comprehensive feedback.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </DialogContent>

      <DialogActions>
        <CustomButton
          onClick={handleClose}
          variant={"text"}
          color="primary"
          style={{ textTransform: "capitalize" }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleProceed}
          variant={"contained"}
          color="primary"
          style={{ textTransform: "capitalize", width: "15em" }}
          disabled={!selectedOption}
        >
          Proceed
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default RedoDialog;
