import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import Textfield from "../../../../../../../UI/textfield/textfield";

const UrlField = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [url, setUrl] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleUrlChange = (event) => {
    const newValue = event.target.value;
    setUrl(newValue);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: url,
        weightage: 1,
        comment: commentBox,
      };

      handleQuesAnswer(obj);
    }
  }, [url, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setUrl(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // const handleSubmit = () => {
  //   if (validateEmail(url)) {
  //     setError(false);
  //     setHelperText("");
  //     onSubmit(url); // Callback to handle submission
  //   } else {
  //     setError(true);
  //     setHelperText("Please enter a valid url address");
  //   }
  // };

  return (
    <Box style={{ pointerEvents: event }}>
      <Textfield
        label="Url"
        variant="outlined"
        fullWidth
        value={url}
        onChange={handleUrlChange}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default UrlField;
