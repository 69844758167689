import { Box, Paper, Typography } from "@material-ui/core";
import HeadActions from "../common/HeadActions";
import FrequencyDropdown from "../common/FrequencyDropdown";
import FrequencySlider from "../common/FrequencySlider";
import FrequencyRange from "../common/FrequencyRange";

const VendorScoreAlert = ({ scoreAlerts, updateAlert, setUpdateAlert }) => {
  // Optimized function to update alerts by id and field
  const updateAlertById = (alert_id, updatedFields) => {
    setUpdateAlert((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert._id === alert_id ? { ...alert, ...updatedFields } : alert
      )
    );
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 10 }}>
      {scoreAlerts?.length > 0 ? (
        scoreAlerts.map((alert) => {
          const range = [alert?.lowerBounds, alert?.upperBounds];

          return (
            <Paper key={alert._id} elevation={2}>
              <Box width={"100%"} p={1}>
                {/* Alert name and options */}
                <HeadActions
                  alertName={alert?.name}
                  isChecked={!alert?.pause}
                  handleCheck={(isChecked) =>
                    updateAlertById(alert?._id, { pause: !isChecked })
                  }
                  handleDelete={() =>
                    updateAlertById(alert?._id, { active: false })
                  }
                />

                {/* Frequency */}
                <Box
                  my={2}
                  mx={1}
                  display="flex"
                  flexDirection="column"
                  sx={{ gap: 5 }}
                >
                  <Typography variant="subtitle2" style={{ color: "#9A9A9A" }}>
                    Frequency
                  </Typography>
                  {/* Dropdown menu */}
                  <FrequencyDropdown
                    selectedOpt={alert?.frequencyType}
                    handleOption={(opt) =>
                      updateAlertById(alert?._id, { frequencyType: opt })
                    }
                  />

                  {/* Range slider and inputs */}
                  <Box display="flex" my={2} px={1} sx={{ gap: 40 }}>
                    {/* Range Slider */}
                    <FrequencySlider
                      range={range}
                      handleRange={(rng) =>
                        updateAlertById(alert?._id, {
                          lowerBounds: rng[0],
                          upperBounds: rng[1],
                        })
                      }
                    />
                    {/* Frequency Inputs */}
                    <FrequencyRange
                      range={range}
                      handleRange={(rng) =>
                        updateAlertById(alert?._id, {
                          lowerBounds: rng[0],
                          upperBounds: rng[1],
                        })
                      }
                    />
                  </Box>

                  <Typography variant="body2">{alert?.comment}</Typography>
                </Box>
              </Box>
            </Paper>
          );
        })
      ) : (
        <Box>No data available</Box>
      )}
    </Box>
  );
};

export default VendorScoreAlert;
