import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";

import RemoveIcon from "@material-ui/icons/Remove";

import { TextField, IconButton, Select, MenuItem } from "@material-ui/core";

import CustomButton from "../../../../../../../UI/button/button";

const Dropdown = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  checkBoxOption,
  setCheckBoxOption,
  usingQues,
  linkageAnswer,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});
  const [newOption, setNewOption] = useState("");
  const [newWeightage, setNewWeightage] = useState("");
  const [open, setOpen] = useState(false);

  const handleOptionChange = (event) => {
    event.preventDefault();
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  const customOptions = [
    // { option: "Option 1", weightage: 2 },
    // { option: "Option 2", weightage: 3 },
  ];

  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(customOptions);
      } else {
        setCheckBoxOption(options || customOptions);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOption = () => {
    if (newOption.trim() && newWeightage.trim()) {
      setCheckBoxOption([
        ...checkBoxOption,
        {
          option: newOption.trim(),
          weightage: parseFloat(newWeightage),
        },
      ]);
      setNewOption("");
      setNewWeightage("");
    }
  };

  const handleWeightageChange = (event) => {
    setNewWeightage(event.target.value);
  };

  const handleNewOption = (event) => {
    setNewOption(event.target.value);
  };

  const handleRemoveOption = (index) => {
    setCheckBoxOption(checkBoxOption?.filter((_, i) => i !== index));
  };

  const handleOption = (opt) => {
    setOption(opt);
    // console.log(opt);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        weightage: option?.weightage,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // console.log(checkBoxOption);

  return (
    <div>
      <FormControl
        component="fieldset"
        style={{ width: isFrom === "surveyCreation" ? "auto" : "100%" }}
      >
        {(checkBoxOption || options)?.length > 0 ? (
          <Box style={{ marginBottom: 10 }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                submittedAnswer && submittedAnswer[0]?.option
                  ? submittedAnswer[0]?.option
                  : selectedOption
              }
              onChange={handleOptionChange}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              fullWidth
              variant="outlined"
              style={{ height: "40px" }}
            >
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
              {(checkBoxOption || options)?.map((item, index) => (
                <MenuItem
                  value={item?.option}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  key={index}
                  onClick={() => {
                    handleOption(item);
                  }}
                >
                  <Typography>{item?.option}</Typography>

                  {isFrom === "surveyCreation" && open && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveOption(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Typography style={{ marginBottom: 10 }}>
            Add some options to the dropdown
          </Typography>
        )}

        {isFrom === "surveyCreation" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 10,
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <TextField
              label="New Option"
              variant="outlined"
              value={newOption}
              onChange={handleNewOption}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              type="number"
              value={newWeightage}
              onChange={handleWeightageChange}
            />
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddOption}
              disabled={newOption === "" || newWeightage === ""}
            >
              Add Option
            </CustomButton>
          </div>
        )}
      </FormControl>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

export default Dropdown;
