import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Box,
  Typography,
} from "@material-ui/core";
import OngoingAssessmentOverallScore from "./OngoingAssessmentOverallScore";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHARED_SURVEY_DETAILS } from "constants/vendorDiagnosisConstants";
import { gql_get_shared_survey_details } from "redux/actions/vendorActions/vendorDiagnosisAction";
import moment from "moment";

const data = [
  {
    task: "Vendor/Vendor Profiling",
    frequency: "Quarter 1",
    assessmentDate: "1st January 2023",
    assessmentStatus: "Completed",
    currentScore: 83,
  },
  {
    task: "Risk Assessment",
    frequency: "Quarter 1",
    assessmentDate: "5th January 2023",
    assessmentStatus: "Pending",
    currentScore: 74,
  },
  {
    task: "Due Diligence",
    frequency: "Quarter 1",
    assessmentDate: "2nd February 2023",
    assessmentStatus: "Completed",
    currentScore: 80,
  },
  {
    task: "Contracting",
    frequency: "Quarter 1",
    assessmentDate: "6th February 2023",
    assessmentStatus: "Pending",
    currentScore: 69,
  },
  {
    task: "Onboarding",
    frequency: "Quarter 1",
    assessmentDate: "3rd March 2023",
    assessmentStatus: "Pending",
    currentScore: 30,
  },
];

const OngoingAssessment = () => {
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { bankId } = useSelector((state) => state.login);
  const { sharedSurveyDetails } = useSelector((state) => state.vendorDiagnosis);
  const storedVendorId = localStorage.getItem("vendorId");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      gql_get_shared_survey_details({
        bankId,
        vendorId: vendorBasicDetails?._id || storedVendorId,
        isFrom: "ongoingAssessment",
      })
    );

    return () => {
      dispatch({ type: SET_SHARED_SURVEY_DETAILS, payload: [] });
    };
  }, []);

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          Ongoing Assessment
        </Typography>
        {/* <CustomButton color="primary">Edit Details</CustomButton> */}
      </Box>

      <Box mt={2} ml={2} className="scroll">
        <OngoingAssessmentOverallScore />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Task Progress</TableCell>
              <TableCell>Current Frequency</TableCell>
              <TableCell>Assessment Date</TableCell>
              <TableCell>Assessment Status</TableCell>
              <TableCell align="center">Current Score</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sharedSurveyDetails?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.surveyName}
                </TableCell>
                <TableCell>{row?.type}</TableCell>
                <TableCell>
                  {moment(row?.dateOfAssessment).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>
                  <Chip
                    label={row?.isCompleted ? "Completed" : "Ongoing"}
                    style={{
                      backgroundColor:
                        row?.isCompleted === "Completed"
                          ? severity("bg", "green")
                          : severity("bg", "yellow"),
                      color:
                        row?.isCompleted === "Completed"
                          ? severity("text", "green")
                          : severity("text", "yellow"),
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  {row?.score && (
                    <Chip
                      size="small"
                      label={
                        row?.score >= 66
                          ? "High"
                          : row?.score < 66 && row?.score >= 33
                          ? "Mid"
                          : "Low"
                      }
                      style={{
                        backgroundColor:
                          row?.score >= 66
                            ? severity("bg", "green")
                            : row?.score < 66 && row?.score >= 33
                            ? severity("bg", "yellow")
                            : severity("bg", "red"),
                        color:
                          row?.score >= 66
                            ? severity("text", "green")
                            : row?.score < 66 && row?.score >= 33
                            ? severity("text", "yellow")
                            : severity("text", "red"),
                      }}
                    />
                  )}{" "}
                  {row?.score ? `${row?.score}/100` : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default OngoingAssessment;
