import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IoEllipsisVertical } from "react-icons/io5";

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function VendorMaterialityTable({
  vendors,
  title,
  setAlertPopper,
  setAlertName,
  setModalText,
  handleShowRisk,
  setPopup,
}) {
  const classes = useStyle();

  function materialitySeverity(value, type) {
    if (type === "text") {
      return value === "Material"
        ? "#154328"
        : value === "Non Material"
        ? "#8C7038"
        : "#000";
    }

    if (type === "bg") {
      return value === "Material"
        ? "#BFE2CD"
        : value === "Non Material"
        ? "#FFEFD0"
        : "transparent";
    }
  }

  function performanceSeverity(value, type) {
    if (type === "text") {
      return value === "Approved"
        ? "#154328"
        : value === "Approved with deviation"
        ? "#8C7038"
        : value === "Rejected"
        ? "#52231D"
        : "#000";
    }

    if (type === "bg") {
      return value === "Approved"
        ? "#BFE2CD"
        : value === "Approved with deviation"
        ? "#FFEFD0"
        : value === "Rejected"
        ? "#EDCAC6"
        : "transparent";
    }
  }

  function categorizationSeverity(value, type) {
    if (type === "text") {
      return value === "High"
        ? "#154328"
        : value === "Medium"
        ? "#8C7038"
        : value === "Low"
        ? "#52231D"
        : "#000";
    }

    if (type === "bg") {
      return value === "High"
        ? "#BFE2CD"
        : value === "Medium"
        ? "#FFEFD0"
        : value === "Low"
        ? "#EDCAC6"
        : "transparent";
    }
  }

  return (
    <Paper elevation={3}>
      <Box sx={{ margin: "10px 0px" }}>
        <Box className={classes.tableHeader}>
          <Typography variant="h6" className={classes.tableHeaderTitle}>
            {title}
          </Typography>

          <Box className={classes.tableHeader} style={{ gap: "50px" }}>
            {handleShowRisk("VENDOR_ONBOARDING_VENDOR_MATERIALITY")
              ?.threshold && (
              <Box
                onClick={() => {
                  setPopup(true);
                  setModalText({
                    heading: "Update Alert for Vendor Materiality",
                    buttonText: "Update Alert",
                    message: "Alert has already been set, you can update it.",
                  });
                  setAlertName("VENDOR_ONBOARDING_VENDOR_MATERIALITY");
                }}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  style={{ textAlign: "center", fontWeight: 500 }}
                  variant="body2"
                >
                  Materiality Risk Alert:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: materialitySeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_MATERIALITY")
                        ?.threshold,
                      "text"
                    ),
                    backgroundColor: materialitySeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_MATERIALITY")
                        ?.threshold,
                      "bg"
                    ),
                    padding: "2px 30px",
                  }}
                  className={classes.severityStyle}
                >
                  {!handleShowRisk("VENDOR_ONBOARDING_VENDOR_MATERIALITY")
                    ?.pause
                    ? handleShowRisk("VENDOR_ONBOARDING_VENDOR_MATERIALITY")
                        ?.threshold
                    : "Paused"}
                </Typography>
              </Box>
            )}
            {handleShowRisk("VENDOR_ONBOARDING_VENDOR_PERFORMANCE")
              ?.threshold && (
              <Box
                onClick={() => {
                  setPopup(true);
                  setModalText({
                    heading: "Update Alert for Vendor Performance",
                    buttonText: "Update Alert",
                    message: "Alert has already been set, you can update it.",
                  });

                  setAlertName("VENDOR_ONBOARDING_VENDOR_PERFORMANCE");
                }}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  style={{ textAlign: "center", fontWeight: 500 }}
                  variant="body2"
                >
                  Performance Risk Alert:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: performanceSeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_PERFORMANCE")
                        ?.threshold,
                      "text"
                    ),
                    backgroundColor: performanceSeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_PERFORMANCE")
                        ?.threshold,
                      "bg"
                    ),
                    padding: "2px 30px",
                  }}
                  className={classes.severityStyle}
                >
                  {!handleShowRisk("VENDOR_ONBOARDING_VENDOR_PERFORMANCE")
                    ?.pause
                    ? handleShowRisk("VENDOR_ONBOARDING_VENDOR_PERFORMANCE")
                        ?.threshold
                    : "Paused"}
                </Typography>
              </Box>
            )}
            {handleShowRisk("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION")
              ?.threshold && (
              <Box
                onClick={() => {
                  setPopup(true);
                  setModalText({
                    heading: "Update Alert for Vendor Categorization",
                    buttonText: "Update Alert",
                    message: "Alert has already been set, you can update it.",
                  });
                  setAlertName("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION");
                }}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  style={{ textAlign: "center", fontWeight: 500 }}
                  variant="body2"
                >
                  Categorization Risk Alert:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: categorizationSeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION")
                        ?.threshold,
                      "text"
                    ),
                    backgroundColor: categorizationSeverity(
                      handleShowRisk("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION")
                        ?.threshold,
                      "bg"
                    ),
                    padding: "2px 30px",
                  }}
                  className={classes.severityStyle}
                >
                  {!handleShowRisk("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION")
                    ?.pause
                    ? handleShowRisk("VENDOR_ONBOARDING_VENDOR_CATEGORIZATION")
                        ?.threshold
                    : "Paused"}
                </Typography>
              </Box>
            )}
            <IoEllipsisVertical
              size={20}
              style={{ color: "#3374B9", cursor: "pointer" }}
              onClick={(e) => {
                if (title === "Vendor Onboarding") {
                  setAlertPopper(e.currentTarget);
                  setAlertName("VENDOR_ONBOARDING");
                  setModalText({
                    heading: "Set Alert for Vendor Onboarding Risk",
                    buttonText: "Set Alert",
                    message:
                      "You will get an alert email if the given threshold value exceeds.",
                  });
                }
              }}
            />
          </Box>
          {/* <Box className={classes.tableHeaderDorpdown}>
            <FormControl
              style={{ width: "200px" }}
              variant="outlined"
              size="small"
            >
              <InputLabel id="menu">DropDown</InputLabel>
              <Select name="drop-down" labelId="menu" label="drop-down">
                <MenuItem value="mongodb">MongoDB</MenuItem>
                <MenuItem value="node">Node</MenuItem>
                <MenuItem value="react">React</MenuItem>
                <MenuItem value="express">Express</MenuItem>
              </Select>
            </FormControl>
            <MoreVertIcon color="primary" />
          </Box> */}
        </Box>

        <TableContainer component="paper">
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell align="left">Vendor Name</TableCell>

                {/* <TableCell align="left" width={200}>
                  Security
                </TableCell> */}
                <TableCell align="center" width={"15%"}>
                  Vendor Materiality
                </TableCell>
                <TableCell align="center" width={"15%"}>
                  Vendor Performance
                </TableCell>
                <TableCell align="center" width={"15%"}>
                  Vendor Categorization
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vendors?.map((data, index) => (
                <TableRow
                  key={data?._id}
                  className={classes.tableRow}
                  onClick={() => console.log(data?.id)}
                >
                  {/* <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell> */}
                  <TableCell align="left">{data?.name}</TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body2"
                      style={{
                        color: materialitySeverity(
                          data.vendorMateriality,
                          "text"
                        ),
                        backgroundColor: materialitySeverity(
                          data.vendorMateriality,
                          "bg"
                        ),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.vendorMateriality || "-"}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="left">
                    <Typography
                      variant="body2"
                      style={{
                        color: severity(data.security, "text"),
                        backgroundColor: severity(data.security, "bg"),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.security || "Low"}
                    </Typography>
                  </TableCell> */}
                  <TableCell align="center" width={"10%"}>
                    <Typography
                      variant="body2"
                      style={{
                        color: performanceSeverity(
                          data.vendorPerformance,
                          "text"
                        ),
                        backgroundColor: performanceSeverity(
                          data.vendorPerformance,
                          "bg"
                        ),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.vendorPerformance || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body2"
                      style={{
                        color: categorizationSeverity(
                          data.vendorCategorization,
                          "text"
                        ),
                        backgroundColor: categorizationSeverity(
                          data.vendorCategorization,
                          "bg"
                        ),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.vendorCategorization || "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
