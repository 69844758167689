import { useMemo, useState, createContext, useContext } from "react";
import {
  CssBaseline,
  createTheme,
  MuiThemeProvider as MUIThemeProvider,
} from "@material-ui/core";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import shadows, { customShadows } from "./shadows";
import componentsOverrides from "./overrides";

// Context for the theme
const ThemeContext = createContext();

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const [isLight, setIsLight] = useState(true);

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  theme.overrides = componentsOverrides(theme);

  const toggleMode = () => {
    setIsLight((prev) => !prev);
  };

  return (
    <ThemeContext.Provider value={{ isLight, toggleMode }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}

// Custom hook to use the ThemeContext
export const useThemeMode = () => useContext(ThemeContext);
