import { useEffect, useState } from "react";
import { Box, Typography, IconButton, Tooltip } from "@material-ui/core";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import SpecificQuestion from "../CommonComps/SpecificQuestion";
import CustomButton from "../../../../../../UI/button/button";
import { UpdateSelectedQuestion } from "../../../../../../../redux/actions/taskActions/TaskAction";
import InfoIcon from "@material-ui/icons/Info";
// ---------- Stylings ----------

const headerStyle = () => {
  return {
    color: "#15314E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const questionsStyle = () => {
  return {
    color: "#242424",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: "72em",
  };
};

// ------------------------------

// ---------- Main Render ----------
const RedoSpecific = ({ taskQuestions, activeSection, setRedoSpecific }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleSelectQuestion = async (question) => {
    setSelectedQuestion(question);
  };

  useEffect(() => {
    const removeSelectedQuestion = async () => {
      setSelectedQuestion(null);
    };

    removeSelectedQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return (
    <Box height={"100%"} style={{ position: "relative" }}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "0px 0px 8px 0px #3374B940",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
          {selectedQuestion ? (
            <SpecificQuestion
              currentQuestion={selectedQuestion}
              activeSection={activeSection}
              setCurrentQuestion={setSelectedQuestion}
              handleSelectQuestion={handleSelectQuestion}
            />
          ) : (
            <>
              {/* header */}
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                p={2}
                style={{ borderBottom: "1px solid #EBF1F8" }}
                width={"75vw"}
              >
                <Box display={"flex"} sx={{ gap: 20 }}>
                  <Typography style={headerStyle()}>Que No.</Typography>
                  <Typography style={headerStyle()}>Question</Typography>
                </Box>
                <Typography style={headerStyle()}>Explore</Typography>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                style={{
                  padding: "0px 18px 0px 35px",
                  gap: 20,
                  overflowY: "auto",
                  height: "56vh",
                  scrollBehavior: "smooth",
                }}
              >
                {/* main questions */}
                {taskQuestions.map((ques, index) => (
                  <Box
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    style={{ opacity: ques?.isApproved ? 0.7 : 1 }}
                  >
                    <Box display={"flex"} flex={"1"} sx={{ gap: 50 }}>
                      <Typography style={questionsStyle()}>
                        {index + 1}
                      </Typography>
                      <Typography style={questionsStyle()}>
                        {ques.question}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={() => handleSelectQuestion(ques)}
                      disabled={ques?.isApproved}
                    >
                      <IoIosArrowForward color={"#3374B9"} />
                    </IconButton>
                    {/* <Tooltip title="Information">
                      <InfoIcon size={"small"} style={{ color: "#999" }} />
                    </Tooltip> */}
                  </Box>
                ))}
              </Box>
              <Box
                style={{
                  display: "flex",
                  position: "absolute",
                  right: 10,
                  bottom: 10,
                  gap: 10,
                }}
              >
                <CustomButton
                  variant={"contained"}
                  color={"primary"}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setRedoSpecific(false);
                  }}
                >
                  Submit Changes On Section
                </CustomButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default RedoSpecific;
