export const SET_SELECTED_MENU = "SET_SELECTED_MENU";

// User Profile
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_USER_POSITION = "SET_USER_POSITION";
export const SET_USER_IMAGE = "SET_USER_IMAGE";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_DISTRICT = "SET_USER_DISTRICT";
export const SET_USER_PINCODE = "SET_USER_PINCODE";
export const SET_USER_CONTACT = "SET_USER_CONTACT";

export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_SECTORS = "SET_SECTORS";
export const SET_BRANCH_ADDRESS = "SET_BRANCH_ADDRESS";
export const UNSET_ADDRESS = "UNSET_ADDRESS";
export const PUSH_BRANCH_ADDRESS = "PUSH_BRANCH_ADDRESS";

export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_MASTER_SECTOR = "SET_MASTER_SECTOR";
export const SET_SELECTED_SECTORS = "SET_SELECTED_SECTORS";

//payment
export const SET_SUBSCRIPTION_DETAILS = "SET_SUBSCRIPTION_DETAILS";
export const SET_INVOICE_DETAILS = "SET_INVOICE_DETAILS";
export const SET_PLAN_AMOUNT = "SET_PLAN_AMOUNT";

// User Details
export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const SET_USERS = "SET_USERS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
