// !------------- (searchClick) of type function needs to be passed --------------!

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Textfield from "./textfield";
import { CgSearch } from "react-icons/cg";
import debounce from "lodash.debounce";
import { InputAdornment, useTheme } from "@material-ui/core";

function SearchTextField({ searchChange, handleSearchQuery, ...props }) {
  const [searchChng, setSearchChng] = React.useState("");
  const theme = useTheme();

  // Debounce the searchClick function
  const debouncedSearchClick = React.useMemo(
    () => debounce((query) => searchChange && searchChange(query), 800), // Debounce delay set to 500 milliseconds
    [searchChange]
  );

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchChng(newValue);
    debouncedSearchClick(newValue);
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      value={searchChng}
      size={"small"}
      onChange={handleSearchChange}
      InputProps={{
        sx: {
          borderRadius: 2,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchChng}
              onClick={() => setSearchChng(searchChng)}
              size="small"
              sx={{ cursor: "pointer", zIndex: 100 }}
            >
              <CgSearch size={24} color={theme.palette.primary.main} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: {
          color: "#9A9A9A",
        },
      }}
      {...props}
    />
  );
}
export default React.memo(SearchTextField);
