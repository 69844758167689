import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Box, Chip, Collapse } from "@material-ui/core";
import CustomButton from "components/UI/button/button";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);

export default function PreviousSubmissionList({
  submissionList,
  handleSelectSection,
  step,
  setIsThreadDrawerOpen,
  isThreadDrawerOpen,
}) {
  const classes = useStyles();
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleListItemClick = (section) => {
    setSelectedSection(section);
    handleSelectSection(section);
  };

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  const sortArray = submissionList?.length > 0 ? [...submissionList] : [];

  return (
    <Box className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folder">
        {submissionList?.length > 0 &&
          sortArray
            ?.sort((a, b) => a?.order - b?.order)
            ?.map((section) => (
              <ListItem
                key={section?._id}
                button
                selected={selectedSection?._id === section?._id}
                onClick={() => handleListItemClick(section)}
                style={{ borderRadius: "8px", marginBottom: "5px" }}
              >
                <ListItemText
                  primary={moment(section?.updatedAt).format("DD MMM yy")}
                />

                <ListItemText
                  align="right"
                  primary={
                    <Chip
                      label={
                        section?.isApproved === false &&
                        section?.isRejected === true
                          ? "Rejected"
                          : "Approved"
                      }
                      style={{
                        color:
                          section?.isApproved === false &&
                          section?.isRejected === true
                            ? severity("text", "red")
                            : severity("text", "green"),
                        backgroundColor:
                          section?.isApproved === false &&
                          section?.isRejected === true
                            ? severity("bg", "red")
                            : severity("bg", "green"),
                      }}
                    />
                  }
                />
              </ListItem>
            ))}
      </List>
    </Box>
  );
}
