export const formattedDate = (time) => {
  const date = new Date(time);

  const formatted = new Intl.DateTimeFormat("US-en", {
    month: "short",
    day: "2-digit",
    minute: "2-digit",
    hour: "2-digit",
    second: "2-digit",
  }).format(date);

  return formatted;
};
