import {
  SET_ELASTIC_ORGANIZATIONS,
  SET_COMPANY_DATA,
  SET_COMPANY_ID,
  SET_TOTAL_COMPANIES,
  SET_CONTROVERSY_LINKS,
  SET_PAGINATION_PAGE,
  SET_SURVEY_DATA,
  SET_SURVEY_PAGINATION_PAGE,
  SET_SELECTED_SURVEY,
  SET_SELECTED_SURVEY_RESPONSE,
  SET_SELECTED_RES_COMPANY,
  SET_SECTION,
  SET_QUESTIONS,
  SET_SELECTED_SECTION,
  SET_USE_VERSION,
  SET_VA_COMPLETED_SURVEY_FOR_BA,
} from "../../../constants/riskDashboardConstants";

const initial_state = {
  companies: [],
  currentCompanyId: "",
  totalCompanies: "",
  currentCompanyData: {},
  controversyLinks: [],
  totalLinks: 0,
  is_published: false,
  dahboardPaginationPage: 1,

  // survey data
  surveyData: [],
  totalSurveys: 0,
  selectedSurveyData: null,
  surveyPaginationPage: 1,

  // survey inner data
  selectedResponse: null,
  selectedResCompany: null,
  sectionWiseData: [],
  sectionWiseQuestion: [],
  selectedSection: "",
  respondedOrganizations: [
    {
      organization_name: "AWS",
      no_of_stakeholders: 15,
      response_count: 10,
      organization_dated: "Feb 1, 2020",
    },
  ],
  surveyRespondedUsers: [
    {
      name: "Bessie Cooper",
      _id: "2134554",
      role: "manager",
      selectedFrequency: "January",
    },
    {
      name: "Ralph Edwards",
      _id: "55514",
      role: "dev",
      selectedFrequency: "February",
    },
  ],

  usingVersion: null,
  vaCompletedSurveyForBa: [],
};

export default function riskDashboardReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ELASTIC_ORGANIZATIONS:
      return (state = { ...state, companies: action.payload });
    case SET_COMPANY_DATA:
      return (state = { ...state, currentCompanyData: action.payload });
    case SET_TOTAL_COMPANIES:
      return (state = { ...state, totalCompanies: action.payload });
    case SET_COMPANY_ID:
      return (state = { ...state, currentCompanyId: action.payload });
    case SET_PAGINATION_PAGE:
      return (state = { ...state, dahboardPaginationPage: action.payload });
    case SET_CONTROVERSY_LINKS:
      return (state = {
        ...state,
        controversyLinks: action.payload.payload,
        totalLinks: action.payload.total,
        is_published: action.payload.is_published,
      });

    // survey cases
    case SET_SURVEY_DATA:
      return {
        ...state,
        surveyData: action.payload,
        totalSurveys: action.total,
      };
    case SET_SELECTED_SURVEY:
      return (state = {
        ...state,
        selectedSurveyData: action.payload,
        // sectionWiseData: initialData2[action.payload?.survey_name || "survey1"],
      });
    case SET_SURVEY_PAGINATION_PAGE:
      return (state = { ...state, surveyPaginationPage: action.payload });

    // survey inner cases
    case SET_SELECTED_SURVEY_RESPONSE:
      return (state = { ...state, selectedResponse: action.payload });
    case SET_SELECTED_RES_COMPANY:
      return (state = { ...state, selectedResCompany: action.payload });
    case SET_SECTION:
      return (state = { ...state, sectionWiseData: action.payload });
    case SET_QUESTIONS:
      return (state = { ...state, sectionWiseQuestion: action.payload });
    case SET_SELECTED_SECTION:
      return (state = { ...state, selectedSection: action.payload });
    case SET_USE_VERSION:
      return (state = { ...state, usingVersion: action.payload });

    case SET_VA_COMPLETED_SURVEY_FOR_BA:
      return { ...state, vaCompletedSurveyForBa: action.payload };
    default:
      return state;
  }
}
