import { Box } from "@material-ui/core";

import { controversyChartData, defaultColors } from "./chartUtils/chartData";
import StackedChart from "./chartComponents/stackedChart";
import BarChart from "./chartComponents/BarChart";

const ControversyChart = ({ finalData }) => {
  return (
    <Box style={{ height: "300px", width: "100%" }}>
      <BarChart data={finalData} />
    </Box>
  );
};
export default ControversyChart;
