import { SET_ORGANIZATION_DATA } from "../../../constants/brmConstants.js";
import {
  SET_LOGOUT,
  SET_LOGIN,
  SET_TOKEN,
  SET_USER_PROFILE,
  SET_SIGNUP_STEP_NO,
  SET_CHAT_TOKENS,
} from "../../actions/login/loginActionType.js";

const initial_state = {
  email: "",
  name: "",
  user_type: "",
  isLoggedIn: false,
  token: "",
  userProfile: "",
  user_id: "",
  bankId: "",
  organization_id: "",
  organization_data: {},
  organization_name: "",
  signupStepNo: JSON.parse(localStorage.getItem("signup_process_no")) || 1,
};

export default function reducer(state = initial_state, action) {
  const useOrg = action.payload?.user?.bank || action.payload?.user?.vendor;
  switch (action.type) {
    case SET_LOGIN:
      localStorage.setItem("organization_id", useOrg?._id);
      return (state = {
        ...state,
        token: action.payload.accessToken,
        user_type: action.payload.user?.user_type, // temp change
        isLoggedIn: true,
        name: action.payload.user?.name,
        email: action.payload.user?.email,
        userProfile: action.payload.user?.profileImg,
        user_id: action.payload.user?._id,
        bankId: action.payload.user?.bank?._id,
        vendorId: action.payload.user?.vendor,
        organization_id: useOrg?._id,
        organization_data: useOrg,
        chat_tokens: action?.payload?.user?.chat_tokens || 0,
        signupStepNo: action?.payload?.user?.signupStepNo || 1,
        organization_name: useOrg?.name,
        bank: action.payload?.user?.bank,

        // signupStepNo: action?.payload?.signupStepNo || localStorage.getItem("signup_process_no"),
      });

    case SET_TOKEN:
      return (state = {
        ...state,
        token: action.payload,
      });

    case SET_LOGOUT:
      return (state = {
        ...state,
        email: "",
        name: "",
        user_type: "",
        isLoggedIn: false,
        token: "",
        userProfile: "",
        user_id: "",
        organization_name: "",
      });

    case SET_USER_PROFILE:
      console.log(action.payload);
      return (state = { ...state, userProfile: action.payload });
    case SET_SIGNUP_STEP_NO:
      return (state = { ...state, signupStepNo: action.payload });

    case SET_CHAT_TOKENS:
      return (state = { ...state, chat_tokens: action.payload });

    case SET_ORGANIZATION_DATA:
      return (state = { ...state, organization_data: action.payload });

    default:
      return state;
  }
}
