import { Grid, Typography } from "@material-ui/core";
import React from "react";

function UltimateParent({ data, classes }) {
  return (
    <Grid style={{ marginTop: "10px", marginLeft: "10px" }}>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Name:</span>
        {"  "}
        {data?.name}
      </Typography>

      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Status:</span>
        {"  "}
        {data?.status}
      </Typography>

      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Country:</span>
        {"  "}
        {data?.country || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Reg. No.:</span>
        {"  "}
        {data?.registrationNumber || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Type:</span>
        {"  "}
        {data?.type}
      </Typography>
    </Grid>
  );
}

export default UltimateParent;
