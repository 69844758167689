import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@material-ui/core";

const CriticalityForVendor = () => {
  const data = [
    {
      function: "Payment Gateway Services",
      criticality: "High",
      impact:
        "Directly affects transaction processing for retail and corporate clients",
      mitigation:
        "Immediate backup system or manual payment processing alternatives",
      backup: "Transition to alternative vendor (SwiftPay, FastPay Networks)",
    },
    {
      function: "Point-of-Sale (POS) Solutions",
      criticality: "Moderate",
      impact: "Affects in-store payment processing and customer experience",
      mitigation: "Enable backup POS systems, leverage mobile payment systems",
      backup: "Contract interim vendor for emergency POS replacements",
    },
    {
      function: "Merchant Acquiring Services",
      criticality: "High",
      impact: "Impacts revenue generation and customer payment acceptance",
      mitigation: "Engage a backup merchant service provider",
      backup: "Consider alternative merchant providers to ensure continuity",
    },
    {
      function: "Data Processing And Reporting",
      criticality: "High",
      impact:
        "Affects financial reporting, analytics, and compliance requirements",
      mitigation:
        "Data backup from PayWise's platform, shift data to internal teams",
      backup: "Transition to internal reporting systems or another provider",
    },
  ];

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead style={{ backgroundColor: "#EBF1F8" }}>
        <TableRow>
          <TableCell>Function Provided</TableCell>
          <TableCell>Criticality Level</TableCell>
          <TableCell>Impact On Bank Operations</TableCell>
          <TableCell>Mitigation If Disrupted</TableCell>
          <TableCell>Backup/Alternative Solution</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.function}
            </TableCell>

            <TableCell>
              <Chip
                label={row.criticality}
                color={row.criticality === "High" ? "error" : "warning"}
              />
            </TableCell>
            <TableCell>{row.impact}</TableCell>
            <TableCell>{row.mitigation}</TableCell>
            <TableCell>{row.backup}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CriticalityForVendor;
