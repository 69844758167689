import { Box, Typography } from "@material-ui/core";
import React from "react";
import Header from "../vendorIntake/Header";
import ContractingOverallScore from "../contracting/ContractingOverallScore";
import OnbaordingOverallScore from "./OnboardingOverallScore";
import OnboardingCompanyFindings from "./OnboardingCompanyFindings";
import OnboardingReportingTable from "./OnboardingReportingTable";

function Onboarding() {
  return (
    <Box style={{ height: "63vh" }}>
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Onboarding
        </Typography>
      </Box>

      <Box className="scroll">
        {/* <Header />
        <br /> */}
        <OnbaordingOverallScore />
        <br />
        <OnboardingCompanyFindings />
        <br />
        <OnboardingReportingTable />
      </Box>
    </Box>
  );
}

export default Onboarding;
