export const SET_SELECTED_TASK = "SET_SELECTED_TASK";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";

export const SET_SELECTED_BANK_SURVEY = "SET_SELECTED_BANK_SURVEY";
export const SET_BANK_TASKS = "SET_BANK_TASKS";

export const SET_TASK_SECTIONS = "SET_TASK_SECTIONS";
export const SET_TASK_QUESTIONS = "SET_TASK_QUESTIONS";

export const SET_TOTAL_SUBMITTED_QUESTIONS = "SET_TOTAL_SUBMITTED_QUESTIONS";
