import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { gql_get_survey_sections } from "../../../../../../redux/actions/riskdashboard/riskDashboardActions";

import MainHeader from "./NewQuestionTabComps/MainHeader/MainHeader";
import LeftSection from "./NewQuestionTabComps/LeftSection/LeftSection";
import RightSection from "./NewQuestionTabComps/RightSection/RightSection";

const NewQuestionsTab = () => {
  // from redux
  const dispatch = useDispatch();
  const { selectedSurveyData, sectionWiseData, usingVersion } = useSelector(
    (state) => state.riskDashboard
  );

  // states
  const [selectedSection, setSelectedSection] = useState(sectionWiseData[0]);

  //------------------- Inner Functions -------------------

  // ------------------------------------------------------

  // ------------------- useEffects() -------------------
  // on page load useEffect
  useEffect(() => {
    // fetch list of left sections..0
    dispatch(
      gql_get_survey_sections(
        selectedSurveyData?._id,
        setSelectedSection,
        true,
        usingVersion
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------------------------------------------------

  return (
    <Box height={"100%"}>
      {/* Question List Header */}
      <MainHeader
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
      />

      {/* Left and Right Sections Grid */}
      <Grid
        container
        style={{ height: "60vh", marginTop: "10px" }}
        justifyContent="space-between"
      >
        {/* Left Section */}
        <LeftSection
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />

        {/* Right Section */}
        <RightSection selectedSection={selectedSection} />
      </Grid>
    </Box>
  );
};
export default NewQuestionsTab;
