import { useHistory } from "react-router-dom";
import {
  Box,
  TableRow,
  TableCell,
  Avatar,
  Typography,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoDocumentText } from "react-icons/io5";

import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { taskBankHeadings, taskHeadings, taskVendorHeadings } from "./taskTemp";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_va_banks_list,
  gql_get_vendor_name_for_va,
  gql_get_vendors_list,
} from "../../../redux/actions/vendorActions/vendorAction";
import { useEffect } from "react";
import { setSelectedBankSurvey } from "../../../redux/actions/taskActions/TaskAction";
import {
  SET_SELECTED_BANK_DETAILS_FOR_VA,
  SET_VA_BANKS_LIST,
} from "../../../constants/brmConstants";
import NoData from "../../widgets/NoData/NoData";
import { toDDMMYY } from "../../../utils/dateTime/convertTimestamp";
import moment from "moment";

// -------- main render ---------
const TaskMain = () => {
  const classes = useTableStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const { vaBanksList, organizationsList } = useSelector(
    (state) => state?.brmData
  );
  const { login } = useSelector((state) => state);

  console.log(organizationsList);

  useEffect(() => {
    if (login?.user_type === "BSA" || login?.user_type === "BG") {
      dispatch(gql_get_vendors_list(8, 1, "Assigned"));
    } else {
      dispatch(gql_get_va_banks_list("Assigned", 6, 1));
    }

    if (login?.user_type === "VG") {
      dispatch(gql_get_vendor_name_for_va(login?.vendorId));
    }

    return () => {
      dispatch({ type: SET_VA_BANKS_LIST, payload: [] });
    };
  }, []);

  const setSelectedBankDetailsForVa = (bank) => {
    return { type: SET_SELECTED_BANK_DETAILS_FOR_VA, payload: bank };
  };

  async function handleClick(rowData) {
    await dispatch(setSelectedBankDetailsForVa(rowData));
    await dispatch(setSelectedBankSurvey(rowData));
    history.push(`/task/${rowData?.name?.replace(" ", "_")}`);
  }

  const list =
    login?.user_type === "BSA" || login?.user_type === "BG"
      ? organizationsList
      : vaBanksList?.banks;

  return (
    <Box>
      <DynamicTable
        headings={
          login?.user_type === "BSA" || login?.user_type === "BG"
            ? taskVendorHeadings
            : taskBankHeadings
        }
      >
        {list?.length > 0 &&
          list?.map((rowData) => (
            <TableRow
              key={rowData?._id}
              className={classes.tableRowHover}
              onClick={() => handleClick(rowData)}
            >
              <TableCell style={textStyle("main")}>
                <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                  <IoDocumentText color="#5C90C7" size={20} />
                  {rowData?.name}
                </Box>
              </TableCell>
              <TableCell style={textStyle()}>{rowData?.count}</TableCell>

              <TableCell style={textStyle()}>
                {rowData?.assignedOn
                  ? moment(rowData?.assignedOn).format("Do MMM, YYYY")
                  : "-"}
              </TableCell>
              <TableCell style={textStyle()}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  sx={{ gap: 10 }}
                  alignItems={"center"}
                >
                  <Avatar
                    src={rowData?.assignedBy?.profileImg?.url}
                    alt={
                      rowData?.assignedBy?.profileImg?.name ||
                      rowData?.assignedBy?.name
                    }
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography style={textStyle("main")}>
                      {rowData?.assignedBy?.name}
                    </Typography>
                    <Typography
                      style={{
                        color: "#AFAFAF",
                        fontWeight: 500,
                        fontSize: "11px",
                        lineHeight: "16px",
                      }}
                    >
                      {rowData?.assignedBy?.user_type}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  style={statusStyle(rowData?.active ? "pending" : "complete")}
                >
                  <DotIcon style={{ widht: "10px", height: "10px" }} />
                  {rowData?.active ? "In Progress" : "Completed"}
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </DynamicTable>
      {vaBanksList?.banks?.length === 0 && (
        <NoData message={"No task found!"} />
      )}
    </Box>
  );
};
export default TaskMain;
