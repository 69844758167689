import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Paper,
  RadioGroup,
  Tab,
  TableCell,
  Tabs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../UI/button/button";
import TabPanel from "../../../UI/tabPanel/tabPanel";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../../UI/textfield/textfield";
import countryList from "../../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import tickGreen from "../../../../images/TickGreen.png";
import Add from "@material-ui/icons/Add";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { appTheme } from "../brmDetaails";
import RiskAnalysisRightTab from "../../riskAnalysisRightTab/riskAnalysisRightTab";
import { corporateSurveyQuestions } from "../../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";
import RadioButton from "../../../UI/radioButton/radioButton";
import SentimentAnalysisRightTab from "../../sentimentAnalysisRightTab/sentimentAnalysisRightTab";
import RiskSummaryTab from "../../riskSummaryTab/riskSummaryTab";
import { addMember, viewMember } from "../../../../redux/actions/member/member";
import CustomCheckBox from "../../../UI/checkBox/checkBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import SurveyQuestions from "../../surveyQuestions/surveyQuestions";
import ControverciesQuestions from "../../controverciesQuestions/controverciesQuestions";
import EsgDeclaration from "../../esgDeclaration/esgDeclaration";
import Controversy from "../../esgControversy/controversy";
import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
import PeerDocuments from "../../publicInfo/peerDocuments";
import PublicReports from "../../publicInfo/publicReports";
import ApiConnections from "../../publicInfo/apiConnections";
import Documents from "../../publicInfo/documents";
import { ChartRenderer } from "../../charts/charts";
import BasicAssessmentDetails from "../../riskAnalysisRightTab/basicAssessmentDetails";
import { TurnoverYearPickerDialog } from "../riskAssessment";
import StakeholderList from "../stakeholderList";
import StakeholderListView from "../stakeholderListView";
import DataBreakdown from "../DataBreakdown";
import SelfAssessmentResponse from "../../selfAssessment/selfAssessmentResponse";
import SurveyResponses from "../../surveyResponses/surveyResponses";
import GetApp from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import { UserCard } from "../riskUtilComponents";
import Contoversies from "../../controversiesTab/controversies";
import EsgRiskRating from "../../esgRiskRating/esgRiskRating";
export const brmRightStyles = makeStyles((theme) => ({
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },
  cardContainerDefault: {
    width: "100%",
    height: "100px",
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",

    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },

  cardTypography: {
    fontWeight: "600",
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12, textTransform: "none" },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typographyshare: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  onListHover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
}));

function BrmDataRight(props) {
  const location = useHistory();
  // console.log(
  //   "🚀 ~ file: brmDataRight.js:130 ~ location",
  //   window.location.origin
  // );

  const classes = brmRightStyles();
  const {
    brmData,
    riskEvaluator,
    riskTabNo,
    setRiskTabNo,
    // organizaitonDetails,
    setOrganizationDetails,
    login,
  } = props;

  const [turnoverData, setTurnoverData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    no_of_employees: "",
    isin: "",
    year_of_assessment: "",
    country_name: "",
    country_code: "",
    address: [
      { landMark: "", state: "", pinCode: "", companyAddress: "", country: "" },
    ],
  });
  // console.log("organizaitonDetailsObject", organizaitonDetailsObject);

  useEffect(() => {
    brmData?.organizationDetails[0] &&
      setOrganizaitonDetailsObject(brmData?.organizationDetails[0]);
    setCompanyLogoURI(brmData?.organizationDetails[0]?.logo?.url);
    setTurnoverData(brmData?.organizationDetails[0]?.turnover || []);
    setRevenueData(brmData?.organizationDetails[0]?.revenue || []);
  }, [brmData?.organizationDetails]);

  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [userId, setUserId] = useState("");

  const [role, setRole] = useState("");
  const [reportsTab, setReportsTab] = useState(0);

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      // uploadUserSheet(login.token, login.current_organisation, file);
      setAddNewUserDialogOpen(false);
    }
  };
  const stakeHolderCategoryList = [
    "Board of Directors",
    "Executive Team",
    "Employees",
    "Investors & Shareholders",
    "Suppliers & Vendors",
    "Consultants",
    "Customers",
    "Government Agencies",
  ];
  const reportData = [
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
  ];
  const breakDown = [
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        { question: "Growth", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        {
          question: "cross region",
          FY2020: "2330",
          FY2021: "2331",
          FY20202: "2332",
        },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "sub question",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
            {
              question: "time",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
          ],
        },
      ],
    },
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", Fy2021: "2331", Fy20202: "2332" },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "Europe",
              FY2020: "2330",
              Fy2021: "2331",
              Fy20202: "2332",
            },
          ],
        },
      ],
    },
  ];
  async function handleAddUser() {
    props.addMember(
      login?.token,
      login?.bankId,
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id"),
      name,
      email,
      role,
      category,
      type,
      window.location.origin
    );
    setAddNewUserDialogOpen(false);
    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData.organizationDetails[0]._id
    //     ? brmData.organizationDetails[0]._id
    //     : localStorage.getItem("organization_id")
    // );
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // setMembers(brmData.members);
  }

  React.useEffect(() => {
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    props.getCalculatedSurvey(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
    // setMembers(brmData.members);
  }, []);

  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>
                Add Stakeholders
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              {/* <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a> */}
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="secondary"
                  component="label"
                >
                  Upload Excel
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<GetApp />}
                  className={classes.CustomButton2}
                  variant="contained"
                  size="small"
                  color="secondary"
                  component="label"
                >
                  Download Excel
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setAddNewUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" || email === "" || category === "" || role === ""
                }
                onClick={handleAddUser}
              >
                Add New User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const editUserDialog = () => {
    return (
      <Dialog open={editUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Edit User</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              {/* <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a> */}
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setEditUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" || email === "" || category === "" || role === ""
                }
                onClick={() => {
                  props.editMember(
                    login.token,
                    userId,
                    brmData?.organizationDetails[0]?._id
                      ? brmData?.organizationDetails[0]?._id
                      : localStorage.getItem("organization_id"),
                    role,
                    category
                  );
                  setEditUserDialogOpen(false);
                }}
              >
                Update User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At  we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });
  // const shareDialog = () => {
  //   return (
  //     <Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
  //       <DialogTitle>
  //         <Grid container justifyContent="center" className={classes.mainGrid2}>
  //           <Grid item xs={4}>
  //             <Typography className={classes.Typographyshare}>
  //               Share Survey
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </DialogTitle>
  //       <DialogContent>
  //         <Grid
  //           container
  //           spacing={1}
  //           direction="column"
  //           alignItems="flex-start"
  //           // style={{}}
  //         >
  //           <Grid item md={12} className={classes.grid8}>
  //             <Paper elevation={0} className={classes.Paper}>
  //               <Autocomplete
  //                 size="small"
  //                 value={emails}
  //                 options={
  //                   brmData.members && brmData.members.length > 0
  //                     ? brmData.members.map((mem) => mem.email)
  //                     : []
  //                 }
  //                 // options={maturityMatrix.notAssignedUsers.map(
  //                 //   (user) => user.email
  //                 // )}
  //                 // renderTags={(value, getTagProps) => {
  //                 //   return value.map((option, index) => (
  //                 //     <>
  //                 //       <Chip
  //                 //         variant="outlined"
  //                 //         label={option}
  //                 //         {...getTagProps({ index })}
  //                 //       />
  //                 //     </>
  //                 //   ));
  //                 // }}
  //                 onChange={(e, newValue) => {
  //                   // console.log('new',e.target)
  //                   setEmails(newValue);
  //                 }}
  //                 renderInput={(params) => (
  //                   <>
  //                     <CustomTextfield
  //                       {...params}
  //                       // onChange={(e, newValue) => {
  //                       //   console.log('new',newValue)
  //                       //   console.log('new',e.target.innerText)
  //                       //   // setEmails(newValue);
  //                       // }}
  //                       size="small"
  //                       variant="outlined"
  //                       fullWidth
  //                       label="Search By Emai ID or Name"
  //                       name="asgEmailStep3"
  //                     />
  //                   </>
  //                 )}
  //               />
  //             </Paper>
  //           </Grid>
  //           {true && (
  //             <>
  //               <Grid item className={classes.grid8}>
  //                 <CustomTextfield
  //                   label="Email Subject"
  //                   name="subject"
  //                   fullWidth
  //                   size="small"
  //                   variant="outlined"
  //                   style={{ marginTop: 10 }}
  //                   // onChange={stakeHolderContentChange}
  //                   helperText="hint : survey ESG requires contribution"
  //                 />
  //               </Grid>
  //               <Grid item className={classes.grid8}>
  //                 <Typography className={classes.Typography5}>
  //                   Content
  //                 </Typography>
  //               </Grid>
  //               <Grid item className={classes.grid8}>
  //                 <CustomTextfield
  //                   name="content"
  //                   multiline="true"
  //                   onChange={(e) => {
  //                     // stakeHolderContentChange(e);
  //                   }}
  //                   className={classes.CustomTextfield}
  //                   defaultValue={`hello user,  Hope this mail finds you in a good place. kindly fillout the survey form to get thr esf in relation to your company.`}
  //                   fullWidth
  //                   variant="outlined"
  //                   inputProps={{ className: classes.shareContentText }}
  //                 />
  //               </Grid>
  //             </>
  //           )}
  //         </Grid>
  //       </DialogContent>

  //       <DialogActions>
  //         <Grid container spacing={2} className={classes.grid7}>
  //           <Grid item xs={12} md={12} className={classes.grid3}>
  //             <CustomButton
  //               color="primary"
  //               // variant="outlined"
  //               className={classes.CustomButton3}
  //               onClick={() => {
  //                 setShareDialogOpen(false);
  //                 // setEmails([]);
  //               }}
  //             >
  //               Cancel
  //             </CustomButton>
  //             <CustomButton
  //               color="primary"
  //               variant="contained"
  //               className={classes.CustomButton3}
  //               onClick={() => {
  //                 // handleShareAssessment();
  //                 // setCheckedState(
  //                 //   new Array(generalSettings.allUsers.length).fill(false)
  //                 // );shareSurvey: (token, organization_id, email) => {

  //                 props.shareSurvey(
  //                   localStorage.getItem("user_token")
  //                     ? localStorage.getItem("user_token")
  //                     : props?.login?.token,
  //                   brmData?.organizationDetails[0]?._id
  //                     ? brmData?.organizationDetails[0]?._id
  //                     : localStorage.getItem("organization_id"),
  //                   emails && emails
  //                 );

  //                 setShareDialogOpen(false);
  //               }}
  //             >
  //               Send Survey
  //             </CustomButton>
  //           </Grid>
  //           {/* <Grid item xs={4} md={4} className={classes.grid6}>

  //           </Grid> */}
  //         </Grid>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };
  const [shareTabNo, setShareTabNo] = useState(0);

  const shareDialog = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setShareDialogOpen(false)}
        // variant="persistent"
        width="lg"
        open={shareDialogOpen}
        style={{ position: "relative" }}
      >
        <div style={{ width: "550px", position: "relative", padding: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "fit-content",
            }}
          >
            <Typography
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              Share Survey
            </Typography>
            <Typography
              onClick={() => setShareDialogOpen(!shareDialogOpen)}
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
            </Typography>
          </div>
          <MuiThemeProvider theme={appTheme}>
            <AppBar
              position="static"
              color="inherit"
              className={classes.AppBar}
            >
              <Tabs
                value={shareTabNo}
                onChange={(e, newVal) => {
                  setShareTabNo(newVal);
                }}
              >
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>Participants</span>}
                />
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>Timing</span>}
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>

          <TabPanel value={shareTabNo} index={0}>
            <div className="scroll" style={{ marginTop: "6%", height: "70vh" }}>
              <CustomTextfield
                name="Search"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label="Search"
                onChange={(e) => {
                  // setSearchCompanyText(e.target.value);
                  // onSearchHandler(e);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: "#3374B9" }} />
                    </InputAdornment>
                  ),
                }}
                // value={searchCompanyText}
              />

              <UserCard
                member={{
                  name: "userName",
                  email: "test@gemail.com",
                  role: "Data Engineer",
                }}
                onClick={() => {}}
              />
              <UserCard
                member={{
                  name: "userName",
                  email: "test@gemail.com",
                  role: "Data Engineer",
                }}
                onClick={() => {}}
              />
              <UserCard
                member={{
                  name: "userName",
                  email: "test@gemail.com",
                  role: "Data Engineer",
                }}
                onClick={() => {}}
              />
            </div>
          </TabPanel>
          <TabPanel value={shareTabNo} index={1}>
            <div
              className="scroll"
              style={{ marginTop: 18, height: "60vh", paddingTop: 5 }}
            >
              <FormLabel style={{ fontSize: 12 }}>Survey Frequency</FormLabel>
              <CustomTextfield
                name="userCategory"
                label="Choose the required frequency"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {[
                  "Every 1 month (Every 30 days)",
                  "Every 3 months (Quarterly)",
                  "Every 6 months (Half yearly)",
                  "Every 1 Year",
                  "Custom Range",
                ].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
              <div style={{ display: "flex", margin: "8px 0px", gap: 8 }}>
                <div>
                  <FormLabel style={{ fontSize: 12 }}>
                    Date in which the survey will be sent
                  </FormLabel>
                  <CustomTextfield
                    name="Search"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label="Pick your date"
                    onChange={(e) => {
                      // setSearchCompanyText(e.target.value);
                      // onSearchHandler(e);
                    }}

                    // value={searchCompanyText}
                  />
                </div>
                <div>
                  <FormLabel style={{ fontSize: 12 }}>
                    Time in which the survey will be sent
                  </FormLabel>
                  <CustomTextfield
                    name="Search"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label="Pick your time"
                    onChange={(e) => {
                      // setSearchCompanyText(e.target.value);
                      // onSearchHandler(e);
                    }}

                    // value={searchCompanyText}
                  />
                </div>
              </div>
              <FormLabel style={{ fontSize: 12 }}>
                How many reminders should be sent ? (max 3)
              </FormLabel>
              <CustomTextfield
                name="Search"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label=""
                onChange={(e) => {
                  // setSearchCompanyText(e.target.value);
                  // onSearchHandler(e);
                }}

                // value={searchCompanyText}
              />
            </div>
          </TabPanel>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
          }}
        >
          <Button
            style={{
              textTransform: "none",
              color: "#3374B9",
            }}
            onClick={() => setShareDialogOpen(!shareDialogOpen)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#3374B9",
              color: "white",
            }}
            onClick={() => setShareDialogOpen(!shareDialogOpen)}
          >
            Save & Continue
          </Button>
        </div>
      </Drawer>
    );
  };
  useEffect(() => {
    if (props.isEvaluator) {
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 0 &&
        props.getScores(login.token, brmData?.organizationDetails[0]?.isin);
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 3 &&
        props.getControversyData(
          login.token,
          brmData?.organizationDetails[0]?.isin
        );
    }
  }, [brmData?.brmMainTabNo, brmData?.brmSubTabNo]);

  const [sectors, setsectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    if (
      brmData?.organizationDetails &&
      brmData?.organizationDetails[0].sectors &&
      brmData.organizationDetails[0].sectors.length > 0
    ) {
      let masterSectors = [...brmData.sectorIndustries];
      masterSectors.map((masterSec, masterSecIdx) => {
        return (
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map((basicInd) =>
                masterInd._id === basicInd
                  ? (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = true)
                  : (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = false)
              )
            )
          )
        );
      });

      setsectors(masterSectors);
    } else {
      setsectors(brmData.sectorIndustries);
    }
  }, [brmData.sectorIndustries, brmData.organizationDetails]);

  useEffect(() => {
    return () => {
      setsectors([]);
      setOrganizationDetails("");
    };
  }, []);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));
    ////////////////
    //  comment this code to select multiple sectors & industries
    sectorL = sectorL.map((sect, i) => {
      // if (sectorIndex !== i) {
      sect.industry_data.map((ind, j) => {
        if (sectorIndex === i && industryIndex === j) {
          ind.isSelected = true;
        } else {
          ind.isSelected = false;
        }
        return ind;
      });
      // }
      return sect;
    });

    // var sectorL = [...sectors];
    // sectorL[sectorIndex].industry_data[industryIndex].isSelected =
    //   !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setsectors(sectorL);
  };
  // const [compayLogo, setCompanyLogo] = useState("");
  // const [companyLogoURI, setCompanyLogoURI] = useState("");
  // const handleClearCompanyLogo = () => {
  //   setCompanyLogo("");
  //   setCompanyLogoURI("");
  // };
  // const handleUploadCompanyLogo = (e) => {
  //   const reader = new FileReader();

  //   const file = e.target.files[0];
  //   setCompanyLogo(file);
  //   reader.onloadend = () => {
  //     setCompanyLogoURI(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={() => setIsSectorDialogOpen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onChange={() => handleIndustryChange(index2, index)}
                          >
                            {/* {industry.isSelected} */}
                            <CustomCheckBox
                              key={industry.industry + index2}
                              checked={industry.isSelected ? true : false}
                              name={industry.Industry}
                            />
                            <Typography id={industry.Industry}>
                              {industry.Industry}
                            </Typography>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsSectorDialogOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleEditUser = (user) => {
    setUserId(user._id);
    setEditUserDialogOpen(true);
    setName(user.name);
    setEmail(user.email);
    setCategory(user.category);
    setRole(user.role);
  };

  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", organizaitonDetailsObject.year_of_assessment);
    organizaitonDetailsObject.year_of_assessment &&
      setStartYear(organizaitonDetailsObject.year_of_assessment?.split("-")[0]);
    organizaitonDetailsObject.year_of_assessment &&
      setEndYear(organizaitonDetailsObject.year_of_assessment?.split("-")[1]);
  }, [organizaitonDetailsObject.year_of_assessment]);

  useEffect(() => {
    const data = [];
    if (!organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(
          organizaitonDetailsObject.year_of_assessment.split("-")[0] - 3 + i
        );
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  year_of_assessment: "",
                }));
                organizaitonDetailsObject.year_of_assessment &&
                  setStartYear(
                    organizaitonDetailsObject.year_of_assessment.split("-")[0]
                  );
                organizaitonDetailsObject.year_of_assessment &&
                  setEndYear(
                    organizaitonDetailsObject.year_of_assessment.split("-")[1]
                  );
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  year_of_assessment: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [turnoverPicker]);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  const handleSetYear = () => {
    !currentIndex.current.revenue &&
      setTurnoverData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  return (
    <div>
      {yearPickerDialog()}
      {isSectorDialogOpen && sectorIndustry()}
      {shareDialogOpen && shareDialog()}
      {addNewUserDialogOpen && addNewUserDialog()}
      {editUserDialogOpen && editUserDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
      >
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
        {!props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                {/* <Card
                    variant="elevation"
                    id="drawer-container"
                    elevation={1}
                    className={classes.cardContainer}
                    data-tut="reactour__basicRight"
                    >
                    <CardContent style={{ padding: 10 }}> */}
                <Typography className={classes.cardTypography}>
                  Basic Company Details
                </Typography>

                <div className="scroll" style={{ height: "61vh" }}>
                  <Grid container>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compName"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Company Name"
                          value={organizaitonDetailsObject?.name}
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.name && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Logo
                        </Typography>
                      </Box>
                      <Box my={2}>
                        {/* {console.log(companyLogoURI)} */}
                        {!companyLogoURI ? (
                          <>
                            <input
                              id="company-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCompanyLogo(event)
                              }
                              style={{ display: "none" }}
                              cursor="pointer"
                            />
                            <label htmlFor="company-logo">
                              <div className={classes.logoSelecter}>
                                <AddCircleOutlineIcon
                                  name="addLogoIcon"
                                  fontSize="large"
                                  className={classes.addIcon}
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.companyLogo}>
                            <img
                              alt="company-logo"
                              accept="image/png, image/gif, image/jpeg"
                              src={companyLogoURI}
                            />
                            <IconButton
                              onClick={handleClearCompanyLogo}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "gray" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Sector & Industry
                          {sectors?.length === 0 && (
                            <span
                              style={{
                                color: "red",
                                padding: "0px 3px",
                                display: "inline",
                              }}
                            >
                              *
                            </span>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={classes.addSectorContainer}
                    >
                      <CustomButton
                        onClick={() => {
                          setIsSectorDialogOpen(true);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Add Sector
                      </CustomButton>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        {sectors.length !== 0 ? (
                          sectors.map((sector) => (
                            <div style={{ marginBottom: 8 }}>
                              {sector.industry_data.some(
                                (inds) => inds.isSelected
                              ) && (
                                <>
                                  <Typography
                                    className={classes.selectedSectorTitle}
                                  >
                                    {sector.Sector}
                                  </Typography>
                                  {sector.industry_data.map(
                                    (industry) =>
                                      industry.isSelected && (
                                        <Chip
                                          className={classes.sectorChip}
                                          label={industry.Industry}
                                        />
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          ))
                        ) : (
                          <Chip
                            className={classes.sectorChip}
                            label="Non Selected"
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Revenue Data
                          {revenueData.length === 0 && (
                            <span
                              style={{
                                color: "red",
                                padding: "0px 3px",
                                display: "inline",
                              }}
                            >
                              *
                            </span>
                          )}
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          onClick={() => {
                            const initialPeer = {
                              revenue: "",
                              year: "",
                              currency: "",
                              figures: "",
                            };
                            const copy = [...turnoverData];
                            copy.push(initialPeer);
                            setRevenueData(copy);
                            window.scrollTo({
                              top: 50,
                              behavior: "smooth",
                            });
                            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Add Revenue e
                        </CustomButton>
                      </Box>
                      {revenueData.map((revenue, i) => {
                        return (
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            key={revenue.revenue + i}
                          >
                            <Grid item xs={12} md={5}>
                              <CustomTextfield
                                name="turnovers"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Add Revenue"
                                value={revenue.revenue}
                                onChange={(e) => {
                                  const copy = [...revenueData];
                                  copy[i].revenue = parseInt(e.target.value);
                                  setRevenueData(copy);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              role="button"
                              onClick={() => handleTurnover(i, true)}
                            >
                              <CustomTextfield
                                name="NumOfEmployee"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Year"
                                onChange={(e) => {
                                  const copy = [...revenueData];
                                  copy[i].year = e.target.value;
                                  setRevenueData(copy);
                                }}
                                value={revenue.year}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const copy = [...revenueData];
                                  copy.splice(i, 1);
                                  setRevenueData(copy);
                                }}
                              >
                                remove
                              </Icon>
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={revenue.currency}
                                options={["USD", "AED", "INR"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Currency"
                                    variant="outlined"
                                    value={revenue.currency}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    newValue &&
                                      setRevenueData((prev) => ({
                                        ...newValue,
                                        currency: newValue,
                                        // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                                      }));
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={5} md={6}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={revenue.figures}
                                options={["Million", "Billion", "Crore"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Figures"
                                    variant="outlined"
                                    value={revenueData.figures}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setRevenueData((prev) => ({
                                      ...prev,
                                      figures: newValue,
                                    }));
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item md={12} style={{ paddingBottom: 20 }}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Turnover Data
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          onClick={() => {
                            const initialPeer = {
                              turnover: "",
                              year: "",
                              currency: "",
                              figures: "",
                            };
                            const copy = [...turnoverData];
                            copy.push(initialPeer);
                            setTurnoverData(copy);
                            window.scrollTo({
                              top: 50,
                              behavior: "smooth",
                            });
                            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Add Turnover
                        </CustomButton>
                      </Box>
                      {turnoverData.map((turnover, i) => {
                        return (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={5}>
                              <CustomTextfield
                                name="turnovers"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Add Turnover"
                                value={turnover.turnover}
                                onChange={(e) => {
                                  const copy = [...turnoverData];
                                  copy[i].turnover = parseInt(e.target.value);
                                  setTurnoverData(copy);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              role="button"
                              onClick={() => handleTurnover(i, false)}
                            >
                              <CustomTextfield
                                name="NumOfEmployee"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Year"
                                onChange={(e) => {
                                  const copy = [...turnoverData];
                                  copy[i].domain = e.target.value;
                                  setTurnoverData(copy);
                                }}
                                value={turnover.year}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const copy = [...turnoverData];
                                  copy.splice(i, 1);
                                  setTurnoverData(copy);
                                }}
                              >
                                remove
                              </Icon>
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={turnover.currency}
                                options={["USD", "AED", "INR"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Currency"
                                    variant="outlined"
                                    value={turnoverData.currency}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setTurnoverData((prev) => ({
                                      ...prev,
                                      currency: newValue,
                                      // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                                    }));
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={5} md={6}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={turnover.figures}
                                options={["Million", "Billion", "Crore"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Figures"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setTurnoverData((prev) => ({
                                      ...prev,
                                      figures: newValue,
                                    }));
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      item
                      xs={12}
                      md={11}
                    >
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User First Name"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User Last Name"
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} md={5}>
     
      </Grid> */}
                    {/* <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Designation Of The Employee"
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={11}>
                      <Autocomplete
                        id="country-select-demo"
                        size="small"
                        // style={{ width: 300 }}
                        value={{
                          country_name: organizaitonDetailsObject?.country_name,
                        }}
                        options={brmData.countries}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.country_name}
                        renderOption={(option) => (
                          <React.Fragment>{option.country_name}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <CustomTextfield
                            {...params}
                            label="Choose a country"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              country_name: newValue?.country_name,
                              country: newValue?.country_code,
                              // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                            }));
                            // setBranchCountry(newValue);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.country_name && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="ISIN Number"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              isin: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.isin}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.isin && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        onClick={() => setIsDatePickerDialogOpen(true)}
                        style={{
                          border: "1px solid #00000050",
                          padding: 12,
                          borderRadius: 5,
                          cursor: "pointer",
                          minHeight: 45,
                        }}
                      >
                        {organizaitonDetailsObject.year_of_assessment ? (
                          <Typography variant="body1" name="yearOfAssesment">
                            {`${
                              organizaitonDetailsObject.year_of_assessment.split(
                                "-"
                              )[0]
                            } - ${
                              organizaitonDetailsObject.year_of_assessment.split(
                                "-"
                              )[1]
                            }`}
                          </Typography>
                        ) : (
                          <Typography
                            style={{ color: "#878282e6", fontFamily: "Roboto" }}
                          >
                            Year Of Assessment
                          </Typography>
                        )}

                        {/* <Typography variant="body1" name="yearOfAssesment">
        {basicInfo.year_of_assessment[0].startDate !== "" &&
        basicInfo.year_of_assessment[0].endDate !== ""
          ? `${moment(
              new Date(basicInfo.year_of_assessment[0].startDate)
            ).format("DD MMM yy")} - ${moment(
              new Date(basicInfo.year_of_assessment[0].endDate)
            ).format("DD MMM yy")}`
          : "Year Of Assessment"}
      </Typography> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.year_of_assessment && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compOperation"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                          label="Company Operation"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              operation: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.operation}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Number of Employees"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              no_of_employees: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.no_of_employees}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="contactEmail"
            variant="outlined"
            fullWidth
            size="small"
            label="Contact Email"
          />
        </Box>
      </Grid> 
      <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="website"
            variant="outlined"
            fullWidth
            size="small"
            label="Website"
          />
        </Box>
      </Grid>*/}

                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Address
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]
                              ?.companyAddress
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  companyAddress: e.target.value,
                                },
                              ],
                            }));
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          variant="outlined"
                          name="compLandmark"
                          fullWidth
                          size="small"
                          label="Landmark"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.landMark
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  landMark: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.pinCode
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  pinCode: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.state
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  state: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="compCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.country
                              ? organizaitonDetailsObject?.address[0]?.country
                              : ""
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setOrganizaitonDetailsObject((prev) => ({
                                ...prev,
                                address: [
                                  {
                                    ...prev?.address[0],
                                    country: newValue,
                                  },
                                ],
                              }));
                            }
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Branch Office Address
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          // onClick={handleAddBranch}
                          variant="outlined"
                          color="primary"
                        >
                          Add Another Branch
                        </CustomButton>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          // value={basicInfo.branchAddress || ""}
                          // onChange={(e) => setBranchAddress(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchLandmark"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Landmark"
                          // value={basicInfo.branchLandmark || ""}
                          // onChange={(e) => setBranchLandmark(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          // value={basicInfo.branchPincode || ""}
                          // onChange={(e) => setBranchPincode(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          // value={basicInfo.branchState || ""}
                          // onChange={(e) => setBranchState(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="branchCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          // value={basicInfo.branchCountry || ""}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // setBranchCountry(newValue);
                            }
                          }}
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <CustomButton
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                        disabled={
                          !organizaitonDetailsObject.isin ||
                          !organizaitonDetailsObject?.country_code ||
                          !organizaitonDetailsObject.yearOfAssessment ||
                          !revenueData.filter(
                            (d) =>
                              d.revenue !== "" &&
                              d.year !== "" &&
                              d.figures !== "" &&
                              d.currency !== ""
                          ).length
                        }
                        onClick={() => {
                          props.updateOrganization(
                            login.token,
                            brmData?.organizationDetails[0]?._id
                              ? brmData?.organizationDetails[0]?._id
                              : localStorage.getItem("organization_id"),
                            organizaitonDetailsObject?.name,
                            organizaitonDetailsObject?.isin,
                            organizaitonDetailsObject?.year_of_assessment,
                            compayLogo,
                            sectors,
                            organizaitonDetailsObject?.operation,
                            organizaitonDetailsObject?.no_of_employees,
                            organizaitonDetailsObject?.address,
                            brmData.sectorIndustries,
                            revenueData.filter(
                              (d) =>
                                d.revenue !== "" &&
                                d.year !== "" &&
                                d.currency !== "" &&
                                d.figures !== ""
                            ),
                            turnoverData.filter(
                              (d) =>
                                d.turnover !== "" &&
                                d.year !== "" &&
                                d.currency !== "" &&
                                d.figures !== ""
                            ),
                            organizaitonDetailsObject?.country
                          );
                        }}
                      >
                        Update Portfolio
                      </CustomButton>
                      <CustomButton
                        onClick={() =>
                          setOrganizaitonDetailsObject(
                            brmData?.organizationDetails[0]
                          )
                        }
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                      >
                        Cancel
                      </CustomButton>
                    </Grid>
                    <Grid xs={7}></Grid>
                    <Grid item xs={9}>
                      {/* {LocationStore} */}
                    </Grid>
                  </Grid>
                </div>
                {/* </CardContent>
</Card> */}
              </div>
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
              <StakeholderList
                setAddNewUserDialogOpen={setAddNewUserDialogOpen}
                handleEditUser={handleEditUser}
                brmData={brmData}
              />
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                setShareDialogOpen={setShareDialogOpen}
                getSurveyeQuestions={props.getSurveyeQuestions}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                      Stakeholders Status
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Add />}
                      style={{ cursor: "pointer" }}
                      color="primary"
                      disabled={
                        brmData.members &&
                        brmData.members.length > 0 &&
                        brmData.members.filter(
                          (member) => member.status === "Sent"
                        ).length > 0
                      }
                      onClick={() => setShareDialogOpen(true)}
                    >
                      Share Survey
                    </Button>
                  </div>
                </div>
                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#EBF1F8",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item md={5} xs={4}>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      Name E-mail
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>
                      Category
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>Status</Typography>
                  </Grid>
                  <Grid item md={2} xs={3} align="center">
                    <Typography style={{ fontSize: "13px" }}>Role</Typography>
                  </Grid>
                  {/* <Grid item md={1} xs={1}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      Edit
                    </Typography>
                  </Grid> */}
                </Grid>
                {brmData.members &&
                  brmData.members.length > 0 &&
                  brmData.members.map((member) => (
                    <Grid
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "10px",
                        padding: "5px",
                        borderBottom: "1px solid grey",
                        borderRadius: "2px",
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={5} xs={4}>
                        <Typography
                          style={{ fontSize: "13px", marginLeft: "5px" }}
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            marginLeft: "5px",
                            color: "grey",
                          }}
                        >
                          {member.email}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography style={{ fontSize: "13px" }}>
                          {member.category}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography
                          style={{
                            diaplay: "inline-flex",
                            alighItems: "center",
                          }}
                        >
                          <Box
                            display="inline-flex"
                            alignItems="center"
                            // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                            p={1}
                            style={{
                              padding: "8px",
                            }}
                            borderRadius="8px"
                          >
                            <Box
                              borderRadius="50%"
                              height={10}
                              width={10}
                              bgcolor={
                                // "#5BB37F"
                                member.status === "Sent" && "#5BB37F"
                                  ? member.status === "Not Sent"
                                    ? "#5BB37F"
                                    : "#5BB37F"
                                  : "#9FA1AF"
                              }
                            />
                            <Box
                              style={{
                                paddingLeft: "5px",
                                fontSize: "14px",
                                color:
                                  member.status === "Sent" && "#5BB37F"
                                    ? member.status === "Not Sent"
                                      ? "#5BB37F"
                                      : "#5BB37F"
                                    : "#9FA1AF",
                              }}
                            >
                              {member.status}
                            </Box>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography style={{ fontSize: "13px" }} align="center">
                          {member.role}
                        </Typography>
                      </Grid>
                      {/* <Grid item md={1} xs={1} align="center">
                        <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
                      </Grid> */}
                    </Grid>
                  ))}
              </div>
            )}
          </CardContent>
        )}
        {props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {/* COMPANY SETUP RIGHT TABS */}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                {/* <Card
                   variant="elevation"
                   id="drawer-container"
                   elevation={1}
                   className={classes.cardContainer}
                   data-tut="reactour__basicRight"
                   >
                   <CardContent style={{ padding: 10 }}> */}
                <Typography className={classes.cardTypography}>
                  My Company details
                </Typography>

                <div className="scroll" style={{ height: "61vh" }}>
                  <Grid container>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compName"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Company Name"
                          value={organizaitonDetailsObject?.name}
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.name && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Logo
                        </Typography>
                      </Box>
                      <Box my={2}>
                        {!companyLogoURI ? (
                          <>
                            <input
                              id="company-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCompanyLogo(event)
                              }
                              style={{ display: "none" }}
                              cursor="pointer"
                            />
                            <label htmlFor="company-logo">
                              <div className={classes.logoSelecter}>
                                <AddCircleOutlineIcon
                                  name="addLogoIcon"
                                  fontSize="large"
                                  className={classes.addIcon}
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.companyLogo}>
                            <img
                              alt="company-logo"
                              accept="image/png, image/gif, image/jpeg"
                              src={companyLogoURI}
                            />
                            <IconButton
                              onClick={handleClearCompanyLogo}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "gray" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Sector & Industry
                          {sectors?.length === 0 && (
                            <span
                              style={{
                                color: "red",
                                padding: "0px 3px",
                                display: "inline",
                              }}
                            >
                              *
                            </span>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={classes.addSectorContainer}
                    >
                      <CustomButton
                        onClick={() => {
                          setIsSectorDialogOpen(true);
                        }}
                        variant="text"
                        color="primary"
                      >
                        Add Industry & Sector
                      </CustomButton>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        {sectors.length !== 0 ? (
                          sectors.map((sector) => (
                            <div style={{ marginBottom: 8 }}>
                              {sector.industry_data.some(
                                (inds) => inds.isSelected
                              ) && (
                                <>
                                  <Typography
                                    className={classes.selectedSectorTitle}
                                  >
                                    {sector.Sector}
                                  </Typography>
                                  {sector.industry_data.map(
                                    (industry) =>
                                      industry.isSelected && (
                                        <Chip
                                          className={classes.sectorChip}
                                          label={industry.Industry}
                                        />
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          ))
                        ) : (
                          <Chip
                            className={classes.sectorChip}
                            label="Non Selected"
                          />
                        )}
                      </Box>
                    </Grid>

                    {/* {console.log(revenueData, turnoverData)} */}
                    <Grid item md={12}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Revenue Data{" "}
                          {revenueData.length === 0 && (
                            <span style={{ color: "red", padding: "0px 3px" }}>
                              *
                            </span>
                          )}
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          onClick={() => {
                            const initialPeer = {
                              revenue: "",
                              year: "",
                              currency: "",
                              figures: "",
                            };
                            const copy = [...revenueData];
                            copy.push(initialPeer);
                            setRevenueData(copy);
                            window.scrollTo({
                              top: 50,
                              behavior: "smooth",
                            });
                            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Add Revenue
                        </CustomButton>
                      </Box>
                      {revenueData.map((revenue, i) => {
                        return (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={5}>
                              <CustomTextfield
                                name="turnovers"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Add Revenue"
                                value={revenue.revenue}
                                onChange={(e) => {
                                  const copy = [...revenueData];
                                  copy[i].revenue = parseInt(e.target.value);
                                  setRevenueData(copy);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              role="button"
                              onClick={() => handleTurnover(i, true)}
                            >
                              <CustomTextfield
                                name="NumOfEmployee"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Year"
                                onChange={(e) => {
                                  const copy = [...revenueData];
                                  copy[i].year = e.target.value;
                                  setRevenueData(copy);
                                }}
                                value={revenue.year}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const copy = [...revenueData];
                                  copy.splice(i, 1);
                                  setRevenueData(copy);
                                }}
                              >
                                remove
                              </Icon>
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={revenue.currency}
                                options={["USD", "AED", "INR"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Currency"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    const copy = [...revenueData];
                                    copy[i].currency = newValue;
                                    setRevenueData(copy);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={5} md={6}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={revenue.figures}
                                options={["Million", "Billion", "Crore"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Figures"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    const copy = [...revenueData];
                                    copy[i].figures = newValue;
                                    setRevenueData(copy);
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item md={12} style={{ paddingBottom: 20 }}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Turnover Data
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          onClick={() => {
                            const initialPeer = {
                              turnover: "",
                              year: "",
                              currency: "",
                              figures: "",
                            };
                            const copy = [...turnoverData];
                            copy.push(initialPeer);
                            setTurnoverData(copy);
                            window.scrollTo({
                              top: 50,
                              behavior: "smooth",
                            });
                            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Add Turnover
                        </CustomButton>
                      </Box>
                      {turnoverData.map((turnover, i) => {
                        return (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={5}>
                              <CustomTextfield
                                name="turnovers"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Add Turnover"
                                value={turnover.turnover}
                                onChange={(e) => {
                                  const copy = [...turnoverData];
                                  copy[i].turnover = parseInt(e.target.value);
                                  setTurnoverData(copy);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              role="button"
                              onClick={() => handleTurnover(i, false)}
                            >
                              <CustomTextfield
                                name="NumOfEmployee"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Year"
                                onChange={(e) => {
                                  const copy = [...turnoverData];
                                  copy[i].year = e.target.value;
                                  setTurnoverData(copy);
                                }}
                                value={turnover.year}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const copy = [...turnoverData];
                                  copy.splice(i, 1);
                                  setTurnoverData(copy);
                                }}
                              >
                                remove
                              </Icon>
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={turnover.currency}
                                options={["USD", "AED", "INR"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Currency"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    const copy = [...turnoverData];
                                    copy[i].currency = newValue;
                                    setTurnoverData(copy);
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={5} md={6}>
                              <Autocomplete
                                id="country-select-demo"
                                size="small"
                                value={turnover.figures}
                                options={["Million", "Billion", "Crore"]}
                                classes={{
                                  endAdornment: classes.icon,
                                  root: {},
                                }}
                                // inputProps={{
                                //   classes: {
                                //     icon: classes.icon,
                                //     root:{}
                                //   },
                                // }}
                                autoHighlight
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(option) => (
                                  <React.Fragment>{option}</React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    label="Figures"
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    const copy = [...turnoverData];
                                    copy[i].figures = newValue;
                                    setTurnoverData(copy);
                                    // setBranchCountry(newValue);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <Grid
                     container
                     justifyContent="space-between"
                     spacing={1}
                     item
                     xs={12}
                     md={11}
                   >
                     <Grid item md={6}>
                       <CustomTextfield
                         name="NumOfEmployee"
                         variant="outlined"
                         fullWidth
                         size="small"
                         type="text"
                         label="User First Name"
                       />
                     </Grid>
                     <Grid item md={6}>
                       <CustomTextfield
                         name="NumOfEmployee"
                         variant="outlined"
                         fullWidth
                         size="small"
                         type="text"
                         label="User Last Name"
                       />
                     </Grid>
                   </Grid> */}
                    {/* <Grid item xs={12} md={5}>
    
     </Grid> */}
                    {/* <Grid item xs={12} md={11}>
                     <Box my={2}>
                       <CustomTextfield
                         name="NumOfEmployee"
                         variant="outlined"
                         fullWidth
                         size="small"
                         type="text"
                         label="Designation Of The Employee"
                       />
                     </Box>
                   </Grid> */}
                    {/* RM */}
                    <Grid item xs={12} md={11}>
                      <Autocomplete
                        id="country-select-demo"
                        size="small"
                        value={{
                          country_name: organizaitonDetailsObject?.country_name,
                        }}
                        options={brmData.countries}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.country_name}
                        renderOption={(option) => (
                          <React.Fragment>{option.country_name}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <CustomTextfield
                            {...params}
                            label="Choose a country"
                            variant="outlined"
                            // value={organizaitonDetailsObject?.country_name}
                            inputProps={{
                              ...params.inputProps,
                              // autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              country_name: newValue?.country_name,
                              country: newValue?.country_code,
                              // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                            }));
                            // setBranchCountry(newValue);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.country_name && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="ISIN Number"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              isin: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.isin}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {!organizaitonDetailsObject?.isin && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        onClick={() => setIsDatePickerDialogOpen(true)}
                        style={{
                          border: "1px solid #00000050",
                          padding: 12,
                          borderRadius: 5,
                          cursor: "pointer",
                          minHeight: 45,
                        }}
                      >
                        {organizaitonDetailsObject.year_of_assessment ? (
                          <Typography variant="body1" name="yearOfAssesment">
                            {`${
                              organizaitonDetailsObject.year_of_assessment.split(
                                "-"
                              )[0]
                            } - ${
                              organizaitonDetailsObject.year_of_assessment.split(
                                "-"
                              )[1]
                            }`}
                          </Typography>
                        ) : (
                          <Typography
                            style={{ color: "#878282e6", fontFamily: "Roboto" }}
                          >
                            Year Of Assessment
                          </Typography>
                        )}

                        {/* <Typography variant="body1" name="yearOfAssesment">
       {basicInfo.year_of_assessment[0].startDate !== "" &&
       basicInfo.year_of_assessment[0].endDate !== ""
         ? `${moment(
             new Date(basicInfo.year_of_assessment[0].startDate)
           ).format("DD MMM yy")} - ${moment(
             new Date(basicInfo.year_of_assessment[0].endDate)
           ).format("DD MMM yy")}`
         : "Year Of Assessment"}
     </Typography> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      {
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      }
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compOperation"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                          label="Company Operation"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              operation: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.operation}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Number of Employees"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              no_of_employees: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.no_of_employees}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} md={8}>
       <Box my={2}>
         <CustomTextfield
           name="contactEmail"
           variant="outlined"
           fullWidth
           size="small"
           label="Contact Email"
         />
       </Box>
     </Grid> 
     <Grid item xs={12} md={8}>
       <Box my={2}>
         <CustomTextfield
           name="website"
           variant="outlined"
           fullWidth
           size="small"
           label="Website"
         />
       </Box>
     </Grid>*/}

                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Address
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]
                              ?.companyAddress
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  companyAddress: e.target.value,
                                },
                              ],
                            }));
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          variant="outlined"
                          name="compLandmark"
                          fullWidth
                          size="small"
                          label="Landmark"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.landMark
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  landMark: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.pinCode
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  pinCode: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.state
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  state: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="compCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.country
                              ? organizaitonDetailsObject?.address[0]?.country
                              : ""
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setOrganizaitonDetailsObject((prev) => ({
                                ...prev,
                                address: [
                                  {
                                    ...prev?.address[0],
                                    country: newValue,
                                  },
                                ],
                              }));
                            }
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} md={11}>
                     <Box
                       my={2}
                       display="flex"
                       alignItems="center"
                       justifyContent="space-between"
                     >
                       <Typography className={classes.headerText}>
                         Branch Office Address
                       </Typography>
                       <CustomButton
                         name="addBranch"
                         size="medium"
                         // onClick={handleAddBranch}
                         variant="outlined"
                         color="primary"
                       >
                         Add Another Branch
                       </CustomButton>
                     </Box>
                     <Box my={2}>
                       <CustomTextfield
                         name="branchAddress"
                         variant="outlined"
                         fullWidth
                         size="small"
                         label="Street/Location"
                         // value={basicInfo.branchAddress || ""}
                         // onChange={(e) => setBranchAddress(e.target.value)}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <RoomOutlinedIcon
                                 fontSize="small"
                                 color="disabled"
                               />
                             </InputAdornment>
                           ),
                         }}
                       />
                     </Box>
                     <Box my={2}>
                       <CustomTextfield
                         name="branchLandmark"
                         variant="outlined"
                         fullWidth
                         size="small"
                         label="Landmark"
                         // value={basicInfo.branchLandmark || ""}
                         // onChange={(e) => setBranchLandmark(e.target.value)}
                       />
                     </Box>
                     <Box my={2}>
                       <CustomTextfield
                         name="branchPincode"
                         variant="outlined"
                         fullWidth
                         size="small"
                         label="Pincode"
                         type="number"
                         // value={basicInfo.branchPincode || ""}
                         // onChange={(e) => setBranchPincode(e.target.value)}
                       />
                     </Box>
                     <Box my={2}>
                       <CustomTextfield
                         name="branchState"
                         variant="outlined"
                         fullWidth
                         size="small"
                         label="State"
                         // value={basicInfo.branchState || ""}
                         // onChange={(e) => setBranchState(e.target.value)}
                       />
                     </Box>
                     <Box my={2}>
                       <Autocomplete
                         name="branchCountry"
                         id="countryCode"
                         options={countryList}
                         getOptionLabel={(option) => option}
                         renderOption={(option) => option}
                         size="small"
                         renderInput={(params) => (
                           <CustomTextfield
                             {...params}
                             label="Country"
                             variant="outlined"
                           />
                         )}
                         // value={basicInfo.branchCountry || ""}
                         onChange={(event, newValue) => {
                           if (newValue) {
                             // setBranchCountry(newValue);
                           }
                         }}
                       />
                     </Box>
                   </Grid> */}
                    <Grid item xs={12} md={12}>
                      <CustomButton
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                        onClick={() => {
                          props.updateOrganization(
                            login.token,
                            brmData?.organizationDetails[0]?._id
                              ? brmData?.organizationDetails[0]?._id
                              : localStorage.getItem("organization_id"),
                            organizaitonDetailsObject?.name,
                            organizaitonDetailsObject?.isin,
                            organizaitonDetailsObject?.year_of_assessment,
                            compayLogo,
                            sectors,
                            organizaitonDetailsObject?.operation,
                            organizaitonDetailsObject?.no_of_employees,
                            organizaitonDetailsObject?.address,
                            brmData.sectorIndustries,
                            revenueData.filter(
                              (d) =>
                                d.revenue !== "" &&
                                d.year !== "" &&
                                d.currency !== "" &&
                                d.figures !== ""
                            ),
                            turnoverData.filter(
                              (d) =>
                                d.turnover !== "" &&
                                d.year !== "" &&
                                d.currency !== "" &&
                                d.figures !== ""
                            ),
                            organizaitonDetailsObject?.country
                          );
                        }}
                      >
                        Update Portfolio
                      </CustomButton>
                      <CustomButton
                        onClick={() =>
                          brmData?.organizationDetails[0] &&
                          setOrganizaitonDetailsObject(
                            brmData?.organizationDetails[0]
                          )
                        }
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                      >
                        Cancel
                      </CustomButton>
                    </Grid>
                    <Grid xs={7}></Grid>
                    <Grid item xs={9}>
                      {/* {LocationStore} */}
                    </Grid>
                  </Grid>
                </div>
                {/* </CardContent>
                    </Card> */}
              </div>
              // <BasicAssessmentDetails
              //   sectors={sectors}
              //   setIsDatePickerDialogOpen={setIsDatePickerDialogOpen}
              //   organizaitonDetailsObject={organizaitonDetailsObject}
              //   setOrganizaitonDetailsObject={setOrganizaitonDetailsObject}
              // />
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
              <StakeholderList
                setAddNewUserDialogOpen={setAddNewUserDialogOpen}
                handleEditUser={handleEditUser}
                brmData={brmData}
              />
            )}
            {/* {brmData?.brmMainTabNo === 4 && (
              <TabPanel value={riskTabNo} index={3}>
                <div
                  className="scroll"
                  style={{ marginTop: 18, height: "60vh" }}
                >
                  <Charts {...props} />
                </div>
              </TabPanel>
            )} */}

            {/* old 0,2 */}
            {false &&
              brmData?.brmMainTabNo === 3 &&
              brmData?.brmSubTabNo === 0 && (
                <div style={{ height: "63vh" }} className={classes.mainDiv}>
                  <MuiThemeProvider theme={appTheme}>
                    <AppBar
                      position="static"
                      color="inherit"
                      className={classes.AppBar}
                    >
                      <Tabs
                        value={riskTabNo}
                        onChange={(e, newVal) => {
                          setRiskTabNo(newVal);
                        }}
                      >
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              ESG Disclosure
                            </span>
                          }
                        />
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              Sentiment Analysis
                            </span>
                          }
                        />
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              ESG Risk Summary
                            </span>
                          }
                        />
                      </Tabs>
                    </AppBar>
                  </MuiThemeProvider>
                  <TabPanel value={riskTabNo} index={0}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <RiskAnalysisRightTab {...props} />
                    </div>
                  </TabPanel>
                  <TabPanel value={riskTabNo} index={1}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <SentimentAnalysisRightTab {...props} />
                    </div>
                  </TabPanel>
                  <TabPanel value={riskTabNo} index={2}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <RiskSummaryTab {...props} />
                    </div>
                  </TabPanel>
                </div>
              )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                getSurveyeQuestions={props.getSurveyeQuestions}
                setShareDialogOpen={setShareDialogOpen}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}

            {/* PRIMART DATA RIGHT TABS */}
            {/* {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                setShareDialogOpen={setShareDialogOpen}
                getSurveyeQuestions={props.getSurveyeQuestions}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 1 && (
              <ControverciesQuestions
                setShareDialogOpen={setShareDialogOpen}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )} */}
            {/* PUBLIC INFORMATION RIGHT TABS */}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
              <ApiConnections {...props} />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 1 && (
              <Documents riskEvaluator={props?.riskEvaluator} {...props} />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 2 && (
              <PeerDocuments riskEvaluator={riskEvaluator} />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 3 && (
              <SurveyQuestions
                login={login}
                brmData={brmData}
                setShareDialogOpen={setShareDialogOpen}
                getSurveyeQuestions={props.getSurveyeQuestions}
                addSurveyeQuestions={props.addSurveyeQuestions}
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}

            {/* ESG DIAGNOSIS TABS */}

            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 0 && (
              <EsgCompantProfile {...props} />
            )}
            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 1 && (
              // <Controversy {...props} />
              <DataBreakdown {...props} />
            )}
            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 2 && (
              <PeerAnalysis {...props} />
            )}
            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 3 && (
              // <EsgDeclaration {...props} />
              <Contoversies {...props} />
            )}
            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 4 && (
              // <EsgDeclaration {...props} />
              <SurveyResponses {...props} />
            )}
            {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 0 && (
              <EsgRiskRating {...props} />
            )}
          </CardContent>
        )}
      </Card>
    </div>
  );
}

export default BrmDataRight;
