import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CyberRisk = ({ cyberRisks }) => {
  const dateData = cyberRisks?.dataLossHistory
    ?.map((item) => item?.date?.split("T")[0])
    ?.sort();

  const dataLoss = [...cyberRisks?.dataLossHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const defensibility = [...cyberRisks?.defensibilityHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const dnsSecurity = [...cyberRisks?.dnsSecurityHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const emailSecurity = [...cyberRisks?.emailSecurityHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const governance = [...cyberRisks?.governanceHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const networkFiltering = [...cyberRisks?.networkFilteringHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const softwarePatching = [...cyberRisks?.softwarePatchingHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const systemHosting = [...cyberRisks?.systemHostingHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const threatIntelligence = [...cyberRisks?.threatIntelligenceHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const webAppSecurity = [...cyberRisks?.webAppSecurityHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const webEncryption = [...cyberRisks?.webEncryptionHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const overall = [...cyberRisks?.overallHistory]
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => item.value);

  const data = {
    labels: dateData,
    datasets: [
      {
        label: "Data Loss",
        data: dataLoss,
        borderColor: "#2D99FF",
        fill: false,
      },
      {
        label: "Defensibility",
        data: defensibility,
        borderColor: "#83CFFF",
        fill: false,
      },
      {
        label: "DNS Security",
        data: dnsSecurity,
        borderColor: "#A5F3FF",
        fill: false,
      },
      {
        label: "Email Security",
        data: emailSecurity,
        borderColor: "#CCFAFF",
        fill: false,
      },
      {
        label: "Governance",
        data: governance,
        borderColor: "#FFE700",
        fill: false,
      },
      {
        label: "Network Filtering",
        data: networkFiltering,
        borderColor: "#FFEF5A",
        fill: false,
      },
      {
        label: "Software Patching",
        data: softwarePatching,
        borderColor: "#FFF7AE",
        fill: false,
      },
      {
        label: "System Hosting",
        data: systemHosting,
        borderColor: "#FFF3D6",
        fill: false,
      },
      {
        label: "Threat Intelligence",
        data: threatIntelligence,
        borderColor: "#2CD9C5",
        fill: false,
      },
      {
        label: "Web App Security",
        data: webAppSecurity,
        borderColor: "#75B833",
        fill: false,
      },
      {
        label: "Web Encryption",
        data: webEncryption,
        borderColor: "#66CC00",
        fill: false,
      },
      {
        label: "Overall",
        data: overall,
        borderColor: "#36A300",
        fill: false,
      },
      // Add other datasets similarly for the remaining labels
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "Recon Rating",
      },
      tooltip: {
        callbacks: {
          // Customizing tooltips to show all datasets on the same date
          label: function (context) {
            const datasetIndex = context.datasetIndex;
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
          title: (tooltipItems) => {
            // Show the full date in tooltip
            return tooltipItems[0].label;
          },
        },
        mode: "index", // This ensures that it displays all data points for the same index
        intersect: false, // Show tooltip even if the point is not directly hovered
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (val, index, ticks) {
            const label = this.getLabelForValue(val);
            const year = label.slice(0, 4); // Extract the year

            // Only show the year if it's the first occurrence of that year
            if (
              year !==
              this.getLabelForValue(ticks[index - 1]?.value)?.slice(0, 4)
            ) {
              return label;
            }

            // console.log(
            //   year !==
            //   this.getLabelForValue(ticks[index - 1]?.value)?.slice(0, 4)
            // );

            // console.log(this.getLabelForValue(ticks[index - 1]), year);
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 10,
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default CyberRisk;
