import React, { useEffect, useState } from "react";

import {
  AppBar,
  Button,
  Drawer,
  FormLabel,
  MenuItem,
  MuiThemeProvider,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import TabPanel from "../../../../../../../../UI/tabPanel/tabPanel";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../../../../../../../UI/textfield/textfield";
import { useDispatch, useSelector } from "react-redux";
import { brmRightStyles } from "../../../../../../../riskAssessment/brmDataRight.js/brmDataRight";
import CustomButton from "../../../../../../../../UI/button/button";

const riskDomains = [
  { title: "Capacity", value: "Capacity" },
  { title: "Competition", value: "Competition" },
  { title: "Corporate Compliance", value: "Corporate Compliance" },
  { title: "Corruption", value: "Corruption" },
  { title: "Data Privacy", value: "Data Privacy" },
  { title: "ESG/Sustainability", value: "ESG/Sustainability" },
  {
    title: "Event Mapping and Monitoring",
    value: "Event Mapping and Monitoring",
  },
  { title: "Financial", value: "Financial" },
  { title: "Geographic", value: "Geographic" },
  {
    title: "Import/Export and Sanctions",
    value: "Import/Export and Sanctions",
  },
  { title: "Operational/Continuity", value: "Operational/Continuity" },
  { title: "Performance", value: "Performance" },
  { title: "Regulatory Compliance", value: "Regulatory Compliance" },
  { title: "Security/Cyber", value: "Security/Cyber" },
  { title: "Vendor Strategy", value: "Vendor Strategy" },
  {
    title: "Workplace Health and Safety",
    value: "Workplace Health and Safety",
  },
];

export default function RiskDrawer({
  toggleDrawer,
  handleToggleRiskDrawer,
  setRiskData,
  riskData,
  handleRiskData,
}) {
  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const { bankId } = useSelector((state) => state?.login);

  const { members, showSelectedSurvey, vendorBasicDetails } = useSelector(
    (state) => state?.brmData
  );

  const { _id: vendorId } = vendorBasicDetails;

  const handleStateReset = () => {
    setRiskData({
      finding: "",
      risk: "",
      description: "",
      severity: "",
      enableCompensationControl: false,
    });
    handleToggleRiskDrawer();
  };

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => handleStateReset()}
      width="lg"
      open={toggleDrawer}
      style={{ position: "relative" }}
    >
      <div style={{ width: "550px", position: "relative", padding: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "fit-content",
          }}
        >
          <Typography
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            Add Risk Element
          </Typography>
          <Typography
            onClick={() => handleStateReset()}
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
          </Typography>
        </div>

        <div
          className="scroll"
          style={{ marginTop: 18, height: "60vh", paddingTop: 5 }}
        >
          <div style={{ marginBottom: "15px" }}>
            <FormLabel style={{ fontSize: 14, marginBottom: "15px" }}>
              Finding
            </FormLabel>
            <CustomTextfield
              style={{ marginTop: "10px" }}
              name="finding"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleRiskData(e)}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={riskData?.finding}
            />
          </div>

          <div style={{ marginBottom: "15px" }}>
            <FormLabel style={{ fontSize: 14, marginBottom: "15px" }}>
              Risk
            </FormLabel>
            <CustomTextfield
              style={{ marginTop: "10px" }}
              name="risk"
              //   label="Choose the required frequency"
              variant="outlined"
              size="small"
              fullWidth
              select
              value={riskData?.risk}
              onChange={(e) => handleRiskData(e)}
            >
              {riskDomains?.map((cat, i) => (
                <MenuItem key={i} value={cat?.value}>
                  {cat?.title}
                </MenuItem>
              ))}
            </CustomTextfield>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <FormLabel style={{ fontSize: 14, marginBottom: "15px" }}>
              Description
            </FormLabel>
            <CustomTextfield
              style={{ marginTop: "10px" }}
              name="description"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleRiskData(e)}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={riskData?.description}
            />
          </div>

          <div style={{ marginBottom: "15px" }}>
            <FormLabel style={{ fontSize: 14, marginBottom: "15px" }}>
              Severity
            </FormLabel>
            <CustomTextfield
              style={{ marginTop: "10px" }}
              name="severity"
              //   label="Choose the required frequency"
              variant="outlined"
              size="small"
              fullWidth
              select
              value={riskData?.severity}
              onChange={(e) => handleRiskData(e)}
            >
              {[
                { title: "High", value: "High" },
                { title: "Medium", value: "Medium" },
                { title: "Low", value: "Low" },
              ].map((cat, i) => (
                <MenuItem key={i} value={cat?.value}>
                  {cat?.title}
                </MenuItem>
              ))}
            </CustomTextfield>
          </div>
          <div>
            <Switch
              color="primary"
              name="enableCompensationControl"
              checked={riskData?.enableCompensationControl}
              onChange={(e) => handleRiskData(e)}
            />
            <FormLabel style={{ fontSize: 14 }}>
              Enable Compensation Control
            </FormLabel>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
      >
        <CustomButton
          style={{
            textTransform: "none",
            color: "#3374B9",
            marginRight: "10px",
          }}
          onClick={() => handleStateReset()}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => handleToggleRiskDrawer()}
          //   disabled={!handleDisableButton}
        >
          Add Risk
        </CustomButton>
      </div>
    </Drawer>
  );
}
