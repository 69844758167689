import { gql } from "@apollo/client";

export const GQL_GET_SHARED_SURVEY_DETAILS = gql`
  query SharedSurveyDetails(
    $bankId: String!
    $surveyIds: [String!]
    $vendorId: String!
  ) {
    sharedSurveyDetails(
      sharedSurveyDetailsInput: {
        bankId: $bankId
        surveyIds: $surveyIds
        vendorId: $vendorId
      }
    ) {
      _id
      chatRoomId
      dateOfAssessment
      isCompleted
      surveyName
      type
      score
    }
  }
`;

export const GQL_GET_ALERT = gql`
  query GetAlert($widgetName: [Widgets!]!) {
    getAlert(getAlertInput: { widgetName: $widgetName }) {
      _id
      active
      bankId
      comment
      frequencyType
      lowerBounds
      name
      nextAlertDate
      pause
      upperBounds
      vendorId
      widgetName
    }
  }
`;

export const GQL_CREATE_ALERT = gql`
  mutation CreateAlert(
    $vendorId: String!
    $surveyId: String
    $comment: String!
    $frequencyType: FrequencyType!
    $lowerBounds: Float!
    $name: String!
    $upperBounds: Float!
    $widgetName: Widgets!
    $stepName: StepName!
  ) {
    createAlert(
      createAlertInput: {
        vendorId: $vendorId
        surveyId: $surveyId
        comment: $comment
        frequencyType: $frequencyType
        lowerBounds: $lowerBounds
        name: $name
        upperBounds: $upperBounds
        widgetName: $widgetName
        stepName: $stepName
      }
    ) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_ALERT = gql`
  mutation UpdateAlert($updatedAlerts: [UpdateAlertInput!]!) {
    updateAlert(updateAlertInput: $updatedAlerts) {
      message
      success
    }
  }
`;

// termination and offboarding
export const GQL_GET_TERMINATION_EXIT_PLAN = gql`
  query GetLatestSubmittedSurveyResponse(
    $bankId: ID!
    $surveyId: ID!
    $vendorId: ID!
  ) {
    getLatestSubmittedSurveyResponse(
      getLatestSubmittedSurveyResponseInput: {
        bankId: $bankId
        surveyId: $surveyId
        vendorId: $vendorId
      }
    ) {
      sectionDescription
      sectionId
      sectionTitle
      questions {
        order
        question
        questionId
        questionType
        weightage
        answers {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
        }
        approvedAnswer {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
        }
        approvedTableRows {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
          questionRisk {
            description
            enableCompensationControl
            finding
            risk
            severity
          }
        }
      }
    }
  }
`;
