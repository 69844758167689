import React, { useEffect } from "react";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import { Card, Grid, ListItem, Paper, Typography } from "@material-ui/core";
import folder from "../../../images/Folder.png";
import charge from "../../../images/charge.png";
import heart from "../../../images/SelfAssessment.png";
import DonutChart from "react-donut-chart";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_controversies_score,
  gql_get_default_surveys,
  rest_get_controversies_details,
  rest_get_esg_score,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_CONTROVERSIES_SCORE } from "../../../constants/esgDiagnosisConstant";
import { SET_SHOW_SURVEYS } from "../../../constants/brmConstants";
function ControversyProfile(props) {
  const { esgScore, controversiesDetails, controversiesScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { token } = useSelector((state) => state.login);

  const { showSurveys, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );
  const { bankId } = useSelector((state) => state.login);
  const storedVendorIsin = localStorage.getItem("vendorIsin");
  const storedVendorId = localStorage.getItem("vendorId");
  const storedBankId = localStorage.getItem("bank_id");

  const dispatch = useDispatch();

  const survey = showSurveys.find(
    (survey) => survey.name.toLowerCase() === "default survey"
  );

  useEffect(() => {
    dispatch(gql_get_default_surveys(bankId || storedBankId));
  }, []);

  useEffect(() => {
    const getScoreInput = {
      vendorId: vendorBasicDetails?._id || storedVendorId,
      surveyId: survey?._id,
    };
    dispatch(gql_get_controversies_score(getScoreInput));

    return () => {
      dispatch({ type: SET_CONTROVERSIES_SCORE, payload: null });
      dispatch({ type: SET_SHOW_SURVEYS, payload: [] });
    };
  }, [survey]);

  useEffect(() => {
    dispatch(
      rest_get_esg_score(vendorBasicDetails?.isin || storedVendorIsin, token)
    );
    dispatch(
      rest_get_controversies_details(
        vendorBasicDetails?.isin || storedVendorIsin,
        token
      )
    );
  }, []);

  const { riskEvaluator } = props;
  const CircularChart = ({ score, title, color }) => {
    return (
      <Card style={{ margin: "10px 5px", padding: "5px", width: "45%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            width: "100%",
            // marginTop: "10px",
            // marginLeft: "10px",
            padding: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={heart}
              alt="charge"
              style={{ width: "40px", height: "40px" }}
            />

            <DonutChart
              // outerRadius={0.5}
              // innerRadius={0.9}

              className="donutchart-innertext-value"
              height={150}
              width={150}
              legend={false}
              emptyOffset={0.01}
              emptyColor="#F9EEED"
              formatValues={(values, total) => `${score}`}
              colors={[color ? color : "#9ABD8B"]}
              innerRadius={0.7}
              outerRadius={0.8}
              data={[
                {
                  label: "",
                  value: score,
                },
                {
                  label: "",
                  value: 100 - score,
                  isEmpty: true,
                },
              ]}
            />
            {/* <Doughnut data={data} options={options} plugins={plugins} /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color ? color : "#9ABD8B",
                borderRadius: "100%",
                width: "12px",
                height: "12px",
              }}
            />
            <Typography style={{ marginLeft: "10px" }}>{title}</Typography>
          </div>
        </div>
      </Card>
    );
  };

  const negativeControversies = {
    "Anti-competition controversy": 0,
    "Business ethics controversies": 0,
    "Intellectual property controversies": 0,
    "Critical countries controversies": 0,
    "Public health controversies": 0,
    "Tax fraud controversies": 0,
    "Child labour controversies": 0,
    "Human rights controversies": 0,
    "Management compensation controversies count": 0,
    "Consumer controversies": 0,
    "Customer health and safety controversies": 0,
    "Privacy controversies": 0,
    "Product access controversies": 0,
    "Responsible marketing controversies": 0,
    "Responsible R&D controversies": 0,
    "Environmental controversies": 0,
    "Accounting controversies count": 0,
    "Insider dealings controversies": 0,
    "Shareholder rights controversies": 0,
    "Diversity and opportunity controversies": 0,
    "Employee health and safety controversies": 0,
    "Wages or working conditions controversies": 0,
    Strikes: 0,
    "Management compensation controversies": 0,
  };

  const positiveControversies = {
    "Environmental Sustainability Initiatives": 0,
    "Carbon Emissions Reduction Efforts": 0,
    "Sustainable Sourcing Practices": 0,
    "Waste Reduction and Recycling Programs": 0,
    "Community Engagement and Philanthropy": 0,
    "Employee Diversity and Inclusion Programs": 0,
    "Fair Labor Practices and Employee Benefits": 0,
    "Ethical Supply Chain Management": 0,
    "Transparency and Accountability in Governance": 0,
    "Board Diversity and Independence": 0,
    "Responsible Corporate Citizenship": 0,
    "Stakeholder Engagement and Dialogue": 0,
    "Innovation in Sustainable Technologies": 0,
    "Social Impact Investing and Financing": 0,
    "Climate Change Adaptation Strategies": 0,
    "Water Conservation and Management Practices": 0,
    "Biodiversity Conservation Efforts": 0,
    "Product Safety and Quality Assurance": 0,
    "Health and Wellness Programs for Employees and Customers": 0,
  };

  return (
    <Grid container spacing={2}>
      {
        <Grid item md={12}>
          <BlueBackgroundCard heading={"Digital Reputation Score"} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card style={{ padding: "5px", width: "100%" }}>
              {/* <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
            ESG Controversy Score
          </Typography> */}
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "13px",
                  color: "#15314E",
                  marginLeft: "10px",
                }}
              >
                Review the digital reputation score assigned to vendors based on
                their history. Get a quick gauge of their overall risk level.
              </Typography>
              <div
                style={{
                  display: "flex",
                  minHeight: "42vh",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "space-between",
                    padding: "10px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "40px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#9ABD8B",
                        borderRadius: "100%",
                        width: "10px",
                        height: "10px",
                      }}
                    />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Digital Reputation Score
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                    }}
                  >
                    <img
                      src={charge}
                      alt="charge"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <PercentageDoughnutChart
                      color="#9ABD8B"
                      emptyColor="#EBF6EF"
                      percentage={
                        esgScore?.length > 0
                          ? Math.round(esgScore[0]?.esgcScore)
                          : 0
                      }
                      customSize={200}
                      footPrint={false}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",

                    justifyContent: "center",
                    padding: "10px 30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "10px",
                        height: "10px",
                      }}
                    />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                        // textAlign: "center",
                      }}
                    >
                      Online Sentiment Score
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      src={heart}
                      alt="charge"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <PercentageDoughnutChart
                      color="#ECA270"
                      emptyColor="#F9EEED"
                      percentage={
                        controversiesScore?.score
                          ? Math.round(controversiesScore?.score)
                          : 0
                      }
                      customSize={200}
                      footPrint={false}
                    />
                  </div>
                </div>
              </div>
            </Card>

            {/* <div style={{ width: "30%", padding: "5px" }}>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Deviation Percentage
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: "24px", padding: "16px 0px" }}
          >
            12%
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            The deviation may indicate a gap between a company's actual ESG
            practices and what is being publicly disclosed, highlighting the
            need for improved transparency and accountability.
          </Typography>
        </div> */}
          </div>
        </Grid>
      }

      <Grid item md={6} style={{ height: "60vh", paddingBottom: "25px" }}>
        <BlueBackgroundCard heading={"Negative Topic"} sideHeading="Count" />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {(controversiesDetails?.negative || negativeControversies) &&
            Object.entries(
              controversiesDetails?.negative ?? negativeControversies
            ).map(([key, value], i) => (
              <Grid
                key={i}
                container
                justifyContent="space-between"
                style={{
                  padding: 10,
                  backgroundColor: "#FBFBFB",
                  borderBottom: "1px solid #EBF1F8",
                }}
              >
                <Grid item md={10}>
                  <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </div>
      </Grid>

      <Grid item md={6} style={{ height: "60vh", paddingBottom: "25px" }}>
        <BlueBackgroundCard heading={"Positive Topic"} sideHeading="Count" />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {(controversiesDetails?.positive || positiveControversies) &&
            Object.entries(
              controversiesDetails?.positive ?? positiveControversies
            ).map(([key, value], i) => (
              <Grid
                key={i}
                container
                justifyContent="space-between"
                style={{
                  padding: 10,
                  backgroundColor: "#FBFBFB",
                  borderBottom: "1px solid #EBF1F8",
                }}
              >
                <Grid item md={10}>
                  <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </div>
      </Grid>
      {/* <Typography style={{ marginTop: 15, fontSize: "15px", fontWeight: 600 }}>
        Controversy Count
      </Typography>
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "13px",
          color: "#15314E",
          paddingBottom: 10,
        }}
      >
        Our Controversy Count provides valuable insights into your ESG
        Controversy practices, helping you meet stakeholder expectations and
        work towards a more sustainable future.
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {props.riskEvaluator.controversyData &&
          Object.entries(props.riskEvaluator.controversyData).map(
            ([key, value]) => (
              <>
                <CircularChart
                  // score={45}
                  score={Math.round(value.toFixed(0))}
                  title={key}
                  color="#4dc9f6"
                />
              </>
            )
          )}
      </div> */}
    </Grid>
  );
}

export default ControversyProfile;
