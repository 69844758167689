import React, { useState } from "react";
import { Typography, Box, makeStyles, Button } from "@material-ui/core";
import { Add, Share } from "@material-ui/icons";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import CustomButton from "../../../UI/button/button";
import Introduction from "./Introduction";
import VendorBackground from "./VendorBackground";
import VendorContractDetails from "./VendorContractDetails";

import VendorIntake from "components/widgets/vendorDiagnosis_v2/vendorIntake";
import TprmRiskCard from "./TprmRiskCard";
import RiskAssessment from "components/widgets/vendorDiagnosis_v2/riskAssessment";
import DueDiligence from "components/widgets/vendorDiagnosis_v2/dueDiligence";
import Contracting from "components/widgets/vendorDiagnosis_v2/contracting";
import Monitoring from "components/widgets/vendorDiagnosis_v2/monitorning";
import TerminationAndOffboarding from "components/widgets/vendorDiagnosis_v2/terminationAndOffboarding";
import TprmRiskReportPdf from "../trpmRiskReportPdf/TprmRiskReportPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#32A05F",
    },
    "&$checked + $track": {
      backgroundColor: "#32A05F",
    },
  },
  checked: {},
  track: {},
  // button: {
  //   borderColor: "#3374B9",
  //   color: "#3374B9",
  //   "&:hover": {
  //     backgroundColor: "#e6f2ff",
  //     borderColor: "#3374B9",
  //   },
  // },
  chip: {
    backgroundColor: "#BFE2CD",
    color: "#195a11",
    // borderColor: "#195a11",
    // border: "1px solid",
    // "& .MuiChip-label": {
    //   color: "#195a11",
    // },
  },
}));

const TprmRiskReport = () => {
  const classes = useStyles();

  const [pdfUrl, setPdfUrl] = useState(null);

  return (
    <Box>
      {/* Final Recommendation Header */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>
          Final Recommendation
        </Typography>
        <Box display={"flex"} gridGap={"20px"}>
          <CustomButton
            size="small"
            color="primary"
            startIcon={<OpenInNewRoundedIcon />}
          >
            Export
          </CustomButton>

          <CustomButton size="small" color="primary" startIcon={<Share />}>
            Share
          </CustomButton>

          <CustomButton
            size="small"
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Add Section
          </CustomButton>
        </Box>
      </Box>

      <Box className="scroll">
        {/* <Button onClick={generatePDF}>Generate PDF</Button>
        {pdfUrl && (
          <div>
            <h3>PDF Preview:</h3>
            <Document file={pdfUrl}>
              <Page pageNumber={1} />
            </Document>
          </div>
        )} */}
        {/* <PDFDownloadLink
          document={<TprmRiskReportPdf />}
          fileName="Tprm Report"
        >
          {({ loading }) =>
            loading ? "Loading..." : <Button>Download</Button>
          }
        </PDFDownloadLink> */}

        {/* <PDFViewer style={{ width: "100%", height: "100%" }}>
          <TprmRiskReportPdf />
        </PDFViewer> */}
        {/* Introduction Section */}
        <Introduction classes={classes} />

        {/* Vendor Background Section */}
        <VendorBackground classes={classes} />

        {/* Vendor Contact Details */}
        <VendorContractDetails classes={classes} />

        {/* Vendor Intake/Scoping */}
        <TprmRiskCard heading={"Vendor Intake/Scoping"}>
          <VendorIntake isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Risk Assessment */}
        <TprmRiskCard heading={"Risk Assessment"}>
          <RiskAssessment isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Due Diligence */}
        <TprmRiskCard heading={"Due Diligence"}>
          <DueDiligence isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Contracting */}
        <TprmRiskCard heading={"Contracting"}>
          <Contracting isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Monitoring */}
        <TprmRiskCard heading={"Monitoring"}>
          <Monitoring isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Termination And Offboarding */}
        <TprmRiskCard heading={"Termination And Offboarding"}>
          <TerminationAndOffboarding isFrom={"tprmRiskReport"} />
        </TprmRiskCard>

        {/* Assessment Findings */}
        {/* <AssessmentFindings classes={classes} /> */}

        {/* Final Recommendation */}
        {/* <FinalRecommendation classes={classes} /> */}
      </Box>
    </Box>
  );
};

export default TprmRiskReport;
