import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

function CreditScores({ scores, classes }) {
  return (
    <Grid style={{ marginTop: "10px", marginLeft: "10px" }}>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Credit Score:</span>
        {"  "}
        {scores?.creditScore}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> International Score:</span>
        {"  "}
        {scores?.internationalScore}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Previous Credit Rating:</span>
        {"  "}
        {scores?.previousCreditRating}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Latest Rating Change Date:</span>
        {"  "}
        {scores?.latestRatingChangeDate
          ? moment(scores?.latestRatingChangeDate).format("Do MMM, YYYY")
          : "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Credit Limit:</span>
        {"  "}
        {scores?.creditLimit || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Contract Limit:</span>
        {"  "}
        {scores?.contractLimit || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Status:</span>
        {"  "}
        {scores?.companyStatus || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Days Beyond Term:</span>
        {"  "}
        {scores?.dbt || "N/A"}
      </Typography>
      <Typography className={classes.fontSize}>
        <span className={classes.fontBold}> Industry Days Beyond Term:</span>
        {"  "}
        {scores?.industryDbt || "N/A"}
      </Typography>
    </Grid>
  );
}

export default CreditScores;
