import { Box } from "@material-ui/core";
import VendorScoreAlert from "./VendorScoreAlert";
import Section from "./AlertSections";

const SetAlert = ({ setAlert, updateAlert, setUpdateAlert, widgetNames }) => {
  const wdNames = widgetNames?.map((name) => name?.split("_").join(" "));

  const getAlertsData = (widgetName) => {
    widgetName = widgetName?.split(" ").join("_").toUpperCase();
    return (
      updateAlert?.filter((e) => e.active && e.widgetName === widgetName) || []
    );
  };

  return (
    <Box>
      {wdNames?.map((sec) => (
        <Section
          key={sec}
          title={sec}
          content={
            <VendorScoreAlert
              scoreAlerts={getAlertsData(sec)}
              updateAlert={updateAlert}
              setUpdateAlert={setUpdateAlert}
            />
          }
          setAlert={setAlert}
        />
      ))}
    </Box>
  );
};

export default SetAlert;

// --------------------------- Additional ---------------------------
