import React from "react";

import { Box, Typography } from "@material-ui/core";

function WidgetComponent({
  title,
  icon,
  children,
  handleShowRisk,
  setModalText,
  setPopup,
  updateWindowText,
  setAlertName,
}) {
  function severity(value, type) {
    if (type === "text") {
      return value >= 0 && value < 1.5
        ? "#154328"
        : value >= 1.5 && value < 3.5
        ? "#8C7038"
        : "#52231D";
    }

    if (type === "bg") {
      return value >= 0 && value < 1.5
        ? "#BFE2CD"
        : value >= 1.5 && value < 3.5
        ? "#FFEFD0"
        : "#EDCAC6";
    }

    if (type === "name") {
      return value >= 0 && value < 1.5
        ? "Low"
        : value >= 1.5 && value < 3.5
        ? "Medium"
        : "High";
    }
  }
  return (
    <Box
      style={{
        border: "2px solid #EBF1F8",
        backgroundColor: "#F6F6F6",
        flex: 1,
        padding: "10px",
        borderRadius: "8px",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Box>{children}</Box>
        <Box>{icon}</Box>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ color: "#505050", fontSize: "16px", fontWeight: 400 }}
        >
          {title}
        </Typography>

        {(updateWindowText?.alertName === "AVERAGE_VENDOR_RISK" ||
          updateWindowText?.alertName === "AVERAGE_ESG_RISK") &&
          handleShowRisk?.threshold &&
          !handleShowRisk?.pause && (
            <Typography
              variant="body2"
              style={{
                color: severity(handleShowRisk?.threshold, "text"),
                backgroundColor: severity(handleShowRisk?.threshold, "bg"),
                padding: "6px 40px",
                textAlign: "center",
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={() => {
                setPopup(true);
                setModalText({
                  heading: updateWindowText?.heading,
                  buttonText: "Update Alert",
                  message: "Alert has already been set, you can update it.",
                });
                setAlertName(updateWindowText?.alertName);
              }}
            >
              Risk Alert:{" "}
              <span style={{ fontWeight: 500 }}>
                {severity(handleShowRisk?.threshold, "name")}
              </span>
            </Typography>
          )}
        {(updateWindowText?.alertName === "AVERAGE_VENDOR_RISK" ||
          updateWindowText?.alertName === "AVERAGE_ESG_RISK") &&
          handleShowRisk?.pause && (
            <Typography variant="body2">
              Risk Alert: <span style={{ fontWeight: 500 }}>Paused</span>
            </Typography>
          )}
      </Box>
    </Box>
  );
}

export default WidgetComponent;
