import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import debounce from "lodash.debounce";
import CustomTextfield from "./../../UI/textfield/textfield";
import "../../../styles/settingScroll.css";
import CustomButton from "./../../UI/button/button";
import SearchIcon from "@material-ui/icons/Search";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { emailValidation } from "../../../redux/actions/regexValidation/regexValidation";
import userSettingStyles from "./userSettingStyles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { set_snack_bar } from "../../../redux/actions/snackbar/snackbar_action";
import {
  gql_add_user,
  gql_change_user_pos,
  gql_get_users,
  gql_remove_user,
} from "../../../redux/actions/generalSettings/generalSettingsAction";
import defaultUser from "../../../images/Default_user.svg";

export default function UserSettings(props) {
  const classes = userSettingStyles();

  // const {
  //   login,
  //   generalSettings,
  //   viewAllUsers,
  //   updateUserPosition,
  //   deleteUserFromOrganisation,
  //   set_snack_bar,

  //   addUser
  // } = props;
  const { generalSettings, login } = useSelector((state) => state);
  const dispatch = useDispatch();
  const stakeHolderCategoryList = [
    "Board of Directors",
    "Executive Team",
    "Employees",
    "Investors & Shareholders",
    "Suppliers & Vendors",
    "Consultants",
    "Customers",
    "Government Agencies",
  ];

  console.log(login);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isChangeUserDialogOpen, setIsChangeUserDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [updatedUserType, setUpdatedUserType] = useState("");
  const [keyword, setkeyword] = useState("");

  const [stakeHolderName, setStakeHolderName] = useState("");
  const [stakeHolderEmail, setStakeHolderEmail] = useState("");
  const [stakeHolderCategory, setStakeHolderCategory] = useState("");
  const [stakeHolderPosition, setStakeHolderPosition] = useState("");
  const [stakeHolderDesignation, setStakeHolderDesignation] = useState("");
  const [stakeHolderOrganization, setStakeHolderOrganization] = useState("");
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const handleCloseAddNewDialog = () => {
    setAddNewUserDialogOpen(false);
  };

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };
  useEffect(() => {
    dispatch(gql_get_users());
  }, []);

  const handleAddStakeholder = () => {
    if (!validateEmail(stakeHolderEmail)) {
      dispatch(set_snack_bar(true, "Please enter a valid email"));
    } else if (stakeHolderName === "") {
      dispatch(set_snack_bar(true, "please enter the name"));
    } else if (stakeHolderCategory === "") {
      dispatch(set_snack_bar(true, "please enter the category"));
    } else if (stakeHolderDesignation === "") {
      dispatch(set_snack_bar(true, "please enter the role"));
    } else {
      let addMemberInput;

      if (login?.user_type === "BA" || login?.user_type === "BSA") {
        addMemberInput = {
          category: stakeHolderCategory,
          designation: stakeHolderDesignation,
          email: stakeHolderEmail,
          name: stakeHolderName,
          user_type: "BG",
        };
      } else {
        addMemberInput = {
          category: stakeHolderCategory,
          designation: stakeHolderDesignation,
          email: stakeHolderEmail,
          name: stakeHolderName,
        };
      }
      dispatch(gql_add_user(addMemberInput));

      setStakeHolderName("");
      setStakeHolderEmail("");
      setStakeHolderDesignation("");
      setStakeHolderCategory("");
      setStakeHolderPosition("");
      setStakeHolderOrganization("");

      handleCloseAddNewDialog();
    }
  };

  useEffect(() => {
    // viewAllUsers(login.token, login.current_organisation, keyword);
  }, [login.current_organisation]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedFetchData = useMemo(
    () =>
      debounce((query) => {
        // viewAllUsers(login.token, login.current_organisation, query);
      }, 1000),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const searchResult = generalSettings?.users.filter((user) =>
    user?.name?.toLowerCase()?.includes(keyword?.toLowerCase())
  );

  const deleteUserDialog = () => {
    return (
      <Dialog open={isDeleteDialogOpen} maxWidth="xs">
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <Typography className={classes.Typography}>
                Delete User
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <Typography className={classes.Typography1}>
                  Are you sure you want to delete the particular user.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item xs={3} className={classes.grid}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setIsDeleteDialogOpen(false);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton}
                onClick={() => {
                  dispatch(gql_remove_user(currentUser._id));
                  setIsDeleteDialogOpen(false);
                }}
              >
                Delete
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const changeUserPositionDialog = () => {
    return (
      <Dialog open={isChangeUserDialogOpen} maxWidth="xs">
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <Typography className={classes.Typography}>
                Change Settings
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <Typography className={classes.Typography1}>
                  Are you sure you want to change the position settings to the
                  particular user.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item xs={3} className={classes.grid}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setIsChangeUserDialogOpen(false);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton}
                onClick={() => {
                  const newUserType =
                    updatedUserType === "Admin"
                      ? "BA"
                      : updatedUserType === "Survey Admin"
                      ? "BSA"
                      : "BG";

                  dispatch(
                    gql_change_user_pos({
                      userId: currentUser._id,
                      type: newUserType,
                    })
                  );
                  setIsChangeUserDialogOpen(false);
                }}
              >
                Change
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid1}>
            <Grid item xs={3}>
              <Typography className={classes.Typography}>
                Add New User
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid3}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderName}
                onChange={(e) => setStakeHolderName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderDesignation}
                onChange={(e) => setStakeHolderDesignation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid3}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                error={
                  stakeHolderEmail !== "" && !validateEmail(stakeHolderEmail)
                }
                helperText={
                  !validateEmail(stakeHolderEmail) &&
                  stakeHolderEmail !== "" &&
                  "Please enter a valid email"
                }
                fullWidth
                value={stakeHolderEmail}
                onChange={(e) => setStakeHolderEmail(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userOrg"
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderOrganization}
                onChange={(e) => setStakeHolderOrganization(e.target.value)}
              />
            </Grid> */}
            {/* <Grid item xs={12}></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid3}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={stakeHolderCategory}
                onChange={(e) => setStakeHolderCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userPosition"
                label="Position"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={stakeHolderPosition}
                onChange={(e) => setStakeHolderPosition(e.target.value)}
              >
                {["BA", "VA", "VG"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid5}>
            <Grid item xs={3} md={6} className={classes.grid6}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={handleCloseAddNewDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid7}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton}
                // disabled={
                //   stakeHolderName === "" ||
                //   stakeHolderEmail === "" ||
                //   stakeHolderCategory === "" ||
                //   // stakeHolderPosition === "" ||
                //   stakeHolderDesignation === ""
                //   // stakeHolderOrganization === ""
                // }
                onClick={() => {
                  handleAddStakeholder();
                }}
              >
                Add
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {deleteUserDialog()}
      {changeUserPositionDialog()}
      {addNewUserDialog()}
      <Card variant="outlined" className={classes.Card1}>
        <CardContent className={`${classes.userContent}`}>
          {/* Title and search */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.Typography2}>
                User Settings
              </Typography>
              <div className={classes.grid8}>
                <Typography>
                  These settings will only be applicable to this account
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                name="userSearch"
                size="small"
                fullwidth="true"
                variant="outlined"
                label="search"
                className={classes.CustomTextfield}
                value={keyword}
                onChange={(e) => {
                  setkeyword(e.target.value);
                  debouncedFetchData(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item xs={4} md={2}>
              <CustomButton
                name="addNew"
                variant="outlined"
                color="primary"
                onClick={() => setAddNewUserDialogOpen(true)}
              >
                Add Users
              </CustomButton>
            </Grid>
          </Grid>

          {/* User List */}
          {searchResult?.length > 0 && (
            <Grid
              container
              className={classes.grid9}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item lg={1} md={1}></Grid>
              <Grid item xs={5} md={7}>
                <Typography variant="body1" className={classes.Typography3}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={5} md={4}>
                <Typography variant="body1" className={classes.Typography3}>
                  Position
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            className="scroll"
            style={{ height: "44vh", paddingBottom: 35 }}
          >
            {searchResult?.length > 0 &&
              searchResult?.map((user, index) => {
                return (
                  <div key={index}>
                    <Grid
                      container
                      className={clsx(classes.container, classes.grid5)}
                      alignItems="center"
                    >
                      <Grid item md={1} sm={0} xs={0}></Grid>
                      {/* User info */}
                      <Grid item container xs={8} sm={8} md={7}>
                        <Grid item xs={3} sm={3} md={3} lg={2}>
                          <Avatar
                            src={
                              user.profileImg?.url !== ""
                                ? user.profileImg.url
                                : defaultUser
                            }
                            className={classes.Avatar}
                          />
                        </Grid>
                        <Grid item container xs={9} sm={9} md={9} lg={9}>
                          <Grid item xs={12}>
                            <Typography variant="body1">{user.name}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              {user.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* User position */}
                      <Grid item xs={3} sm={3} md={3}>
                        <CustomTextfield
                          name="switchUser"
                          variant="outlined"
                          select
                          fullwidth="true"
                          size="small"
                          value={
                            user?.user_type.charAt(1) === "A"
                              ? "Admin"
                              : user?.user_type.charAt(1) === "S"
                              ? "Survey Admin"
                              : "General"
                          }
                          onChange={(event) => {
                            setIsChangeUserDialogOpen(true);
                            setUpdatedUserType(event.target.value);
                            setCurrentUser(user);
                            changeUserPositionDialog();
                          }}
                          disabled={
                            user.email === login.email ||
                            user?.user_type === "BA"
                          }
                        >
                          {["General", "Admin", "Survey Admin"].map(
                            (type, index) => (
                              <MenuItem
                                name="changeBtn"
                                value={type}
                                key={index}
                              >
                                {type}
                              </MenuItem>
                            )
                          )}
                        </CustomTextfield>
                      </Grid>

                      {/* User delete */}
                      {(!user.super_admin &&
                        (user.email === login.email ||
                          user?.user_type === "BA")) || (
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <IconButton
                            className={classes.actionButton}
                            onClick={() => {
                              setIsDeleteDialogOpen(true);
                              setCurrentUser(user);
                            }}
                          >
                            <RemoveCircleOutlineIcon
                              name="removeUser"
                              color="primary"
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                );
              })}
            {searchResult?.length === 0 && (
              <Typography style={{ textAlign: "center", marginTop: 70 }}>
                No result found with "{keyword}" keyword
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
