import { Card, Typography, Switch, Box, makeStyles } from "@material-ui/core";
import CustomButton from "components/UI/button/button";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#32A05F",
    },
    "&$checked + $track": {
      backgroundColor: "#32A05F",
    },
  },
  checked: {},
  track: {},
  // button: {
  //   borderColor: "#3374B9",
  //   color: "#3374B9",
  //   "&:hover": {
  //     backgroundColor: "#e6f2ff",
  //     borderColor: "#3374B9",
  //   },
  // },
  chip: {
    backgroundColor: "#BFE2CD",
    color: "#195a11",
    // borderColor: "#195a11",
    // border: "1px solid",
    // "& .MuiChip-label": {
    //   color: "#195a11",
    // },
  },
}));

function TprmRiskCard({ children, heading = "Module Heading" }) {
  const classes = useStyles();
  return (
    <Card style={{ marginBottom: "20px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={"10px 20px"}
        alignItems={"center"}
      >
        <Typography style={{ fontWeight: 600 }}>{heading}</Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={"20px"}>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            checked
          />
          <Typography variant="body2">Add This to Report Section</Typography>
          <CustomButton color="primary" size="small" startIcon={<Edit />}>
            Edit
          </CustomButton>
        </Box>
      </Box>
      {children}
    </Card>
  );
}

export default TprmRiskCard;
