import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/Close";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_create_survey,
  gql_get_survey_list,
  setSurveyPage,
} from "../../../../redux/actions/riskdashboard/riskDashboardActions";

import CustomTextfield from "../../../UI/textfield/textfield";
import CustomButton from "../../../UI/button/button";
import { set_snack_bar } from "../../../../redux/actions/snackbar/snackbar_action";

const RiskSurveyDrawer = ({ handleDrawer, setCurrentPage }) => {
  // using redux
  const dispatch = useDispatch();
  const { bankId } = useSelector((state) => state.login);

  const [surveyData, setSurveyData] = useState({
    surveyName: "",
    surveyDescription: "",
    surveyType: "INTERNAL",
  });
  const { totalSurveys } = useSelector((state) => state.riskDashboard);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSurveyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateSurvey = async () => {
    // Destructure surveyData object
    const { surveyName, surveyDescription, surveyType } = surveyData;

    if (surveyName === "") {
      dispatch(set_snack_bar(true, "Please enter survey name"));
      // } else if (surveyDescription === "") {
      //   dispatch(set_snack_bar(true, "Please enter the survey description"));
    } else {
      await dispatch(
        gql_create_survey(surveyDescription, surveyName, surveyType)
      );

      dispatch(gql_get_survey_list(8, Math.ceil(totalSurveys / 8)));
      setCurrentPage(Math.ceil(totalSurveys / 8));
      dispatch(setSurveyPage(Math.ceil(totalSurveys / 8)));

      // Reset the form or perform other actions as needed
      setSurveyData({
        surveyName: "",
        surveyDescription: "",
      });

      // close drawer
      handleDrawer();
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: 700,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Drawer Header */}
      <Box
        sx={{
          boxShadow: "0px 2px 4px #3374B926",
          px: 2,
          py: 0.5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: "600" }}>Create Dataform</Typography>
          <IconButton
            onClick={() => {
              handleDrawer();
            }}
          >
            <CloseIcon style={{ color: "#3374B9" }} />
          </IconButton>
        </div>
      </Box>

      {/* Drawer Contents */}
      <Box
        sx={{
          p: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              Dataform Name
            </Typography>
            <CustomTextfield
              id="surveyName"
              name="surveyName"
              variant="outlined"
              fullWidth
              size="small"
              value={surveyData.surveyName}
              onChange={handleInputChange}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              Dataform Type
            </Typography>
            <Select
              id="surveyType"
              name="surveyType"
              variant="outlined"
              style={{ width: "100%", height: "40px" }}
              value={surveyData.surveyType}
              onChange={handleInputChange}
            >
              <MenuItem value="INTERNAL">Internal</MenuItem>
              <MenuItem value="EXTERNAL">External</MenuItem>
            </Select>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              Describe about the dataform
            </Typography>
            <CustomTextfield
              id="surveyDescription"
              name="surveyDescription"
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              size="small"
              value={surveyData.surveyDescription}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Drawer Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            onClick={handleDrawer}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleCreateSurvey}
            style={{ textTransform: "capitalize" }}
            // disabled={!surveyData.surveyName || !surveyData.surveyDescription}
          >
            Create Dataform
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RiskSurveyDrawer;
