import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import QuestionTabDrawer from "../../QuestionTabComponents/QuestionTabDrawer";

export default function LeftSectionDrawer({
  OpenDrawer,
  handleDrawer,
  setSectionValue,
  sectionValue,
  selectedSection,
  surveyId,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  return (
    <QuestionTabDrawer
      headerName={"Section Settings"}
      buttonText={"Save settings"}
      open={OpenDrawer}
      handleClose={handleDrawer}
      title={title}
      description={description}
      id={selectedSection?._id}
      surveyId={surveyId}
      isFrom={"sectionSettings"}
    >
      <FormControl
        style={{ width: "100%", padding: "20px" }}
        component="fieldset"
      >
        <FormGroup style={{ gap: "20px" }}>
          {/* <FormControlLabel
            control={
              <Switch
                checked={sectionValue?.show}
                onChange={(e) =>
                  setSectionValue((preValue) => ({
                    ...preValue,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="show"
                color="primary"
              />
            }
            label="Hide/Show Section "
          /> */}
          {/* <FormControlLabel
            control={
              <Switch
                checked={sectionValue?.mandatory}
                onChange={(e) =>
                  setSectionValue((preValue) => ({
                    ...preValue,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="mandatory"
                color="primary"
              />
            }
            label="Make Section Mandatory"
          /> */}
          <FormControlLabel
            control={
              <TextField
                style={{ width: "100%" }}
                id="outlined-multiline-static"
                label="Name of this section"
                defaultValue=""
                variant="outlined"
                onChange={(e) => setTitle(e.target.value)}
              />
            }
            // label="Make Section Mandatory"
          />
          <FormControlLabel
            control={
              <TextField
                style={{ width: "100%" }}
                id="outlined-multiline-static"
                label="Description about this section "
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
              />
            }
            // label="Make Section Mandatory"
          />

          {/* <FormControlLabel
            control={
              <Switch
                // checked={state.antoine}
                // onChange={handleChange}
                name="antoine"
                color="primary"
              />
            }
            label="Request Custom Logic for this section"
          /> */}
        </FormGroup>
        {/* <FormHelperText>
          Each sections output will be setup to have an output score on your
          survey response tab. If you would like to see an output score of the
          section end in a request to our team and we'll get back to you.
        </FormHelperText> */}
      </FormControl>
    </QuestionTabDrawer>
  );
}
