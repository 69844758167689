import { gql } from "@apollo/client";

export const GET_DEFAULT_SURVEY = gql`
  query Surveys {
    surveys {
      surveys {
        _id
        name
      }
    }
  }
`;

export const GET_SELF_ASSESSMENT_SCORE = gql`
  query SelfAssessmentScore($getScoreInput: GetScoreInput!) {
    selfAssessmentScore(getScoreInput: $getScoreInput)
  }
`;

export const GET_CONTROVERCIES_SCORE = gql`
  query ControversyScore($getScoreInput: GetScoreInput!) {
    controversyScore(getScoreInput: $getScoreInput)
  }
`;

export const GQL_GET_FREQUENCIES = gql`
  query SurveyResponseFrequencies(
    $surveyResponseFrequenciesInput: SurveyResponseFrequenciesInput!
  ) {
    surveyResponseFrequencies(
      surveyResponseFrequenciesInput: $surveyResponseFrequenciesInput
    ) {
      _id
      endDate
      startDate
      type
    }
  }
`;

export const GQL_GET_COMPLETED_QUESTIONS = gql`
  query GetCompletedQuestions(
    $getCompletedQuestionsInput: GetCompletedQuestionsInput!
  ) {
    getCompletedQuestions(
      getCompletedQuestionsInput: $getCompletedQuestionsInput
    ) {
      questions {
        order
        question
        questionId
        questionType
        weightage
        tableHeaders {
          title
          type
        }
        approvedTableRows {
          comment
          option
          weightage
          documentUrl {
            name
            type
            url
          }
        }
        tableHeaders {
          type
          title
        }
        answers {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
        }
        approvedAnswer {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
          comment
          startDate
          endDate
        }
      }
      sectionDescription
      sectionId
      sectionTitle
    }
  }
`;

export const GQL_GET_TPRM_SCORE = gql`
  query TprmSurveyScores($getScoreInput: GetScoreInput!) {
    tprmSurveyScores(getScoreInput: $getScoreInput)
  }
`;

export const GQL_GET_VENDOR_ASSESSMENT_SCORE = gql`
  query VendorAssessmentMetrics($wazuhGroup: String!) {
    vendorAssessmentMetrics(wazuhGroup: $wazuhGroup)
  }
`;

export const GQL_GET_DORA_COMPLIANCE_FILES = gql`
  query DoraComplianceFiles($isin: String!, $type: String!) {
    doraComplianceFiles(isin: $isin, type: $type)
    doraOverallScores(isin: $isin, type: $type) {
      contractComment
      incidentPolicyComment
      isPolicyComment
      overallScore
      tspsPolicyComment
    }
  }
`;

export const GQL_GET_DORA_COMPLIANCE_FILES_FOR_DOCS = gql`
  query DoraComplianceFiles($isin: String!, $type: String) {
    doraComplianceFiles(isin: $isin, type: $type)
  }
`;

export const GQL_GET_ACTIVE_CONTRACT_FILE_DATA = gql`
  query GetActiveContractFileData($isin: String!) {
    getActiveContractFileData(isin: $isin)
  }
`;

// export const GQL_GET_DORA_OVERALL_SCORES = gql`
//   query DoraOverallScores($isin: String!, $type: String) {
//     doraOverallScores(isin: $isin, type: $type) {
//       contractComment
//       incidentPolicyComment
//       isPolicyComment
//       overallScore
//       tspsPolicyComment
//     }
//   }
// `;

export const GQL_GET_DORA_COMPLIANCE_RESULTS = gql`
  query MagpieResult($fileId: String!) {
    magpieResult(fileId: $fileId)
  }
`;

export const GQL_SET_CONTRACT_FILE_AS_ACTIVE = gql`
  mutation SetContractFileAsActive($id: String!, $isin: String!) {
    setContractFileAsActive(id: $id, isin: $isin) {
      message
      success
    }
  }
`;
