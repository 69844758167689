// RiskScatterChart.js
import React from "react";
import { Bar, Bubble, Line, Scatter } from "react-chartjs-2";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale
);

const RiskScatterChart = ({ data }) => {
  // Sample data for demonstration purposes
  const structuredData = {
    labels: ["Mon", "Tue", "Wed"],
    datasets: [
      {
        label: "High Risk",
        data: [...data["High"]],
        backgroundColor: "#D0766B", // Red color for high risk
        pointRadius: 5,
      },
      {
        label: "Medium Risk",
        data: [...data["Medium"]],
        backgroundColor: "#FFD685", // Yellow color for medium risk
        pointRadius: 5,
      },
      {
        label: "Low Risk",
        data: [...data["Low"]],
        backgroundColor: "#5BB37F", // Green color for low risk
        pointRadius: 5,
      },
    ],
  };

  // Optional chart options
  const options = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Vendor Risk",
        },
        ticks: {
          //   display: false,
        },
        grid: {
          //   drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "ESG Risk",
        },
        ticks: {
          //   display: false,
        },
        // grid: {
        //   drawOnChartArea: false,
        // },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            if (label) {
              return `${label}: (${context.parsed.x}, ${context.parsed.y})`;
            }
            return "";
          },
        },
      },
      legend: {
        // position: 'left',
        labels: {
          align: "left",
          // Customize label styles here
          font: {
            size: "10rem",
            weight: "bold",
          },
          usePointStyle: true,
          pointStyle: "circle",
          color: "#878787",
        },
      },
    },
  };

  // const chartAreaBackground = {
  //   id: "chartAreaBackground",
  //   beforeDatasetsDraw(chart, args, plugins) {
  //     const {
  //       ctx,
  //       chartArea: { top, bottom, left, right, width, height },
  //     } = chart;

  //     ctx.save();

  //     // ctx.fillStyle = "green";
  //     ctx.fillRect(left, top, width, height);
  //   },
  // };

  return (
    <div>
      {/* <Bar data={data} options={options} plugins={[chartAreaBackground]} />; */}
      <Bubble data={structuredData} options={options} />
    </div>
  );
};

export default RiskScatterChart;
