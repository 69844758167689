import { useState, useCallback } from "react";
import Box from "@material-ui/core/Box";
import debounce from "lodash.debounce";

import MapChart from "./mapComponents/Map";
import Legend from "./mapComponents/Legend";
// import { interestData } from "./mapUtils/interestData";
import { useSelector } from "react-redux";

const InterestMap = () => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { controInsights } = useSelector((state) => state.esgDiagnosisReducer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetTooltipContent = useCallback(
    debounce((content) => {
      setTooltipContent(content);
    }, 0),
    []
  );

  const handleTooltipPosition = (event) => {
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box
      style={{
        marginTop: 40,
        marginLeft: -40,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {/* map container */}
      <Box
        style={{
          width: "70em",
        }}
      >
        <MapChart
          setTooltipContent={debouncedSetTooltipContent}
          setTooltipPosition={handleTooltipPosition}
          handleTooltipOpen={handleTooltipOpen}
          handleTooltipClose={handleTooltipClose}
          countries={controInsights?.countries}
          tooltipContent={tooltipContent}
          tooltipPosition={tooltipPosition}
          tooltipOpen={tooltipOpen}
        />
      </Box>

      {/* legend container */}
      <Box style={{ width: "30em", marginRight: 40 }}>
        <Legend countries={controInsights?.countries} />
      </Box>
    </Box>
  );
};

export default InterestMap;
