import React, { useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";

import { TextField } from "@material-ui/core";

const NumberFieldWithUpload = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  linkageAnswer,
}) => {
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");
  const [file, setFile] = useState(null);

  const inputRef = useRef(null);

  const handleChange = (event) => {
    // Remove non-numeric characters from the input value
    const formattedValue = event.target.value.replace(/\D/g, "");

    setValue(formattedValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value,
        document: file,
        weightage: 1,
        comment: commentBox,
      };

      handleQuesAnswer(obj);
    }
  }, [file, value]);

  useEffect(() => {
    if (linkageAnswer || (submittedAnswer && submittedAnswer?.length > 0)) {
      setValue(linkageAnswer || submittedAnswer[0]?.option);
    }
  }, [submittedAnswer, linkageAnswer]);

  return (
    <div>
      <TextField
        style={{ pointerEvents: event }}
        label={"Numeric Value"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          inputMode: "numeric", // Set input mode to allow numeric input
          pattern: "[0-9]*", // Set pattern to allow only numeric characters
        }}
      />
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {submittedAnswer ? (
          <Box
            border={1}
            borderRadius={4}
            p={1}
            borderColor={"#cececece"}
            ml={2}
          >
            {submittedAnswer[0]?.documentUrl === null ? (
              <Typography variant={"body2"}>
                No document has been uploaded
              </Typography>
            ) : (
              <a
                href={submittedAnswer[0]?.documentUrl?.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer[0]?.documentUrl?.url}
              </a>
            )}
          </Box>
        ) : (
          <>
            <input
              name="actUpload"
              id="contained-button-activityFile"
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none", pointerEvents: event }}
              multiple
            />
            <div
              style={{
                borderBottom: "2px solid #ced4da",
                marginLeft: 20,
                width: "100%",
              }}
            >
              {file?.name ? file.name : "Upload file"}
            </div>
          </>
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

export default NumberFieldWithUpload;
