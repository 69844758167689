import { Box, Drawer, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import DrawerHeader from "./DrawrComps/DrawerHeader";
import DrawerMain from "./DrawrComps/DrawerMain";
import DrawerFooter from "./DrawrComps/DrawerFooter";
import { connectSocket } from "../../../../../../socket";
import { useDispatch, useSelector } from "react-redux";
import {
  RECEIVE_MESSAGE,
  ROOM_MEMBERS,
} from "../../../../../../constants/threadConstants";
import SurveyDetailsHeader from "./DrawrComps/SurveyDetailsHeader";
import AuditChatHeader from "./DrawrComps/AuditChatHeader";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "10px 20px",
    width: "70vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: "10px",
    alignItems: "flex-start",
  },

  headerPrimaryText: {
    fontWeight: 500,
    fontSize: "11px",
    marginBottom: "7px",
    color: "#666666",
  },

  headerSecondaryText: {
    fontWeight: 500,
    fontSize: "13px",
  },

  headerInner: {
    display: "flex",
    gap: "30px",
  },

  chip: {
    borderRadius: theme.spacing(1), // Adjust to your desired border radius
    padding: theme.spacing(1), // Adjust to your desired padding
    fontSize: "13px",
    fontWeight: 500,
    textTransform: "capitalize",
  },

  dot: {
    width: "10px !important",
    height: "10px !important",
    display: "block",
    borderRadius: "50%",
    backgroundColor: "orange", // Use currentColor to inherit Chip's color
  },

  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },

  subHeading: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    borderBottom: `1px solid ${theme.palette.divider}`,

    padding: "10px 0",
  },

  customButtom: {
    fontSize: "20px",
  },

  smallerButton: {
    minWidth: "30px",
    height: "30px",
    padding: "4px",
    fontSize: "0.875rem",
    transition: "background-color 0.3s, padding 0.3s",
    "&:hover": {
      padding: "4px",
      height: "30px",
    },
  },

  tableHeaderDorpdown: {
    width: "150px",
    fontSize: "12px !important",
  },

  name: {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },

  message: {
    fontSize: "12px",
  },

  icon: {
    color: "#3374B9",
    marginRight: "10px",
  },
}));

function ThreadDrawer({
  isDrawerOpen,
  handleCloseDrawer,
  selectedQues,
  setIsDrawerOpen,
  isFrom,
  frequencyDetails,
  bankRoomId,
}) {
  const classes = useStyles();
  const { user_id } = useSelector((state) => state.login);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [roomMembers, setRoomMembers] = useState([]);
  const [contributor, setContributor] = useState(null);
  const dispatch = useDispatch();

  const roomId = contributor
    ? contributor?.next?.chatRoomId
    : selectedQues?.chatRoomId || bankRoomId;

  useEffect(() => {
    const connectSocketAndSubscribe = () => {
      const newSocket = connectSocket();
      setSocket(newSocket);

      if (newSocket) {
        newSocket.on("connect", () => {
          console.log("Connected to Socket.io Server");
          newSocket.emit("join", {
            userId: user_id,
            roomId: roomId,
          });
        });

        newSocket.on("connect_error", (error) => {
          if (newSocket.active) {
            // temporary failure, the socket will automatically try to reconnect
            console.log("Failed to connect. Reconnecting...");
          } else {
            // the connection was denied by the server
            // display a notification saying failed to connect to server
            console.log(error.message);
          }
        });

        newSocket.on("message", (data) => {
          // console.log("Received message", data);
          setMessages((prevMessages) => [...prevMessages, data]);
        });
      } else {
        console.log("Unable to connect to socket server");
      }

      return newSocket;
    };

    const newSocket = connectSocketAndSubscribe();

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [user_id, roomId]);

  useEffect(() => {
    setMessages([]);
    setRoomMembers([]);
    dispatch({ type: RECEIVE_MESSAGE, payload: [] });
    dispatch({ type: ROOM_MEMBERS, payload: [] });
  }, [roomId]);

  // console.log(messages);
  // console.log("contribut", contributor);
  return (
    <Fragment>
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => {
          handleCloseDrawer();
          setContributor(null);
        }}
      >
        <Box className={classes.mainContainer}>
          {/*-------- HEADER ---------*/}
          {isFrom === "vendorDiagnosis" ? (
            <SurveyDetailsHeader
              classes={classes}
              selectedQues={selectedQues}
              setIsDrawerOpen={setIsDrawerOpen}
              contributor={contributor}
              setContributor={setContributor}
              roomId={roomId}
              members={roomMembers}
              setRoomMembers={setRoomMembers}
              frequencyDetails={frequencyDetails}
            />
          ) : isFrom === "audit" ? (
            <AuditChatHeader
              classes={classes}
              selectedQues={selectedQues}
              setIsDrawerOpen={setIsDrawerOpen}
              contributor={contributor}
              setContributor={setContributor}
              roomId={roomId}
              members={roomMembers}
              setRoomMembers={setRoomMembers}
              frequencyDetails={frequencyDetails}
            />
          ) : (
            <DrawerHeader
              classes={classes}
              selectedQues={selectedQues}
              setIsDrawerOpen={setIsDrawerOpen}
              contributor={contributor}
              setContributor={setContributor}
              roomId={roomId}
              members={roomMembers}
              setRoomMembers={setRoomMembers}
            />
          )}

          {/*----------- BODY ------------*/}
          <DrawerMain
            msg={messages}
            setMsg={setMessages}
            roomId={roomId}
            userId={user_id}
            setContributor={setContributor}
            contributor={contributor}
            selectedQues={selectedQues}
            isFrom={isFrom}
          />

          {/*---------- FOOTER -----------*/}
          <DrawerFooter
            socket={socket}
            userId={user_id}
            roomId={roomId}
            contributor={contributor}
            setContributor={setContributor}
            selectedQues={selectedQues}
            isFrom={isFrom}
          />
        </Box>
      </Drawer>
    </Fragment>
  );
}

export default ThreadDrawer;
