/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_task_questions,
  setTaskQuestions,
  setTotalSubmittedQuestions,
} from "../../../../../../redux/actions/taskActions/TaskAction";
import QuestionBox from "./ContributonComps/QuestionBox";
import RedoSpecific from "./ContributonComps/RedoSpecific";
import SectionComplete from "./ContributonComps/SectionComplete";

const ContributorMain = ({
  activeSection,
  activeSectionIndex,
  setActiveSectionIndex,
}) => {
  // from redux
  const dispatch = useDispatch();
  const { taskQuestions, selectedTask, totalSubmittedQuestions } = useSelector(
    (state) => state.taskReducer
  );

  console.log(
    selectedTask?.survey?.name
      ?.toLowerCase()
      ?.includes("register of information")
  );

  const { login } = useSelector((state) => state);
  const { selectedBankDetailsForVa } = useSelector((state) => state?.brmData);
  // variables
  const totalQues = taskQuestions?.length;
  const changeProgressBy = (1 / totalQues) * 100;

  // states
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuesNo, setCurrentQuesNo] = useState(0);
  const [redoSpecific, setRedoSpecific] = useState(false);

  const [sectionComplete, setSectionComplete] = useState(false);
  const [progress, setProgress] = useState(changeProgressBy);

  // -------------------- useEffects() --------------------

  // initial fetch for sections
  useEffect(() => {
    if (activeSection?._id) {
      // get list of questions

      if (login?.user_type === "BSA" || login?.user_type === "BG") {
        dispatch(
          gql_get_task_questions(
            selectedTask?.frequencyId,
            activeSection?._id,
            setCurrentQuestion,
            selectedBankDetailsForVa?._id,
            login?.bankId
          )
        );
      } else {
        dispatch(
          gql_get_task_questions(
            selectedTask?.frequencyId,
            activeSection?._id,
            setCurrentQuestion,
            login?.vendorId,
            selectedBankDetailsForVa?._id
          )
        );
      }
    }

    return () => {
      dispatch(setTaskQuestions([]));
      dispatch(setTotalSubmittedQuestions(0));
      setRedoSpecific(false);
    };
  }, [activeSection?._id]);

  // useEffect for whenever status changes for section or total questions changes
  useEffect(() => {
    // if survey is complete
    if (totalSubmittedQuestions === totalQues) {
      setProgress(99.9);
      setCurrentQuesNo(totalSubmittedQuestions);
    }
    // if survey is incomplete
    else {
      if (
        selectedTask?.survey?.name
          ?.toLowerCase()
          ?.includes("register of information")
      ) {
        const currentProgress = 1;
        setProgress((currentProgress / totalQues) * 100);
        setCurrentQuesNo(currentProgress);
      } else {
        const currentProgress = totalSubmittedQuestions + 1;
        setProgress((currentProgress / totalQues) * 100);
        setCurrentQuesNo(currentProgress);
      }

      // setCurrentQuestion(taskQuestions[currentProgress - 1]);
    }

    if (totalSubmittedQuestions === totalQues) {
      setSectionComplete(true);
    } else {
      setSectionComplete(false);
    }
  }, [totalQues]);

  // useEffect for whenever current ques no changes
  useEffect(() => {
    setCurrentQuestion(taskQuestions[currentQuesNo - 1]);
  }, [currentQuesNo]);

  // --------------------------------------------------------

  return (
    <Box>
      {redoSpecific ? (
        // Redo Specific Questions

        <RedoSpecific
          taskQuestions={taskQuestions}
          activeSection={activeSection}
          setRedoSpecific={setRedoSpecific}
        />
      ) : sectionComplete ? (
        // show survey complete screen

        <SectionComplete
          progress={progress}
          setProgress={setProgress}
          changeProgressBy={changeProgressBy}
          totalQues={totalQues}
          currentQuesNo={currentQuesNo}
          setRedoSpecific={setRedoSpecific}
          activeSectionIndex={activeSectionIndex}
          setActiveSectionIndex={setActiveSectionIndex}
          setCurrentQuesNo={setCurrentQuesNo}
          setCurrentQuestion={setCurrentQuestion}
          setSectionComplete={setSectionComplete}
          activeSection={activeSection}
        />
      ) : (
        <QuestionBox
          totalQues={totalQues}
          taskQuestions={taskQuestions}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          currentQuesNo={currentQuesNo}
          setCurrentQuesNo={setCurrentQuesNo}
          progress={progress}
          setProgress={setProgress}
          changeProgressBy={changeProgressBy}
          activeSection={activeSection}
          activeSectionIndex={activeSectionIndex}
          setActiveSectionIndex={setActiveSectionIndex}
          setSectionComplete={setSectionComplete}
        />
      )}
    </Box>
  );
};
export default ContributorMain;
