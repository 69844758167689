import UNIVERSAL from "../../../config";
import {
  SET_SELECTED_MENU,
  SET_USER_IMAGE,
  SET_USER_NAME,
  SET_USER_POSITION,
  SET_USER_CONTACT,
  SET_USER_DISTRICT,
  SET_USER_LOCATION,
  SET_USER_PINCODE,
  SET_USER_STATE,
  SET_COMPANY_INFO,
  SET_SECTORS,
  UNSET_ADDRESS,
  SET_BRANCH_ADDRESS,
  PUSH_BRANCH_ADDRESS,
  SET_ALL_USERS,
  SET_MASTER_SECTOR,
  SET_SELECTED_SECTORS,
  SET_SUBSCRIPTION_DETAILS,
  SET_INVOICE_DETAILS,
  SET_PLAN_AMOUNT,
  SET_USERS,
  SET_COMPANY_DETAILS,
} from "../../../constants/generalSettings";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import {
  GQL_ADD_USER,
  GQL_CHANGE_USER_POSITION,
  GQL_GET_COMPANY_DETAILS,
  GQL_GET_USERS,
  GQL_GET_VA_COMPANY_DETAILS,
  GQL_REMOVE_USER,
  GQL_UPDATE_COMPANY_DETAILS,
  GQL_UPDATE_VA_COMPANY_DETAILS,
  UPDATE_USER,
} from "../../graphql/gql_GeneralSettings";
import { client } from "../../store/store";
import {
  checkIsUnAuth,
  handleUnauthInterceptor,
  setLogin,
} from "../login/loginAction";
import { SET_USER_PROFILE } from "../login/loginActionType";
import {
  gql_get_vendor_name_for_va,
  setVendorBasicDetails,
} from "../vendorActions/vendorAction";
import {
  SET_ORGANIZATION_DATA,
  SET_VENDOR_BASIC_DETAILS,
} from "../../../constants/brmConstants";

// import {
//   setIsSubscriptionActive,
//   setName,
//   setUserProfile,
// } from "../../actions/login/loginActions";
// import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
// import { SET_ALL_STEP_5_USERS } from "../../constants/fielddata/fielddataConst";

export function setSelectedMenu(payload) {
  return {
    type: SET_SELECTED_MENU,
    payload: payload,
  };
}

export function setUserName(payload) {
  return {
    type: SET_USER_NAME,
    payload: payload,
  };
}

export function setSelectedSectors(payload) {
  return {
    type: SET_SELECTED_SECTORS,
    payload,
  };
}

export function setUserPosition(payload) {
  return {
    type: SET_USER_POSITION,
    payload: payload,
  };
}

export function setUserImage(payload) {
  return {
    type: SET_USER_IMAGE,
    payload: payload,
  };
}

export function setUserContact(payload) {
  return {
    type: SET_USER_CONTACT,
    payload: payload,
  };
}

export function setUserDistrict(payload) {
  return {
    type: SET_USER_DISTRICT,
    payload: payload,
  };
}

export function setUserLocation(payload) {
  return {
    type: SET_USER_LOCATION,
    payload: payload,
  };
}

export function setUserState(payload) {
  return {
    type: SET_USER_STATE,
    payload: payload,
  };
}

export function setUserPincode(payload) {
  return {
    type: SET_USER_PINCODE,
    payload: payload,
  };
}

export function setCompanyInfo(payload) {
  return {
    type: SET_COMPANY_INFO,
    payload: payload,
  };
}

export function setSectors(payload) {
  return {
    type: SET_SECTORS,
    payload: payload,
  };
}

export function setBranchAddress(payload) {
  return {
    type: SET_BRANCH_ADDRESS,
    payload: payload,
  };
}

export function unsetAddress(payload) {
  return {
    type: UNSET_ADDRESS,
    payload: payload,
  };
}

export function pushBranchAddress(payload) {
  return {
    type: PUSH_BRANCH_ADDRESS,
    payload: payload,
  };
}

export function setAllUsers(payload) {
  return {
    type: SET_ALL_USERS,
    payload: payload,
  };
}

export function viewUserProfile(token) {
  const data = encrypt({
    "user-token": token,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/userDetails/view_profile_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(setUserName(responseJson.result.name));
          dispatch(setUserPosition(responseJson.result.designation));
          dispatch(setUserImage(responseJson.result.profile_image));
          dispatch(setUserContact(responseJson.result.contact_info.contact_no));
          dispatch(setUserDistrict(responseJson.result.contact_info.district));
          dispatch(setUserLocation(responseJson.result.contact_info.location));
          dispatch(setUserState(responseJson.result.contact_info.state));
          dispatch(setUserPincode(responseJson.result.contact_info.pincode));
        }
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}

// export function upodateUserProfile(
//   token,
//   name,
//   position,
//   contact_info,
//   profile_image
// ) {
//   var formData = new FormData();
//   const data = encrypt({
//     "user-token": token,
//     name: name,
//     position: position,
//     contact_info: contact_info,
//     profile_image: profile_image,
//   });
//   formData.append("data", data);
//   formData.append("profile_image", profile_image);
//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + "/userDetails/update_profile_details", {
//       method: "POST",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           dispatch(setName(name));
//           dispatch(setUserProfile(responseJson.result));

//           localStorage.setItem("userName", name);
//           localStorage.setItem(
//             "userProfile",
//             JSON.stringify(responseJson.result)
//           );
//         }
//         dispatch(unset_loader());
//         dispatch(set_snack_bar(true, responseJson.message));
//       })
//       .catch((error) => {
//         dispatch(unset_loader());
//         console.error(error);
//       });
//   };
// }

export function resetUserPassword(token, current_password, new_password) {
  const data = encrypt({
    "user-token": token,
    current_password: current_password,
    new_password: new_password,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/userDetails/reset_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}

export const setMasterSector = (payload) => {
  payload.map((sector, index) => {
    return sector.industry_data.map((industry, index2) => {
      return (payload[index].industry_data[index2].isSelected = false);
    });
  });

  return {
    type: SET_MASTER_SECTOR,
    payload: payload,
  };
};

export function viewCompanyInfo(token, organisation_id) {
  const data = encrypt({
    "user-token": token,
    organisation_id: organisation_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/businessUnit/view_company_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(setCompanyInfo(responseJson.result.company_details));
          dispatch(setMasterSector(responseJson.result.sector_data));
          dispatch(
            setSelectedSectors(responseJson.result.company_details.sector)
          );
          dispatch(setSectors(responseJson.result.sector_data));
          dispatch(
            setBranchAddress(responseJson.result.company_details.branch_office)
          );
        }
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}

//common action for removing the files

export const removeFile = (
  token,
  assessment_id,
  step_no,
  url,
  field_name,
  assessment_topic_id,
  activity_id,
  kpi_id,
  metric_id
) => {
  const data = encrypt({
    "user-token": token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id: assessment_topic_id,
    activity_id: activity_id,
    kpi_id: kpi_id,
    metric_id: metric_id,
  });
  return (dispatch) => {
    fetch(UNIVERSAL.BASEURL + "/remove_file/remove_file", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
        }
      })
      .catch((err) => console.log(err));
  };
};

export const removeSettingsFile = (token, url, field_name, company_id) => {
  const data = encrypt({
    "user-token": token,
    url,
    field_name,
    company_id,
  });
  return (dispatch) => {
    fetch(UNIVERSAL.BASEURL + "/remove_file/remove_file_from_settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
};

// export const upgradePlan = (
//   token,
//   organisation_id,
//   name,
//   shared_by_user_img
// ) => {
//   const data = encrypt({
//     "user-token": token,
//     organisation_id,
//   });

//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + "/razorpay/create_subsciption", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json",
//       },
//       body: JSON.stringify({
//         data,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           console.log('res',responseJson)
//           const options = {
//             key: "rzp_test_ok9Z4MvOECP4YV",
//             subscription_id: responseJson.result,
//             name: name,
//             description: "Monthly Test Plan",
//             image: shared_by_user_img,
//             // callback_url:`${UNIVERSAL.BASEURL + '/razorpay/verify'}`,
//             // redirect: false,
//             handler: (response) => {
//               const {
//                 razorpay_payment_id,
//                 razorpay_subscription_id,
//                 razorpay_signature,
//               } = response;
//               dispatch(
//                 verifySubscription(
//                   razorpay_payment_id,
//                   razorpay_subscription_id,
//                   razorpay_signature,
//                   token,
//                   organisation_id,
//                   shared_by_user_img
//                 )
//               );
//             },
//             //     handler: async function (response) {
//             //     console.log('1',response.razorpay_payment_id);
//             //     console.log('2',response.razorpay_subscription_id);
//             //     console.log('3',response.razorpay_signature);
//             //     const body =
//             //       response.razorpay_payment_id +
//             //       "|" +
//             //       response.razorpay_subscription_id;
//             //     const generated_signature = await crypto
//             //       .createHmac("sha256", "rzp_test_ok9Z4MvOECP4YV")
//             //       .update(body.toString())
//             //       .digest("hex");
//             //     // const generated_signature = await crypto.hmac_sha256( response.razorpay_payment_id+ "|" + response.razorpay_subscription_id, "rzp_test_ok9Z4MvOECP4YV");
//             //      console.log('generated_signature',generated_signature)
//             //     if (generated_signature == response.razorpay_signature) {
//             //       console.log('payment');
//             //     }else{
//             //       console.log('failed')
//             //     }
//             //   },
//             // prefill: {
//             //   name: "Philimon Nag",
//             //   email: "Philimon@appstone.in",
//             //   contact: "+919741831711",
//             // },
//             // "notes": {
//             //   "note_key_1": "Tea. Earl Grey. Hot",
//             //   "note_key_2": "Make it so."
//             // },
//             theme: {
//               color: "#3374b9",
//             },
//           };
//           // const rapay = window.Razorpay(options);
//           const rapay = window.Razorpay(options);
//           console.log('rapay',rapay)
//           rapay.open();
//           // dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         }
//         dispatch(unset_loader());
//       })
//       .catch((err) => console.log(err));
//   };
// };

export const setSubscriptionDetails = (payload) => ({
  type: SET_SUBSCRIPTION_DETAILS,
  payload,
});
export const setInvoiceDetails = (payload) => ({
  type: SET_INVOICE_DETAILS,
  payload,
});

export const verifySubscription = (
  razorpay_payment_id,
  razorpay_subscription_id,
  razorpay_signature,
  token,
  organisation_id
) => {
  const url = window.location.origin;
  const domain_name = window.location.hostname;

  const data = encrypt({
    "user-token": token,
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature,
    url,
    domain_name,
    organisation_id,
  });

  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/verify", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.setItem("status", "ACTIVE");
          localStorage.setItem("location", window.location.pathname);

          dispatch(setSubscriptionDetails(data));
          // dispatch(getInvoice(token, organisation_id));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
};

export const getInvoice = (token, organization_id, payment_status) => {
  const data = encrypt({
    "user-token": token,
    organisation_id: organization_id,
    payment_status,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/view_invoices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        dispatch(setInvoiceDetails(responseJson.result));
        // dispatch(
        //   setIsSubscriptionActive(
        //     responseJson.result?.payment_status === "EXPIRED" ? false : true
        //   )
        // : responseJson.result[0]?.paid
        // );
        dispatch(unset_loader());
        // async () => {
        // const browser = await puppeteer.launch();
        // const page = await browser.newPage();
        // await page.goto(url);
        // await page.pdf({ path: "doc.pdf" });
        // await page.screenshot({ path: "image.png" });
        // await browser.close();
        // }
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

const setPlanAmount = (payload) => ({ type: SET_PLAN_AMOUNT, payload });

export const getPlans = (token, organization_id, payment_status) => {
  const data = encrypt({
    "user-token": token,
    organisation_id: organization_id,
    payment_status,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/get_payment_amount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status)
          dispatch(setPlanAmount(responseJson?.payment_amount));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

// -------------------- REST Actions --------------------
export async function upload_user_img(file, token, isFor, id) {
  const formData = new FormData();

  if (isFor === "user") {
    formData.append("image", file);
  } else if (
    isFor === "BA" ||
    isFor === "VA" ||
    isFor === "VG" ||
    isFor === "BSA"
  ) {
    formData.append("logo", file);
  }

  let endPoint = "";

  if (isFor === "user") {
    endPoint = `/users/updateProfileImage`;
  } else if (isFor === "BA" || isFor === "BSA") {
    endPoint = `/bank/${id}/uploadLogo`;
  } else if (isFor === "VA" || isFor === "VG") {
    endPoint = `/vendors/upload/logo/${id}`;
  }

  try {
    const res = await fetch(UNIVERSAL.BASEURL + endPoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    console.log("Profile image uploaded successfully", data);

    return data?.profileImg;
  } catch (error) {
    throw error.message;
  }
}

// ----------------------GQL------------------------

export function gql_update_user(
  active,
  designation,
  is_premium,
  name,
  logo,
  token,
  address
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const profileImg = await upload_user_img(logo, token, "user");

      const updateUserMutation = {
        mutation: UPDATE_USER,
        variables: {
          updateUserInput: {
            active,
            designation,
            is_premium,
            name,
            address,
          },
        },
      };

      const result = await client.mutate(updateUserMutation);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(updateUserMutation, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      dispatch(set_snack_bar(true, "Profile updated successfully"));

      dispatch({ type: SET_USER_PROFILE, payload: profileImg });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_company_details(isFor, id) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      let updateUserMutation = {};

      if (isFor === "BA" || isFor === "BSA") {
        updateUserMutation = {
          query: GQL_GET_COMPANY_DETAILS,
        };
      } else {
        updateUserMutation = {
          query: GQL_GET_VA_COMPANY_DETAILS,
          variables: { id },
        };
      }

      // console.log(updateUserMutation);

      const result = await client.query(updateUserMutation);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(updateUserMutation, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);

      if (isFor === "BA" || isFor === "BSA") {
        dispatch({
          type: SET_COMPANY_DETAILS,
          payload: result?.data?.bankDetails,
        });

        dispatch({
          type: SET_ORGANIZATION_DATA,
          payload: result?.data?.bankDetails,
        });
      } else {
        dispatch({
          type: SET_COMPANY_DETAILS,
          payload: result?.data?.vendor,
        });
      }
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_update_company_details(
  headOfficeAddress,
  permanentEmployeeCount,
  website,
  name,
  logo,
  token,
  isFor,
  id,
  operation,
  sectorObj
) {
  return async (dispatch) => {
    console.log({ token, isFor, id });
    dispatch(set_loader());

    try {
      if (logo?.lastModified) {
        logo = await upload_user_img(logo, token, isFor, id);
      }

      let updateUserMutation = {};

      if (isFor === "BA" || isFor === "BSA") {
        updateUserMutation = {
          mutation: GQL_UPDATE_COMPANY_DETAILS,
          variables: {
            updateBankInput: {
              headOfficeAddress,
              permanentEmployeeCount,
              website,
              name,
            },
          },
        };
      } else {
        updateUserMutation = {
          mutation: GQL_UPDATE_VA_COMPANY_DETAILS,
          variables: {
            id,
            updateVendorInput: {
              address: headOfficeAddress,
              employeesCount: permanentEmployeeCount,
              logo,
              name,
              website,
              sector: {
                sector: sectorObj?.sector,
                industry: sectorObj?.industry,
              },
              operation,
            },
          },
        };
      }

      const result = await client.mutate(updateUserMutation);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(updateUserMutation, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      dispatch(set_snack_bar(true, "Profile updated successfully"));

      if (isFor === "BA") {
        dispatch(gql_get_company_details("BA"));
      } else if (isFor === "BSA") {
        dispatch(gql_get_company_details("BSA"));
      } else {
        dispatch(gql_get_company_details("VA", id));
        dispatch({
          type: SET_VENDOR_BASIC_DETAILS,
          payload: {
            logo: result?.data?.updateVendor?.logo,
            name: result?.data?.updateVendor?.name,
          },
        });
      }

      // console.log(result);
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_users(includeBranches = false) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const obj = {
        query: GQL_GET_USERS,
        variables: {
          includeBranches,
        },
      };

      const result = await client.query(obj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(obj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({ type: SET_USERS, payload: result?.data?.members });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_change_user_pos(input) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutationDetails = {
        mutation: GQL_CHANGE_USER_POSITION,
        variables: {
          input,
        },
      };
      const result = await client.mutate(mutationDetails);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutationDetails, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(
        set_snack_bar(
          result.data.changeUserType.success,
          result.data.changeUserType.message
        )
      );

      dispatch(gql_get_users());
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(unset_loader());
    }
  };
}

export function gql_remove_user(userId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutationDetails = {
        mutation: GQL_REMOVE_USER,
        variables: {
          userId,
        },
      };
      const result = await client.mutate(mutationDetails);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutationDetails, "mutate"));
        } else {
          throw result.errors[0];
        }
      }
      dispatch(
        set_snack_bar(
          result.data.removeMember.success,
          result.data.removeMember.message
        )
      );
      dispatch(gql_get_users());
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(unset_loader());
    }
  };
}

export function gql_add_user(
  addMemberInput,
  closeDrawer,
  includeBranches = false
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const obj = {
        mutation: GQL_ADD_USER,
        variables: { addMemberInput },
      };

      const result = await client.mutate(obj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(obj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      if (!result?.data?.addMember?.success) {
        dispatch(set_snack_bar(true, result?.data?.addMember?.message));
      } else {
        if (Boolean(closeDrawer)) {
          closeDrawer();
        }

        dispatch(set_snack_bar(true, "User Added successfully"));
      }
      await new Promise((resolve) => setTimeout(resolve, 50));
      dispatch(gql_get_users(includeBranches));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}
