import React, { useEffect, useRef, useState } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import CustomTextfield from "../../UI/textfield/textfield";
import PublicReports from "./publicReports";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/DateRange";

import Analytics from "../../../images/Analytics.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomButton from "../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../riskAssessment/riskUtilComponents";
import { appTheme } from "../riskAssessment/brmDetaails";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import YearRangePicker from "./documentsElements/YearRangePicker";
import {
  gql_get_all_stakeholders,
  gql_request_files_stakeholder,
  rest_get_vendor_uploaded_files,
  rest_upload_dora_compliance_file,
  rest_upload_va_file_from_ba,
} from "../../../redux/actions/vendorActions/vendorAction";
import { gql_get_dora_compliance_files_for_diligence } from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";

const docStyles = makeStyles((theme) => ({
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },

  endButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    textTransform: "capitalize",
    marginRight: "5px",
    fontSize: "14px",
    padding: 10,
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374D9",
      boxShadow: "1px 1px 3px #3374B9",
    },
  },
}));

function CompanyDocuments({
  showPeerDocs,
  companyName,
  setShowpeerDocs,
  brmData,
  getPublicReports,
  login,
  ...props
}) {
  const { uploadedFiles } = useSelector((state) => state.vendorReducer);

  const { token, name } = useSelector((state) => state.login);

  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { doraComplianceFiles } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const customUploadedFiles = uploadedFiles?.length > 0 ? uploadedFiles : [];
  const customDoraFiles =
    doraComplianceFiles?.doraFiles?.length > 0
      ? doraComplianceFiles?.doraFiles
      : [];

  const classes = docStyles();
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const [reportsTab, setReportsTab] = useState(0);
  const [file, setFile] = React.useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [isrequestUpload, setIsRequestUpload] = useState(false);
  const [requestTabNo, setRequestTabNo] = useState(0);

  const [fileData, setFileData] = useState({
    type: "",
    sourceUrl: "",
    year: "",
    file: null,
    xbrlUrl: "",
  });

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  // States for categories
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [yearPicker, setYearPicker] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState({});

  const [selectParticipants, setSelectParticipants] = useState([]);
  const [checked, setChecked] = useState({});

  const [preFileUpload, setPreFileUpload] = useState([]);

  const [requestFiles, setRequestFiles] = useState({});
  const modifiedCat = Object.values(categoryData);

  const storedVendorIsin = localStorage.getItem("vendorIsin");

  useEffect(() => {
    dispatch(
      rest_get_vendor_uploaded_files(
        token,
        vendorBasicDetails?.isin || storedVendorIsin
      )
    );
    dispatch(
      gql_get_dora_compliance_files_for_diligence(
        vendorBasicDetails?.isin || storedVendorIsin
      )
    );
  }, []);

  function handleParticipants(id) {
    const index = selectParticipants.indexOf(id);

    if (index === -1) {
      // If ID is not in the array, add it
      setSelectParticipants([...selectParticipants, id]);
    } else {
      // If ID is already in the array, remove it
      const updatedIds = [...selectParticipants];
      updatedIds.splice(index, 1);
      setSelectParticipants(updatedIds);
    }

    setChecked((prevState) => ({
      ...prevState,
      [id]: !prevState[id] || false,
    }));
  }

  function handleRequestFiles(tabNo) {
    if (tabNo === 0) {
      setRequestTabNo((prevTab) => prevTab + 1);
    } else {
      const modifiedCat = Object.values(categoryData);
      const vendorId = brmData.vendorBasicDetails?._id;

      dispatch(
        gql_request_files_stakeholder(modifiedCat, selectParticipants, vendorId)
      );

      setRequestTabNo(0);
      setSelectParticipants([]);
    }
  }

  const handleAddCategory = () => {
    setCategories((prevCategories) => [...prevCategories, {}]);
  };

  const handleCategoryChange = (index, key, value) => {
    setCategoryData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [key]: value,
      },
    }));
  };

  const toggleYearPicker = () => {
    setYearPicker(!yearPicker);
  };

  const handleRangeData = (rangeData) => {
    handleCategoryChange(selectedCategoryIndex, "year", rangeData);
  };

  const handleFileUpload = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined &&
      setFileData((prev) => ({
        ...prev,
        file: uploadFile[0],
      }));
  };
  const handleFile = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined && setFile([...file, ...uploadFile]);
  };

  useEffect(() => {
    const vendorId = vendorBasicDetails?._id;
    dispatch(gql_get_all_stakeholders(vendorId, "EXTERNAL"));
  }, []);

  useEffect(() => {
    // reportsTab === 0 &&
    //   getPublicReports(login.token, brmData?.organizationDetails[0]?._id);
    // reportsTab === 1 &&
    //   props.getScores(login.token, brmData?.organizationDetails[0]?.isin);
  }, [reportsTab]);
  //   useEffect(()=>{
  //     const copyfile = new Blob([file], { type: "application/pdf" });
  //     //Build a URL from the file
  //     const fileURL = URL.createObjectURL(copyfile);
  //     //Open the URL on new Window
  //     //  const pdfWindow = window.open();
  //     //  pdfWindow.location.href = fileURL;
  //   },[file])

  const handleFileRemove = (value) => {
    setFile(value);
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  useEffect(() => {
    fileData.year && setStartYear(fileData.year?.split("-")[0]);
    fileData.year && setEndYear(fileData.year?.split("-")[1]);
  }, [fileData.year]);
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  useEffect(() => {
    const data = [];
    if (!fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(fileData.year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const handleFileSubmit = async () => {
    setIsUploadDrawerOpen(false);

    if (fileData?.type?.includes("Magpie")) {
      if (fileData?.type === "Magpie (Contract)") {
        await dispatch(
          rest_upload_dora_compliance_file(
            vendorBasicDetails?.isin,
            fileData?.file,
            token,
            "Contract"
          )
        );
      } else if (fileData?.type === "Magpie (Incident Policy)") {
        await dispatch(
          rest_upload_dora_compliance_file(
            vendorBasicDetails?.isin,
            fileData?.file,
            token,
            "Incident_Policy"
          )
        );
      } else if (fileData?.type === "Magpie (TPSP Policy)") {
        await dispatch(
          rest_upload_dora_compliance_file(
            vendorBasicDetails?.isin,
            fileData?.file,
            token,
            "TPSP_Policy"
          )
        );
      } else if (fileData?.type === "Magpie (IS Policy)") {
        await dispatch(
          rest_upload_dora_compliance_file(
            vendorBasicDetails?.isin,
            fileData?.file,
            token,
            "IS_Policy"
          )
        );
      }

      const uploadedData = [
        {
          createdAt: new Date().toLocaleDateString(),
          file: {
            name: fileData?.file?.name,
            type: fileData?.file?.type,
            url: "",
          },
          uploadedBy: {
            name: name,
          },
          inprogress: true,
        },
      ];

      setPreFileUpload(uploadedData);
    } else {
      const dateSplit = fileData?.year?.split("-");
      const startDate = dateSplit[0];
      const endDate = dateSplit[dateSplit.length - 1];

      const whichFileToBeSent =
        fileData?.type === "XBRL" ? fileData?.xbrlUrl : fileData?.file;

      await dispatch(
        rest_upload_va_file_from_ba(
          token,
          vendorBasicDetails?.isin,
          fileData?.year,
          whichFileToBeSent,
          startDate,
          endDate,
          fileData?.type,
          vendorBasicDetails?._id,
          fileData?.sourceUrl,
          name
        )
      );
    }

    setFileData({});

    dispatch(rest_get_vendor_uploaded_files(token, vendorBasicDetails?.isin));
    dispatch(
      gql_get_dora_compliance_files_for_diligence(vendorBasicDetails?.isin)
    );
  };

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  yearOfAssessment: "",
                }));
                fileData.year && setStartYear(fileData.year.split("-")[0]);
                fileData.year && setEndYear(fileData.year.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  year: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const fileUploadDrawer = () => {
    const vendorStakeholders = brmData?.members;

    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => {
          setIsRequestUpload(false);
          setIsUploadDrawerOpen(false);
        }}
        // variant="persistent"
        width="lg"
        open={isUploadDrawerOpen}
      >
        <div style={{ width: "500px", padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              {isrequestUpload ? "Request File" : "Upload File"}
            </Typography>
            <Typography
              onClick={() => {
                setIsRequestUpload(false);
                setIsUploadDrawerOpen(false);
              }}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer" }} />
            </Typography>
          </div>
          {isrequestUpload ? (
            <div>
              <MuiThemeProvider theme={appTheme}>
                <AppBar
                  position="static"
                  color="inherit"
                  className={classes.AppBar}
                >
                  <Tabs
                    value={requestTabNo}
                    onChange={(e, newVal) => {
                      setRequestTabNo(newVal);
                      // setReportsTab(0);
                    }}
                  >
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Participants</span>
                      }
                    />
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Document Type</span>
                      }
                      disabled={requestTabNo !== 1}
                    />
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
              <TabPanel value={requestTabNo} index={0}>
                <div style={{ marginTop: 40 }}>
                  <CustomTextfield
                    name="Search"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Search user by name or email Id or employee id"
                    onChange={(e) => {
                      // setSearchCompanyText(e.target.value);
                      // onSearchHandler(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon style={{ color: "#3374B9" }} />
                        </InputAdornment>
                      ),
                    }}
                    // value={searchCompanyText}
                  />

                  {vendorStakeholders?.map((stakeholder) => (
                    <UserCard
                      member={{
                        name: stakeholder.name,
                        email: stakeholder.email,
                        designation: stakeholder.designation,
                        id: stakeholder._id,
                      }}
                      checked={checked}
                      setChecked={setChecked}
                      onClick={(id) => handleParticipants(id)}
                      key={stakeholder._id}
                    />
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={requestTabNo} index={1}>
                <div style={{ marginTop: 40 }}>
                  <BlueBackgroundCard heading="Click on add categories and select the required document type and the year." />

                  {categories.map((_, index) => (
                    <Grid container key={index} style={{ margin: "1.5em 0" }}>
                      <Grid md={6}>
                        <CustomTextfield
                          name={`category-${index}`}
                          value={categoryData[index]?.type}
                          label="Select Type of Document"
                          variant="outlined"
                          size="small"
                          fullWidth
                          select
                          onChange={(e) =>
                            handleCategoryChange(index, "type", e.target.value)
                          }
                        >
                          {[
                            "Annual Report",
                            "ESG Report",
                            "GRI Report",
                            "XBRL",
                            "Magpie (Contract)",
                            "Magpie (Incident Policy)",
                            "Magpie (TPSP Policy)",
                            "Magpie (IS Policy)",
                            "Other",
                          ]?.map((cat) => (
                            <MenuItem key={cat} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </CustomTextfield>
                      </Grid>
                      <Grid md={6}>
                        <CustomTextfield
                          name={`selectYear-${index}`}
                          value={categoryData[index]?.year}
                          label={!categoryData[index]?.year && "Select Year"}
                          variant="outlined"
                          style={{ marginLeft: "1em", cursor: "pointer" }}
                          InputProps={{
                            style: {
                              pointerEvents: "none",
                            },
                            endAdornment: (
                              <CalendarIcon style={{ color: "#3374B9" }} />
                            ),
                          }}
                          size="small"
                          onClick={() => {
                            setSelectedCategory(categoryData[index]);
                            setSelectedCategoryIndex(index);
                            toggleYearPicker();
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <CustomButton
                    color="primary"
                    style={{ textTransform: "none" }}
                    onClick={handleAddCategory}
                  >
                    Add Category
                  </CustomButton>
                </div>
              </TabPanel>

              {/* submit button for Request File */}
              <Button
                variant="text"
                style={{
                  color: "#3374B9",
                  position: "fixed",
                  bottom: 10,
                  right: 160,
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  requestTabNo === 1
                    ? setRequestTabNo(0)
                    : setIsRequestUpload(false) || setIsUploadDrawerOpen(false);
                }}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                className={classes.endButton}
                disabled={
                  requestTabNo === 0
                    ? selectParticipants.length === 0
                    : !modifiedCat[modifiedCat?.length - 1]?.hasOwnProperty(
                        "year"
                      ) ||
                      !modifiedCat[modifiedCat?.length - 1]?.hasOwnProperty(
                        "type"
                      ) ||
                      modifiedCat?.length !== categories?.length
                }
                onClick={() => {
                  setCategoryData({});
                  setCategories([]);
                  setIsRequestUpload((prev) =>
                    requestTabNo === 0 ? true : false
                  );
                  setIsUploadDrawerOpen((prev) =>
                    requestTabNo === 0 ? true : false
                  );
                  handleRequestFiles(requestTabNo);
                }}
              >
                {requestTabNo === 0 ? "Next" : "Assign to Users"}
              </Button>
            </div>
          ) : (
            <div>
              <FormControl
                variant="outlined"
                style={{ width: "100%", margin: "15px 0px" }}
              >
                <InputLabel htmlFor="age-native-helper">
                  Select File Type
                </InputLabel>{" "}
                <Select
                  fullWidth
                  size="small"
                  // style={{
                  //   border: "1px solid #C0D4E9",
                  //   backgroundColor: "#FEFEFE",
                  //   borderRadius: "8px",
                  //   // height: "40px",
                  //   // margin: "15px 0px",
                  // }}
                  // inputProps={{
                  //   name: 'Select File Type',
                  //   id: 'age-native-helper',
                  // }}
                  label="Select Type Of Report"
                  // value={currentLink.controversy}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setFileData((prev) => ({
                      ...prev,
                      type: value,
                      xbrlUrl: "",
                      file: value === "XBRL" ? null : fileData?.file,
                    }));
                  }}
                  // input={<OutlinedInput />}
                >
                  {[
                    "Annual Report",
                    "ESG Report",
                    "GRI Report",
                    "XBRL",
                    "Magpie (Contract)",
                    "Magpie (Incident Policy)",
                    "Magpie (TPSP Policy)",
                    "Magpie (IS Policy)",
                    "Other",
                  ].map((item) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{ backgroundColor: "white" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {!fileData?.type?.includes("Magpie") && (
                <Box
                  my={2}
                  onClick={() => setIsDatePickerDialogOpen(true)}
                  style={{
                    border: "1px solid #00000050",
                    padding: 12,
                    borderRadius: 5,
                    cursor: "pointer",
                    minHeight: 45,
                  }}
                >
                  {fileData.year ? (
                    <Typography variant="body1" name="yearOfAssesment">
                      {`${fileData.year.split("-")[0]} - ${
                        fileData.year.split("-")[1]
                      }`}
                    </Typography>
                  ) : (
                    <Typography
                      style={{ color: "#878282e6", fontFamily: "Roboto" }}
                    >
                      Year Of Assessment
                    </Typography>
                  )}

                  {/* <Typography variant="body1" name="yearOfAssesment">
                    {basicInfo.yearOfAssessment[0].startDate !== "" &&
                    basicInfo.yearOfAssessment[0].endDate !== ""
                      ? `${moment(
                          new Date(basicInfo.yearOfAssessment[0].startDate)
                        ).format("DD MMM yy")} - ${moment(
                          new Date(basicInfo.yearOfAssessment[0].endDate)
                        ).format("DD MMM yy")}`
                      : "Year Of Assessment"}
                  </Typography> */}
                </Box>
              )}

              {!fileData?.type?.includes("Magpie") &&
              fileData?.type === "XBRL" ? (
                <Box
                  width="100%"
                  style={{
                    margin: "26px 0",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <TextField
                    variant={"outlined"}
                    size={"medium"}
                    placeholder="XBRL URL"
                    fullWidth
                    onChange={(e) => {
                      setFileData((prev) => ({
                        ...prev,
                        xbrlUrl: e.target.value,
                      }));
                    }}
                  />
                </Box>
              ) : (
                <Box
                  width="100%"
                  style={{
                    margin: "26px 5px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  <AttachFileIcon
                    style={{ backgroundColor: "#ced4da", padding: 4 }}
                    color="disabled"
                  />
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    accept=".pdf"
                    ref={inputRef}
                    onChange={(e) => handleFileUpload(e)}
                    style={{ display: "none" }}
                    multiple
                  />
                  <div
                    style={{
                      borderBottom: "2px solid #ced4da",
                      marginLeft: 20,
                      width: "100%",
                    }}
                  >
                    {fileData?.file?.name ? fileData.file.name : "Upload file"}
                  </div>
                </Box>
              )}

              {!fileData.type?.includes("Magpie") && (
                <Box
                  width="100%"
                  style={{
                    margin: "26px 0",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <TextField
                    variant={"outlined"}
                    // size={"medium"}
                    placeholder="Soruce URL"
                    fullWidth
                    onChange={(e) => {
                      setFileData((prev) => ({
                        ...prev,
                        sourceUrl: e.target.value,
                      }));
                    }}
                  />
                </Box>
              )}

              <CustomButton
                style={{
                  position: "fixed",
                  bottom: 10,
                  right: 5,
                  // color: "#3374b9",
                  marginRight: "5px",
                  fontSize: "14px",
                  // padding: 10,
                }}
                disabled={
                  (!fileData?.file && fileData?.xbrlUrl === "") ||
                  fileData?.type === "" ||
                  (!fileData?.type?.includes("Magpie") &&
                    fileData?.sourceUrl === "") ||
                  (!fileData?.type?.includes("Magpie") && fileData?.year === "")
                }
                // variant={"filled"}
                color="primary"
                onClick={() => {
                  handleFileSubmit();
                }}
              >
                Upload file
              </CustomButton>
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      {isUploadDrawerOpen && fileUploadDrawer()}
      {isDatePickerDialogOpen && yearPickerDialog()}

      <YearRangePicker
        open={yearPicker}
        onClose={toggleYearPicker}
        currentRange={selectedCategory?.year}
        handleRangeData={handleRangeData}
        singleYear={true}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showPeerDocs && brmData?.brmSubTabNo === 2 && (
            <Icon>
              <ArrowBackIcon
                onClick={() => setShowpeerDocs(false)}
                style={{ color: "#3374B9", cursor: "pointer" }}
              />
            </Icon>
          )}
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "16px",
              marginLeft: showPeerDocs ? "5px" : 0,
            }}
          >
            {showPeerDocs && brmData?.brmSubTabNo === 2
              ? companyName
              : "Company Documents"}
          </Typography>
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "20%",
          }}
        >
          {/* <Typography
            style={{
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Filter
          </Typography> */}

          {/* <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            accept=".pdf"
            onChange={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="contained-button-activityFile"> */}
          {/* <CustomButton
            color="primary"
            style={{ textTransform: "none", padding: "3px 10px" }}
          >
            Request Files
          </CustomButton> */}
          {/* {console.log("button", isrequestUpload, isUploadDrawerOpen)} */}
          <Typography
            role="button"
            onClick={() => {
              setIsUploadDrawerOpen(!isUploadDrawerOpen);
              setIsRequestUpload(!isrequestUpload);
            }}
            style={{
              cursor: "pointer",
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
            }}
          >
            Request File
          </Typography>
          <Typography
            role="button"
            onClick={() => {
              setIsUploadDrawerOpen(!isUploadDrawerOpen);
            }}
            style={{
              cursor: "pointer",
              textTransform: "none",
              color: "#3374b9",
              marginRight: "5px",
              fontSize: "14px",
            }}
          >
            Upload File
          </Typography>
          {/* </label> */}
        </div>
      </div>
      {/* {console.log(companyDocuments.length)} */}
      {[...customUploadedFiles, ...customDoraFiles]?.length > 0 ? (
        <>
          {/* <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={reportsTab}
            onChange={(e, newVal) => {
              setReportsTab(newVal);
              // setReportsTab(0);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Reports</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Data Breakdown</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider> */}
          <TabPanel value={reportsTab} index={0}>
            <PublicReports
              {...props}
              publicDocuments={[
                ...customUploadedFiles,
                ...customDoraFiles,
                ...preFileUpload,
              ]}
              doraComplianceFiles={customDoraFiles}
              file={file}
              handleFileRemove={handleFileRemove}
              isFrom={"companyDocs"}
            />
          </TabPanel>
          {/* <TabPanel value={reportsTab} index={1}>
        <DataBreakdown
          riskEvaluator={riskEvaluator}
          scores={riskEvaluator?.scores}
        />
      </TabPanel> */}
        </>
      ) : (
        <div className={classes.emptyCont}>
          <img className={classes.emptyImg} src={Analytics} alt="analytics" />
          <Typography className={classes.text}>
            Currently No File has Been Uploaded To Showcase
          </Typography>
          <Typography className={classes.subText}>
            Upload file type (.xls, .pdf, .doc) Max size of 5 Mb
          </Typography>
          <Grid container justifyContent="center">
            <CustomButton
              className={classes.uploadBtn}
              // variant="contained"
              color="primary"
              onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            >
              Upload File
            </CustomButton>
            <CustomButton
              className={classes.uploadBtn}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsUploadDrawerOpen(!isUploadDrawerOpen);
                setIsRequestUpload(true);
              }}
            >
              Request File
            </CustomButton>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default CompanyDocuments;
