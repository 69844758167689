import { Box, IconButton, Typography } from "@material-ui/core";
import { AntSwitch } from "components/UI/switch/AntSwitch";
import { useState } from "react";
import { FiTrash } from "react-icons/fi";

const HeadActions = ({
  alertName,
  isChecked = true,
  handleCheck,
  handleDelete,
}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleSwitch = (e) => {
    const isCheck = e.target.checked;
    setChecked(isCheck);

    handleCheck && handleCheck(isCheck);
  };

  return (
    <Box
      padding="8px 10px 8px 10px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid #A1BFDF"
    >
      <Typography variant="body2" style={{ fontWeight: 600 }}>
        {alertName}
      </Typography>

      {/* Action buttons */}
      <Box display="flex" alignItems="center" sx={{ gap: 10 }}>
        <AntSwitch checked={checked} onChange={handleSwitch} />
        <IconButton size="small" onClick={handleDelete}>
          <FiTrash size={18} color="#C45446" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default HeadActions;
