import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import { set_snack_bar } from "../../../../../../../../redux/actions/snackbar/snackbar_action";
import Textfield from "../../../../../../../UI/textfield/textfield";

const EmailQuestion = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const handleEmailChange = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
  };

  useEffect(() => {
    let obj = {};
    if (
      validateEmail(email) &&
      isFrom === "taskSection" &&
      isFrom !== "approvalSection"
    ) {
      obj = {
        value: email,
        weightage: 1,
        comment: commentBox,
      };
      setError(false);
    } else {
      setError(true);
      set_snack_bar(true, "Please enter a valid email address");
    }

    if (isFrom !== "approvalSection" && isFrom === "taskSection") {
      handleQuesAnswer(obj);
    }
  }, [email, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setEmail(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  // const handleSubmit = () => {
  //   if (validateEmail(email)) {
  //     setError(false);
  //     setHelperText("");
  //     onSubmit(email); // Callback to handle submission
  //   } else {
  //     setError(true);
  //     setHelperText("Please enter a valid email address");
  //   }
  // };

  return (
    <Box style={{ pointerEvents: event }}>
      <Textfield
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        error={isFrom === "taskSection" ? error : false}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default EmailQuestion;
