import { makeStyles } from "@material-ui/core";

const proceedButtonUseStyles = makeStyles((theme) => ({
  checkmargin: {
    marginRight: 10,
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
    minWidth: "175px",
  },
  card: {
    textAlign: "right",
    position: "fixed",
    bottom: 0,
    width: "94%",
    zIndex: 100,
    height: 50,
  },
  costumButton: { marginRight: 10 },
  link: { textDecoration: "none" },
  cardContent: { padding: 7 },
}));

export default proceedButtonUseStyles;
