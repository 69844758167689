import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";

import { TextField } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";

const TextFieldQue = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  linkageAnswer,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const handleChange = (e) => {
    if (isFrom === "taskSection") {
      const newValue = e.target.value;
      setInputValue(newValue);

      const obj = {
        value: newValue,
        weightage: 1,
      };

      handleQuesAnswer(obj);
    }
  };

  React.useEffect(() => {
    if (!responseAnswer && linkageAnswer !== null) {
      if (submittedAnswer?.answer || submittedAnswer?.option) {
        dispatch(
          setSelectedOption(submittedAnswer?.option || submittedAnswer?.answer)
        );
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setInputValue(submittedAnswer[0]?.option);
    }
  }, [submittedAnswer]);

  useEffect(() => {
    if (linkageAnswer !== null && isFrom === "taskSection") {
      const obj = {
        value: linkageAnswer,
        weightage: 1,
      };

      setInputValue(linkageAnswer);
      handleQuesAnswer(obj);
    }
  }, [linkageAnswer, inputValue]);

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = inputValue || submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = inputValue || selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = inputValue || submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = inputValue || responseAnswer;
  } else {
    handleValue = inputValue || "";
  }

  return (
    <Box>
      <TextField
        label="Type your text here"
        variant="outlined"
        value={handleValue}
        onChange={(e) => handleChange(e)}
        fullWidth
        disabled={linkageAnswer !== null ? true : false}
        multiline
        minRows={4}
        margin="normal"
        style={{ pointerEvents: event }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};
export default TextFieldQue;
