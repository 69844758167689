import { Box } from "@material-ui/core";
import RangeFields from "components/UI/textfield/rangeFields";

const FrequencyRange = ({ range, handleRange, disableEdit }) => {
  return (
    <Box>
      <RangeFields
        range={range}
        handleRange={handleRange}
        minValue={0}
        maxValue={100}
        disableEdit={disableEdit}
      />
    </Box>
  );
};

export default FrequencyRange;
