import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomTextfield from "../../UI/textfield/textfield";
import { emailValidation } from "../../../redux/actions/regexValidation/regexValidation";
import { useDispatch } from "react-redux";
import { gql_forgot_password } from "../../../redux/actions/login/loginAction";

export default function LoginForgetPasswordDialog({
  forgetPassword,
  forgetPasswordDialog,
  setForgetPasswordDialog,
}) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const handleEmail = () => {
    dispatch(gql_forgot_password(email));
  };

  return (
    <Dialog
      open={forgetPasswordDialog}
      onClose={() => setForgetPasswordDialog(false)}
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle>
        Reset Password
        <Typography variant="body2">
          Please enter your email address to reset your password. We will send a
          reset link to your email address.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CustomTextfield
          variant="outlined"
          placeholder="Enter your Email"
          fullWidth
          onKeyPress={(e) => e.key === "Enter" && handleEmail()}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleEmail();
            setForgetPasswordDialog(false);
            setEmail("");
          }}
          disabled={!emailValidation(email)}
        >
          reset password
        </Button>
      </DialogActions>
    </Dialog>
  );
}
