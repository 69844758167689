import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Icon,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomButton from "../../../UI/button/button";
import { gql_get_exit_plan } from "redux/actions/vendorActions/vendorDiagnosisAction";
import { useSelector } from "react-redux";

const data = [
  {
    point: "Reason for Termination",
    details:
      "Multiple service delivery issues, security concerns, and failure to meet compliance standards",
  },
  {
    point: "BCP (Business Continuity Process)",
    details:
      "Engage interim vendor, migrate critical data and operations with minimal service disruption. Existing systems will run in parallel during the transition period to ensure uninterrupted services.",
  },
  {
    point: "Data Handling Post-Termination",
    details:
      "Ensure all customer data is securely transferred to the new provider and any remaining data with PayWise is securely deleted.",
  },
  {
    point: "Asset Transfer/Return",
    details:
      "All software licenses, hardware (POS equipment), and access credentials will be revoked or transferred.",
  },
  {
    point: "Financial Settlement",
    details:
      "Final payment settlement to PayWise on 30-Nov-2024. No outstanding liabilities remain.",
  },
];

const TerminationExitPlan = () => {
  const [openRow, setOpenRow] = useState({}); // Store the open state of each row

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const storedVendorId = localStorage.getItem("vendorId");

  const { bankId } = useSelector((state) => state.login);
  const { exitPlan } = useSelector((state) => state.vendorDiagnosis);
  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );

  const terminationAndOffboardingSurveyId = surveyListDataForm?.[
    "terminationSurveys"
  ]?.find((item) => item?.name === "Termination And Offboarding")?._id;

  useEffect(() => {
    if (terminationAndOffboardingSurveyId) {
      gql_get_exit_plan({
        bankId,
        surveyId: terminationAndOffboardingSurveyId,
        vendorId: vendorBasicDetails?._id || storedVendorId,
      });
    }
  }, [bankId, terminationAndOffboardingSurveyId, vendorBasicDetails?._id]);

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
        >
          Exit Plan for {vendorBasicDetails?.name}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#EBF1F8" }}>
            <TableRow>
              <TableCell>Point</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exitPlan?.[0]?.questions?.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{row?.question}</TableCell>
                  <TableCell>{row?.approvedAnswer?.[0]?.option}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>

        {exitPlan?.length === 0 && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Typography variant="body2">No exit plan data available</Typography>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default TerminationExitPlan;
