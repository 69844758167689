import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";

import React from "react";

export default function SetAlertPopper({ anchorEl, handleClose, setPopup }) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setPopup(true)}>
          {/* <ListItemIcon>
            <EditOutlined style={{ color: "#3374B9" }} />
          </ListItemIcon> */}
          <ListItemText primary="Set Alert" />
        </MenuItem>
      </Menu>
    </div>
  );
}
