import {
  Avatar,
  Button,
  Card,
  CardContent,
  Icon,
  Typography,
  useTheme,
} from "@material-ui/core";
import Stack from "components/UI/Stack/Stack";
// icons
import hirarchyIcon from "images/orgstructure/hierachy.svg";
import hirarchyChildIcon from "images/orgstructure/hierachy-child.svg";
import { GrLocation } from "react-icons/gr";
import { AvatarGroup } from "@material-ui/lab";

const RenderNode = ({ nodeDatum, onNodeClick }) => {
  const theme = useTheme();
  const isParentNode = nodeDatum.children && nodeDatum.children.length > 0;

  const fields = [
    { label: "Vendors", value: nodeDatum.vendors },
    { label: "Assets", value: nodeDatum.total_assets },
    { label: "Suppliers", value: nodeDatum.total_suppliers },
  ].filter((field) => field.value !== undefined);

  return (
    <foreignObject width="295" height="360" x="-150" y="-180">
      <Card
        style={{
          minWidth: 295,
          textAlign: "center",
          boxShadow: "0px 4px 8px -2px #15314E1A",
          border: "1px solid #cccccc80",
        }}
        onClick={onNodeClick}
      >
        <CardContent>
          <Stack spacing={10} alignItems={"center"} mb={1}>
            <Stack
              direction="column"
              minWidth={40}
              minHeight={40}
              alignItems={"center"}
              justifyContent={"center"}
              bgcolor={"primary.lighter"}
              borderRadius={0.5}
            >
              <img
                src={isParentNode ? hirarchyIcon : hirarchyChildIcon}
                alt="hirarchyIcon"
                style={{ width: 20, height: 20 }}
              />
            </Stack>

            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {nodeDatum.entity_name || nodeDatum.name}
            </Typography>
          </Stack>

          {nodeDatum?.state && (
            <Stack alignItems={"flex-start"} spacing={10} mb={1}>
              <Icon color="primary">
                <GrLocation size={18} />
              </Icon>
              <Typography
                variant="subtitle2"
                style={{
                  color: theme.palette.text.faded,
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {nodeDatum?.state}
              </Typography>
            </Stack>
          )}

          {/* Type */}
          <Stack spacing={10} alignItems={"flex-end"} mb={1}>
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.text.faded, fontWeight: 600 }}
            >
              Type
            </Typography>

            <Typography
              variant="body2"
              style={{ fontWeight: 500, textTransform: "capitalize" }}
            >
              {nodeDatum.type?.toLowerCase() || "Parent"}
            </Typography>
          </Stack>

          {/* Units/Assets/Supplier */}
          <Stack spacing={20} mb={1}>
            {fields.map((field, index) => (
              <Stack
                key={index}
                direction="column"
                spacing={10}
                alignItems={"flex-start"}
              >
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: 600, color: theme.palette.text.faded }}
                >
                  {field.label}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                  {field.value}
                </Typography>
              </Stack>
            ))}
          </Stack>

          {/* Stakeholders */}
          {nodeDatum?.stakeholders && nodeDatum?.stakeholders?.length !== 0 && (
            <Stack spacing={10} mb={1}>
              <Stack direction="column" spacing={10} alignItems={"flex-start"}>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: 600, color: theme.palette.text.faded }}
                >
                  Stakeholders
                </Typography>

                {/* Avatar group */}
                <AvatarGroup total={nodeDatum?.stakeholders?.length} max={4}>
                  {nodeDatum?.stakeholders?.map((user) => (
                    <Avatar
                      key={user?._id}
                      alt={user?.profile_img?.name || "Avatar"}
                      src={user?.profile_img?.url}
                    />
                  ))}
                </AvatarGroup>
              </Stack>
            </Stack>
          )}

          <Stack alignItems={"flex-start"}>
            <Button color={"primary"}>View</Button>
          </Stack>
        </CardContent>
      </Card>
    </foreignObject>
  );
};
export default RenderNode;
