import { Card, CardContent } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import {
  LeftCard,
  brmLeftStyles,
} from "../../widgets/riskAssessment/brmDataLeft/brmDataLeft";
import { gql_get_linkage_surveys } from "../../../redux/actions/vendorActions/vendorAction";
import { useDispatch, useSelector } from "react-redux";

function VendorLeft({ setLinkageSurveyId, ...props }) {
  const { vendorTabNo, vendorSubTabNo } = props.vendor;
  const { setVendorSubTabNo } = props;
  const classes = brmLeftStyles();

  const handleTabChange = (index, surveyId) => {
    setVendorSubTabNo(index);

    setLinkageSurveyId(surveyId);
  };
  const { linkageSurveys } = useSelector((state) => state.brmData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gql_get_linkage_surveys());
  }, []);

  const basicDataFirstTab = [
    {
      id: 0,
      mainHead: "Basic Dataform Details",
      // subHead:
      //   "Enter key details to define the scope and objectives of your dataform. Tailor the TPRM process to fit your company's unique needs.",
    },
    {
      id: 1,
      mainHead: "Stakeholders List",
      // subHead:
      //   "List all relevant stakeholders, their roles, and contact details. Ensure effective communication and accountability in the risk management process.",
    },
    {
      id: 2,
      mainHead: "Ongoing Assessment",
      // subHead:
      //   "List all relevant stakeholders, their roles, and contact details. Ensure effective communication and accountability in the risk management process.",
    },

    ...linkageSurveys.map((survey, index) => {
      return {
        id: index + 3,
        mainHead: survey?.name,
        _id: survey?._id,
        // subHead: survey?.details,
      };
    }),
    // {
    //   id: 2,
    //   mainHead: "Register Of Information",
    //   subHead:
    //     "Document critical third-party engagement details, including data categories and retention. Maintain transparency and compliance with a comprehensive register.",
    // },
    // {
    //   id: 3,
    //   mainHead: "Vendor Materiality",
    //   subHead:
    //     "Document critical third-party engagement details, including data categories and retention. Maintain transparency and compliance with a comprehensive register.",
    // },
    // {
    //   id: 4,
    //   mainHead: "Vendor Performance",
    //   subHead:
    //     "Document critical third-party engagement details, including data categories and retention. Maintain transparency and compliance with a comprehensive register.",
    // },
    // {
    //   id: 5,
    //   mainHead: "Vendor Categorization",
    //   subHead:
    //     "Document critical third-party engagement details, including data categories and retention. Maintain transparency and compliance with a comprehensive register.",
    // },
  ];

  const dataSetup = [
    {
      id: 0,
      mainHead: "API Connection",
      // subHead:
      //   "Integrate TPRM dataform data with your systems via a secure API. Streamline data flow and enhance risk analysis.",
    },

    {
      id: 1,
      mainHead: "Data Forms",
      // subHead:
      //   "Create and deploy detailed dataforms to gather vendor data. Tailor questionnaires to capture relevant insights for risk management.",
    },
    {
      id: 2,
      mainHead: "Documents",
      // subHead:
      //   "Facilitate secure document submissions from vendors. Ensure critical files are easily accessible and safely stored for audits.",
    },
  ];

  const vendorDiagnosis = [
    {
      id: 0,
      mainHead: "Vendor Intake/Scoping",
      // subHead:
      //   "Access detailed vendor profiles with risk ratings, compliance status, and performance history. Evaluate reliability and identify risks at a glance.",
    },
    {
      id: 1,
      mainHead: "Risk Assessment",
      // subHead:
      //   "Verify vendor compliance across their contracts, policies and more. Ensure your vendors meet regulatory requirements to mitigate operational risks.",
    },

    {
      id: 2,
      mainHead: "Due Diligence",
      // subHead:
      //   "Review vendor dataform responses to evaluate their risk profile. Gain insights into their practices and uncover potential issues.",
    },
    {
      id: 3,
      mainHead: "Contracting",
    },
    {
      id: 4,
      mainHead: "Onboarding",
    },
    {
      id: 5,
      mainHead: "Monitoring",
    },
    {
      id: 6,
      mainHead: "Termination And Offboarding",
    },
  ];

  const esgDiagnosis = [
    {
      id: 0,
      mainHead: "ESG Profile",
      // subHead:
      //   "Evaluate the environmental, social, and governance profile of your partners. Understand their commitment to sustainable practices.",
    },
    {
      id: 1,
      mainHead: "ESG KPI's",
      // subHead:
      //   "Track and measure key performance indicators related to ESG criteria. Analyze data to ensure compliance with your sustainability goals.",
    },

    {
      id: 2,
      mainHead: "Dataform Responses",
      // subHead:
      //   "Analyze dataform responses focused on ESG criteria to gauge vendor compliance and risk. Identify strengths and areas for improvement.",
    },
  ];

  const contro = [
    {
      id: 0,
      mainHead: "Digital Reputation",
      // subHead:
      //   "Explore records of digital reputation associated with your vendors. Assess potential risks and impact on your business.",
    },
    {
      id: 1,
      mainHead: "Articles",
      // subHead:
      //   "Access a collection of articles and links related to vendor digital reputations. Stay informed with the latest information and insights.",
    },

    {
      id: 2,
      mainHead: "Insights",
      // subHead:
      //   "Gain detailed insights into the nature and impact of digital reputations. Understand how these issues could affect your partnership.",
    },
  ];
  const vendorRisk = [
    {
      id: 0,
      mainHead: "TPRM Risk Report",
      // subHead:
      //   "Examine the ESG risk rating for your vendors. Assess their alignment with your sustainability and ethical standards.",
    },
    {
      id: 1,
      mainHead: "Third Party Risk Rating",
      // subHead:
      //   "View the risk rating for each third-party vendor. Evaluate their potential impact on your operations and compliance..",
    },
  ];

  const scroll = useRef(null);

  useEffect(() => {
    if (vendorSubTabNo === 3 && vendorTabNo === 0) {
      scroll?.current?.scrollTo(0, scroll.current.scrollHeight);
    }
    if (vendorSubTabNo === 2 && vendorTabNo === 0) {
      scroll?.current?.scrollTo(0, 0);
    }
  }, [vendorSubTabNo, vendorTabNo]);

  return (
    <div>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        {
          <CardContent
          // className={
          //   vendorTabNo !== 1 && vendorTabNo !== 2 ? classes.cardContent : {}
          // }
          >
            {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={vendorTabNo} index={0}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
                ref={scroll}
              >
                <LeftCard
                  toMap={basicDataFirstTab}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>

            {/* <TabPanel value={vendorTabNo} index={1}>
            <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
              {leftCard(basicDataThirdTab)}
            </div>
          </TabPanel> */}
            <TabPanel value={vendorTabNo} index={1}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {" "}
                <LeftCard
                  toMap={dataSetup}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                  vendorTabNo={vendorTabNo}
                />
              </div>
            </TabPanel>

            <TabPanel value={vendorTabNo} index={2}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                <LeftCard
                  toMap={vendorDiagnosis}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                  vendorTabNo={vendorTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={3}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {" "}
                <LeftCard
                  toMap={esgDiagnosis}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={4}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {" "}
                <LeftCard
                  toMap={contro}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={5}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                <LeftCard
                  toMap={vendorRisk}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            {/* </div> */}
          </CardContent>
        }
      </Card>
    </div>
  );
}

export default VendorLeft;
