import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  Typography,
} from "@material-ui/core";
import tableStatusColors from "../../../../utils/getColors/tableStatusColors";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_shared_survey_details } from "redux/actions/vendorActions/vendorDiagnosisAction";
import { SET_SHARED_SURVEY_DETAILS } from "constants/vendorDiagnosisConstants";
import { PaymentOutlined } from "@material-ui/icons";

const AssessmentTable = ({
  setIsDrawerOpen,
  setFrequencyDetails,
  setBankRoomId,
  surveyIds = [],
}) => {
  const dispatch = useDispatch();

  const { bankId } = useSelector((state) => state.login);
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { sharedSurveyDetails } = useSelector((state) => state.vendorDiagnosis);
  const storedVendorId = localStorage.getItem("vendorId");

  const toggleThread = (chat_room_id) => {
    if (chat_room_id) {
      setBankRoomId(chat_room_id);
    }
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    if (surveyIds?.length > 0) {
      dispatch(
        gql_get_shared_survey_details({
          bankId,
          surveyIds,
          vendorId: vendorBasicDetails?._id || storedVendorId,
        })
      );
    }

    return () => {
      dispatch({ type: SET_SHARED_SURVEY_DETAILS, payload: [] });
    };
  }, [surveyIds?.length]);

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#EBF1F8",
              borderBottom: "1px solid #A1BFDF",
            }}
          >
            {tableHeaders.map((head) => (
              <TableCell
                style={{ fontWeight: 600, color: "#15314E" }}
                key={head}
              >
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sharedSurveyDetails?.length > 0 &&
            sharedSurveyDetails?.map((row, index) => {
              const { bgColor, color } = tableStatusColors({
                type: "TF",
                status: row?.isCompleted,
              });

              return (
                <TableRow key={index}>
                  <TableCell>{row?.surveyName}</TableCell>
                  <TableCell>{row?.type?.split("_").join(" ")}</TableCell>
                  <TableCell>
                    {moment(row?.dateOfAssessment).format("Do MMM, YYYY")}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row?.isCompleted ? "Completed" : "Ongoing"}
                      style={{
                        width: "10em",
                        backgroundColor: bgColor,
                        color: color,
                        fontWeight: 400,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => {
                        toggleThread(row?.chatRoomId);
                        setFrequencyDetails(row);
                      }}
                    >
                      Open Chat
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {sharedSurveyDetails?.length === 0 && (
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          <Typography variant="body2">No survey has been shared yet</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AssessmentTable;

// -------------- Additional --------------
// Table Headers
const tableHeaders = [
  "Assessment Name",
  "Frequency",
  "Date of Assessment",
  "Status",
  "Open Chat",
];

// Temporary data for rows
const rows = [
  {
    name: "Assessment 1",
    frequency: "Monthly",
    date: "2024-01-15T00:00:00Z",
    status: "Completed",
  },
  {
    name: "Assessment 2",
    frequency: "Quarterly",
    date: "2024-03-01T00:00:00Z",
    status: "In Progress",
  },
  {
    name: "Assessment 3",
    frequency: "Yearly",
    date: "2024-08-21T00:00:00Z",
    status: "Pending",
  },
];
