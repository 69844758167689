import { Box, createTheme, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";
import { BlueBackgroundCard } from "components/widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import {
  Question,
  Wrapper,
} from "components/widgets/selfAssessment/selfAssessmentResponse";
import { gql_get_completed_questions } from "redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_COMPLETED_QUESTIONS } from "constants/esgDiagnosisConstant";
import OptimizeInitialRender from "components/widgets/riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";
import NoData from "components/widgets/NoData/NoData";
import { gql_get_submitted_survey_responses } from "redux/actions/vendorActions/vendorAction";
import { SET_SUBMITTED_SURVEY_RESPONSES } from "constants/brmConstants";

export const appbarStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3374B9",
    },
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

export default function SurveyResponsesForChat({
  viewSurveyDrawer,
  handleCloseViewDialog,
  showSelectedSurvey,
  contributor,
}) {
  const {
    vendorBasicDetails,
    submittedSurveyResponses,
    selectedBankDetailsForVa,
  } = useSelector((state) => state.brmData);

  const { bankId, user_type, vendorId } = useSelector((state) => state.login);

  console.log(bankId, vendorId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user_type === "BSA") {
      dispatch(
        gql_get_submitted_survey_responses(
          selectedBankDetailsForVa?._id,
          bankId,
          contributor?.next?.connectedEntityId
        )
      );
    } else if (user_type === "VA") {
      dispatch(
        gql_get_submitted_survey_responses(
          vendorId,
          selectedBankDetailsForVa?._id,
          contributor?.next?.connectedEntityId
        )
      );
    } else {
      dispatch(
        gql_get_submitted_survey_responses(
          vendorBasicDetails?._id,
          bankId,
          contributor?.next?.connectedEntityId
        )
      );
    }
    return () => {
      dispatch({ type: SET_SUBMITTED_SURVEY_RESPONSES, payload: [] });
    };
  }, [contributor]);

  return (
    <Box sx={{ height: "80%", overflowY: "scroll", paddingBottom: "10px" }}>
      {submittedSurveyResponses?.length > 0 ? (
        <OptimizeInitialRender chunkSize={1}>
          {submittedSurveyResponses?.map((data) => (
            <>
              <BlueBackgroundCard heading={data?.sectionTitle} />
              {data?.questions?.map((question) => (
                <Question
                  key={question?.questionId}
                  question={question?.question}
                  options={question?.answers}
                  type={question?.questionType}
                  weightage={question?.weightage}
                  submittedAnswer={
                    question?.questionType === "TABLE"
                      ? question?.approvedTableRows
                      : question?.approvedAnswer
                  }
                  comment={
                    question?.questionType === "TABLE"
                      ? question?.approvedTableRows?.length > 0 &&
                        question?.approvedTableRows[0][0]?.comment !== null &&
                        question?.approvedTableRows[0][0]?.comment !== ""
                      : question?.approvedAnswer &&
                        question?.approvedAnswer[0]?.comment !== null &&
                        question?.approvedAnswer[0]?.comment !== ""
                  }
                  tableHeadersApi={question?.tableHeaders}
                />
              ))}
            </>
          ))}
        </OptimizeInitialRender>
      ) : (
        <NoData message={"No responses found"} />
      )}
    </Box>
  );
}
