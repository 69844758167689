import { makeStyles } from "@material-ui/core/styles";

const signupCardUseStyles = makeStyles({
  root: {
    maxWidth: "705px",
    minWidth: "680px",
    minHeight: "722px",
    border: "none",
    borderRadius: "8px",
  },
  topicCont: {
    padding: 5,
    width: "100%",
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    justifyContent: "center",
    borderRadius: 6,
  },
  topic: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #EBF1F8",
    borderRadius: 3,
    backgroundColor: "#FBFBFB",
    padding: "8px 8px",
    width: "46%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D6E3F1",
      "& $topicArrow": {
        color: "#FFF",
        backgroundColor: "#3374B9",
      },
    },
  },
  topicArrow: {
    borderRadius: 6,
    fontSize: 20,
    color: "#3374B9",
    marginLeft: "auto",
  },
  sideBarSubTitle: {
    fontSize: 13,
    lineHeight: "24px",
    fontWeight: "400",
    // color: "#FFFFFF",
    padding: "0px 5px",
    textTransform: "capitalize",
  },
  cardHeader: {
    fontWeight: 600,
  },
  tab: {
    fontSize: 12,
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  jc: {
    justifyContent: "flex-end",
    paddingTop: "20px",
  },
  cardcontent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  labelText: {
    color: "#9FA1AF",
    fontSize: 13,
    lineHeight: "24px",
    fontWeight: "400",
    paddingBottom: "3px",
  },
  checkboxLabelText: {
    color: "#5C90C7",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "400",
  },
  signupBtn: {
    textTransform: "none",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    maxWidth: "263px",
    width: "100%",
    minWidth: "100px",
    height: "43px",
  },
  codeTitle: {
    textTransform: "capitalize",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "500",
    color: "#242424",
  },
  didntReceive: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "400",
    color: "#242424",
    paddingRight: "16px",
  },
  sendAgain: {
    textTransform: "none",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "Poppins",
    margin: "0px 2px",
  },
  freetrial: {
    fontSize: 32,
    lineHeight: "48px",
    fontWeight: "600",
    paddingLeft: "23px",
  },
  featureTitle: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "600",
    color: "#3374B9",
  },
  featureSubTitle: {
    color: "#9FA1AF",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "400",
  },
  inputFieldFont: {
    fontSize: "13px",
  },
  inputField: {
    border: " 1px solid #DADBE6",
    borderRadius: "5px",
    marginBottom: 5,
  },
  inputMobileWidth: {
    width: 300,
  },
  inputMobile: {},
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  inputCountryCode: {
    width: 70,
    height: 40,
    marginRight: 25,
    marginTop: 10,
    padding: "0 12px",
  },
  displayFlex: {
    display: "Flex",
    alignItems: "center",
  },
  labelCheckbox: {
    fontSize: "12px !important",
    color: "#828282",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  rootTab: {
    "&$selected": {
      backgroundColor: "#FFFFFF",
      background: "none",
      borderBottom: "3px solid #47BB76",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selected: {
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  cardBox: {
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.18)",
  },
  cardList: {
    display: "flex",
    paddingBottom: "0px",
    paddingRight: "88px",
  },
  listTypo: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  listTypo1: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  listTypo2: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  costumTextField: {
    marginBottom: "12px",
    backgroundColor: "#FCFCFC",
    borderColor: "#E9E9E9",
  },
  inputStyle: {
    cursor: "pointer",
  },
  a: { textDecoration: "none" },

  otpSpan: {
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
  },
  otpSeperator: { width: "16px" },

  costumBtn: { width: "415px", height: "48px" },
  btnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "80px",
  },
});

export default signupCardUseStyles;
