import { Card, CardContent } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

import proceedButtonUseStyles from "../../../UI/proceedButtonCard/proceedButtonStyles";
import CustomButton from "../../../UI/button/button";
import { useSelector } from "react-redux";

const VendorProceedButton = (props) => {
  const classes = proceedButtonUseStyles();

  const { organizationDetails } = useSelector((state) => state.brmData);

  const history = useHistory();
  const location = useLocation();
  // console.log(location, history)
  const firstRoute = `/${location.pathname.split("/")[1]}`;

  const { vendor, setVendorTabNo, setVendorSubTabNo } = props;
  const { vendorTabNo, vendorSubTabNo } = vendor;

  const currentOrgData = organizationDetails[0];

  return (
    <div className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CustomButton
          name="exitBtn"
          color="primary"
          variant="text"
          style={{ textTransform: "capitalize" }}
          className={classes.costumButton}
          onClick={() => {
            if (vendorTabNo === 0 && vendorSubTabNo === 0) {
              history.push(firstRoute);
            } else if (
              vendorTabNo === 0 ||
              vendorTabNo === 1 ||
              vendorTabNo === 2 ||
              vendorTabNo === 3 ||
              vendorTabNo === 4 ||
              vendorTabNo === 5
            ) {
              if (vendorSubTabNo === 0) {
                setVendorTabNo(vendorTabNo - 1);
              } else {
                setVendorSubTabNo(vendorSubTabNo - 1);
              }
            }
          }}
        >
          {vendorTabNo === 0 && vendorSubTabNo === 0 ? "Exit" : "Previous"}
        </CustomButton>

        <CustomButton
          name="proceedBtn"
          color="primary"
          variant="contained"
          data-tut="reactour__proceed"
          className={classes.checkmargin}
          // disabled={!currentOrgData?.website}
          onClick={() => {
            // for tab 0
            if (vendorTabNo === 0) {
              if (vendorSubTabNo === 6) {
                setVendorTabNo(vendorTabNo + 1);
                setVendorSubTabNo(0);
              } else {
                setVendorSubTabNo(vendorSubTabNo + 1);
              }
            }
            // for tab 2
            else if (vendorTabNo === 1) {
              if (vendorSubTabNo === 2) {
                setVendorTabNo(vendorTabNo + 1);
                setVendorSubTabNo(0);
              } else {
                setVendorSubTabNo(vendorSubTabNo + 1);
              }
            }
            // for tab 2
            else if (vendorTabNo === 2) {
              if (vendorSubTabNo === 6) {
                setVendorTabNo(vendorTabNo + 1);
                setVendorSubTabNo(0);
              } else {
                setVendorSubTabNo(vendorSubTabNo + 1);
              }
            }
            // for tab 3-4
            else if (vendorTabNo > 2 && vendorTabNo < 5) {
              if (vendorSubTabNo === 2) {
                setVendorTabNo(vendorTabNo + 1);
                setVendorSubTabNo(0);
              } else {
                setVendorSubTabNo(vendorSubTabNo + 1);
              }
            }
            // for tab 5
            else if (vendorTabNo === 5) {
              if (vendorSubTabNo < 1) {
                setVendorSubTabNo(vendorSubTabNo + 1);
              } else {
                history?.push("/vendor");
                // submit and exit function
              }
            }
          }}
        >
          {vendorTabNo === 5 && vendorSubTabNo === 1
            ? "Save & Exit"
            : "Proceed"}
        </CustomButton>
      </CardContent>
    </div>
  );
};
export default VendorProceedButton;
