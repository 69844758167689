import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import DueDiligenceOverallScore from "./DueDiligenceOverallScore";
import CapacityAndAbility from "./CapacityAndAbility";
import ComplianceList from "./ComplianceList";
import CompilanceScore from "./ComplianceScore";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_survey_list_dataform,
  gql_get_survey_scores,
  gql_get_survey_scores2,
} from "../../../../redux/actions/vendorActions/vendorAction";
import DoraDocuments from "../../vendor/DoraDocuments";
import {
  gql_get_dora_compliance_files,
  gql_get_dora_compliance_files_for_diligence,
  gql_get_dora_compliance_results,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import AlertsDrawer from "../vendorIntake/AlertsDrawer";
import AssessmentTable from "../vendorIntake/AssessmentTable";
import ThreadDrawer from "components/widgets/vendorAdmin/surveySteps/step2/threadDrawer/ThreadDrawer";
import TechnicalAndOperationalSecurity from "./TechnicalAndOperationalSecurity";
import { ChartRenderer } from "components/widgets/charts/HistoricChart";

function DueDiligence({ isFrom }) {
  const dispatch = useDispatch();

  const [docId, setDocId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );
  const { bankId } = useSelector((state) => state?.login);

  const { doraComplianceResults, doraComplianceFiles } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { doraOverallScores, doraComplianceFiles: complianceFilesDiligence } =
    doraComplianceFiles;

  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [frequencyDetails, setFrequencyDetails] = useState({});
  const [bankRoomId, setBankRoomId] = useState("");

  const handleCloseDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  const storedVendorId = localStorage.getItem("vendorId");
  const storedVendorIsin = localStorage.getItem("vendorIsin");

  const handleSelectedDoc = (id, pdfUrl, pdfName) => {
    setDocId(id);
    dispatch(gql_get_dora_compliance_results(id));
    setPdfUrl(pdfUrl);
    setPdfName(pdfName);

    console.log(id, pdfUrl, pdfName);
  };

  useEffect(() => {
    if (isFrom !== "tprmRiskReport") {
      dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }
  }, []);

  const dueDiligenceSurveyId = surveyListDataForm?.[
    "dueDiligenceSurveys"
  ]?.find((item) => item?.name?.toLowerCase() === "vendor due diligence");

  const isoSurveyId = surveyListDataForm?.["dueDiligenceSurveys"]?.find(
    (item) => item?.name?.toLowerCase() === "iso 2700"
  );

  useEffect(() => {
    if (surveyListDataForm?.["dueDiligenceSurveys"]?.length > 0) {
      dispatch(
        gql_get_survey_scores(
          dueDiligenceSurveyId?._id,
          vendorBasicDetails?._id || storedVendorId
        )
      );

      dispatch(
        gql_get_survey_scores2(
          isoSurveyId?._id,
          vendorBasicDetails?._id || storedVendorId
        )
      );
    }
  }, [surveyListDataForm]);

  useEffect(() => {
    dispatch(
      gql_get_dora_compliance_files(
        vendorBasicDetails?.isin || storedVendorIsin,
        "Policy"
      )
    );
  }, []);

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  const customDoraResults =
    doraComplianceResults?.length > 0 ? [...doraComplianceResults] : [];

  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "" : "63vh" }}>
      {docId === null && (
        <>
          {isFrom === "tprmRiskReport" || (
            <Box
              sx={{
                mb: 2,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Due Diligence
              </Typography>

              <Button
                variant="text"
                style={{ color: "#3374B9", textTransform: "none" }}
                onClick={toggleAlertsDrawer}
              >
                Set Alerts
              </Button>
            </Box>
          )}

          <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
            {isFrom === "tprmRiskReport" || (
              <>
                <AssessmentTable
                  setIsDrawerOpen={setIsDrawerOpen}
                  setFrequencyDetails={setFrequencyDetails}
                  setBankRoomId={setBankRoomId}
                  surveyIds={
                    dueDiligenceSurveyId?._id || isoSurveyId?._id
                      ? [dueDiligenceSurveyId?._id, isoSurveyId?._id]
                      : []
                  }
                />
                <br />
              </>
            )}

            <DueDiligenceOverallScore
              severity={severity}
              complianceScore={doraOverallScores?.overallScore}
            />
            <br />
            <CapacityAndAbility severity={severity} />
            <br />
            <TechnicalAndOperationalSecurity severity={severity} />
            <br />
            <CompilanceScore score={doraOverallScores?.overallScore} />
            <br />
            <ComplianceList
              handleSelectedDoc={handleSelectedDoc}
              type={"Policy"}
              complianceFiles={complianceFilesDiligence}
              doraOverallScores={doraOverallScores}
            />
            <br />
            <ChartRenderer
              vendorId={vendorBasicDetails?._id}
              bankId={bankId}
              surveys={[
                {
                  title: "Due Diligence Score",
                  value: "DUE_DILIGENCE_SCORE",
                },
                {
                  title: "Security And Risk Management",
                  value: "SECURITY_AND_RISK_MANAGEMENT",
                },
                {
                  title: "Compliance And Financial Stability",
                  value: "COMPLIANCE_AND_FINANCIAL_STABILITY",
                },
                {
                  title: "Technical And Operational Security",
                  value: "TECHNICAL_AND_OPERATIONAL_SECURITY",
                },
                {
                  title: "Governance And Organizational Security",
                  value: "GOVERNANCE_AND_ORGANIZATIONAL_SECURITY",
                },
              ]}
              defaultSurvey={{
                title: "Due Diligence Score",
                value: "DUE_DILIGENCE_SCORE",
              }}
            />
          </Box>
        </>
      )}
      {docId !== null && (
        <DoraDocuments
          setDocId={setDocId}
          docId={docId}
          pdfUrl={pdfUrl}
          pdfName={pdfName}
          fileData={customDoraResults[0]?.data}
        />
      )}

      {isDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          isFrom="vendorDiagnosis"
          frequencyDetails={frequencyDetails}
          bankRoomId={bankRoomId}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"DUEDILIGENCE"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={[
            "Due_Diligence_Score",
            "Security_And_Risk_Management",
            "Compliance_And_Financial_Stability",
            "Governance_And_Organizational_Security",
            "Technical_And_Operational_Security",
            "Due_Compliance_Score",
          ]}
        />
      )}
    </Box>
  );
}

export default DueDiligence;
