import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import DynamicTable, { textStyle, useTableStyles } from "./DynamicTable";
import Stack from "../Stack/Stack";

// Helper function to access nested properties with fallback options
const getNestedValue = (obj, path) => {
  const options = path.split("||").map((opt) => opt.trim());
  for (let option of options) {
    const value = option
      .split(".")
      .reduce((acc, part) => acc && acc[part], obj);
    if (value) return value; // Return the first non-null, non-undefined value
  }
  return "-"; // Return - if none of the options have a value
};

const DynamicTableFull = ({
  tableHead,
  tableData,
  tableRowOrder,
  rowClicked,
  maxHeight,
}) => {
  const classes = useTableStyles();
  const hasRowClick = Boolean(rowClicked);

  const handleRowClick = (row) => {
    if (hasRowClick) {
      rowClicked(row);
    }
  };

  return (
    <>
      <DynamicTable headings={tableHead} maxHeight={maxHeight}>
        {tableData &&
          tableData?.length !== 0 &&
          tableData?.map((row, rowIndex) => (
            <TableRow
              key={row?._id || rowIndex}
              onClick={() => handleRowClick(row)}
              className={hasRowClick ? classes.tableRowHover : ""}
              style={{
                borderBottom: "1px solid #EBF1F8",
                cursor: hasRowClick ? "pointer" : "default",
                transition: "background-color 0.3s ease",
              }}
            >
              {tableRowOrder.map((column, colIndex) => {
                let content;
                switch (column.type) {
                  case "raw":
                    // Render raw data using `getNestedValue`
                    content = getNestedValue(row, column.data);
                    break;

                  case "comp":
                    // Clone the component and pass the row data to the onClick handler
                    content = React.cloneElement(column.data, {
                      onClick: (e) => {
                        e.stopPropagation(); // Prevent triggering the row's click event
                        if (column.data.props.onClick) {
                          column.data.props.onClick(row);
                        }
                      },
                    });
                    break;

                  default:
                    // Fallback for unknown types
                    content = "-";
                }

                return (
                  <TableCell key={colIndex} style={textStyle("main")}>
                    {content}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
      </DynamicTable>

      {tableData && tableData?.length === 0 && (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="20vh"
        >
          No Data Available
        </Stack>
      )}
    </>
  );
};

export default DynamicTableFull;
