import {
  IS_APPROVED,
  RECEIVE_MESSAGE,
  ROOM_MEMBERS,
} from "../../../constants/threadConstants";

const initialState = {
  messages: [],
  roomMembers: [],
  isApproved: null,
};

export default function threadReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MESSAGE:
      return { ...state, messages: action.payload };
    case ROOM_MEMBERS:
      return { ...state, roomMembers: action.payload };
    case IS_APPROVED:
      return { ...state, isApproved: action.payload };
    default:
      return state;
  }
}
