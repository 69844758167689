import {
  Box,
  Chip,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DynamicDrawer from "components/UI/dynamicDrawer/DynamicDrawer";
import RequiredField from "components/UI/inputField/requiredShow";
import Stack from "components/UI/Stack/Stack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql_add_user } from "redux/actions/generalSettings/generalSettingsAction";
import { gql_get_entity_list } from "redux/actions/vendorActions/vendorOrgAction";

const StakeholdersDrawer = ({ open, onClose }) => {
  const { action } = useTheme().palette;
  const dispatch = useDispatch();

  const initialState = {
    name: "",
    email: "",
    userDefinedId: "",
    user_type: "",
    designation: "",
    category: "",
    bankId: "",
  };

  const [inputData, setInputData] = useState(initialState);
  const [loadingEntities, setLoadingEntities] = useState(false);

  const { entity_list } = useSelector((state) => state.vendorOrg);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChipClick = (user_type) => {
    setInputData((prev) => ({ ...prev, user_type }));
  };

  const disableSubmit = Object.values(inputData).some((value) => !value);

  const closeDrawer = () => {
    setInputData(initialState);

    onClose();
  };

  const handleSubmit = () => {
    dispatch(gql_add_user(inputData, closeDrawer, true));
  };

  const fetchEntities = async () => {
    setLoadingEntities(true);
    await gql_get_entity_list();
    setLoadingEntities(false);
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Stakeholder"}
      buttonText={"Add Stakeholder"}
      disableSubmit={disableSubmit}
      submitClick={handleSubmit}
      style={{ padding: 10 }}
    >
      <Stack flexDirection={"column"} spacing={20}>
        {/* Stakeholder Name */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Stakeholder Name {RequiredField(!inputData.name)}
          </Typography>
          <TextField
            name="name"
            variant="outlined"
            placeholder="Enter Name"
            value={inputData.name}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Email */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Email {RequiredField(!inputData.email)}
          </Typography>
          <TextField
            name="email"
            type="email"
            variant="outlined"
            placeholder="Enter Email"
            value={inputData.email}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* CIN No. */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            CIN No. {RequiredField(!inputData.userDefinedId)}
          </Typography>
          <TextField
            name="userDefinedId"
            variant="outlined"
            placeholder="CIN No."
            value={inputData.userDefinedId}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Type */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            User Type {RequiredField(!inputData.user_type)}
          </Typography>
          <Stack spacing={10}>
            {[
              { label: "Bank Admin", value: "BA" },
              { label: "Bank Survey Admin", value: "BSA" },
            ].map((e) => (
              <Chip
                key={e.value}
                label={e.label}
                variant="outlined"
                onClick={() => handleChipClick(e.value)}
                color={inputData.user_type === e.value ? "primary" : "default"}
              />
            ))}
          </Stack>
        </Box>

        {/* Designation */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Designation {RequiredField(!inputData.designation)}
          </Typography>
          <TextField
            name="designation"
            variant="outlined"
            placeholder="Enter Designation"
            value={inputData.designation}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Category */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Category {RequiredField(!inputData.category)}
          </Typography>
          <TextField
            name="category"
            variant="outlined"
            placeholder="Enter Category"
            value={inputData.category}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Entity Field */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Entity {RequiredField(!inputData.bankId)}
          </Typography>
          <Autocomplete
            options={entity_list || []}
            getOptionLabel={(option) => option.name}
            loading={loadingEntities}
            onOpen={fetchEntities} // Trigger fetch when dropdown is opened
            onChange={(event, value) =>
              setInputData((prev) => ({
                ...prev,
                bankId: value ? value._id : "",
              }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search Entity"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingEntities ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Stack>
    </DynamicDrawer>
  );
};
export default StakeholdersDrawer;
