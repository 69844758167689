import {
  SET_SHARED_SURVEY_DETAILS,
  SET_TERMINATION_EXIT_PLAN,
  SET_VENDOR_ALERTS,
  SET_VENDOR_RECOMMENDATION,
} from "../../../constants/vendorDiagnosisConstants";

const initialState = {
  sharedSurveyDetails: null,
  // vendor alerts
  alerts: [],

  // termination and offboarding
  exitPlan: null,
  vendorRecommendation: null,
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHARED_SURVEY_DETAILS:
      return { ...state, sharedSurveyDetails: action.payload };

    case SET_VENDOR_ALERTS:
      return { ...state, alerts: action.payload };

    case SET_TERMINATION_EXIT_PLAN:
      return { ...state, exitPlan: action.payload };

    case SET_VENDOR_RECOMMENDATION:
      return { ...state, vendorRecommendation: action.payload };

    default:
      return state;
  }
};
export default vendorReducer;
