import { Snackbar } from "@material-ui/core";
import { SET_USER_DETAILS } from "../../../constants/generalSettings";
import { GQL_RESET_PASSWORD } from "../../graphql/gql_GeneralSettings";
import { GET_USER_DETAILS } from "../../graphql/gql_UserQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { GQL_RESET_FORGOT_PASSWORD } from "../../graphql/gql_AuthQueries";

const setUserDetails = (userDetails) => {
  return { type: SET_USER_DETAILS, payload: userDetails };
};

export function gql_get_user_details() {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const userQuery = {
        query: GET_USER_DETAILS,
      };

      const result = await client.query(userQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(userQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setUserDetails(result.data.me));
      //   console.log(result.data.me);
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

// It will be called when we are already logged in and perform reset password...
export function gql_reset_password(resetPasswordInput) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const obj = {
        mutation: GQL_RESET_PASSWORD,
        variables: { resetPasswordInput },
      };

      const result = await client.mutate(obj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(obj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result?.data?.resetPassword?.message));
      //   console.log(result.data.me);
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

// It will be called when we reset forgot password in the login screen...
export function gql_reset_forgot_password(email, newPassword, resetCode) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const obj = {
        mutation: GQL_RESET_FORGOT_PASSWORD,
        variables: { email, newPassword, resetCode },
      };

      const result = await client.mutate(obj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(obj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result?.data?.resetForgotPassword?.message));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}
