import { Box, Typography, LinearProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    padding: "0px 12px",

    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#3374B9",
      borderRadius: 20,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#FEFEFE",
    },
  },
}));

const ProgressHeader = ({ title, sub, progress, totalQues, currentQuesNo }) => {
  const classes = useStyles();

  return (
    <Box
      width={"100%"}
      p={2}
      display={"flex"}
      flexDirection={"column"}
      style={{ backgroundColor: "#EBF1F8", gap: 15 }}
    >
      <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
        {/* title text */}
        <Typography
          style={{
            color: "#15314E",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {title}
        </Typography>

        {/* sub text */}
        <Typography
          style={{
            color: "#245283",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {sub}
        </Typography>
      </Box>

      <Box className={classes.root}>
        {/* Progress */}
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{
            width: "20em",
            height: "0.5em",
            borderRadius: 20,
          }}
        />
        {/* Questions */}
        <Typography
          style={{
            color: "#15314E",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {currentQuesNo}/{totalQues} Questions
        </Typography>
      </Box>
    </Box>
  );
};
export default ProgressHeader;
