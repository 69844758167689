import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

// const data = [
//   {
//     title: "Lawsuit Filed",
//     status: "Pending",
//     date: "2024-09-15",
//     description:
//       "A lawsuit was filed against the company for breach of contract.",
//   },
//   {
//     title: "Regulatory Compliance Check",
//     status: "Completed",
//     date: "2024-10-01",
//     description:
//       "Compliance with financial regulations was reviewed and approved.",
//   },
//   {
//     title: "Patent Dispute",
//     status: "Resolved",
//     date: "2024-08-20",
//     description:
//       "Patent dispute with a competitor was resolved through settlement.",
//   },
//   {
//     title: "Tax Audit",
//     status: "In Progress",
//     date: "2024-10-15",
//     description:
//       "Ongoing audit by tax authorities to verify company's financial records.",
//   },
// ];

export default function LegalEvents({ data }) {
  const classes = useStyle();

  return (
    <Box sx={{ margin: "10px 0px" }}>
      <TableContainer component="paper">
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Description</TableCell>

              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                className={classes.tableRow}
                // onClick={() => console.log(data.id)}
              >
                <TableCell align="left" width={"250px"}>
                  {item?.title}
                </TableCell>
                <TableCell align="left" width={"350px"}>
                  {item?.description}
                </TableCell>
                <TableCell align="left">{item?.date?.split("T")[0]}</TableCell>

                <TableCell align="left">{item?.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
