import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Box, Collapse } from "@material-ui/core";
import CustomButton from "components/UI/button/button";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);

export default function SurveyList({
  surveyList,
  handleSelectSection,
  step,
  setIsThreadDrawerOpen,
  isThreadDrawerOpen,
}) {
  const classes = useStyles();
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleListItemClick = (section) => {
    setSelectedSection(section);
    handleSelectSection(section);
  };

  const sortArray = surveyList?.length > 0 ? [...surveyList] : [];

  return (
    <Box className={classes.root} mt={2}>
      {step === 3 && (
        <ListItem
          selected={isThreadDrawerOpen}
          button
          onClick={() => {
            setOpen(false);
            setIsThreadDrawerOpen(true);
          }}
          style={{
            // backgroundColor: "#f0f5fc",
            borderRadius: "8px",
            marginBottom: "10px",
            padding: "10px 16px",
          }}
        >
          <ListItemText
            primary="Chat"
            style={{
              // color: "#123456",
              fontWeight: "500",
            }}
          />
        </ListItem>
      )}
      {step === 3 ? (
        <>
          <ListItem
            selected={!isThreadDrawerOpen}
            button
            onClick={handleToggle}
            style={{
              // backgroundColor: "#f0f5fc",
              borderRadius: "8px",
              marginBottom: "10px",
              padding: "10px 16px",
            }}
          >
            <ListItemText
              primary="Sections"
              style={{
                // color: "#123456",
                fontWeight: "500",
              }}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="nav" aria-label="secondary mailbox folder">
              {surveyList?.length > 0 &&
                sortArray
                  ?.sort((a, b) => a?.order - b?.order)
                  ?.map((section) => (
                    <ListItem
                      key={section?._id}
                      button
                      selected={selectedSection?._id === section?._id}
                      onClick={() => handleListItemClick(section)}
                      style={{ borderRadius: "8px", marginBottom: "5px" }}
                    >
                      <ListItemText primary={section?.title} />
                    </ListItem>
                  ))}
            </List>
          </Collapse>
        </>
      ) : (
        <List component="nav" aria-label="secondary mailbox folder">
          {surveyList?.length > 0 &&
            sortArray
              ?.sort((a, b) => a?.order - b?.order)
              ?.map((section) => (
                <ListItem
                  key={section?._id}
                  button
                  selected={selectedSection?._id === section?._id}
                  onClick={() => handleListItemClick(section)}
                  style={{ borderRadius: "8px", marginBottom: "5px" }}
                >
                  <ListItemText primary={section?.title} />
                </ListItem>
              ))}
        </List>
      )}
    </Box>
  );
}
