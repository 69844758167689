import { gql } from "@apollo/client";

export const CREATE_SURVEY = gql`
  mutation CreateSurvey($createSurveyInput: CreateSurveyInput!) {
    createSurvey(createSurveyInput: $createSurveyInput) {
      _id
    }
  }
`;

export const GET_SURVEY_DETAILS = gql`
  query Survey($id: String!) {
    survey(id: $id) {
      _id
      name
      details
      latestVersion
      publishedVersion
      default
      type
    }
  }
`;

export const GET_SURVEY_SECTIONS = gql`
  query Survey($id: String!, $version: Float) {
    survey(id: $id) {
      sections(version: $version) {
        noOfSections
        sections {
          _id
          active
          default
          description
          isPublished
          order
          publishedBy
          publishedOn
          title
          version
          noOfQuestions
        }
      }
    }
  }
`;

export const GET_SECTION_QUESTIONS = gql`
  query Section($id: String!) {
    section(id: $id) {
      questions {
        _id
        active
        category
        categorySuggestion
        editedAt
        editedBy
        order
        question
        questionType
        version
        weightage
        commentBox
        isMandatory
        hints
        showComment
        tableHeaders {
          title
          type
        }
        answers {
          option
          weightage
          justification
          documentUpload
          questionRisk {
            description
            enableCompensationControl
            finding
            risk
            severity
          }
        }
      }
    }
  }
`;

export const GET_AUDIT_SECTIONS = gql`
  query GetAssignedSections(
    $getAssignedSectionsInput: GetAssignedSectionsInput!
  ) {
    getAssignedSections(getAssignedSectionsInput: $getAssignedSectionsInput) {
      totalQuestionsAssigned
      totalQuestionsCompleted
      totalSubmittedQuestions
      totalQuestionsApproved
      sections {
        _id
        order
        title
      }
    }
  }
`;

export const GET_AUDIT_QUESTIONS = gql`
  query GetAssignedQuestions(
    $getAssignedQuestionsInput: GetAssignedQuestionsInput!
  ) {
    getAssignedQuestions(
      getAssignedQuestionsInput: $getAssignedQuestionsInput
    ) {
      overallStatus
      totalApprovedCount
      totalAssignedCount
      totalCompletedCount
      questions {
        _id
        order
        question
        status
        questionType
        answers {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
        }
        approvedAnswer {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
          startDate
          endDate
          comment
          riskCompensationStatement
        }
        tableHeaders {
          title
          type
        }
        approvedTableRows {
          documentUrl {
            name
            type
            url
          }
          option
          weightage
          comment
        }
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($updateQuestionInput: UpdateQuestionInput!) {
    updateQuestion(updateQuestionInput: $updateQuestionInput) {
      _id
      commentBox
      hints
      isMandatory
      showComment
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: String!) {
    deleteQuestion(id: $id) {
      _id
    }
  }
`;

export const ADD_SECTION_QUESTION = gql`
  mutation AddQuestion(
    $createQuestionInput: CreateQuestionInput!
    $surveyId: String!
  ) {
    addQuestion(
      createQuestionInput: $createQuestionInput
      surveyId: $surveyId
    ) {
      _id
      order
      question
      questionType
      weightage
    }
  }
`;

export const REMOVE_SURVEY_SECTION = gql`
  mutation RemoveSection($id: ID!) {
    removeSection(id: $id) {
      message
      success
    }
  }
`;

export const PUBLISH_SURVEY = gql`
  mutation PublishSurvey($publishSurveyInput: PublishSurveyInput!) {
    publishSurvey(publishSurveyInput: $publishSurveyInput) {
      _id
    }
  }
`;

export const GET_VA_SECTION_QUESTIONS = gql`
  query GetQuestionsWithStatus(
    $frequencyId: String!
    $sectionId: String!
    $bankId: String!
    $vendorId: String!
  ) {
    getQuestionsWithStatus(
      getQuestionsWithStatusInput: {
        frequencyId: $frequencyId
        sectionId: $sectionId
        bankId: $bankId
        vendorId: $vendorId
      }
    ) {
      noOfAssignedQuestions
      overallStatus
      noOfCompletedQuestions
      questions {
        _id
        status
        question
        order
        version
      }
    }
  }
`;

export const EDIT_SURVEY_SECTION_SETTINGS = gql`
  mutation UpdateSection($updateSectionInput: UpdateSectionInput!) {
    updateSection(updateSectionInput: $updateSectionInput) {
      _id
      title
      description
    }
  }
`;

export const GET_VERSION_HISTORY = gql`
  query Survey($surveyId: String!) {
    survey(id: $surveyId) {
      versions {
        addedBy
        addedOn
        version
      }
    }
  }
`;

export const GET_COMPLETED_SECTION_QUESTIONS = gql`
  query ($getCompletedQuestionsInput: GetCompletedQuestionsInput!) {
    getCompletedQuestions(
      getCompletedQuestionsInput: $getCompletedQuestionsInput
    ) {
      questions {
        tableHeaders {
          title
          type
        }
        approvedTableRows {
          comment
          option
          weightage
          documentUrl {
            name
            type
            url
          }
        }
        approvedAnswer {
          option
          weightage
          justificationText
          documentUrl {
            name
            type
            url
          }
          endDate
          startDate
          comment
          riskCompensationStatement
        }
        answers {
          option
          weightage
          justificationText
          justification
          documentUrl {
            name
            type
            url
          }
          documentUpload
        }
        order
        question
        questionId
        questionType
        weightage
      }
      sectionId
      sectionTitle
    }
  }
`;
