import React, { useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import RadioGroup from "@material-ui/core/RadioGroup";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { TextField } from "@material-ui/core";

import CustomButton from "../../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";

import RadioButton from "../../../../../../../UI/radioButton/radioButton";

import RiskDrawer from "../../NewQuestionTabComps/RightSection/riskDrawer/RiskDrawer";
import { gql_get_risk_auto_populate } from "redux/actions/vendorActions/vendorAction";

const SingleChoiceWithMultiFeatures = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  checkBoxOption,
  setCheckBoxOption,
  usingQues,
  linkageAnswer,
  questionId,
  sectionTitle,
  questionTitle,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [justification, setJustification] = useState("");
  const [file, setFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});
  const [newOption, setNewOption] = useState("");
  const [newWeightage, setNewWeightage] = useState("");

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [riskData, setRiskData] = useState({
    description: "",
    enableCompensationControl: false,
    finding: "",
    risk: "",
    severity: "",
  });

  console.log(questionType);

  const [optionIndex, setOptionIndex] = useState(null);
  const [compensationStatement, setCompensationStatement] = useState("");

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { riskAutoPopulate } = useSelector((state) => state?.brmData);

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  const handleRiskAutoPopulate = (option) => {
    dispatch(gql_get_risk_auto_populate(option, questionTitle, sectionTitle));
  };

  useEffect(() => {
    if (riskAutoPopulate) {
      setRiskData({
        description: riskAutoPopulate?.Descriptions || "",
        finding: riskAutoPopulate?.Findings || "",
        risk: riskAutoPopulate["Risk Factors"][0] || "",
        severity: riskAutoPopulate["Severity levels"] || "",
      });
    }
  }, [riskAutoPopulate]);

  const customOptions = [
    // { option: "Option 1", weightage: 2 },
    // { option: "Option 2", weightage: 3 },
  ];

  useEffect(() => {
    // const exceptions = [
    //   ...radioOption[SUB_QUESTION_0_10],
    //   ...radioOption[SUB_QUESTION_QUALITY],
    //   ...radioOption[SUB_QUESTION_SATISFACTION],
    //   ...radioOption[SUB_QUESTION_LIKERT],
    //   ...radioOption[SUB_QUESTION_FREQUENCY],
    //   ...radioOption[SUB_QUESTION_PERFORMANCE],
    //   ...radioOption[SUB_QUESTION_IMPORTANCE],
    //   ...radioOption[SUB_QUESTION_EMOTIONS],
    // ];

    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(customOptions);
      } else {
        setCheckBoxOption(options || customOptions);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOption = () => {
    if (newOption.trim() && newWeightage.trim()) {
      setCheckBoxOption([
        ...checkBoxOption,
        {
          option: newOption.trim(),
          weightage: parseFloat(newWeightage),
          justification: "Yes",
          documentUpload: "Yes",
        },
      ]);
      setNewOption("");
      setNewWeightage("");
    }
  };

  const handleWeightageChange = (event) => {
    setNewWeightage(event.target.value);
  };

  const handleNewOption = (event) => {
    setNewOption(event.target.value);
  };

  const handleRemoveOption = (index) => {
    setCheckBoxOption(checkBoxOption?.filter((_, i) => i !== index));
  };

  const handleOption = (opt, i) => {
    setOption(opt);
    if (isFrom !== "surveyCreation") {
    }
    setOptionIndex(i);
  };

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: selectedOption,
        justification,
        document: file,
        weightage: option?.weightage,
        riskCompensationStatement: compensationStatement,
      };

      handleQuesAnswer(obj);
    }
  }, [selectedOption, justification, file, compensationStatement]);

  useEffect(() => {
    return () => {
      if (isFrom === "taskSection") {
        setFile(null);
        setJustification("");
      }
    };
  }, [selectedOption]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setSelectedOption(submittedAnswer[0]?.option);

      setCompensationStatement(submittedAnswer[0]?.riskCompensationStatement);

      if (submittedAnswer[0]?.justificationText) {
        setJustification(submittedAnswer[0]?.justificationText);
      }
    }
  }, [submittedAnswer]);

  const handleToggleRiskDrawer = () => {
    setToggleDrawer(false);
  };

  const handleRiskData = (e) => {
    setCheckBoxOption((prev) =>
      prev.map((item, i) => {
        if (i === optionIndex) {
          return {
            ...item,
            questionRisk: {
              ...item?.questionRisk,
              [e.target.name]: e.target.value ? e.target.value : "",
              // description:
              //   e.target.name === "description" ? e.target.value : "",
              // finding: e.target.name === "finding" ? e.target.value : "",
              // risk: e.target.name === "risk" ? e.target.value : "",
              // severity: e.target.name === "severity" ? e.target.value : "",
              enableCompensationControl: e.target.checked ? true : false,
            },
          };
        }
        return item;
      })
    );

    if (e.target.name === "enableCompensationControl") {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setRiskData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleMapRiskData = (i) => {
    if (isFrom === "surveyCreation" && checkBoxOption[i]?.questionRisk) {
      setRiskData(checkBoxOption[i]?.questionRisk);
    }
  };

  console.log(riskData);

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <div>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        {(checkBoxOption || options)?.length > 0 ? (
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            {(checkBoxOption || options)?.map((option, index) => (
              <>
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    key={option?.option}
                    value={option?.option}
                    control={<RadioButton color="primary" />}
                    label={option?.option}
                    style={{ pointerEvents: event }}
                    onClick={() => handleOption(option, index)}
                  />

                  {isFrom === "surveyCreation" && (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gridGap={"20px"}
                      style={{ marginRight: "10px" }}
                    >
                      <ErrorOutlineOutlinedIcon
                        onClick={() => {
                          setToggleDrawer(true);
                          setOptionIndex(index);
                          handleMapRiskData(index);
                          if (!option?.questionRisk) {
                            handleRiskAutoPopulate(option?.option);
                          }
                        }}
                        style={{
                          color: !option?.questionRisk ? "#3374B9" : "#B81D13",
                          cursor: "pointer",
                        }}
                      />

                      <DeleteOutlinedIcon
                        onClick={() => handleRemoveOption(index)}
                        style={{ color: "#3374B9", cursor: "pointer" }}
                      />
                    </Box>
                  )}
                </Box>
                {((index === optionIndex &&
                  option?.questionRisk &&
                  isFrom !== "surveyCreation") ||
                  (submittedAnswer?.length > 0 &&
                    submittedAnswer[0]?.option === option?.option)) && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gridGap={7}
                    mb={1}
                    ml={3}
                  >
                    {/* <Typography>
                        Finding: <strong>{option?.questionRisk?.finding}</strong>
                      </Typography>
                      <Typography>
                        Description:{" "}
                        <strong>{option?.questionRisk?.description}</strong>
                      </Typography>
                      <Typography>
                        Severity:{" "}
                        <Chip
                          label={
                            <strong>{option?.questionRisk?.severity}</strong>
                          }
                          style={{
                            color:
                              option?.questionRisk?.severity === "HIGH"
                                ? severity("text", "red")
                                : option?.questionRisk?.severity === "MEDIUM"
                                ? severity("text", "yellow")
                                : severity("text", "green"),
                            backgroundColor:
                              option?.questionRisk?.severity === "HIGH"
                                ? severity("bg", "red")
                                : option?.questionRisk?.severity === "MEDIUM"
                                ? severity("bg", "yellow")
                                : severity("bg", "green"),
                          }}
                        ></Chip>
                        
                      </Typography> */}
                    {(option?.questionRisk?.enableCompensationControl ||
                      submittedAnswer[0]?.riskCompensationStatement) && (
                      <TextField
                        fullWidth
                        id="comment"
                        label="Compensation Statement"
                        variant="outlined"
                        multiline
                        minRows={2}
                        // placeholder="Compensation comment"
                        style={{ marginTop: "10px" }}
                        value={compensationStatement}
                        // defaultValue={usingQues?.commentBox}
                        onChange={(e) =>
                          setCompensationStatement(e.target.value)
                        }
                      />
                    )}
                  </Box>
                )}
              </>
            ))}
          </RadioGroup>
        ) : (
          <Typography style={{ marginBottom: 10 }}>Add some options</Typography>
        )}
        {isFrom === "surveyCreation" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 10,
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <TextField
              label="New Option"
              variant="outlined"
              value={newOption}
              onChange={handleNewOption}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              type="number"
              value={newWeightage}
              onChange={handleWeightageChange}
            />
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddOption}
              disabled={newOption === "" || newWeightage === ""}
            >
              Add Option
            </CustomButton>
          </div>
        )}
      </FormControl>
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.justification === "Yes") ||
        questionType === "SCT" ||
        questionType === "SCTD") && (
        <TextField
          id="justification"
          label="Justification"
          variant="outlined"
          value={justification}
          onChange={handleJustificationChange}
          fullWidth
          multiline
          minRows={4}
          margin="normal"
          style={{ pointerEvents: event }}
        />
      )}
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.documentUpload === "Yes") ||
        questionType === "SCD" ||
        questionType === "SCTD") && (
        <Box
          width="100%"
          style={{
            margin: "26px 5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          <AttachFileIcon
            style={{ backgroundColor: "#ced4da", padding: 4 }}
            color="disabled"
          />
          {submittedAnswer ? (
            <Box
              border={1}
              borderRadius={4}
              p={1}
              borderColor={"#cececece"}
              ml={2}
            >
              {submittedAnswer[0]?.documentUrl === null ? (
                <Typography variant={"body2"}>
                  No document has been uploaded
                </Typography>
              ) : (
                <a
                  href={submittedAnswer[0]?.documentUrl?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#999" }}
                >
                  {submittedAnswer[0]?.documentUrl?.url}
                </a>
              )}
            </Box>
          ) : (
            <>
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none", pointerEvents: event }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {file?.name ? file.name : "Upload file"}
              </div>
            </>
          )}
        </Box>
      )}

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
      <RiskDrawer
        toggleDrawer={toggleDrawer}
        handleToggleRiskDrawer={handleToggleRiskDrawer}
        setRiskData={setRiskData}
        handleRiskData={handleRiskData}
        riskData={riskData}
      />
    </div>
  );
};

export default SingleChoiceWithMultiFeatures;
