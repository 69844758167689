import RouterCont from "./containers/router/routerCont";
import { ApolloProvider } from "@apollo/client";

import { client } from "./redux/store/store";
import palette from "theme/palette";
import ThemeProvider from "theme";

// const theme = createTheme({
//   palette: palette.light,
//   typography: {
//     fontFamily: ["poppins", "sans-serif"].join(","),
//   },
//   overrides: {
//     MuiTabs: {
//       indicator: {
//         color: "green",
//         // border:"1px solid red",
//         backgroundColor: "#32A05F",
//       },
//     },
//     MuiButton: {
//       root: {
//         textTransform: "none",
//         borderRadius: "8px",
//         padding: "8px 28px",
//       },
//     },
//   },
// });

function App() {
  return (
    <>
      <ThemeProvider>
        <ApolloProvider client={client}>
          <RouterCont />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
