import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import VendorScore from "./VendorScore";
import VendorMaterialityScores from "./VendorMaterialityScores";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_historic_data,
  gql_get_survey_list_dataform,
  gql_get_survey_response_frequencies,
  gql_get_vendor_materiality_scores,
} from "../../../../redux/actions/vendorActions/vendorAction";
import { gql_get_vendor_Assessment_score } from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import AssessmentTable from "./AssessmentTable";
import AlertsDrawer from "./AlertsDrawer";
import ThreadDrawer from "components/widgets/vendorAdmin/surveySteps/step2/threadDrawer/ThreadDrawer";
import BarGraph from "../BarGraph/BarGraph";
import { ChartRenderer } from "components/widgets/charts/HistoricChart";

function VendorIntake({ isFrom }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [frequencyDetails, setFrequencyDetails] = useState({});
  const [bankRoomId, setBankRoomId] = useState("");

  const dispatch = useDispatch();

  const { vendorBasicDetails, surveyListDataForm } = useSelector(
    (state) => state.brmData
  );

  const { bankId } = useSelector((state) => state.login);

  const surveyIds = surveyListDataForm?.["vendorScopingSurveys"].map(
    (item) => item?._id
  );

  const storedVendorId = localStorage.getItem("vendorId");

  const [alertsDrawer, setAlertsDrawer] = useState(false);

  const toggleAlertsDrawer = () => {
    setAlertsDrawer((prev) => !prev);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      gql_get_vendor_materiality_scores(
        vendorBasicDetails?._id || storedVendorId
      )
    );

    async function getSurveys() {
      await dispatch(
        gql_get_survey_list_dataform(vendorBasicDetails?._id || storedVendorId)
      );
    }

    getSurveys();
  }, []);

  return (
    <Box style={{ height: isFrom === "tprmRiskReport" ? "100%" : "63vh" }}>
      {isFrom === "tprmRiskReport" || (
        <Box
          sx={{
            mb: 2,
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Vendor Intake/Scoping
          </Typography>

          <Button
            variant="text"
            style={{ color: "#3374B9", textTransform: "none" }}
            onClick={toggleAlertsDrawer}
          >
            Set Alerts
          </Button>
        </Box>
      )}

      <Box className={isFrom === "tprmRiskReport" ? "" : "scroll"}>
        {/* <BarGraph
          surveys={[
            { title: "Vendor Materiality", value: "materiality" },
            { title: "Vendor Performance", value: "performance" },
            { title: "Vendor Categorization", value: "categorization" },
          ]}
          defaultSurvey={"materiality"}
        /> */}

        {isFrom === "tprmRiskReport" || (
          <>
            <AssessmentTable
              setIsDrawerOpen={setIsDrawerOpen}
              setFrequencyDetails={setFrequencyDetails}
              setBankRoomId={setBankRoomId}
              surveyIds={surveyIds}
            />
            <br />
          </>
        )}
        {/* Score */}
        <VendorScore />
        {/* <br /> */}
        <VendorMaterialityScores />
        <br />
        <ChartRenderer
          vendorId={vendorBasicDetails?._id}
          bankId={bankId}
          surveys={[
            { title: "Vendor Score", value: "VENDOR_SCORE" },
            { title: "Vendor Materiality", value: "VENDOR_MATERIALITY" },
            { title: "Vendor Performance", value: "VENDOR_PERFORMANCE" },
            { title: "Vendor Categorization", value: "VENDOR_CATEGORIZATION" },
          ]}
          defaultSurvey={{ title: "Vendor Score", value: "VENDOR_SCORE" }}
        />

        {/* <Header /> */}
      </Box>

      {isDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          isFrom="vendorDiagnosis"
          frequencyDetails={frequencyDetails}
          bankRoomId={bankRoomId}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {alertsDrawer && (
        <AlertsDrawer
          open={alertsDrawer}
          onClose={toggleAlertsDrawer}
          stepName={"VENDOR_INTAKE_OR_SCOPING"}
          tabName={"VENDOR_DIAGNOSIS"}
          widgetNames={[
            "Vendor_Score",
            "Vendor_Performance",
            "Vendor_Categorization",
            "Vendor_Materiality",
          ]}
        />
      )}
    </Box>
  );
}

export default VendorIntake;
