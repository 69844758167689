const QUESTION_TYPE_SCALE = "SCALE";
const QUESTION_TYPE_MULTICHOICE = "MULTICHOICE";
const QUESTION_TYPE_COMMENT_BOX = "COMMENT_BOX";
const QUESTION_TYPE_LOCATION = "LOCATION";
const QUESTION_TYPE_DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD";
const QUESTION_TYPE_DATE_PICKER = "DATE_PICKER";

const SUB_QUESTION_0_10 = "ZERO_TO_TEN";
const SUB_QUESTION_LIKERT = "LIKERT";
const SUB_QUESTION_SATISFACTION = "SATISFACTION";
const SUB_QUESTION_QUALITY = "QUALITY";
const SUB_QUESTION_FREQUENCY = "FREQUENCY";
const SUB_QUESTION_PERFORMANCE = "PERFORMANCE";
const SUB_QUESTION_IMPORTANCE = "IMPORTANCE";
const SUB_QUESTION_EMOTIONS = "EMOTIONS";
const SUB_QUESTION_SINGLECHOICE = "SINGLECHOICE";
const DATE_PICKER_SINGLE_DATE = "DATE_PICKER_SINGLE_DATE";
const DATE_PICKER_RANGE_DATE = "DATE_PICKER_RANGE_DATE";

const QUESTION_TYPE_TEXT_FIELD = "TF";
export const QUESTION_TYPE_SINGLE_CHOICE = "SC";
export const QUESTION_TYPE_SINGLE_CHOICE_TEXT = "SCT";
export const QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC = "SCTD";
export const QUESTION_TYPE_SINGLE_CHOICE_DOC = "SCD";
export const QUESTION_TYPE_TEXT_DOCUMENT = "TD";
export const QUESTION_TYPE_NUMBER_DOCUMENT = "ND";
export const QUESTION_TYPE_CURRENCY_FIELD = "CF";
export const QUESTION_TYPE_NUMERIC_FIELD = "NF";
export const QUESTION_TYPE_EMAIL_FIELD = "EMAIL";
export const QUESTION_TYPE_URL_FIELD = "URL";
export const QUESTION_TYPE_TABLE = "TABLE";
export const QUESTION_TYPE_DROPDOWN = "DROPDOWN";
export const QUESTION_TYPE_COUNTRY = "COUNTRY";

// Custom Questions
// export const CUSTOM_QUESTION_TYPE_SINGLE_CHOICE = "SINGLE_CHOICE";
// export const CUSTOM_QUESTION_TYPE_SINGLE_CHOICE_TEXT =
//   "SINGLE_CHOICE_WITH_TEXT";
// export const CUSTOM_QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC =
//   "SINGLE_CHOICE_WITH_TEXT_&_DOC";

// new
const QUESTION_TYPE_NUMBER = "Number";
const QUESTION_TYPE_CURRENCY = "Currency";
const questionTypes = [
  {
    primary: QUESTION_TYPE_SCALE,
    secondary: "Quantitative Ranking",
  },
  {
    primary: QUESTION_TYPE_MULTICHOICE,
    secondary: "Select Options",
  },
  {
    primary: QUESTION_TYPE_COMMENT_BOX,
    secondary: "Open-Ended Response",
  },
  {
    primary: QUESTION_TYPE_LOCATION,
    secondary: "Geographical Input",
  },
  {
    primary: QUESTION_TYPE_DOCUMENT_UPLOAD,
    secondary: "Attach Supporting Files",
  },
  {
    primary: QUESTION_TYPE_DATE_PICKER,
    secondary: "Select Date",
  },
  {
    primary: QUESTION_TYPE_SINGLE_CHOICE,
    secondary: "Select Option",
  },
  {
    primary: QUESTION_TYPE_SINGLE_CHOICE_TEXT,
    secondary: "Select Option + Text",
  },
  {
    primary: QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC,
    secondary: "Select Option + Text + Doc",
  },
  {
    primary: QUESTION_TYPE_SINGLE_CHOICE_DOC,
    secondary: "Select Option + Doc",
  },
  {
    primary: QUESTION_TYPE_TEXT_DOCUMENT,
    secondary: "Text + Doc",
  },
  {
    primary: QUESTION_TYPE_TEXT_FIELD,
    secondary: "Text",
  },
  {
    primary: QUESTION_TYPE_CURRENCY_FIELD,
    secondary: "Currency",
  },
  {
    primary: QUESTION_TYPE_NUMERIC_FIELD,
    secondary: "Numeric Value",
  },
  {
    primary: QUESTION_TYPE_EMAIL_FIELD,
    secondary: "Email",
  },
  {
    primary: QUESTION_TYPE_URL_FIELD,
    secondary: "Url",
  },
  {
    primary: QUESTION_TYPE_NUMBER_DOCUMENT,
    secondary: "Numeric Value + Doc",
  },
  {
    primary: QUESTION_TYPE_TABLE,
    secondary: "Dynamic Table",
  },
  {
    primary: QUESTION_TYPE_DROPDOWN,
    secondary: "Dynamic Dropdown",
  },
  {
    primary: QUESTION_TYPE_COUNTRY,
    secondary: "Country List",
  },
];

const subQuestionTypes = {
  [QUESTION_TYPE_SCALE]: [
    { primary: SUB_QUESTION_0_10, secondary: "not at all - absolutely" },
    {
      primary: SUB_QUESTION_LIKERT,
      secondary: "Strongly disagree - strongly agree",
    },
    {
      primary: SUB_QUESTION_SATISFACTION,
      secondary: "Very dissatisfied - Very satisfied",
    },
    { primary: SUB_QUESTION_QUALITY, secondary: "Very poor - Very Good" },
    { primary: SUB_QUESTION_FREQUENCY, secondary: "Never - Very frequently" },
    { primary: SUB_QUESTION_PERFORMANCE, secondary: "Awfully - Superbly" },
    {
      primary: SUB_QUESTION_IMPORTANCE,
      secondary: "Not at all important - Extremely important",
    },
    { primary: SUB_QUESTION_EMOTIONS, secondary: "Sad face - Happy Face" },
  ],
  [QUESTION_TYPE_DATE_PICKER]: [
    { primary: DATE_PICKER_SINGLE_DATE, secondary: "DD/MM/YYYY" },
    { primary: DATE_PICKER_RANGE_DATE, secondary: "DD/MM/YYYY - DD/MM/YYYY" },
  ],
};

const radioOption = {
  [SUB_QUESTION_0_10]: [
    "Not at all",
    "Slightly",
    "Somewhat",
    "Moderately",
    "Considerably",
    "Significantly",
    "Substantially",
    "Greatly",
    "Extremely",
    "Absolutely",
  ],
  [SUB_QUESTION_QUALITY]: [
    "Very Poor",
    "Poor",
    "Below Average",
    "Average",
    "Above Average",
    "Good",
    "Very Good",
  ],
  [SUB_QUESTION_SATISFACTION]: [
    "Very dissatisfied",
    "Dissatisfied",
    "Somewhat dissatisfied",
    "Neither satisfied nor dissatisfied",
    "Somewhat satisfied",
    "Satisfied",
    "Very satisfied",
  ],
  [SUB_QUESTION_LIKERT]: [
    "Strongly Disagree",
    "Disagree",
    "Slightly Disagree",
    "Neutral",
    "Slightly Agree",
    "Agree",
    "Strongly Agree",
  ],
  [SUB_QUESTION_SINGLECHOICE]: ["Yes", "No"],
  [SUB_QUESTION_FREQUENCY]: [
    "Never",
    "Rarely",
    "Occasionally",
    "Sometimes",
    "Frequently",
    "Very Frequently",
  ],
  [SUB_QUESTION_PERFORMANCE]: [
    "Awfully",
    "Very Poor",
    "Poor",
    "Below Average",
    "Average",
    "Above Average",
    "Good",
    "Very Good",
    "Exceptional",
    "Superbly",
  ],
  [SUB_QUESTION_IMPORTANCE]: [
    "Not at all important",
    "Slightly important",
    "Somewhat important",
    "Moderately important",
    "Considerably important",
    "Significantly important",
    "Substantially important",
    "Greatly important",
    "Extremely important",
  ],
  [SUB_QUESTION_EMOTIONS]: [
    "😢 Sad face",
    "😟 A little sad",
    "😐 Neutral",
    "😊 A little happy",
    "😄 Happy face",
  ],
};

const sectionWiseData = {
  "Self Assessment Section": [
    {
      question:
        "How well has your company been able to adapt its business model in response to environmental and social changes?",
      id: 1,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company’s current business model take into account environmental and social changes that may occur in the future?",
      id: 2,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company assessed the risks of climate change and other external environmental and social factors to its materials supply chains?",
      id: 3,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company taken action to reduce the use of key materials?",
      id: 4,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company taken action to make R&D investments in substitute materials?",
      id: 5,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well is your company able to adapt to increased frequency and severity of extreme weather, shifting climate, sea level risk, and other expected physical impacts of climate change?",
      id: 6,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you agree that the company's Product Design & Lifecycle Management practices are environmentally and socially responsible?",
      id: 7,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How important are environmental, social, and governance (ESG) considerations in the product design and lifecycle management of your company?",
      id: 8,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company focus on reducing environmental and social risks in your supply chain?",
      id: 9,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company focus on promoting good labor practices in your supply chain?",
      id: 10,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "The company has a comprehensive air quality management system that is designed to ensure compliance with all relevant laws and regulations:",
      id: 11,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company consider ecological impacts when making decisions about land use for exploration, natural resource extraction, and cultivation?",
      id: 12,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's level of maturity in Energy Management?",
      id: 13,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much experience does your company have in measuring and managing GHG emissions from its operations?",
      id: 14,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Waste & Hazardous Materials Management?",
      id: 15,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's Water & Wastewater Management practices?",
      id: 16,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How important is Employee Engagement, Diversity & Inclusion to your company's success?",
      id: 17,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How diverse is your company's workforce?",
      id: 18,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often are Employee Engagement, Diversity & Inclusion issues discussed at your company?",
      id: 19,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1-5, how would you rate your company's Employee Health & Safety policies and procedures?",
      id: 20,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how would you rate the maturity level of your company on Labor Practices?",
      id: 21,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Business Ethics?",
      id: 22,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's management of legal and social expectation around monopolistic and anti-competitive practices?",
      id: 23,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Critical Incident Risk Management?",
      id: 24,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's management of the legal and regulatory environment?",
      id: 25,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management reduce its contributions to systemic risks?",
      id: 26,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management improve safeguards against systemic risks?",
      id: 27,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management protect against financial and economic stress?",
      id: 28,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you think that the company's products and services are accessible to underserved markets and population groups?",
      id: 29,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you think that the company is doing enough to ensure that its products and services are accessible and affordable to underserved markets and population groups?",
      id: 30,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How mature is your company in managing risks related to the use of personally identifiable information (PII) and other customer or user data for secondary purposes?",
      id: 31,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does the company address customer welfare concerns over issues including, but not limited to, health and nutrition of foods and beverages, antibiotic use in animal production, and management of controlled substances?",
      id: 32,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How effective do you feel your company is at managing risks related to customer or user data?",
      id: 33,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How secure does your company keep customer or user data?",
      id: 34,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company identify and assess impacts on local communities, including impacts on core human rights and indigenous peoples?",
      id: 35,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company engage with local communities to understand their perspectives and needs?",
      id: 36,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company proactively seek to create positive impacts on local communities?",
      id: 37,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How effective is your company at ensuring product quality and safety?",
      id: 38,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How often do you test your products for quality and safety?",
      id: 39,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree that the company's selling practices & product labeling are ethical and responsible?",
      id: 40,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
  ],
  "Controversy Survey": [
    {
      question:
        "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
      id: 14,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often do you come across controversies related to patents and intellectual property infringements?",
      id: 13,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
      id: 7,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
      id: 11,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 4,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
      id: 15,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
      id: 23,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
      id: 5,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 7,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
      id: 8,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 8,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about controversies linked to responsible R&D in our company?",
      id: 1,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 9,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to customer health and safety at this company?",
      id: 19,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
      id: 4,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
      id: 6,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
      id: 22,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
    {
      question:
        "How many controversies have been linked to employees or customers in the past year?",
      id: 18,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 4,
      hasNewlyAdded: false,
    },
    {
      question:
        'How strongly do you agree with the statement: "The company should prioritize avoiding controversies in countries that do not respect human rights principles"?',
      id: 20,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
      id: 2,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
      id: 10,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 7,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often do you believe that access to a product has been associated with controversies?",
      id: 17,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 8,
      hasNewlyAdded: false,
    },
    {
      question:
        '"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing."',
      id: 9,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 9,
      hasNewlyAdded: false,
    },
    {
      question:
        'How much do you agree with the following statement: "There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.',
      id: 12,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
      id: 16,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to human rights issues within the company?",
      id: 21,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
      id: 3,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
  ],

  "Company Profile": [
    {
      id: 1,
      question: "Please provide the full name for the company?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Please provide a summary of the scope of services you will be providing ?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question: "Where is compnay located ?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Will the  goods and services be contracted from an entity in EU?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question: "Primary Industry Area Primary Industry Area",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Please provide the contact details and job title of the person completing the questionnaire",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Please indicate the first, middle and surname of each of the company managing directors and individuals with 5% or more ownership of your company",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question: "What is the ownership type of your company?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question: "Number of employees",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        "What is the approximate annual turnover (in USD) of your companies last fiscal year?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Information Security": [
    {
      id: 1,
      question:
        'Would your service require access to  any software applications (e.g., via a network connection)? \n\nProviding clear details about the nature and purpose of access is crucial. Please be aware that a "yes" answer may imply your willingness to undergo a further security assessment for your service.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        'Will your tools or applications require remote access to our software applications  (e.g., via VPN)? Please describe the specific access needs and protocols involved.\n\nif \'Yes\' you maybe required to complete an additional Security Assessment).\nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do your tools or applications directly deliver or manage cloud/SaaS products or services associated with software applications? \n\nPlease provide details of the specific products or services involved. If yes, be aware that you may be required to undergo a further security assessment due to the sensitive nature of the data involved.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Data Privacy": [
    {
      id: 1,
      question:
        "Will your tools or applications have access to or control over any of our software application's data (including client data)? \n\nThis may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Third Party/Subcontractors": [
    {
      id: 1,
      question:
        "In delivering services or products to your clients, do you utilize any third-party vendors or subcontractors to perform any part of the work? \n\nPlease provide details on the scope of their involvement and the types of services they undertake. We're particularly interested in any subvendors who may interact with our critical software or systems.",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Business and Ethical Conduct": [
    {
      id: 1,
      question:
        "Does your company have a formal policy with related processes and procedures in place regarding business conduct?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        'Is business integrity included in the scope of your companies Business Conduct Policy or related rules and procedures?\n\n If "Yes," please provide details (e.g. anti-corruption, Anti money laundering, anti bribery etc..) \n\nIf "Planned," please provide details',
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Has your company implemented a risk assessment process to identify, prioritize, and mitigate business integrity risks associated with your suppliers?\n\n If 'Yes' please provide details how your company addresses and mitigates these risks \n\nPlease provide further details if 'No' or 'Planned'.",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question: "Are your financial statements audited by external auditors?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does your company have a programme and/or procedures in place to prevent corruption including bribery on the part of your suppliers?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  Environment: [
    {
      id: 1,
      question:
        "Do you have an environment policy in place?\n\n If 'Yes' please attach\n If 'No' or 'Planned' please provide justification or further information",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are environmental policies communicated to all employees and suppliers in local or appropriate languages?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you have an environmental system in place, e.g. ISO14001, EMAS?\n\n\n If yes, please attach certificate",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Do you have all the legaly required environmental permits, approvals, licenses and registrations?\n\n If 'Yes' please provide details\n\n If 'No' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Do you have established climate change targets aligned with the Business Ambition of 1.5C towards a net – zero economy? \ne.g. To reduce GHG emissions, increase use of renewable energy, reduce energy/water consumption, reduce waste generation \n\nIf yes, please specify. \n\nIf no, please share what other environmental targets you have in place",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Please indicate what percentage of renewable energy you use as a company.",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Labour Standards": [
    {
      id: 1,
      question:
        "Do you have a management system in place to assess health and safety as wel as fair labour standard risks?\n\nPlease provide any supporting documentation or details.",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Do you have a written corporate HR policy and/or internal guidelines that define your company's approach to fair labour standards and respect for human rights as wel as workplace health and safety policy?\n(e.g. diversity, inclusion, equal opportunity, elimination of discrimination, preventing harassment, zero tolerance to violence and bu lying, etc.)",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are labour, health and safety policies communicated to all employees and suppliers in local or appropriate languages?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your labour, health and safety policies cover all workers, full-time, part-time, external contractors, including migrant workers?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Do you have procedures in place to ensure all workers are paid at least the legal minimum wage for standard working hours according to the local regulations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Are you respecting national and international laws regulating working and overtime hours?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Do you ensure safe working environment and address any such potential issue ahead of time so as to ensure prevention of dangerous situations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Do you provide your employees with a mechanism to raise concerns or complaints and ensure a fair, just and reasonable resolution?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Human Rights": [
    {
      id: 1,
      question:
        "Do you operate in compliance with all applicable human rights laws and regulations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Do you have policies that prohibit forced labour as well as child labour?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you ensure that the labour you employ is voluntarily performed and not forced through any penalties?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question: "Do you employ workers younger than 15 years?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question: "Do you employ workers age between 16-18 years?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Do you allow your employees to form work councils according to the applicable laws and regulations and ensure that these are protected from any acts of interference with their establishments, administration or functioning? \n \nIf 'Yes' Please provide details.\n\n If 'No or Not Applicable' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  Compliance: [
    {
      id: 1,
      question:
        "Is your organization required to comply with any legal, regulatory or industry, requirements (e.g. GLBA, SOX, PCI, FEICC)?\n\nIf 'Yes' please provide details Is your organization required to comply with any legal, regulatory or industry, requirements (e.g. GLBA, SOX, PCI, FEICC)?\n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Within the last year, has there been an independent review of the company’s security policies, standards, procedures, and/or guidelines?\n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Does the organization undergo SOC2 Type II examination on an annual basis?\n\n If 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Have remediation plans been developed for all identified exceptions? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Risk Management": [
    {
      id: 1,
      question:
        "Does your company have a risk assessment program? (e.g. ISO31000 /ISF) \n\nIf 'Yes' please provide details and attach any supporting documentation\nIf 'No' please let us know if these are planned or how you assess and mitigate risks to your systems and clients",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Organizational Security": [
    {
      id: 1,
      question:
        "Does your organization have a documented information security policy that has been approved by management, published and communicated to all employees and is available on demand to relevant external parties?  \n\nSecurity policies should include 'Password Policy/ Governance & Responsibilities / SDLC / Vulnerability Management etc.. \n\nIf 'Yes' please provide details of content and how often the policy is reviewed \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Is there a formal process to approve exceptions to the policy? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is there an individual or group with the responsibilities of implementing/auditing and verifying the compliance against security policies?\n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your company have an Acceptable Use Policy? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Have you outsourced any of your operations to third parties and if so are vendor agreements in place covering security controls your vendors are accountable for? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Do you have an independent audit performed on your dependent third parties? \n\nIf 'Yes' please provide details and at what frequency \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Physical and Environmental Security": [
    {
      id: 1,
      question:
        "Does your company have a Physical Security policy? This should include CCTV, Physical ID's cards, identification of visitors, maintenance of buildings including generators and UPS. \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are strong physical controls in place around the access to your organizations building/premises?\n\nIf 'Yes' please provide a short description of these controls \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you have a secured work area Do you have a secured work area (segregated space) where employees access target data? \n If 'Yes' please provide details\n  If 'No' please provide a justifica",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does the organization ensure that the segregation is there between production and development environment?\n\n If 'Yes' please provide details \n If 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does your company have an approved Security Incident Management policy and documented plan? \n\nIf 'Yes' please provide details of the plan and how often it is tested. \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Does your company have a security incident response team with clearly defined and documented roles and responsibilities? \n\nIf 'Yes' please provide details (please also state if the team is available 24x7x265 and whether the individuals undergo formal training or qualification) \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Does the incident response teams receive any incident-response related training or qualifications? \n\nIf 'Yes' please provide details\n\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Does the Incident Response Plan require the notification to customers in the event of an incident?\n\n If 'Yes' please provide details and how fast customers are notified of an incident \n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question:
        "Is an Incident Response contact list or calling tree maintained?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        "Do you maintain documentation of all incidents, outcomes and their remediation/issues? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 11,
      question:
        "Does you organization have a formal change management / change control process? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 12,
      question:
        "Do you have a documented process on how to handle Emergency Changes?\n\n If 'Yes' please provide details\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 13,
      question:
        "Are policies and procedures in place that ensure modifications and changes to software packages/code are strictly controlled, reviewed and tested prior to being introduced into production?\n\n If 'Yes' please provide details on risk assessment and security review/approval. \n If 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 14,
      question:
        "If system changes occur, are changes reviewed and tested prior to being introduced into production?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 15,
      question:
        "Are policies and procedures in place that ensure modifications and essential changes to software packages are strictly controlled?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 16,
      question:
        "Is a formal process in place to patch systems and applications? Includes test patches, service packs and hot fixes prior to installation?\nIf 'Yes' please provide details \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Asset Management": [
    {
      id: 1,
      question:
        "Does your company have an approved asset management program that maintains inventory and classification of hardware/software assets?\n If 'Yes' please provide details\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does your organization have an approved information classification policy in place that is communicated to all employees?\n\n If 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are documented procedures in place for the disposal and/or destruction of assets? This includes physical media (e.g. Paper documents, CDs, DVDs, memory sticks, disk drives, etc.)\n\n If 'Yes' please provide details \n If 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Are documented procedures in place for the reuse of physical media (e.g.: Tapes, disk drives, etc.)? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Access Control": [
    {
      id: 1,
      question:
        "Is an approved Access Control and Remote Working policy in place? \n\nIf 'Yes' please provide details of level of the following \n- multi-factor authentication \n- how your organization restricts access to environments and systems \n- What type of technology used \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does your organization utilize unique user IDs to access company systems where each ID is associated to an individual? \n\nIf 'Yes' please provide details\n\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is access to a l systems and applications based on defined roles and responsibilities? And how regularly are these reviewed? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your organization have a leavers policy in place that notifies security / access administration teams to cease logical access of leavers in a timely manner? \nIf ' Yes' please provide details \nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Is a remote access solution present in the environment? (e.g. VPN)\n\nIf 'Yes' please provide details of the solution being used.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Is a approved teleworking policy in place which is consistent with organization security policy? Example would be home working or on a client site. \n\nIf 'Yes' please provide details",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Is there an approved password policy in place? \n\nIf 'Yes' please provide details of how initial passwords are communicated to users? \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Vulnerability and Threat Management": [
    {
      id: 1,
      question:
        "Does your organization have a documented process in place for system penetration, threat or vulnerability assessment tools? \n\nIf 'Yes' Please provide details of tools used.\nIf 'No' please provide details of what is used.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are your organizations workstations and servers protected with endpoint security controls?\n\nIf 'Yes' please provide details\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are third party alert services/tools used to keep up to date with the latest vulnerabilities? \n\nIf 'Yes' please provide details of the tools used. \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Are systems and networks periodically assessed for vulnerabilities and the results reported and action taken for remediation? \n\nIf 'Yes' please provide details of process in place to evaluate and prioritize vulnerabilities",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Has an external company performed a vulnerability assessment of the IT environment within the last year?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Are regular penetration tests executed against web-based applications?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Do you encrypt data in transit?\n\n If 'Yes' please provide details on technology being used.\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Business Continuity Management": [
    {
      id: 1,
      question:
        "Does the organization have a Business Continuity Management System (BCMS)? \nIf  Yes' please state if it is ISO 22301 and for which products.\nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does the organization have a Business Continuity Plan (BCP)?\n\nPlease indicate if these and are in place for critical functions of your business, which contain information from the Business Impact Analysis (BIA) and guidance on how to recover from likely risk scenarios, for your operating locations. This is about business functions and processes, not technology",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Does the organization perform annual BCP Tests? \nBCP tests should be:\n• Based on likely scenarios. \n• Includes your critical third parties. \n• this is about business functions and processes, not Disaster Recovery Tests for IT Services/Systems.\n\nIf 'Yes' Please provide details if not inline with the above. \nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does the organization have an IT Disaster Recovery Plan (DPR)?\n \n• Relates to Information, Communication Technology (ICT) only.\n• Should include your critical third parties and be reviewed annually. \n• Outlines how ICT would recover and/or failover to other technology, systems and locations, based on likely disruption scenarios to your ICT environment and /or applications or data. \n\nIf 'Yes ' Please provide details if not inline with the above. \nIf ' No' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does the organization perform IT DRP Test? \n• Should be conducted annualy. \n• Based on likely scenarios. \n• Includes your critical third party \n\nIf 'Yes' Please provide details if not inline with the above. \nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Does the organization have a Pandemic Plan? \nIf 'Yes' please provide details. \nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Does the organization have a Crisis Management Plan? \n\nUsed to guide the organization through a strategic response to a major crisis.\n\nIf 'Yes' please provide details\nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Does the organization have Supplier Contingency Plans? \n\n- Used for your critical third parties and documents the contingency for supplier failure. \n- Should be reviewed annually or upon a material change. \n\nIf 'Yes' please provide details\nIf ' No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question:
        'Do you have a formal and documented Software Development Life Cycle (SDLC)? \n\nIf "Yes," please describe. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        'Do your developers undergo training to ensure knowledge of security standards and industry best practices? \nIf "Yes," please describe the type of training and regularity of training (on-boarding only, annua ly, etc.). \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 11,
      question:
        'Does your SDLC align with industry standards and best practices, such as the Open Web Application Security Project (OWASP)?\n If "Yes," please list the standards and best practices. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 12,
      question:
        'Do you have a process for identifying vulnerabilities?\n\nIf "Yes," please describe your process. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 13,
      question:
        'Do you have controls in place to protect against malicious code?\n\nIf "Yes," please describe your process. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 14,
      question:
        'Do you outsource any part of your software development? \n\nIf "Yes" or "Not Applicable," please provide an explanation.\nIf "No," please confirm that all development is performed by your in-house team of developers.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 15,
      question:
        'Do you use any open-source software as part of providing your services?\n\nIf "Yes," please list the open-source software used as part of your services.\nIf "Not Applicable," please explain your answer',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 16,
      question:
        'Do you have a policy for managing access to the program source code?\n\nIf "Yes," please describe your policy. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 17,
      question:
        'Do you have procedures in place to ensure that only authorized individuals have access to the program source code? \n\nIf "Yes," please describe the procedures. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Cloud Specific Requirements": [
    {
      id: 1,
      question:
        "Please specify the type of cloud hosting services you provide. \nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are you using private or third party data centers? \nIf 'Private' please provide details of the controls in place \nIf 'Third Party' please provide company details",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is the Cloud Service Provider certified by an independent third party for compliance with domestic or international control standards? \nThese would include, but not limited to, NIST, ISO, PCI, SOC etc.. \n\nIf 'Yes' please specify \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
};

const response1 = {
  "Company Profile": [
    {
      id: 1,
      question: "Please provide the full name for the company?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Please provide a summary of the scope of services you will be providing ?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question: "Where is compnay located ?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Will the  goods and services be contracted from an entity in EU?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question: "Primary Industry Area Primary Industry Area",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Please provide the contact details and job title of the person completing the questionnaire",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Please indicate the first, middle and surname of each of the company managing directors and individuals with 5% or more ownership of your company",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question: "What is the ownership type of your company?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question: "Number of employees",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        "What is the approximate annual turnover (in USD) of your companies last fiscal year?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Information Security": [
    {
      id: 1,
      question:
        'Would your service require access to  any software applications (e.g., via a network connection)? \n\nProviding clear details about the nature and purpose of access is crucial. Please be aware that a "yes" answer may imply your willingness to undergo a further security assessment for your service.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        'Will your tools or applications require remote access to our software applications  (e.g., via VPN)? Please describe the specific access needs and protocols involved.\n\nif \'Yes\' you maybe required to complete an additional Security Assessment).\nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do your tools or applications directly deliver or manage cloud/SaaS products or services associated with software applications? \n\nPlease provide details of the specific products or services involved. If yes, be aware that you may be required to undergo a further security assessment due to the sensitive nature of the data involved.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Data Privacy": [
    {
      id: 1,
      question:
        "Will your tools or applications have access to or control over any of our software application's data (including client data)? \n\nThis may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Third Party/Subcontractors": [
    {
      id: 1,
      question:
        "In delivering services or products to your clients, do you utilize any third-party vendors or subcontractors to perform any part of the work? \n\nPlease provide details on the scope of their involvement and the types of services they undertake. We're particularly interested in any subvendors who may interact with our critical software or systems.",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Business and Ethical Conduct": [
    {
      id: 1,
      question:
        "Does your company have a formal policy with related processes and procedures in place regarding business conduct?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        'Is business integrity included in the scope of your companies Business Conduct Policy or related rules and procedures?\n\n If "Yes," please provide details (e.g. anti-corruption, Anti money laundering, anti bribery etc..) \n\nIf "Planned," please provide details',
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Has your company implemented a risk assessment process to identify, prioritize, and mitigate business integrity risks associated with your suppliers?\n\n If 'Yes' please provide details how your company addresses and mitigates these risks \n\nPlease provide further details if 'No' or 'Planned'.",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question: "Are your financial statements audited by external auditors?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does your company have a programme and/or procedures in place to prevent corruption including bribery on the part of your suppliers?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  Environment: [
    {
      id: 1,
      question:
        "Do you have an environment policy in place?\n\n If 'Yes' please attach\n If 'No' or 'Planned' please provide justification or further information",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are environmental policies communicated to all employees and suppliers in local or appropriate languages?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you have an environmental system in place, e.g. ISO14001, EMAS?\n\n\n If yes, please attach certificate",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Do you have all the legaly required environmental permits, approvals, licenses and registrations?\n\n If 'Yes' please provide details\n\n If 'No' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Do you have established climate change targets aligned with the Business Ambition of 1.5C towards a net – zero economy? \ne.g. To reduce GHG emissions, increase use of renewable energy, reduce energy/water consumption, reduce waste generation \n\nIf yes, please specify. \n\nIf no, please share what other environmental targets you have in place",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Please indicate what percentage of renewable energy you use as a company.",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Labour Standards": [
    {
      id: 1,
      question:
        "Do you have a management system in place to assess health and safety as wel as fair labour standard risks?\n\nPlease provide any supporting documentation or details.",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Do you have a written corporate HR policy and/or internal guidelines that define your company's approach to fair labour standards and respect for human rights as wel as workplace health and safety policy?\n(e.g. diversity, inclusion, equal opportunity, elimination of discrimination, preventing harassment, zero tolerance to violence and bu lying, etc.)",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are labour, health and safety policies communicated to all employees and suppliers in local or appropriate languages?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your labour, health and safety policies cover all workers, full-time, part-time, external contractors, including migrant workers?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Do you have procedures in place to ensure all workers are paid at least the legal minimum wage for standard working hours according to the local regulations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Are you respecting national and international laws regulating working and overtime hours?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Do you ensure safe working environment and address any such potential issue ahead of time so as to ensure prevention of dangerous situations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Do you provide your employees with a mechanism to raise concerns or complaints and ensure a fair, just and reasonable resolution?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Human Rights": [
    {
      id: 1,
      question:
        "Do you operate in compliance with all applicable human rights laws and regulations?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Do you have policies that prohibit forced labour as well as child labour?",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you ensure that the labour you employ is voluntarily performed and not forced through any penalties?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question: "Do you employ workers younger than 15 years?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question: "Do you employ workers age between 16-18 years?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Do you allow your employees to form work councils according to the applicable laws and regulations and ensure that these are protected from any acts of interference with their establishments, administration or functioning? \n \nIf 'Yes' Please provide details.\n\n If 'No or Not Applicable' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  Compliance: [
    {
      id: 1,
      question:
        "Is your organization required to comply with any legal, regulatory or industry, requirements (e.g. GLBA, SOX, PCI, FEICC)?\n\nIf 'Yes' please provide details Is your organization required to comply with any legal, regulatory or industry, requirements (e.g. GLBA, SOX, PCI, FEICC)?\n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Within the last year, has there been an independent review of the company’s security policies, standards, procedures, and/or guidelines?\n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Does the organization undergo SOC2 Type II examination on an annual basis?\n\n If 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Have remediation plans been developed for all identified exceptions? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Risk Management": [
    {
      id: 1,
      question:
        "Does your company have a risk assessment program? (e.g. ISO31000 /ISF) \n\nIf 'Yes' please provide details and attach any supporting documentation\nIf 'No' please let us know if these are planned or how you assess and mitigate risks to your systems and clients",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Organizational Security": [
    {
      id: 1,
      question:
        "Does your organization have a documented information security policy that has been approved by management, published and communicated to all employees and is available on demand to relevant external parties?  \n\nSecurity policies should include 'Password Policy/ Governance & Responsibilities / SDLC / Vulnerability Management etc.. \n\nIf 'Yes' please provide details of content and how often the policy is reviewed \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Is there a formal process to approve exceptions to the policy? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is there an individual or group with the responsibilities of implementing/auditing and verifying the compliance against security policies?\n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your company have an Acceptable Use Policy? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Have you outsourced any of your operations to third parties and if so are vendor agreements in place covering security controls your vendors are accountable for? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Do you have an independent audit performed on your dependent third parties? \n\nIf 'Yes' please provide details and at what frequency \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Physical and Environmental Security": [
    {
      id: 1,
      question:
        "Does your company have a Physical Security policy? This should include CCTV, Physical ID's cards, identification of visitors, maintenance of buildings including generators and UPS. \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are strong physical controls in place around the access to your organizations building/premises?\n\nIf 'Yes' please provide a short description of these controls \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Do you have a secured work area Do you have a secured work area (segregated space) where employees access target data? \n If 'Yes' please provide details\n  If 'No' please provide a justifica",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does the organization ensure that the segregation is there between production and development environment?\n\n If 'Yes' please provide details \n If 'No' please provide a justification",
      question_type: "likert",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does your company have an approved Security Incident Management policy and documented plan? \n\nIf 'Yes' please provide details of the plan and how often it is tested. \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Does your company have a security incident response team with clearly defined and documented roles and responsibilities? \n\nIf 'Yes' please provide details (please also state if the team is available 24x7x265 and whether the individuals undergo formal training or qualification) \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Does the incident response teams receive any incident-response related training or qualifications? \n\nIf 'Yes' please provide details\n\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Does the Incident Response Plan require the notification to customers in the event of an incident?\n\n If 'Yes' please provide details and how fast customers are notified of an incident \n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question:
        "Is an Incident Response contact list or calling tree maintained?",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        "Do you maintain documentation of all incidents, outcomes and their remediation/issues? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 11,
      question:
        "Does you organization have a formal change management / change control process? \n\nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 12,
      question:
        "Do you have a documented process on how to handle Emergency Changes?\n\n If 'Yes' please provide details\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 13,
      question:
        "Are policies and procedures in place that ensure modifications and changes to software packages/code are strictly controlled, reviewed and tested prior to being introduced into production?\n\n If 'Yes' please provide details on risk assessment and security review/approval. \n If 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 14,
      question:
        "If system changes occur, are changes reviewed and tested prior to being introduced into production?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 15,
      question:
        "Are policies and procedures in place that ensure modifications and essential changes to software packages are strictly controlled?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 16,
      question:
        "Is a formal process in place to patch systems and applications? Includes test patches, service packs and hot fixes prior to installation?\nIf 'Yes' please provide details \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Asset Management": [
    {
      id: 1,
      question:
        "Does your company have an approved asset management program that maintains inventory and classification of hardware/software assets?\n If 'Yes' please provide details\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does your organization have an approved information classification policy in place that is communicated to all employees?\n\n If 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are documented procedures in place for the disposal and/or destruction of assets? This includes physical media (e.g. Paper documents, CDs, DVDs, memory sticks, disk drives, etc.)\n\n If 'Yes' please provide details \n If 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Are documented procedures in place for the reuse of physical media (e.g.: Tapes, disk drives, etc.)? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Access Control": [
    {
      id: 1,
      question:
        "Is an approved Access Control and Remote Working policy in place? \n\nIf 'Yes' please provide details of level of the following \n- multi-factor authentication \n- how your organization restricts access to environments and systems \n- What type of technology used \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does your organization utilize unique user IDs to access company systems where each ID is associated to an individual? \n\nIf 'Yes' please provide details\n\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is access to a l systems and applications based on defined roles and responsibilities? And how regularly are these reviewed? \n\nIf 'Yes' please provide details \n\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does your organization have a leavers policy in place that notifies security / access administration teams to cease logical access of leavers in a timely manner? \nIf ' Yes' please provide details \nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Is a remote access solution present in the environment? (e.g. VPN)\n\nIf 'Yes' please provide details of the solution being used.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Is a approved teleworking policy in place which is consistent with organization security policy? Example would be home working or on a client site. \n\nIf 'Yes' please provide details",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Is there an approved password policy in place? \n\nIf 'Yes' please provide details of how initial passwords are communicated to users? \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Vulnerability and Threat Management": [
    {
      id: 1,
      question:
        "Does your organization have a documented process in place for system penetration, threat or vulnerability assessment tools? \n\nIf 'Yes' Please provide details of tools used.\nIf 'No' please provide details of what is used.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are your organizations workstations and servers protected with endpoint security controls?\n\nIf 'Yes' please provide details\nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Are third party alert services/tools used to keep up to date with the latest vulnerabilities? \n\nIf 'Yes' please provide details of the tools used. \nIf 'No' please justify",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Are systems and networks periodically assessed for vulnerabilities and the results reported and action taken for remediation? \n\nIf 'Yes' please provide details of process in place to evaluate and prioritize vulnerabilities",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Has an external company performed a vulnerability assessment of the IT environment within the last year?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Are regular penetration tests executed against web-based applications?",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Do you encrypt data in transit?\n\n If 'Yes' please provide details on technology being used.\n If 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Business Continuity Management": [
    {
      id: 1,
      question:
        "Does the organization have a Business Continuity Management System (BCMS)? \nIf  Yes' please state if it is ISO 22301 and for which products.\nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Does the organization have a Business Continuity Plan (BCP)?\n\nPlease indicate if these and are in place for critical functions of your business, which contain information from the Business Impact Analysis (BIA) and guidance on how to recover from likely risk scenarios, for your operating locations. This is about business functions and processes, not technology",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Does the organization perform annual BCP Tests? \nBCP tests should be:\n• Based on likely scenarios. \n• Includes your critical third parties. \n• this is about business functions and processes, not Disaster Recovery Tests for IT Services/Systems.\n\nIf 'Yes' Please provide details if not inline with the above. \nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 4,
      question:
        "Does the organization have an IT Disaster Recovery Plan (DPR)?\n \n• Relates to Information, Communication Technology (ICT) only.\n• Should include your critical third parties and be reviewed annually. \n• Outlines how ICT would recover and/or failover to other technology, systems and locations, based on likely disruption scenarios to your ICT environment and /or applications or data. \n\nIf 'Yes ' Please provide details if not inline with the above. \nIf ' No' please provide justification",
      question_type: "Comment box",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 5,
      question:
        "Does the organization perform IT DRP Test? \n• Should be conducted annualy. \n• Based on likely scenarios. \n• Includes your critical third party \n\nIf 'Yes' Please provide details if not inline with the above. \nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 6,
      question:
        "Does the organization have a Pandemic Plan? \nIf 'Yes' please provide details. \nIf 'No' please provide justification.",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 7,
      question:
        "Does the organization have a Crisis Management Plan? \n\nUsed to guide the organization through a strategic response to a major crisis.\n\nIf 'Yes' please provide details\nIf 'No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 8,
      question:
        "Does the organization have Supplier Contingency Plans? \n\n- Used for your critical third parties and documents the contingency for supplier failure. \n- Should be reviewed annually or upon a material change. \n\nIf 'Yes' please provide details\nIf ' No' please provide justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 9,
      question:
        'Do you have a formal and documented Software Development Life Cycle (SDLC)? \n\nIf "Yes," please describe. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 10,
      question:
        'Do your developers undergo training to ensure knowledge of security standards and industry best practices? \nIf "Yes," please describe the type of training and regularity of training (on-boarding only, annua ly, etc.). \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 11,
      question:
        'Does your SDLC align with industry standards and best practices, such as the Open Web Application Security Project (OWASP)?\n If "Yes," please list the standards and best practices. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 12,
      question:
        'Do you have a process for identifying vulnerabilities?\n\nIf "Yes," please describe your process. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 13,
      question:
        'Do you have controls in place to protect against malicious code?\n\nIf "Yes," please describe your process. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 14,
      question:
        'Do you outsource any part of your software development? \n\nIf "Yes" or "Not Applicable," please provide an explanation.\nIf "No," please confirm that all development is performed by your in-house team of developers.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 15,
      question:
        'Do you use any open-source software as part of providing your services?\n\nIf "Yes," please list the open-source software used as part of your services.\nIf "Not Applicable," please explain your answer',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 16,
      question:
        'Do you have a policy for managing access to the program source code?\n\nIf "Yes," please describe your policy. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 17,
      question:
        'Do you have procedures in place to ensure that only authorized individuals have access to the program source code? \n\nIf "Yes," please describe the procedures. \nIf "No" or "Not Applicable," please provide an explanation.',
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
  "Cloud Specific Requirements": [
    {
      id: 1,
      question:
        "Please specify the type of cloud hosting services you provide. \nIf 'Yes' please provide details \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question:
        "Are you using private or third party data centers? \nIf 'Private' please provide details of the controls in place \nIf 'Third Party' please provide company details",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      id: 3,
      question:
        "Is the Cloud Service Provider certified by an independent third party for compliance with domestic or international control standards? \nThese would include, but not limited to, NIST, ISO, PCI, SOC etc.. \n\nIf 'Yes' please specify \nIf 'No' please provide a justification",
      question_type: "likert",
      comment: true,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
  ],
};

const response2 = {
  "Self Assessment Section": [
    {
      question:
        "How well has your company been able to adapt its business model in response to environmental and social changes?",
      id: 1,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company’s current business model take into account environmental and social changes that may occur in the future?",
      id: 2,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company assessed the risks of climate change and other external environmental and social factors to its materials supply chains?",
      id: 3,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company taken action to reduce the use of key materials?",
      id: 4,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent has your company taken action to make R&D investments in substitute materials?",
      id: 5,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well is your company able to adapt to increased frequency and severity of extreme weather, shifting climate, sea level risk, and other expected physical impacts of climate change?",
      id: 6,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you agree that the company's Product Design & Lifecycle Management practices are environmentally and socially responsible?",
      id: 7,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How important are environmental, social, and governance (ESG) considerations in the product design and lifecycle management of your company?",
      id: 8,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company focus on reducing environmental and social risks in your supply chain?",
      id: 9,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company focus on promoting good labor practices in your supply chain?",
      id: 10,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "The company has a comprehensive air quality management system that is designed to ensure compliance with all relevant laws and regulations:",
      id: 11,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much does your company consider ecological impacts when making decisions about land use for exploration, natural resource extraction, and cultivation?",
      id: 12,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's level of maturity in Energy Management?",
      id: 13,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How much experience does your company have in measuring and managing GHG emissions from its operations?",
      id: 14,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Waste & Hazardous Materials Management?",
      id: 15,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's Water & Wastewater Management practices?",
      id: 16,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How important is Employee Engagement, Diversity & Inclusion to your company's success?",
      id: 17,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How diverse is your company's workforce?",
      id: 18,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often are Employee Engagement, Diversity & Inclusion issues discussed at your company?",
      id: 19,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1-5, how would you rate your company's Employee Health & Safety policies and procedures?",
      id: 20,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how would you rate the maturity level of your company on Labor Practices?",
      id: 21,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Business Ethics?",
      id: 22,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's management of legal and social expectation around monopolistic and anti-competitive practices?",
      id: 23,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's maturity level on Critical Incident Risk Management?",
      id: 24,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your company's management of the legal and regulatory environment?",
      id: 25,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management reduce its contributions to systemic risks?",
      id: 26,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management improve safeguards against systemic risks?",
      id: 27,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does your company's risk management protect against financial and economic stress?",
      id: 28,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you think that the company's products and services are accessible to underserved markets and population groups?",
      id: 29,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Do you think that the company is doing enough to ensure that its products and services are accessible and affordable to underserved markets and population groups?",
      id: 30,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How mature is your company in managing risks related to the use of personally identifiable information (PII) and other customer or user data for secondary purposes?",
      id: 31,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How well does the company address customer welfare concerns over issues including, but not limited to, health and nutrition of foods and beverages, antibiotic use in animal production, and management of controlled substances?",
      id: 32,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How effective do you feel your company is at managing risks related to customer or user data?",
      id: 33,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How secure does your company keep customer or user data?",
      id: 34,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company identify and assess impacts on local communities, including impacts on core human rights and indigenous peoples?",
      id: 35,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company engage with local communities to understand their perspectives and needs?",
      id: 36,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "Does the company proactively seek to create positive impacts on local communities?",
      id: 37,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How effective is your company at ensuring product quality and safety?",
      id: 38,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question: "How often do you test your products for quality and safety?",
      id: 39,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree that the company's selling practices & product labeling are ethical and responsible?",
      id: 40,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
  ],
  "Controversy Survey": [
    {
      question:
        "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
      id: 14,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often do you come across controversies related to patents and intellectual property infringements?",
      id: 13,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
      id: 7,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
      id: 11,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 4,
      hasNewlyAdded: false,
    },
    {
      question:
        "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
      id: 15,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
      id: 23,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
      id: 5,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 7,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
      id: 8,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 8,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about controversies linked to responsible R&D in our company?",
      id: 1,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 9,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to customer health and safety at this company?",
      id: 19,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
      id: 4,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
      id: 6,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
      id: 22,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
    {
      question:
        "How many controversies have been linked to employees or customers in the past year?",
      id: 18,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 4,
      hasNewlyAdded: false,
    },
    {
      question:
        'How strongly do you agree with the statement: "The company should prioritize avoiding controversies in countries that do not respect human rights principles"?',
      id: 20,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 5,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
      id: 2,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 6,
      hasNewlyAdded: false,
    },
    {
      question:
        "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
      id: 10,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 7,
      hasNewlyAdded: false,
    },
    {
      question:
        "How often do you believe that access to a product has been associated with controversies?",
      id: 17,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 8,
      hasNewlyAdded: false,
    },
    {
      question:
        '"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing."',
      id: 9,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 9,
      hasNewlyAdded: false,
    },
    {
      question:
        'How much do you agree with the following statement: "There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.',
      id: 12,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 10,
      hasNewlyAdded: false,
    },
    {
      question:
        "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
      id: 16,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      question:
        "How concerned are you about the number of controversies linked to human rights issues within the company?",
      id: 21,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    {
      question:
        "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
      id: 3,
      question_type: "Satisfaction",
      comment: false,
      isEdit: false,
      weightage: 3,
      hasNewlyAdded: false,
    },
  ],
};

export {
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_CURRENCY,
  QUESTION_TYPE_NUMBER,
  questionTypes,
  subQuestionTypes,
  radioOption,
  sectionWiseData,
  response1,
  response2,
};
