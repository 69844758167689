import { Box, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  gql_create_alert,
  gql_update_alert,
} from "redux/actions/vendorActions/vendorDiagnosisAction";

const AlertFooter = ({
  alertScreen,
  setAlert,
  initialAlert,
  newAlert,
  setNewAlert,
  closeDrawer,
  alerts,
  updateAlert,
  setUpdateAlert,
  fetchAlerts,
  stepName,
  tabName,
}) => {
  const { surveyListDataForm } = useSelector((state) => state.brmData);

  const disableAddAlert =
    !newAlert.name ||
    !newAlert.frequency ||
    newAlert.range.length === 0 ||
    !newAlert.comment;

  const disableSaveChanges =
    JSON.stringify(updateAlert) === JSON.stringify(alerts);

  const handleClearAlert = () => {
    setNewAlert(initialAlert);
  };

  const getSurveyId = (from = "VENDOR_INTAKE_OR_SCOPING") => {
    switch (from) {
      case "RISK_ASSESMENT":
        return surveyListDataForm?.riskAssessmentSurveys[0]?._id || "";

      case "CONTRACTING":
        return "";

      case "MONITORING":
        return "";

      case "DUEDILIGENCE":
        if (
          alertScreen?.from === "TECHNICAL_AND_OPERATIONAL_SECURITY" ||
          alertScreen?.from === "GOVERNANCE_AND_ORGANIZATIONAL_SECURITY"
        ) {
          return (
            surveyListDataForm?.dueDiligenceSurveys?.find(
              (survey) => survey?.name?.toLowerCase() === "iso 2700"
            )?._id || ""
          );
        }
        if (alertScreen?.from === "DUE_COMPLIANCE_SCORE") {
          return "";
        } else {
          return (
            surveyListDataForm?.dueDiligenceSurveys?.find(
              (survey) => survey?.name?.toLowerCase() === "vendor due diligence"
            )?._id || ""
          );
        }

      default:
        if (alertScreen?.from === "VENDOR_SCORE") {
          return "";
        } else {
          const survey = surveyListDataForm?.vendorScopingSurveys?.find(
            (e) =>
              e?.name?.toLowerCase() ===
              alertScreen?.from?.split("_")?.join(" ")?.toLowerCase()
          );

          return survey?._id || "";
        }
    }
  };

  const handleAddAlert = async () => {
    const surveyId = getSurveyId(stepName);

    if (!disableAddAlert) {
      let obj;
      if (
        alertScreen?.from === "DUE_COMPLIANCE_SCORE" ||
        alertScreen?.from === "CONTRACTING_COMPLIANCE_SCORE" ||
        alertScreen?.from === "VENDOR_SCORE" ||
        stepName === "MONITORING"
      ) {
        obj = {
          name: newAlert?.name,
          frequencyType: newAlert?.frequency,
          lowerBounds: newAlert?.range[0],
          upperBounds: newAlert?.range[1],
          comment: newAlert?.comment,
          widgetName: alertScreen?.from,
          stepName,
        };
      } else {
        obj = {
          name: newAlert?.name,
          surveyId,
          frequencyType: newAlert?.frequency,
          lowerBounds: newAlert?.range[0],
          upperBounds: newAlert?.range[1],
          comment: newAlert?.comment,
          widgetName: alertScreen?.from,
          stepName,
        };
      }

      await gql_create_alert(obj);

      setNewAlert(newAlert);
      handleClearAlert();
      setAlert({ type: "set", from: "" });
    }
  };

  const handleSave = async () => {
    if (!disableSaveChanges) {
      const transformedUpdateAlert = updateAlert?.map((alert) => ({
        _id: alert._id,
        active: alert.active,
        lowerBounds: alert.lowerBounds,
        pause: alert.pause,
        upperBounds: alert.upperBounds,
      }));

      await gql_update_alert({ updatedAlerts: transformedUpdateAlert });
      await fetchAlerts();
      setUpdateAlert(alerts);

      // closeDrawer();
    }
  };

  return (
    <Box>
      {alertScreen?.type === "add" ? (
        <StackWrapper>
          <Button color="primary" onClick={handleClearAlert}>
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAlert}
            disabled={disableAddAlert}
          >
            Add Alert
          </Button>
        </StackWrapper>
      ) : (
        <StackWrapper>
          <Button color="primary" onClick={closeDrawer}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={disableSaveChanges}
          >
            Save changes
          </Button>
        </StackWrapper>
      )}
    </Box>
  );
};
export default AlertFooter;

const StackWrapper = ({ children }) => {
  return (
    <Box display={"flex"} justifyContent={"flex-end"} sx={{ gap: 10 }}>
      {children}
    </Box>
  );
};
