import { Box } from "@material-ui/core";
import DynamicTableFull from "components/UI/dynamicTable/DynamicTableFull";
import NoData from "components/widgets/NoData/NoData";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const ROIScreen = ({ selectedROI }) => {
  const dispatch = useDispatch();
  const headings = [
    { name: "LEI of the entity maintaining the register of information" },
    { name: "Name of the entity" },
    { name: "Country of the entity" },
    { name: "Type of entity" },
  ];

  useEffect(() => {
    // dispatch(gql_get_linkage_survey_questions(selectedROI?._id, vendorId))

    return () => {};
  }, [selectedROI?._id]);

  // If selectedROI is not available
  if (!selectedROI) {
    return <NoData height="60vh" message={"Please select an ROI"} />;
  }

  return (
    <Box style={{ height: "70vh" }}>
      <DynamicTableFull tableHead={headings} />
    </Box>
  );
};

export default ROIScreen;
