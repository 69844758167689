import React, { useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import CustomButton from "../../../../../../../UI/button/button";

import Textfield from "../../../../../../../UI/textfield/textfield";
import TrashIcon from "../../../../../../../../../src/images/trashRed.png";

const TableTypeQuestion = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  setTableHeader,
  tableHeadersApi,
  linkageAnswer,
}) => {
  const [headers, setHeaders] = useState([]);
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const [row, setRow] = useState([[]]);
  const [commentBox, setCommentBox] = useState("");
  // const [modalOpen, setModalOpen] = useState(false);
  // const pdfUrl = "https://example.com/path/to/your/pdf.pdf";

  const inputRefs = useRef({});
  const checkClickRef = useRef(null);

  const columnTypes = ["Document", "Text", "Number", "Url", "Email"];

  const onTypesChange = (newTypes) => {
    setTypes(newTypes);
  };

  const onHeadersChange = (newHeaders) => {
    setHeaders(newHeaders);
  };

  const handleHeaderChange = (event, index) => {
    const newHeaders = [...headers];
    newHeaders[index] = event.target.value;
    setHeaders(newHeaders);
    onHeadersChange(newHeaders);
    setCategory((prev) => {
      const newValue = [...category];
      newValue[index] = { ...category[index], title: event.target.value };

      return newValue;
    });
  };

  const handleTypeChange = (event, index) => {
    const newTypes = [...types];
    newTypes[index] = event.target.value;
    setTypes(newTypes);
    onTypesChange(newTypes);

    let modifiedType;
    if (event.target.value === "Text") {
      modifiedType = "TF";
    } else if (event.target.value === "Document") {
      modifiedType = "DOCUMENT_UPLOAD";
    } else if (event.target.value === "Email") {
      modifiedType = "EMAIL";
    } else if (event.target.value === "Number") {
      modifiedType = "NF";
    } else if (event.target.value === "Url") {
      modifiedType = "URL";
    }

    setCategory((prev) => {
      const newValue = [...category];
      newValue[index] = { ...category[index], type: modifiedType };

      return newValue;
    });
  };

  const handleRowData = (event, cellIndex, rowIndex, type) => {
    const formattedValue = event.target.value.replace(/\D/g, "");
    const newData = [...row];

    if (type === "Document") {
      newData[rowIndex][cellIndex] = {
        option: null,
        weightage: 2,
        documentUrl: event.target.files[0],
        comment: null,
        type,
      };
    } else {
      newData[rowIndex][cellIndex] = {
        option:
          type === "Number"
            ? formattedValue
            : type === "Email"
            ? event.target.value.toLowerCase()
            : event.target.value,
        weightage: 2,
        documentUrl: null,
        comment: null,
        type,
      };
    }

    setRow(newData);
  };

  const handleFileInputClick = (rowIndex, cellIndex) => {
    const key = `${rowIndex}-${cellIndex}`;
    if (inputRefs.current[key]) {
      inputRefs.current[key].current.click();
    }
  };

  const addHeader = () => {
    const newHeaders = [...headers, `Header ${headers?.length + 1}`];
    const newTypes = [...types, "TF"];
    setHeaders(newHeaders);
    setTypes(newTypes);
    onHeadersChange(newHeaders);
    onTypesChange(newTypes);

    setCategory((prev) => {
      const newValue = [
        ...category,
        {
          type: "TF",
          title: `Header ${headers?.length + 1}`,
        },
      ];

      return newValue;
    });
  };

  const removeHeader = (index) => {
    const newHeaders = headers?.filter((_, i) => i !== index);
    const newTypes = types?.filter((_, i) => i !== index);
    setHeaders(newHeaders);
    setTypes(newTypes);
    onHeadersChange(newHeaders);
    onTypesChange(newTypes);

    setCategory((prev) => {
      const removeObj = category?.filter((_, i) => i !== index);
      return removeObj;
    });
  };

  const addRow = () => {
    if (isFrom === "taskSection") {
      const rowData = Array.from({ length: tableHeadersApi?.length }, () => {
        return {
          option: null,
          weightage: 2,
          documentUrl: null,
          comment: null,
        };
      });

      const newRow = [...row, rowData];
      setRow(newRow);
    }
  };

  const removeRow = (index) => {
    const remove = row?.filter((_, i) => i !== index);
    setRow(remove);
  };

  useEffect(() => {
    if (isFrom === "surveyCreation") {
      setTableHeader(category);
    }
  }, [category]);

  useEffect(() => {
    setHeaders((prev) => tableHeadersApi?.map((header) => header?.title));

    setTypes((prev) => tableHeadersApi?.map((header) => header?.type));

    setCategory(tableHeadersApi);

    if (isFrom === "taskSection") {
      const rowData = Array.from({ length: tableHeadersApi?.length }, () => {
        return {
          option: null,
          weightage: 2,
          documentUrl: null,
          comment: null,
        };
      });

      setRow(() => [rowData]);
    }
  }, [tableHeadersApi]);

  const formattedType = (value) => {
    if (value === "TF") {
      value = "Text";
    } else if (value === "DOCUMENT_UPLOAD") {
      value = "Document";
    } else if (value === "EMAIL") {
      value = "Email";
    } else if (value === "NF") {
      value = "Number";
    } else if (value === "URL") {
      value = "Url";
    }
    return value;
  };

  const shortenFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    }

    const headLength = Math.floor((maxLength - 3) / 2);
    const tailLength = Math.ceil((maxLength - 3) / 2);

    const head = fileName?.substring(0, headLength);
    const tail = fileName?.substring(fileName?.length - tailLength);

    return fileName === undefined ? "-" : `${head}...${tail}`;
  };

  useEffect(() => {
    if (
      isFrom === "taskSection" &&
      row?.some((ans) => ans?.some((a) => a.option !== null))
    ) {
      const withCommentOption = row?.map((opt) =>
        opt.map((op) => ({
          ...op,
          comment: commentBox,
        }))
      );

      handleQuesAnswer(withCommentOption);
    }
  }, [row, commentBox]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer?.length > 0) {
      setRow(submittedAnswer);
    }
  }, [submittedAnswer]);

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const validURL = (value) => {
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    return urlRegex.test(value);
  };

  // const handlePdfOpen = () => {
  //   setModalOpen(true);
  // };

  // const handlePdfClose = () => {
  //   setModalOpen(false);
  // };

  return (
    <Box>
      <TableContainer style={{ pointerEvents: event }}>
        <Table>
          <TableHead>
            <TableRow style={{ display: "flex" }}>
              {headers?.map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    backgroundColor:
                      isFrom !== "surveyCreation" ? "#EBF1F8" : "",
                    flex: 1,
                  }}
                >
                  {isFrom === "surveyCreation" ? (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 5 }}
                    >
                      <Box>
                        <Textfield
                          value={header}
                          onChange={(event) => handleHeaderChange(event, index)}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            marginRight: 8,
                            pointerEvents:
                              isFrom === "surveyCreation" ? "initial" : "none",
                          }}
                        />

                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            marginRight: 8,
                            minWidth: 120,
                            marginTop: 10,
                          }}
                        >
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={formattedType(types[index])}
                            onChange={(event) => handleTypeChange(event, index)}
                            label="Type"
                          >
                            {columnTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <IconButton
                        onClick={() => removeHeader(index)}
                        aria-label="delete"
                        size="small"
                        color="primary"
                        style={{}}
                      >
                        <img
                          src={TrashIcon}
                          style={{ height: "20px" }}
                          alt="upload"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isFrom !== "surveyCreation" && (
            <TableBody>
              {row?.map((rowD, rowIndex) => (
                <TableRow key={rowIndex} style={{ display: "flex" }}>
                  {headers?.map((header, cellIndex) => {
                    const key = `${rowIndex}-${cellIndex}`;
                    if (!inputRefs.current[key]) {
                      inputRefs.current[key] = React.createRef();
                    }

                    return (
                      <TableCell
                        style={{ flex: 1 }}
                        key={`${rowIndex}-${cellIndex}`}
                        onClick={() => {
                          if (formattedType(types[cellIndex]) === "Document") {
                            handleFileInputClick(rowIndex, cellIndex);
                          }
                        }}
                      >
                        {formattedType(types[cellIndex]) !== "Document" ? (
                          isFrom === "taskSection" ||
                          isFrom === "publishedSurvey" ? (
                            <Textfield
                              value={rowD[cellIndex] && rowD[cellIndex]?.option}
                              ref={checkClickRef}
                              onChange={(event) =>
                                handleRowData(
                                  event,
                                  cellIndex,
                                  rowIndex,
                                  formattedType(types[cellIndex])
                                )
                              }
                              error={
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Email" &&
                                  !validateEmail(rowD[cellIndex]?.option)) ||
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Url" &&
                                  !validURL(rowD[cellIndex]?.option))
                              }
                              helperText={
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Url" &&
                                  !validURL(rowD[cellIndex]?.option) &&
                                  "Please enter a valid URL") ||
                                (isFrom === "taskSection" &&
                                  formattedType(types[cellIndex]) === "Email" &&
                                  !validateEmail(rowD[cellIndex]?.option) &&
                                  "Please enter a valid Email")
                              }
                              variant="outlined"
                              size="small"
                              // fullWidth
                              style={{
                                marginRight: 8,
                              }}
                            />
                          ) : (
                            <Typography
                              style={{
                                width: "100%", // Adjust the width to fill the cell
                              }}
                              variant="body2"
                            >
                              {rowD[cellIndex] &&
                              rowD[cellIndex]?.option !== null ? (
                                rowD[cellIndex]?.option
                              ) : (
                                <span>&#8212;</span>
                              )}
                            </Typography>
                          )
                        ) : (
                          <Box
                            style={{
                              width: "fit-content",
                              cursor: "pointer",
                              // height: "39px",
                            }}
                          >
                            <input
                              name="actUpload"
                              id="contained-button-activityFile"
                              type="file"
                              accept=".pdf"
                              ref={inputRefs.current[key]}
                              onChange={(event) =>
                                handleRowData(
                                  event,
                                  cellIndex,
                                  rowIndex,
                                  formattedType(types[cellIndex])
                                )
                              }
                              style={{
                                display: "none",
                                pointerEvents: event,
                              }}
                            />
                            <div
                              style={{
                                borderBottom:
                                  isFrom === "taskSection" ||
                                  isFrom === "publishedSurvey"
                                    ? "2px solid #ced4da"
                                    : "none",
                                marginTop:
                                  isFrom === "taskSection" ||
                                  isFrom === "publishedSurvey"
                                    ? 6
                                    : 0,
                                width: "100%",
                              }}
                            >
                              {rowD[cellIndex]?.documentUrl !== null ? (
                                rowD[cellIndex]?.documentUrl?.name ? (
                                  shortenFileName(
                                    rowD[cellIndex]?.documentUrl?.name,
                                    isFrom === "taskSection" ? 20 : 30
                                  )
                                ) : (
                                  "Upload file"
                                )
                              ) : isFrom === "taskSection" ||
                                isFrom === "publishedSurvey" ? (
                                "Upload file"
                              ) : (
                                <span>&#8212;</span>
                              )}
                            </div>
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                  {isFrom === "taskSection" && tableHeadersApi?.length > 0 && (
                    <IconButton
                      onClick={() => removeRow(rowIndex)}
                      aria-label="delete"
                      size="small"
                      color="primary"
                      style={{ alignSelf: "center" }}
                      // style={{ marginTop: "24px" }}
                    >
                      <img
                        src={TrashIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="upload"
                      />
                    </IconButton>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {isFrom === "surveyCreation" && (
          <CustomButton
            onClick={addHeader}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "20px" }}
          >
            Add Header
          </CustomButton>
        )}
        {isFrom === "taskSection" && (
          <CustomButton
            onClick={addRow}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "20px" }}
          >
            Add Row
          </CustomButton>
        )}
      </TableContainer>
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableTypeQuestion;
