import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_vendor_iq_data } from "../../../redux/actions/vendorActions/vendorAction";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import VendorIqAddresses from "./VendorIqAddresses";
import VendorIqAlerts from "./VendorIqAlerts";
import VendorIqCyber from "./VendorIqCyber";

const data = {
  organization: {
    name: "BOOTHS GARDEN STUDIOS LIMITED",
    source: "companies_house",
    type: "corporate",
    external_identifier: "07197460",
    active: true,
    status: "Active",
    incorporation_date: "2010-03-22",
    business_type: "Private Limited Company",
    postcode: "NN18 8AN",
    region: "East Midlands",
    country: "England",
    domains: ["boothsgardenstudios.co.uk"],
  },
  names: {
    total: 2,
    data: [
      {
        source: "fca",
        type: "Registered",
        name: "Booths Garden Studios Limited",
        from: "2016-10-10",
        to: null,
        active: true,
      },
      {
        source: "companies_house",
        type: "Registered",
        name: "BOOTHS GARDEN STUDIOS LIMITED",
        from: "2010-03-22",
        to: null,
        active: true,
      },
    ],
  },
  addresses: {
    total: 2,
    data: [
      {
        type: "Registered",
        address_1: "11 ST. MARKS ROAD",
        address_2: "ST. JAMES INDUSTRIAL ESTATE",
        address_3: null,
        address_4: null,
        town: "CORBY",
        county: "NORTHAMPTONSHIRE",
        postcode: "NN18 8AN",
        pobox: null,
        country: "ENGLAND",
        email: null,
        phone: null,
        website: "www.boothsgardenstudios.co.uk",
      },
      {
        type: "Principal Place of Business",
        address_1: "11 St. Marks Road",
        address_2: "St. James Industrial Estate",
        address_3: null,
        address_4: null,
        town: "Corby",
        county: null,
        postcode: "NN18 8AN",
        pobox: null,
        country: "United Kingdom",
        email: "iain@boothsgardenstudios.co.uk",
        phone: null,
        website: "www.boothsgardenstudios.co.uk",
      },
    ],
  },
  cyber: {
    total: 1,
    data: [
      {
        timestamp: "2024-08-21T16:21:26.644316",
        domain: "boothsgardenstudios.co.uk",
        status: "active",
        score: 350,
        priorities: {
          high: 2,
          medium: 4,
          low: 25,
          monitor: 21,
        },
        categories: {
          data_security: {
            score: 300,
            priority: "LOW",
          },
          network_security: {
            score: 243,
            priority: "LOW",
          },
          website_security: {
            score: 100,
            priority: "LOW",
          },
          digital_footprint: {
            score: 100,
            priority: "LOW",
          },
          reputational_risk: {
            score: 462,
            priority: "MEDIUM",
          },
        },
      },
    ],
  },
  alerts: {
    total: 14,
    data: [
      {
        id: "584878fb62bab6698d0cc31c95c04120",
        timestamp: "2024-09-01T21:07:02.168929",
        date: "2024-09-01",
        name: "Overboarding",
        type: "Corporate",
        category: "Company",
        message:
          "There are 2 directors with multiple appointments in this firm. The director with the most commitments holds 3 directorships.",
        description:
          "Mr I. Wetherell currently holds 3 directorships.\nMr A. Booth currently holds 3 directorships.",
        impact: "medium",
        information:
          "Identifies risk of director overcommitment affecting board efficacy by identifying where multiple directorships held be an individual could cause lack of attention or direction.",
      },
      {
        id: "8905b9e4c72795900605d60ce1959a2e",
        timestamp: "2024-09-01T20:40:48.372307",
        date: "2024-03-22",
        name: "Returns Due",
        type: "Corporate",
        category: "Company",
        message:
          "Confirmation statement last filed on 2024-03-22, and due on 2025-04-05 (7 months).",
        description: null,
        impact: "low",
        information:
          "Warning for confirmation statements that will soon be due.",
      },
      {
        id: "844f983fc22cdc714f1221f96f514cde",
        timestamp: "2024-09-02T01:11:54.246272",
        date: "2023-03-31",
        name: "Total Liabilities Growth Rate",
        type: "Financials",
        category: "Company",
        message:
          "Total liabilities growth rate of -8.4%, last financials submitted up to 2023-03-31 with Full Accounts accounts type.",
        description: null,
        impact: "low",
        information:
          "Reviews the company financials total liabilities growth rate to highlight concerning drops in profit.",
      },
      {
        id: "ed79ae750016961d668439283c6342b6",
        timestamp: "2024-09-02T01:11:54.18234",
        date: "2023-03-31",
        name: "Net Worth Growth Rate",
        type: "Financials",
        category: "Company",
        message:
          "Net worth growth rate of 2.7%, last financials submitted up to 2023-03-31 with Full Accounts accounts type.",
        description: null,
        impact: "low",
        information:
          "Reviews the company financials net worth growth rate to highlight concerning drops in profit.",
      },
      {
        id: "eac515d1fb8156009024a466a8824d03",
        timestamp: "2024-09-02T01:11:53.935175",
        date: "2023-03-31",
        name: "Total Assets Growth Rate",
        type: "Financials",
        category: "Company",
        message:
          "Total asset growth rate of -1.0%, last financials submitted up to 2023-03-31 with Full Accounts accounts type.",
        description: null,
        impact: "medium",
        information:
          "Reviews the company financials total assets growth rate to highlight concerning drops in profit.",
      },
      {
        id: "2154a562a378c8c444b90f8c8e1c1bb4",
        timestamp: "2024-09-02T01:11:53.631496",
        date: "2023-03-31",
        name: "Cash in Bank Growth Rate",
        type: "Financials",
        category: "Company",
        message:
          "Cash in bank growth rate of -19.6%, last financials submitted up to 2023-03-31 with Full Accounts accounts type.",
        description: null,
        impact: "high",
        information:
          "Reviews the company financials cash in bank growth rate to highlight concerning drops in profit.",
      },
      {
        id: "e314f93399735c0b6179b4228fa45e2a",
        timestamp: "2024-09-01T20:40:48.813449",
        date: "2023-03-31",
        name: "Accounts Due",
        type: "Corporate",
        category: "Financials",
        message:
          "Accounts last made up to 2023-03-31, due on 2024-12-31 (3 months).",
        description: null,
        impact: "low",
        information: "Warning for accounts that will soon be due.",
      },
      {
        id: "5c2e077eb991c8321872a79f7d3081fc",
        timestamp: "2024-09-01T21:05:28.691486",
        date: "2016-07-01",
        name: "International Control Review (Non-UK)",
        type: "Corporate",
        category: "Company",
        message:
          "Company has 2 active persons of significant control either registered, residing or corresponding from overseas, last changed on 2016-07-01.",
        description:
          "**Persons of significant control**\n\n * Mr Alexander Booth residing in United Kingdom corresponding from England, notified on 2016-07-01 - Ownership of shares - More than 25% but not more than 50%\n * Mr Iain Wetherell residing in United Kingdom corresponding from England, notified on 2016-07-01 - Ownership of shares - More than 25% but not more than 50%",
        impact: "low",
        information:
          "Evaluates non-UK significant control for compliance and strategy impact.",
      },
      {
        id: "b2b358eb66008444295c2a1916a759ec",
        timestamp: "2024-09-01T21:05:28.64009",
        date: "2016-07-01",
        name: "Significant Control",
        type: "Corporate",
        category: "Company",
        message:
          "2 active (Individual Person with Significant Control)last changed on 2016-07-01",
        description:
          "**Persons of significant control**\n\n * Mr Alexander Booth, Individual Person with Significant Control, notified on 2016-07-01 - Ownership of shares - More than 25% but not more than 50%\n * Mr Iain Wetherell, Individual Person with Significant Control, notified on 2016-07-01 - Ownership of shares - More than 25% but not more than 50%",
        impact: "low",
        information:
          "Detects changes in major ownership affecting control dynamics.",
      },
      {
        id: "a27db3568b8dc48159ccc3838bad2fe2",
        timestamp: "2024-09-01T21:10:21.048844",
        date: "2015-12-31",
        name: "Directorships",
        type: "Corporate",
        category: "Indiviudal",
        message:
          "Company has 3 active directors (1 resignations), last changed on 2015-12-31.",
        description:
          "**Directors**\n\n * Mr B.J. Connellan, appointed on 2010-03-22, ceased on 2015-12-31\n * Mr A. Booth, appointed on 2010-03-22\n * Mr A. Booth, appointed on 2010-03-22\n * Mr I. Wetherell, appointed on 2010-03-22",
        impact: "low",
        information:
          "Monitors director changes for governance and strategic impacts.",
      },
      {
        id: "94072201c5b5f7d4624db82e9d1e5ded",
        timestamp: "2024-09-02T02:54:50.827475",
        date: "2010-03-22",
        name: "Corporate Name Changes",
        type: "Corporate",
        category: "Company",
        message:
          "No name changes, registered as BOOTHS GARDEN STUDIOS LIMITED on 2010-03-22.",
        description: null,
        impact: "low",
        information:
          "Signals strategic repositioning or rebranding through name changes.",
      },
      {
        id: "0923d1dd59a4a3806bcb24e4a13f9e20",
        timestamp: "2024-09-01T20:40:48.801906",
        date: "2010-03-22",
        name: "Registered On",
        type: "Corporate",
        category: "Company",
        message: "Age of company is 14 years 5 months 10 days.",
        description: null,
        impact: "low",
        information:
          "Highlights companies that have only been recently registered.",
      },
      {
        id: "319ee00b09cbec26612c9d585874bf3b",
        timestamp: "2024-09-01T20:40:48.536579",
        date: "2010-03-22",
        name: "Registration Status",
        type: "Corporate",
        category: "Company",
        message: "Status of Active, incorporated since 2010-03-22.",
        description: null,
        impact: "low",
        information:
          "Verifies corporate company has an active status with Companies House, and is not in the process of closing down.",
      },
      {
        id: "445223f3ca80efd01bfcef6338a7b8e3",
        timestamp: "2024-09-01T20:40:47.725136",
        date: "2010-03-22",
        name: "Registered",
        type: "Corporate",
        category: "Company",
        message: "Company is Active, since 2010-03-22.",
        description: null,
        impact: "low",
        information:
          "Verifies whether corporate company is active or is now closed.",
      },
    ],
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },

  fontSize: {
    fontSize: "16px",
  },
  fontBold: {
    fontWeight: 600,
  },
}));

function VendorIqTab() {
  const { vendorBasicDetails, vendorIqData } = useSelector(
    (state) => state.brmData
  );
  const storedVendorIqId = localStorage.getItem("vendoriq_id");

  console.log(data);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      gql_get_vendor_iq_data(
        vendorBasicDetails?.vendoriq_id || storedVendorIqId
      )
    );
  }, []);
  return (
    <Grid>
      {vendorIqData?.organization && (
        <>
          <BlueBackgroundCard heading={"Organization"} />
          <Paper style={{ padding: "10px", marginBottom: "20px" }}>
            <Typography
              style={{ fontSize: "20px", fontWeight: "600", color: "#3374B9" }}
            >
              {vendorIqData?.organization?.name}
            </Typography>

            <Grid style={{ marginTop: "10px" }}>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Type:</span>
                {"  "}
                {`${vendorIqData?.organization?.type[0]?.toUpperCase()}${data?.organization?.type.slice(
                  1
                )}`}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Incorporation Date:</span>
                {"  "}
                {vendorIqData?.organization?.incorporation_date}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Business Type:</span>
                {"  "}
                {vendorIqData?.organization?.business_type}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Country:</span>
                {"  "}
                {vendorIqData?.organization?.country}
              </Typography>
              <Typography className={classes.fontSize}>
                <span className={classes.fontBold}> Status:</span>
                {"  "}
                {vendorIqData?.organization?.status}
              </Typography>
            </Grid>
          </Paper>
        </>
      )}

      {vendorIqData?.addresses.data.length > 0 && (
        <>
          <BlueBackgroundCard heading={"Addresses"} />
          <VendorIqAddresses classes={classes} data={vendorIqData} />
        </>
      )}

      {vendorIqData?.cyber.data.length > 0 && (
        <>
          <BlueBackgroundCard heading={"Cyber"} />
          <VendorIqCyber classes={classes} data={vendorIqData} />
        </>
      )}
      {vendorIqData?.alerts.data.length > 0 && (
        <>
          <BlueBackgroundCard heading={"Alerts"} />
          <VendorIqAlerts classes={classes} data={vendorIqData} />
        </>
      )}
    </Grid>
  );
}

export default VendorIqTab;
