import {
  radioOption,
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
  QUESTION_TYPE_TEXT_FIELD,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT,
  QUESTION_TYPE_SINGLE_CHOICE,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC,
  QUESTION_TYPE_SINGLE_CHOICE_DOC,
  QUESTION_TYPE_CURRENCY,
  QUESTION_TYPE_CURRENCY_FIELD,
  QUESTION_TYPE_NUMERIC_FIELD,
  QUESTION_TYPE_TEXT_DOCUMENT,
  CUSTOM_QUESTION_TYPE_SINGLE_CHOICE,
  QUESTION_TYPE_EMAIL_FIELD,
  QUESTION_TYPE_URL_FIELD,
  QUESTION_TYPE_NUMBER_DOCUMENT,
  QUESTION_TYPE_TABLE,
  QUESTION_TYPE_DROPDOWN,
  QUESTION_TYPE_COUNTRY,
} from "./QuestionData";
import CommentBox from "./questionTypes/CommentBox";
import CountryField from "./questionTypes/CountryField";
import CurrencyField from "./questionTypes/CurrencyField";
import DatePicker from "./questionTypes/DatePicker";
import DocumentUpload from "./questionTypes/DocumentUpload";
import Dropdown from "./questionTypes/Dropdown";
import EmailQuestion from "./questionTypes/EmailQuestion";
import Location from "./questionTypes/Location";
import MultiChoice from "./questionTypes/MultiChoice";
import NumberField from "./questionTypes/NumberField";
import NumberFieldWithUpload from "./questionTypes/NumberFieldWithUpload";
import Scale from "./questionTypes/Scale";
import SingleChoiceWithMultiFeatures from "./questionTypes/SingleChoiceWithMultiFeatures";
import TableTypeQuestion from "./questionTypes/TableTypeQuestion";
import TextFieldQue from "./questionTypes/TextFieldQue";
import TextFieldWithUpload from "./questionTypes/TextFieldWithUpload";
import UrlField from "./questionTypes/UrlField";

function QuestionBody({
  questionId,
  questionType,
  answers,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  handleQuesAnswer,
  isFrom,
  event,
  variant,
  comment,
  usingQues,
  setTableHeader,
  tableHeadersApi,
  linkageAnswer,
  sectionTitle,
  questionTitle,
}) {
  switch (questionType) {
    case QUESTION_TYPE_COMMENT_BOX:
      return (
        <CommentBox
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case QUESTION_TYPE_DOCUMENT_UPLOAD:
      return (
        <DocumentUpload
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case QUESTION_TYPE_MULTICHOICE:
      return (
        <MultiChoice
          questionId={questionId}
          option={answers}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          answers={answers}
          submittedAnswer={submittedAnswer}
          removeEditOption={removeEditOption}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          isFrom={isFrom}
          event={event}
          comment={comment}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
        />
      );
    case DATE_PICKER_SINGLE_DATE:
      return (
        <DatePicker
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );
    case DATE_PICKER_RANGE_DATE:
      return (
        <DatePicker
          hasRange={true}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_SCALE:
    case SUB_QUESTION_0_10:
    case SUB_QUESTION_LIKERT:
    case SUB_QUESTION_SATISFACTION:
    case SUB_QUESTION_QUALITY:
    case SUB_QUESTION_FREQUENCY:
    case SUB_QUESTION_PERFORMANCE:
    case SUB_QUESTION_IMPORTANCE:
    case SUB_QUESTION_EMOTIONS:
      return (
        <Scale
          questionId={questionId}
          questionType={questionType}
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          handleQuesAnswer={handleQuesAnswer}
          isFrom={isFrom}
          event={event}
          variant={variant}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_LOCATION:
      return (
        <Location
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TEXT_FIELD:
      return (
        <TextFieldQue
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    // Satisfaction

    case QUESTION_TYPE_SINGLE_CHOICE:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC:
    case QUESTION_TYPE_SINGLE_CHOICE_DOC:
      return (
        <SingleChoiceWithMultiFeatures
          questionId={questionId}
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
          questionTitle={questionTitle}
          sectionTitle={sectionTitle}
        />
      );

    case QUESTION_TYPE_CURRENCY_FIELD:
      return (
        <CurrencyField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_NUMERIC_FIELD:
      return (
        <NumberField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TEXT_DOCUMENT:
      return (
        <TextFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_EMAIL_FIELD:
      return (
        <EmailQuestion
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_URL_FIELD:
      return (
        <UrlField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_NUMBER_DOCUMENT:
      return (
        <NumberFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_TABLE:
      return (
        <TableTypeQuestion
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          setTableHeader={setTableHeader}
          tableHeadersApi={tableHeadersApi}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_DROPDOWN:
      return (
        <Dropdown
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          usingQues={usingQues}
          linkageAnswer={linkageAnswer}
        />
      );

    case QUESTION_TYPE_COUNTRY:
      return (
        <CountryField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          linkageAnswer={linkageAnswer}
        />
      );

    // Add more cases as needed
    default:
      return <></>;
  }
}

export default QuestionBody;
